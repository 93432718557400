import { SEARCH_TAG_LIST } from 'api/mock/community';
import BoardItem from 'components/boardItem';
import Section from 'components/common/section';
import React from 'react';
import styled from 'styled-components';
import { FilterSearch, Pagination } from 'styles';
import APIService from 'api/index';
import bg_search from 'assets/bg/search.png';
import DefaultLayout from './layout/Default';
import { useInfiniteScroll } from 'hook/useInfiniteScroll';

const apiService = new APIService();
const CommunityView: React.FC<any> = () => {
  const [query, setQuery] = React.useState({ filter: 0, text: '' });
  const [community, setCommunity] = React.useState<any>();
  const [channelTag, setChannelTag] = React.useState<any[]>([]);

  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const getCommunity = async ({ communityChannelUid, searchKey, searchValue, reset }: any) => {
    setLoading(true);
    const communityData = await apiService.getCommunityChannelList({ communityChannelUid, searchKey, searchValue });

    if (community?.list.length > 0 && !reset) {
      community.list.at(-1).uid !== communityData.list.at(-1).uid && setCommunity({ ...community, list: [...community.list, ...communityData.list] });
    } else {
      setCommunity(communityData);
    }

    setLoading(false);
  };

  const getChannelTag = async () => {
    const channelTag = await apiService.getCommunityChannelTag();

    const valueTemp: any[] = [];

    channelTag.list.forEach((item: any) => {
      valueTemp.push({ value: item.uid, label: `#${item.name}` });
    });

    setChannelTag(valueTemp);
  };

  React.useEffect(() => {
    getCommunity({ searchValue: query.text, reset: true });

    if (channelTag.length === 0) {
      getChannelTag();
    }
  }, [query]);

  React.useEffect(() => {
    if (isScroll && community?.list.length > 0) getCommunity({ communityChannelUid: community.list.at(-1).uid, searchValue: query.text });
  }, [isScroll]);

  return (
    <DefaultLayout>
      <Wrap>
        <StyledSearch>
          <h2>Find your community</h2>
          <FilterSearch className="search" placeholder={'Search by name'} query={query} setQuery={setQuery} />
          {channelTag.map((item: any, idx: number) => (
            <a className={'link-tag'} key={idx} href="#">
              {item.label}
            </a>
          ))}
        </StyledSearch>

        <Section title={query.text.length > 0 ? `${community?.totalCount} communities for ${query.text}` : 'Hot Communities'} className="wrap-board-list">
          {/* {query.text.length > 0 && (
            <Filter
              tagList={tagList}
              setTagList={setTagList}
              categories={categories}
              setCategories={setCategories}
              saleType={saleType}
              setSaleType={setSaleType}
              filterList={{ price: true, category: true, sale: true }}
            />
          )} */}

          {community?.list.map((item: any, idx: number) => (
            <BoardItem key={idx} {...item} />
          ))}
          <div ref={senserRef} className={`observer ${!isLoading ? 'completed' : ''}`} />
        </Section>

        {/* <Pagination total={community?.totalCount} /> */}
      </Wrap>
    </DefaultLayout>
  );
};

export default CommunityView;

const Wrap = styled.div`
  padding-bottom: 30px;

  .search {
    margin: 22px 0 16px 0;
    & > div {
      border-radius: 8px;
    }
  }

  .wrap-board-list {
    padding: 0;
    margin-bottom: 30px;

    & > h2 {
      padding: 0 18px;
      margin-bottom: 0;
    }
  }

  .nftmoa-filter-container {
    margin-top: 18px;

    & > div {
      border-top: 1px solid #eaeaea;
      background-color: #f9f9f9;
    }
  }
`;

const Header = styled.dl`
  text-align: left;
  margin-bottom: 28px;

  dt {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 5px;
    color: #000000;
  }

  dd {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #6f6f6f;
  }
`;

const FixedHeader = styled.div`
  background: transparent;
  position: fixed;
  top: 5px;
  right: 20px;
  z-index: 99;
`;

const StyledChannel = styled.div`
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;

  ul {
    display: flex;
    flex: 1;
    height: 39px;
    border-right: 1px solid #eaeaea;
    margin-right: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 17px;

    & > li {
      img {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }

  svg.active {
    rect {
      fill: #000;
      fill-opacity: 1;
    }
  }
`;

const StyledChannelCtg = styled.div`
  padding: 20px;
`;

const StyledForm = styled.div`
  padding: 0 20px;
  margin-bottom: 36px;

  .link-notice {
    padding: 5px 20px;
  }

  .avatar-uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 20px;

    .ant-upload.ant-upload-select-picture-card {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;

      &:hover {
        border: none;
      }
    }
  }
`;

const StyledSearch = styled.div`
  margin-bottom: 20px;
  position: relative;
  min-height: 170px;
  padding: 0 20px;
  background: url(${bg_search}) no-repeat center center;
  background-size: cover;

  h2 {
    padding-top: 22px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
  }

  .link-tag {
    display: inline-block;
    padding-right: 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 26px;
    color: #fff;
  }
`;

const ChannelItem = styled.li<{ msgCount?: number }>`
  position: relative;

  &::after {
    content: '${p => p.msgCount}';
    position: absolute;
    right: 0;
    top: 0;
    background: #000;
    border-radius: 50%;
    padding: 4px;
    font-weight: 700;
    font-size: 10px;
    color: #ffffff;

    ${p => !p.msgCount && `display: none;`}
  }
`;

const HexagonLabel = styled.div`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);

  .hexagon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    padding: 4px 10px 5px 5px;
    background-color: #f900d4;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: -0.02em;
    white-space: wrap;
    text-align: left;

    &::after {
      content: '';
      position: absolute;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      left: 100%;
      border-left: 8px solid #f900d4;
    }
  }
`;
