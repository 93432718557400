import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import SubHeader from 'components/Header/SubHeader';

import { MoaLogo, Tab, View, Text, Button, PublicKey, Price } from 'styles';
import BackgroundUI from 'ui/background/Background';
import { ReactComponent as HeartIcon } from 'assets/icon/heart-lined.svg';
import { ReactComponent as SirenIcon } from 'assets/svg/siren-lined.svg';

import { cryptoMarket, mappingNftTypeIcon, mappingNftTypeName, setDigit } from 'utils';
import NftTypeComponent from 'components/v2/nft-card-item/components/nft-type/NftType';
import IconButtonUI from 'ui/icon-button/IconButton';
import ButtonUI from 'ui/button/Button';

import InfoComponent from 'views/market/detail/components/Info';
import Input from 'ui/input/Input';
import PriceItemComponent from '../components/price-item/PriceItem';
import AuctinoItemComponent from '../components/auction-item/AuctinoItem';
import APIService from 'api';

import { useSelector, useDispatch } from 'react-redux';
const apiService = new APIService();
const NftItemSellPage: React.FC<any> = () => {
  const { profile } = useSelector((store: IStore) => store.System);
  const navigate = useNavigate();
  const { uid } = useParams();
  const [nftDetail, setNftDetail] = React.useState<any>();
  const [quantity, setQuantity] = React.useState<number | string>('');

  const getNftDetail = async () => {
    const resultNftDetail = await apiService.getMarketItemCreatedDetail({ uid });
    setNftDetail(resultNftDetail);
  };

  const [activeTab, setActiveTab] = React.useState<'Price' | 'Auction'>('Price');
  const reg = /^[0-9]*$/gi;

  const changeQuantity = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (reg.test(value)) {
      const numberChange = Number(value);
      setQuantity(numberChange || '');
    } else {
      alert('only number please');
    }
  };

  React.useEffect(() => {
    getNftDetail();
  }, [uid]);

  const regexSpace = / /gi;

  return (
    <StyledWrap>
      {nftDetail && (
        <>
          <SubHeader title="Sell" />

          <View direction="column" block padding="16px 20">
            <Text size="xl" weight="semi-bold">
              List item for sale
            </Text>

            <View direction="column" spacing={16} block mt={8}>
              <BackgroundUI h={365} radius={6} bgUrl={nftDetail.imageUrl} />

              <View direction="column" mb={16} block spacing={8}>
                <NftTypeComponent type={nftDetail.categoryName.toLocaleLowerCase().replace(regexSpace, '')} />
                <Text eclipse={1} size="3xl" weight="bold">
                  {nftDetail.name}
                </Text>
              </View>
            </View>
            <Input block label="Quntity" value={`${quantity}`} placeholder="Please enter a quantity" onChange={changeQuantity} />
          </View>

          <View direction="column" bgColor="#F9F9F9" pt={20} border="1px solid #E6E8EC" borderPos="top">
            <Tab active={activeTab} shape="bar" block>
              <Tab.Item
                text={
                  <View align="center" spacing={11} block justify="center">
                    <img style={{ width: 12, height: 20, objectFit: 'cover' }} src={cryptoMarket(1)} />
                    <Text size="xs" weight="bold">
                      Fixed Price
                    </Text>
                  </View>
                }
                value="Price"
                onClick={() => setActiveTab('Price')}
              />
              <Tab.Item
                text={
                  <View align="center" spacing={11} block justify="center">
                    <img style={{ width: 12, height: 20, objectFit: 'cover' }} src={cryptoMarket(1)} />
                    <Text size="xs" weight="bold">
                      Timed Auction
                    </Text>
                  </View>
                }
                value="Auction"
                onClick={() => setActiveTab('Auction')}
              />
            </Tab>

            <View direction="column" block padding="32px 0" bgColor="#F9F9F9">
              {activeTab === 'Price' && <PriceItemComponent {...nftDetail} quantity={quantity} />}
              {activeTab === 'Auction' && <AuctinoItemComponent {...nftDetail} quantity={quantity} />}
            </View>
          </View>
        </>
      )}
    </StyledWrap>
  );
};

export default NftItemSellPage;

const StyledWrap = styled.div`
  background-color: #f5f5f5;
`;
