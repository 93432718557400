import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'prefix'> {
  w?: number;
  h?: number;
  suffix?: React.ReactNode;
  border?: string;
  bgColor?: string;
  full?: boolean;
  radius?: number;
  color?: 'black' | 'borderBlack' | 'blue' | 'borderGray';
  fontColor?: string;
  fontSize?: number;
  fontWeight?: string | number;
  children?: React.ReactNode;
  shape?: 'round' | 'rect' | 'circle';
  disabled?: boolean;
  maxW?: number;
  block?: boolean;
}

const ButtonUI: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default ButtonUI;

ButtonUI.defaultProps = {
  h: 52,
  full: false,
  shape: 'round',
  fontSize: 16,
  fontWeight: 800
};

const colorBlack = css`
  background-color: #000;
  color: #fff;
`;

const colorBorderBlack = css`
  background-color: #fff;
  color: #000;
  border: 3px solid #000000;
`;

const colorBlue = css`
  background-color: #0075ff;
  color: #fff;
  border: 1px solid #cbcbcb;
`;

const colorBorderGray = css`
  border: 1px solid #eae9e9;
  color: #000000;
`;

const StyledButton = styled.button<ButtonProps>`
  box-sizing: border-box;
  ${p => (p.full || p.block ? 'width: 100%' : 'width: auto')};
  height: ${p => p.h}px;
  border-radius: ${p => (p.radius ? p.radius : 0)}px;
  padding: 0 14px;
  ${p => p.bgColor && `background-color: ${p.bgColor}`};
  ${p => p.fontColor && `color: ${p.fontColor}`};
  ${p => p.fontWeight && `font-weight: ${p.fontWeight}`};
  ${p => p.fontSize && `font-size: ${p.fontSize}px`};

  ${p => p.shape === 'round' && 'border-radius: 10px'};
  ${p => p.shape === 'rect' && 'border-radius: 0'};
  ${p => p.shape === 'circle' && 'border-radius: 999px'};

  ${p => p.color === 'black' && colorBlack};
  ${p => p.color === 'borderBlack' && colorBorderBlack};
  ${p => p.color === 'blue' && colorBlue};
  ${p => p.color === 'borderGray' && colorBorderGray};

  ${p => p.border && `border: ${p.border}`};
  ${p => p.maxW && `width:100%; max-width: ${p.maxW}px`};

  &:disabled {
    opacity: 0.15;
  }
`;
