import React from 'react';
import styled from 'styled-components';
import { View, Text } from 'styles';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-more.svg';
import { useNavigate } from 'react-router-dom';

const MenuItemComponent: React.FC<any> = ({ id, sortNumber, label, image, router, setOpenMenu, onClick }) => {
  const navigate = useNavigate();
  const moveToPage = () => {
    if (!router) return;
    navigate(router);
    setOpenMenu(false);
  };
  return (
    <StyledMenuItem block align="center" justify="space-between" onClick={onClick || moveToPage}>
      <View spacing={11} align="center">
        {image}
        <Text size="md" weight="bold">
          {label}
        </Text>
      </View>
      <ArrowIcon />
    </StyledMenuItem>
  );
};

export default MenuItemComponent;

const StyledMenuItem = styled(View)``;
