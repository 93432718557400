import React from 'react';
import styled, { css } from 'styled-components';
import IconButtonUI from 'ui/icon-button/IconButton';
import useUpload from './useUpload';

export type PreviewType = 'edit' | 'upload';

export interface UploadProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  previewIconType?: PreviewType;
  spacing?: number;
  label?: string;
  accept?: string;
  maxSize?: number;
  fileChange: (file: File | null) => void;
  imageUrl?: string;
  isDefaultImage?: boolean;
  defaultImage?: any;
  userName?: string;
  isLoading?: boolean;
  w?: number;
  h?: number;
  block?: boolean;
  minh?: number;
  radius?: boolean;
  required?: boolean;
  infoNode?: React.ReactNode;
  fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}

export type StyledUploadProps = Pick<UploadProps, 'minh' | 'w' | 'h' | 'radius' | 'previewIconType' | 'block' | 'fit' | 'defaultImage'>;

const UploadUI: React.FC<UploadProps> = ({
  previewIconType,
  minh,
  spacing,
  accept,
  maxSize,
  imageUrl,
  fileChange,
  defaultImage,
  isDefaultImage,
  isLoading,
  userName,
  required,
  infoNode,
  label,
  w,
  h,
  radius,
  ...props
}) => {
  const fileRef = React.useRef<HTMLInputElement>(null);
  const { previewImg, fileType, renderDefaultImage, onChangeImage, hasUploadButton, renderIcon } = useUpload({
    fileChange,
    maxSize,
    fileRef,
    accept,
    imageUrl,
    isDefaultImage,
    previewIconType,
    defaultImage
  });

  return (
    <StyledWrap>
      {label && <StyledLabel required={required}>{label}</StyledLabel>}
      <input ref={fileRef} type="file" accept={accept} hidden onChange={onChangeImage} />

      {isLoading ? (
        <div>loading...</div>
      ) : (
        <StyledUpload {...props} w={w} h={h} minh={minh} radius={radius} previewIconType={previewIconType} defaultImage={defaultImage}>
          {previewImg && <div className="img-preview">{fileType.includes('image') ? <img src={previewImg} /> : <video src={previewImg} />}</div>}

          <div className="upload-container">
            {defaultImage === 'profile' && !previewImg && renderDefaultImage(defaultImage)}
            {defaultImage === 'bg' && !previewImg && renderDefaultImage(defaultImage)}
            <div className="icon-upload">{renderIcon()}</div>
          </div>
        </StyledUpload>
      )}
    </StyledWrap>
  );
};

export default UploadUI;

UploadUI.defaultProps = {
  fit: 'cover'
};

const styleEdit = css`
  background-color: #eaeaea;

  .img-preview {
    width: 100%;
    height: 100%;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.2;
      z-index: 3;
    }

    video {
      width: 100%;
    }
  }
`;

const styleProfile = css`
  width: 72px;
  & > img {
    object-fit: cover;
  }
`;

const StyledWrap = styled.div`
  width: 100%;
`;

const StyledLabel = styled.label<{ required?: boolean; infoNode?: boolean }>`
  display: inline-block;
  margin-bottom: ${p => (p.infoNode ? 2 : 8)}px;
  color: #000 !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  ${p =>
    p.required &&
    css`
      &::after {
        content: ' *';
        color: #ab3fff;
      }
    `}
`;

const StyledUpload = styled.div<StyledUploadProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  overflow: hidden;

  ${p => p.w && `width:${p.w}px`};
  ${p => p.h && `height:${p.h}px`};
  ${p => p.block && 'width: 100% !important'};
  ${p => p.minh && `min-height:${p.minh}px`};
  ${p => p.radius && `border-radius:10px`};
  ${p => p.previewIconType === 'edit' && styleEdit};

  .img-preview {
    ${p => p.defaultImage === 'profile' && styleProfile};

    & > img {
      ${p => p.fit === 'contain' && 'object-fit: contain'};
      ${p => p.fit === 'cover' && 'object-fit: cover'};
      ${p => p.fit === 'fill' && 'object-fit: fill'};
      ${p => p.fit === 'scale-down' && 'object-fit: scale-down'};
      ${p => p.fit === 'none' && 'object-fit: none'};
    }

    video {
      width: 100%;
    }
  }

  .upload-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .img-default {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .img-default-profile {
    & .avatar {
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.2;
        z-index: 3;
      }
    }
  }

  .icon-upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }
`;
