import SubHeader from 'components/Header/SubHeader';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MsgIcon } from 'assets/svg/speech-bubble.svg';
import { ReactComponent as MenuIcon } from 'assets/svg/bar-3.svg';

import { MoaLogo, Tab, View, Text } from 'styles';
import DetailItemComponent from 'components/detailItem';
import { ITEM_DETAIL } from 'api/mock/item';

import InfoComponent from './components/Info';
import ScrollWrap from 'components/common/slideWrap';
import FullItemComponent from 'components/nftsItem/fullItem';
import googleIcon from 'assets/icon/google.png';
import Section from 'components/common/section';
import APIService from 'api';
const apiService = new APIService();

const ItemDetailView: React.FC<any> = () => {
  const { uid } = useParams();
  const [activeTab, setActiveTab] = React.useState(1);
  const [nftDetail, setNftDetail] = React.useState<any>();

  const getNftDetail = async () => {
    const resultNftDetail = await apiService.getMarketView({ uid });
    console.log(resultNftDetail, 'resultNftDetail');
    setNftDetail(resultNftDetail);
  };

  const HeaderSuffix = () => {
    return (
      <View spacing={17} align="center">
        <MsgIcon />
        <MenuIcon />
      </View>
    );
  };

  React.useEffect(() => {
    getNftDetail();
  }, []);

  const getGoogle = async () => {
    (function () {
      const cx = '647afc5dcdf884aac';
      const gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx + '&gsc.q=' + `${nftDetail.name} %26 NFT`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode?.insertBefore(gcse, s);
    })();

    setTimeout(() => {
      if (window.location.href.indexOf('gsc.tab') > 0 && window.location.href.indexOf('gsc.q') < 0) {
        window.location.href = window.location.href + '&gsc.q=' + `${nftDetail.name} %26 NFT`;
      }
    }, 1000);
  };

  React.useEffect(() => {
    if (activeTab === 2 && nftDetail) {
      getGoogle();
    }
  }, [activeTab]);

  return (
    <Wrap>
      {nftDetail && (
        <>
          <DetailItemComponent {...nftDetail} {...ITEM_DETAIL} />
          <StyledTab>
            <Tab active={activeTab}>
              <Tab.Item value={1} text={'Information'} onClick={() => setActiveTab(1)} />
              <Tab.Item value={2} imgText={googleIcon} imgSize={'m'} onClick={() => setActiveTab(2)} />
              <Tab.Item value={3} text={'Community'} onClick={() => setActiveTab(3)} />
            </Tab>
          </StyledTab>

          {activeTab === 1 && <InfoComponent {...ITEM_DETAIL?.information} {...nftDetail} desc={nftDetail.description} />}
          {activeTab === 2 && (
            <Section className="wrap-google">
              <div className="gcse-searchresults-only"></div>
            </Section>
          )}
          <ScrollWrap className={'wrap-related'} title="Related" bg="#F5F5F5">
            {ITEM_DETAIL.relatedData.map((item: any, idx: number) => (
              <FullItemComponent className={'item-related'} key={idx} {...item} />
            ))}
          </ScrollWrap>
        </>
      )}
    </Wrap>
  );
};

export default ItemDetailView;

const Wrap = styled.div`
  .wrap-related {
    .item-related {
      min-width: 291px;

      & + div {
        margin-left: 24px;
      }
    }
  }
`;

const StyledTab = styled.div`
  margin-top: 28px;
  padding-bottom: 30px;
`;
