import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CancelIcon } from 'assets/svg/deleteCircle.svg';
import { useNavigate } from 'react-router-dom';

import Login from 'components/modal/signIn';

const SignInView: React.FC<any> = () => {
  const navigate = useNavigate();
  return (
    <Wrap>
      <CancelIcon className={'cancel'} onClick={() => navigate(-1)} />
      <Login />
    </Wrap>
  );
};

export default SignInView;

const Wrap = styled.div`
  position: absolute;
  top: -50px;
  left: 0;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;

  .signup {
    display: block;
  }
  .cancel {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  > div > div {
    padding: 20px 0 0;
  }
`;
