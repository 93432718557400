export const nftMoaBest = [
  {
    title: 'THE WORLD OF TENNESSEE LOVELESS',
    desc: 'Tennessee Loveless is an American Pop-Op and Urban Folk artist based out of Chicago, IL',
    ad: 'https://www.loveless.city/'
  },
  {
    title: 'Love Addicted Girls',
    desc: 'The ‘Love Addicted Girls with BIGLOVE’ (LAG) Collection is a generative NFT collection',
    ad: 'https://lag.soudan-nft.xyz/'
  },
  {
    title: 'SHAPE OF LOVE by Michael Benisty',
    desc: 'The pursuit of Love is perhaps the most common of human desires.',
    ad: 'https://www.niftygateway.com/collections/shapes-of-love'
  }
];

export const nftMoaPower = [
  {
    photo: 'https://user-images.githubusercontent.com/6345000/192322521-660c928c-1206-4ca6-b691-2af003597d62.png',
    title: 'AI LOVES, HUMANS LOST | PVNKR',
    desc: 'This series is dedicated to an ai artists who submitted ai artwork to a Colorado(former US) State Fair competition in the summe',
    url: 'https://knownorigin.io/gallery/19353000-ai-loves-humans-lost-pvnkr',
    ad: 'knownorigin.io'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/192322535-4b135468-ff42-46f2-a43e-91e8ca6aa1db.png',
    title: 'Good buy, My Love',
    desc: 'Adventures of Lady in Red',
    url: 'https://knownorigin.io/gallery/18664000-good-buy-my-love?query_id=bd195eeed7e91bc59b66cd8495349af8&index=mainnet-marketplace-index_filters_createdTimestamp_desc&position=4',
    ad: 'knownorigin.io'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/192322548-5b136a7a-ba43-4b09-b6b5-0b49c7d4cc7a.png',
    title: 'Peace & Love',
    desc: 'This series is dedicated to an ai artists who submitted ai artwork to a Colorado(former US) State Fair competition in the summe',
    url: 'https://wax.atomichub.io/market/sale/90674684',
    ad: 'wax.atomichub.io'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/192322563-d1a9f133-dd4f-4c74-ab65-8b0a46c195d4.png',
    title: 'Emotion. Happy - Lurking in nature',
    desc: 'Some of the very best NFT artists have been brought together to showcase the kaleidoscope of emotional states that make up the human condition.',
    url: 'https://makersplace.com/lurklovesyou/emotion-happy-lurking-in-nature-4-of-5-78882/',
    ad: 'makersplace.com'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/192322572-976dd6d4-7c1c-456c-937d-73cc564b1a3b.png',
    title: 'UNREQUITED LOVE',
    desc: 'Grasping hope in shades of colour, she watches at the stair; her gaze the look of unrequited love. TAGS',
    url: 'https://superrare.com/artwork-v2/unrequited-love-4628',
    ad: 'superrare.com'
  }
];

export const nftMedia = [
  {
    photo: 'https://user-images.githubusercontent.com/6345000/192326072-e52c2b16-9f06-4431-b48d-c729b4667735.png',
    title: 'Whatever Happened To NFTs?',
    desc: 'Logically Answered',
    url: 'https://www.youtube.com/watch?v=g1_fgQak_HU'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/192326096-b227658c-ea5d-4d45-8119-742c9f45df59.png',
    title: 'Logan Paul Fires Co-Host, Loses Millions On NFTs (Shocking) - IMPAULSIVE EP. 326',
    desc: 'IMPAULSIVE',
    url: 'https://www.youtube.com/watch?v=VYjHTqBHhB0'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/195075859-0f096628-05d1-4777-9738-2b6734a717d8.png',
    title: 'Gary Vee Explains How He Made $90 Million on NFTs & Why They’ll Change the World',
    desc: 'FULL SEND PODCAST',
    url: 'https://www.youtube.com/watch?v=VYjHTqBHhB0'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/195075893-93236739-8d97-48fd-bfe7-13bf91348375.png',
    title: 'Ragnarok Labyrinth NFT – First Play-To-Earn Mobile Game Based On Ragnarok Online IP',
    desc: 'Gravity Game Link',
    url: 'https://www.youtube.com/watch?v=uKYAoOISl4Y'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/195075906-0ee8223c-c417-43b8-a575-537980681431.png',
    title: 'NFTs For Beginners in 45 minutes',
    desc: 'GaryVee',
    url: 'https://www.youtube.com/watch?v=4se4_ZqkcKw'
  },
  {
    photo: 'https://user-images.githubusercontent.com/6345000/195075938-3d639c85-2e47-452d-bf6c-6e3b0b26ca99.png',
    title: `I Built The World's Largest NFT!`,
    desc: 'Matthew Beem',
    url: 'https://www.youtube.com/watch?v=FzLwoDBQsUI'
  }
];

export const nftBestAd = [
  {
    name: '#15352',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192329558-4ae0d6e3-5c58-4d32-ba68-262d8739e3a2.png',
    market: 1,
    price: '42.69',
    url: 'https://opensea.io/assets/ethereum/0x60e4d786628fea6478f785a6d7e704777c86a7c6/15352',
    site: 6,
    buy: false
  },
  {
    name: 'Pixel Neon',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192329582-1cc995fd-860f-448b-8b6a-3df885ac08ca.png',
    market: 1,
    price: '0.065',
    url: 'https://knownorigin.io/gallery/17570000-pixel-neon',
    site: 8,
    buy: false
  },
  {
    name: 'RENGA #8787',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/194063664-34ba5d99-fd9e-4f71-9855-ab802925cf3e.png',
    market: 1,
    price: '65',
    url: 'https://opensea.io/assets/ethereum/0x394e3d3044fc89fcdd966d3cb35ac0b32b0cda91/8787',
    site: 6,
    buy: false
  },
  {
    name: 'Flamboyant Panthers #3258',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/194063670-bac111a8-8d1c-4fe0-b8a6-0d5859f820a0.png',
    market: 3,
    price: '1',
    site: 6,
    buy: false,
    url: 'https://opensea.io/assets/solana/4uwo7nbVKMg73ZV7qKfG5WM2zWqN3MdKQ3W5fMYxZEny'
  },
  {
    name: 'Kanpai Panda',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/194063680-0bec1562-158b-43ae-b04b-203516e6732e.png',
    market: 1,
    price: '0.62',
    site: 6,
    buy: false,
    url: 'https://opensea.io/assets/ethereum/0xacf63e56fd08970b43401492a02f6f38b6635c91/6683'
  },
  {
    name: 'Watercolor Dreams #415',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/194063690-e571161d-94fc-4b6d-be2f-ba6771b14da0.png',
    market: 1,
    price: '1.9',
    site: 6,
    buy: false,
    url: 'https://opensea.io/assets/ethereum/0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270/59000415'
  },
  {
    name: 'Altar Girl #18617000',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/194063702-7bf1f23d-572a-4793-94c4-ba2a7e227eb0.png',
    market: 1,
    price: '0.2',
    site: 8,
    buy: false,
    url: 'https://knownorigin.io/gallery/18617000-altar-girl'
  },
  {
    name: 'Aurora',
    imageUrl: 'https://assets.foundation.app/QQ/ar/QmfX9vxEMejCZqoQsUk8Nwg1LX7zrYPWGf1xWrXHNHQQar/nft.mp4',
    market: 1,
    price: '5.00',
    site: 2,
    buy: false,
    url: 'https://foundation.app/@soyounlee/fortune/1'
  },
  {
    name: 'Psilocybe',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/194063745-66aa043c-e6ad-4a94-bc5d-ad3cb500104d.png',
    market: 1,
    price: '0.25',
    site: 2,
    buy: false,
    url: 'https://foundation.app/@adacrow/principle/1'
  },
  {
    name: 'Cool Cat #9558',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/194064783-92795c70-7776-4acb-8f1b-56729d398a0a.png',
    market: 1,
    price: '3.05',
    site: 7,
    url: 'https://rarible.com/token/0x1a92f7381b9f03921564a437210bb9396471050c:9558?tab=overview',
    buy: false
  }
];

export const nftItem = [
  {
    name: 'love',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192332798-2704776f-8b7a-43cb-8fd4-a4df1fe3b0fc.png',
    market: 1,
    price: '0.004',
    url: 'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/9140374655798785169224343665444434815100314830603525191308604231050332209153',
    site: 6,
    buy: false,
    rate: true,
    reactionCount: 10
  },
  {
    name: 'Luna Love Leaflets',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192332809-b397f155-9721-458c-8d92-5bca3c244126.png',
    market: 4,
    price: '1.48',
    url: 'https://wax.atomichub.io/market/sale/90573279',
    site: 9,
    buy: false,
    rate: true,
    reactionCount: 42
  },
  {
    name: 'love',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192332812-2ddb2ffc-eeb3-47ad-a97e-b83cece39f8c.png',
    market: 1,
    price: '0.01',
    url: 'https://mintable.app/ART/item/love-parting-lovers/Bw-zglFH-aor6sx',
    site: 11,
    buy: false,
    rate: true,
    reactionCount: 82
  },
  {
    name: 'Monochrome Love',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/194078399-84a46879-2403-40a2-aa54-d0f6f85261ac.png',
    market: 1,
    price: null,
    url: 'https://makersplace.com/basileus/monochrome-love-1-of-5-46229/',
    site: 10,
    buy: false,
    rate: true,
    reactionCount: 320
  },
  {
    name: 'love fountain',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192332837-d13c357a-96a8-4850-b86e-ae0096fdee36.png',
    market: 1,
    price: '0.0174',
    url: 'https://mintable.app/ART/item/love-fountain-love-fountain_pink/ApXhdefoi6Rb4Vl',
    site: 11,
    buy: false,
    rate: true,
    reactionCount: 342
  },
  {
    name: 'why does love grow, then burn to flames?',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192332870-b2cca40f-93cb-4361-a43c-bee502cb2086.png',
    market: 1,
    price: '0.45',
    url: 'https://www.niftygateway.com/marketplace/collection/0xd18c7eb98a1eb678f5a373a416b173109183b37c/1?filters[onSale]=true&sortBy=lowest',
    site: 5,
    buy: false,
    rate: true,
    reactionCount: 34
  },
  {
    name: 'Lovely Nyan Cat',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192333281-d1ecbe90-1852-465b-aafe-4d26e5dd955d.png',
    market: 1,
    price: '0.7',
    url: 'https://rarible.com/token/0xb32979486938aa9694bfc898f35dbed459f44424:10065?tab=overview',
    site: 7,
    buy: false,
    rate: true,
    reactionCount: 778
  },
  {
    name: 'Hearts and Butterflies',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192332879-91ec25c2-52ae-4628-8f58-c38506469bee.png',
    market: 1,
    price: '0.33',
    url: 'https://makersplace.com/jamessuret/hearts-and-butterflies-1-of-1-343216/',
    site: 10,
    buy: false,
    rate: true,
    reactionCount: 42
  },
  {
    name: 'Amor vincit omnia : love conquers all',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192332919-a067085a-8edc-4164-a687-e1452d91a792.png',
    market: 1,
    price: null,
    url: 'https://superrare.com/artwork-v2/amor-vincit-omnia-:-love-conquers-all-36933',
    site: 1,
    buy: false,
    rate: true,
    reactionCount: 12
  },
  {
    name: 'Sending Love',
    imageUrl: 'https://user-images.githubusercontent.com/6345000/192333218-762dc6c8-7d8d-412c-b9cc-de4219273763.png',
    market: 1,
    price: '0.059',
    url: 'https://rarible.com/token/0x1acbd5ce03bdd2a898f4acf91a39cc9c0807c39c:22?tab=overview',
    site: 7,
    buy: false,
    rate: true,
    reactionCount: 34
  }
];

export const adSlide = [
  {
    id: 0,
    url: 'https://superrare.com/providerka',
    img: 'https://user-images.githubusercontent.com/6345000/192335778-16030696-51de-4486-a7c3-51871b87254d.png'
  },
  {
    id: 1,
    url: 'https://makersplace.com/neongreen/',
    img: 'https://user-images.githubusercontent.com/45615584/194041435-d9348878-3274-40dd-afe5-afb082bc6a68.png'
  }
];

export const communityList = [
  {
    uid: 8,
    createdAt: '2022-08-08T18:39:40.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'Game is our future!',
    profileIcon: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/community/channel/4b55d717c5ed4efbbfc49370e82d151a.jpg',
    introduce: 'Through the game we can have FUN and proper REWARD as well.\nCan you believe this?',
    memberUid: 74,
    categoryCodeUid: 23,
    categoryName: 'Community Group',
    memberName: 'Janice',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/74/90b2c1ea28944cf0bc6a897d06448940.jpeg',
    tags: [
      {
        uid: 9,
        createdAt: '2022-08-08T18:39:40.000+0900',
        deletedFlag: false,
        usedFlag: true,
        communityChannelUid: 8,
        commonCodeUid: 38,
        name: 'Game'
      }
    ],
    online: 1,
    memberCount: 421
  },
  {
    uid: 14,
    createdAt: '2022-08-08T19:28:32.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'Game of NFT that play to earn',
    profileIcon: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/community/channel/6064995eb4e24efe8641671801abb2de.jpg',
    introduce: "Let's PLAY and have FUN and EARN the money for real!\nI can give you some tips for this and you can share yours as well! :p",
    memberUid: 77,
    categoryCodeUid: 21,
    categoryName: 'Friends',
    memberName: 'Christine',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/77/c46e6289b9be48b9b293a334b116e91f.jpg',
    tags: [
      {
        uid: 15,
        createdAt: '2022-08-08T19:28:32.000+0900',
        deletedFlag: false,
        usedFlag: true,
        communityChannelUid: 14,
        commonCodeUid: 38,
        name: 'Game'
      }
    ],
    online: 26,
    memberCount: 102
  },
  {
    uid: 16,
    createdAt: '2022-08-08T19:41:10.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'NFT of Music makes you happy',
    profileIcon: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/community/channel/a06d8b29e9864c4fa17d4b6945a5e677.jpg',
    introduce:
      'Hearing the music makes us happy and sometimes it makes you much happier if you get the some opportunity to get unique NFT of Music.\nWe can figure out the great chance for sharing our knowhow via this community.',
    memberUid: 78,
    categoryCodeUid: 20,
    categoryName: 'Create My Own',
    memberName: 'Joy',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/78/28f74c54b1204981a6ba7c6c74a1cb6b.jpg',
    tags: [
      {
        uid: 17,
        createdAt: '2022-08-08T19:41:10.000+0900',
        deletedFlag: false,
        usedFlag: true,
        communityChannelUid: 16,
        commonCodeUid: 18,
        name: 'MUSIC'
      }
    ],
    online: 29,
    memberCount: 1001
  },
  {
    uid: 1,
    createdAt: '2022-08-08T18:09:59.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'Art of NFT',
    profileIcon: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/community/channel/a99e20532d04400880a11766848110ac.jpg',
    introduce: 'Art for NFT! NFT for Art! \nColoring on art with NFT',
    memberUid: 73,
    categoryCodeUid: 20,
    categoryName: 'Create My Own',
    memberName: 'Judy',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/73/c6ac03d5cb4041a794ecefb79047cc9c.png',
    tags: [
      {
        uid: 2,
        createdAt: '2022-08-08T18:09:59.000+0900',
        deletedFlag: false,
        usedFlag: true,
        communityChannelUid: 1,
        commonCodeUid: 39,
        name: 'Photography'
      },
      {
        uid: 1,
        createdAt: '2022-08-08T18:09:59.000+0900',
        deletedFlag: false,
        usedFlag: true,
        communityChannelUid: 1,
        commonCodeUid: 17,
        name: 'ART'
      }
    ],
    online: 3,
    memberCount: 29
  },
  {
    uid: 13,
    createdAt: '2022-08-08T19:25:23.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'Love of my life in ART',
    profileIcon: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/community/channel/03aca6f243ce4f27b7e48b062b91b1f9.jpg',
    introduce: "I love the Mona Lisa by Leonardo di ser Piero da Vinci. \nI couldn't get it but I can get it based on NFT goods. This is amazing opportunity for me!",
    memberUid: 77,
    categoryCodeUid: 23,
    categoryName: 'Community Group',
    memberName: 'Christine',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/77/c46e6289b9be48b9b293a334b116e91f.jpg',
    tags: [
      {
        uid: 14,
        createdAt: '2022-08-08T19:25:23.000+0900',
        deletedFlag: false,
        usedFlag: true,
        communityChannelUid: 13,
        commonCodeUid: 17,
        name: 'ART'
      }
    ],
    online: 17,
    memberCount: 68
  }
];

export const scamList = [
  {
    subject: `Your NFT and Crypto Wallets Can Be Expired From This Email`,
    desc: `Josh M. Chavez, US-based NFT artist who created collectibles for Tiger Woods, Tom Brady and Rafael Nadal, falls victim to massive scam.

    Josh Chavez took to Twitter to share a sad story about a dangerous scam by which he was targeted. The fraudsters used an older technique with an infected file in the documents attached to the email message.
    `,
    datetime: '01.21.2023',
    link: 'https://biz.crast.net/scam-alert-your-nft-and-crypto-wallets-can-be-expired-from-this-email/',
    emoji: [11, 22, 21, 22]
  },
  {
    subject: `NFT marketplace Magic Eden flooded with fake digital art`,
    desc: `Plummeting prices for nonfungible tokens have not stopped certain individuals from scamming NFT lovers into buying fake digital artwork.

    Counterfeit NFTs were listed and sold within real collections on Magic Eden, the leading NFT marketplace on the Solana blockchain, according to a company statement.
    `,
    datetime: '01.05.2023',
    link: 'https://english.alarabiya.net/life-style/2023/01/05/NFT-marketplace-Magic-Eden-flooded-with-fake-digital-art',
    emoji: [36, 23, 309, 207]
  }
];

export const articlesList = [
  {
    headline: null,
    subject: `Bored Ape Whale Franklin Has Already Sold His Y00ts NFT`,
    desc: `The y00ts NFT mint keeps making headlines, this time thanks to the NFT whale Franklin. The collector, whose portfolio currently boasts 60 Bored Apes, made a 90 SOL profit by selling his y00ts mint spot.`,
    hashTags: ['#Y00ts', '#whaleFranklin', '#90SOL', '#profit'],
    sourceLink: 'https://nftevening.com/bored-ape-whale-franklin-has-already-sold-his-y00ts-nft/',
    bgUrl: 'https://user-images.githubusercontent.com/6345000/192348941-f47d5b27-3ed4-4d6a-b187-98606028de86.png',
    createAt: '09/06/22'
  },
  {
    headline: null,
    subject: `Upcoming NFT Mints: 21st - 28th Aug: Yoots, Cel Mates and more!`,
    desc: `Between the 21st and 28th of August, we have some whitelist NFT projects for you, along with NFTs that are minting now and throughout the week.`,
    hashTags: ['#Whitelist', '#Minting', '#Ethereum', '#Solana'],
    sourceLink: 'https://nftevening.com/upcoming-nft-mints-21st-28th-aug-yoots-cel-mates-and-more/',
    bgUrl: 'https://user-images.githubusercontent.com/6345000/192348975-4ecacaaa-c30c-4866-a671-87c35781bcd9.png',
    createAt: '08/22/22'
  },
  {
    headline: null,
    subject: `UK Rapper Aitch Drops NFT Music on Limewire Marketplace`,
    desc: `‘Close to Home’ is Aitch’s debut album, he is launching two exclusive NFT collections on LimeWire. The NFT which hides the unlimited lifetime ticket perk in the Mystery Box can be transferable. This means that it might come at a hefty price on the secondary market. Particularly in the case that Aitch grows his fan base.`,
    hashTags: ['#ClosetoHome', '#LimeWire', '#Unlimited', '#MysteryBox', '#Aitch'],
    sourceLink: 'https://nftevening.com/uk-rapper-aitch-drops-nft-music-on-limewire-marketplace/',
    bgUrl: 'https://user-images.githubusercontent.com/6345000/192348988-27cfe2b7-35d7-4f30-92dc-5ec59b48379a.png',
    createAt: '08/13/22'
  },
  {
    headline: null,
    subject: `OpenSea Introduces New NFT Theft Policy`,
    desc: `OpenSea, the world’s biggest venue for trading Non-Fungible Token (NFT) items, has revealed its new policy governing the handling of stolen setDigital arts and general theft on its platform.`,
    hashTags: ['#OpenSea', '#NewPolicy', '#cross_bound', '#protection'],
    sourceLink: 'https://blockchain.news/news/opensea-introduces-new-nft-theft-policy',
    bgUrl: 'https://user-images.githubusercontent.com/6345000/192349002-c5955073-0442-496b-ab83-e171d339cfbd.png',
    createAt: '08/12/22'
  }
];

export const marketList = [
  {
    uid: 42,
    createdAt: '2022-09-23T16:36:30.000+0900',
    updatedAt: '2022-09-23T16:38:09.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'QB_Illustrator',
    imageUrl: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/market/126/10f6bfe5201d4fb58fb81c12984497a2.png',
    communityUrl: '',
    categoryCodeUid: 25,
    memberUid: 126,
    categoryCodeName: 'Photography',
    memberName: 'QB',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/126/6884b8112b7842c68ee33fa215a1dd16.png',
    url: 'https://www.cantena.io/market/42'
  },
  {
    uid: 41,
    createdAt: '2022-09-23T16:24:18.000+0900',
    updatedAt: '2022-09-23T16:25:50.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'Love myself',
    imageUrl: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/market/125/2b887ebffb234835808b7aa1ed11dbec.jpg',
    communityUrl: '',
    categoryCodeUid: 25,
    memberUid: 125,
    categoryCodeName: 'Photography',
    memberName: 'Love myself',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/125/73f617bd0794441bac9af1d961eed1f0.jpg',
    url: 'https://www.cantena.io/market/41'
  },
  {
    uid: 39,
    createdAt: '2022-09-23T15:59:23.000+0900',
    updatedAt: '2022-09-23T16:07:08.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'Lovely Emma',
    imageUrl: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/market/124/08a735f91a8f47c59d9ea32aebd9b6a3.jpg',
    communityUrl: '',
    categoryCodeUid: 25,
    memberUid: 124,
    categoryCodeName: 'Photography',
    memberName: 'Emma',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/124/4cf180074b874a6991afcd6654f76739.jpg',
    url: 'https://www.cantena.io/market/39'
  },
  {
    uid: 38,
    createdAt: '2022-09-23T14:32:10.000+0900',
    updatedAt: '2022-09-23T15:39:50.000+0900',
    deletedFlag: false,
    usedFlag: true,
    name: 'Love mother nature',
    imageUrl: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/market/123/7f054694a27d4d7b8c2203e97bc8001a.jpg',
    communityUrl: '',
    categoryCodeUid: 25,
    memberUid: 123,
    categoryCodeName: 'Photography',
    memberName: 'Apolline',
    memberPhoto: 'https://kr.object.ncloudstorage.com/nftmoa-upload-s3/member/photo/123/618f6dcdefd8474aaafa5cddb1bb22b9.jpg',
    url: 'https://www.cantena.io/market/38'
  }
];
