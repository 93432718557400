import styled from 'styled-components';

export interface ViewProps {
  flex?: number | string;
  flexBasis?: number;
  direction?: 'row' | 'column';
  justify?: 'baseline' | 'center' | 'end' | 'first baseline' | 'flex-end' | 'flex-start' | 'last baseline' | 'left' | 'right' | 'safe' | 'space-around' | 'space-between';
  align?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch' | 'inherit' | 'initial' | 'unset';
  spacing?: number;
  cursor?: 'default' | 'pointer';
  w?: number | string;
  h?: number | string;
  bgColor?: string;
  radius?: number;
  block?: boolean;
  children?: React.ReactNode;
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'inherit' | 'initial' | 'unset';

  className?: string;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  onClick?: any;
  mt?: number;
  ml?: number;
  mb?: number;
  pl?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  padding?: string;
  margin?: string;

  border?: string;
  opacity?: number;

  position?: string;
  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
  zIndex?: number;
  shadow?: string;

  borderPos?: 'top' | 'bottom';
  isOverflow?: boolean;

  gradient?: boolean;
}

export const View: React.FC<ViewProps> = ({ children, ...props }) => {
  // eslint-disable-next-line react/react-in-jsx-scope
  return <StyledView {...props}>{children}</StyledView>;
};

export default View;

View.defaultProps = {
  flex: 1,
  direction: 'row',
  justify: 'flex-start',
  align: 'flex-start',
  cursor: 'default',
  spacing: 0,
  bgColor: 'transparent',
  block: false,
  wrap: 'nowrap',
  radius: 0,
  shadow: 'none'
};

const StyledView = styled.div<ViewProps>`
  position: relative;
  display: flex !important;
  flex-direction: ${p => p.direction} !important;
  justify-content: ${p => p.justify} !important;
  align-items: ${p => p.align} !important;
  gap: ${p => p.spacing}px !important;
  cursor: ${p => p.cursor} !important;
  background-color: ${p => p.bgColor} !important;
  background-color: transparent;
  color: inherit;
  flex-wrap: ${p => p.wrap};

  ${p => p.flex && p.flex > 0 && `flex: ${p.flex} !important`};
  ${p => p.w && `max-width: ${p.w}px !important`};
  ${p => p.h && `height: ${p.h}px !important`};
  ${p => p.flexBasis && `flex-basis: ${p.flexBasis}px !important`};
  ${p => p.block && `width: 100% !important`};
  ${p => p.radius && `border-radius: ${p.radius}px !important; overflow: hidden`};

  ${p => p.minW && `min-width:${p.minW}px`};
  ${p => p.maxW && `max-width:${p.maxW}px`};
  ${p => p.minH && `min-height:${p.minH}px`};
  ${p => p.maxH && `max-height:${p.maxH}px`};
  ${p => p.block && `width: 100% !important`};
  ${p => p.padding && `padding:${p.padding}px`};
  ${p => p.mt && `margin-top:${p.mt}px`};
  ${p => p.ml && `margin-left:${p.ml}px`};
  ${p => p.mb && `margin-bottom:${p.mb}px`};
  ${p => p.pl && `padding-left:${p.pl}px`};
  ${p => p.pr && `padding-right:${p.pr}px`};
  ${p => p.pt && `padding-top:${p.pt}px`};
  ${p => p.pb && `padding-bottom:${p.pb}px`};
  ${p => p.margin && `margin:${p.margin}px`};

  ${p => p.border && `border: ${p.border}!important`};
  ${p => p.opacity && `opacity: ${p.opacity}`};

  ${p => p.position && `position: ${p.position}`};
  ${p => (typeof p.top === 'number' ? `top: ${p.top}px` : `top: ${p.top}`)};
  ${p => (typeof p.bottom === 'number' ? `bottom: ${p.bottom}px` : `bottom: ${p.bottom}`)};
  ${p => (typeof p.left === 'number' ? `left: ${p.left}px` : `left: ${p.left}`)};
  ${p => (typeof p.right === 'number' ? `right: ${p.right}px` : `right: ${p.right}`)};
  ${p => p.zIndex && `z-index: ${p.zIndex}`};
  ${p => p.shadow && `box-shadow: ${p.shadow}`};

  ${p => p.borderPos === 'top' && 'border: none !important; border-top: 1px solid #E6E8EC !important'};
  ${p => p.borderPos === 'bottom' && 'border: none !important; border-bottom: 1px solid #E6E8EC !important'};

  ${p => p.isOverflow && 'overflow: hidden'};
  ${p =>
    p.gradient &&
    `
    &:after {
      content: '';
      width:100%;
      height:100%;
      position:absolute;
      background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%);
    }
    `}
`;
