import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TwiiterLogo } from 'assets/logo/twitter-fill.svg';
import { ReactComponent as FacebookLogo } from 'assets/logo/facebook-fill.svg';
import { ReactComponent as InstagramLogo } from 'assets/logo/instagram-original.svg';
import { View, Text } from 'styles';
import ButtonUI from 'ui/button/Button';

export type SocialList = 'twitter' | 'instagram' | 'facebook';

interface SocialButton {
  social: SocialList;
  isConnect?: boolean;
}

const SocialButtonUI: React.FC<SocialButton> = ({ social, isConnect }) => {
  const renderIcon = () => {
    const item: { name: string; icon: React.ReactNode | null } = { name: '', icon: null };
    switch (social) {
      case 'twitter':
        item.name = 'Twitter';
        item.icon = <TwiiterLogo className="logo-twitter" />;
        return { item };
      case 'facebook':
        item.name = 'Facebook';
        item.icon = <FacebookLogo className="logo-facebook" />;
        return { item };
      case 'instagram':
        item.name = 'Instagram';
        item.icon = <InstagramLogo />;
        return { item };
    }
  };

  return (
    <StyledWrap>
      <View spacing={20}>
        {renderIcon()?.item.icon}
        <Text size="sm">{renderIcon()?.item.name}</Text>
      </View>
      <ButtonUI shape="circle" color="blue" h={30} fontSize={12} fontWeight={700}>
        Connect
      </ButtonUI>
    </StyledWrap>
  );
};

export default SocialButtonUI;

const StyledWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 8px;

  width: 100%;
  height: 46px;
  background-color: #fff;
  border-radius: 20px;

  .logo-twitter {
    path {
      fill: #1da1f2;
    }
  }

  .logo-facebook {
    path {
      fill: #1877f2;
    }
  }
`;
