import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styles/theme';

import logo from 'assets/common/logo.png';
import logoTxt from 'assets/common/logo_txt.png';
import { Button, View, WrapIcon } from 'styles';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as DefaultProfileSvg } from 'assets/profile/default.svg';
import { ReactComponent as NftMoaLogo } from 'assets/logo/nftmoa.svg';
import { ReactComponent as MenuIcon } from 'assets/common/menu.svg';
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg';
import { ReactComponent as FolderIcon } from 'assets/icon/folder.svg';
import { ReactComponent as PhotoIcon } from 'assets/icon/add-photo.svg';
import { ReactComponent as AccountIcon } from 'assets/icon/account.svg';
import { ReactComponent as SettingIcon } from 'assets/icon/setting.svg';
import { ReactComponent as WalletIcon } from 'assets/icon/wallet.svg';
import { ReactComponent as LogoutIcon } from 'assets/icon/logout.svg';

import { useSelector, useDispatch } from 'react-redux';
import { Drawer } from 'antd';
import MenuItemComponent from './components/menu-item/MenuItem';
import Footer from './components/menu-item-footer/Footer';
import { logout, profileUpdate } from 'store/reducers/System';
import ButtonUI from 'ui/button/Button';
import qs from 'query-string';

export default ({ callLogin, callSign, callLogout, callConnect }: any) => {
  const { search } = useLocation();
  const { menuOn } = qs.parse(search);
  const { isLoggedIn, profile } = useSelector((store: IStore) => store.System);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(menuOn ? true : false);
  const menuList = [
    {
      id: 0,
      sortNumber: 0,
      label: 'MY NFT',
      image: <FolderIcon />,
      router: '/mynft'
    },
    {
      id: 1,
      sortNumber: 1,
      label: 'NFT Create',
      image: <PhotoIcon />,
      router: '/nft/item/create'
    },
    {
      id: 2,
      sortNumber: 2,
      label: 'Account',
      image: <AccountIcon />,
      router: '/account/edit'
    },
    {
      id: 3,
      sortNumber: 3,
      label: 'Settings',
      image: <SettingIcon />,
      router: '/setting'
    },
    {
      id: 4,
      sortNumber: 4,
      label: 'My wallet',
      image: <WalletIcon />,
      router: '/my/wallet'
    },
    {
      id: 5,
      sortNumber: 5,
      label: 'Log out',
      image: <LogoutIcon />,
      router: '/'
    }
  ];
  const closeMenu = () => {
    setOpenMenu(false);
    navigate('/');
  };

  const logoutCall = () => {
    console.log('logout');
    dispatch(logout());
  };

  useEffect(() => {}, [profile]);

  useEffect(() => {
    if (menuOn) {
      setOpenMenu(true);
    }
  }, [search]);

  return (
    <MainHeader>
      <LogoTxt>
        <Link to="/" style={{ display: 'flex', gap: '10px' }}>
          <img src={logo} />
          <img src={logoTxt} style={{ margin: 'auto' }} />
        </Link>
      </LogoTxt>
      <RightArea>
        {isLoggedIn && (
          // <MintBtn>
          //   <button onClick={() => navigate('/profile/create')}>
          //     <WrapIcon w={30} h={30}>
          //       <PlusIcon />
          //     </WrapIcon>
          //     <HexagonLabel>
          //       <div className="hexagon">Minting now!</div>
          //     </HexagonLabel>
          //   </button>
          // </MintBtn>
          <View spacing={18} align="center">
            <Button onClick={() => navigate('/nft/item/create')} weight="normal" text={'NFT Create'} color={'borderThinGray'} w={84} h={30} fontSize={10} />
            {profile?.photoUri ? <ProfileImg src={profile.photoUri} /> : <DefaultProfileSvg />}
            <MenuIcon onClick={() => setOpenMenu(true)} />
          </View>
        )}
        {/* {isLoggedIn && (
          <SignUp>
            <Button onClick={callLogout} text={'Log out'} h={30} w={54} fontSize={10} />
          </SignUp>
        )} */}
        {!isLoggedIn && (
          // <SignUp>
          //   <Button onClick={callLogin} text={'Log In'} h={30} w={54} fontSize={10} />
          //   <Button onClick={callSign} text={'Sign Up'} h={30} w={54} fontSize={10} />
          // </SignUp>
          <Button onClick={callConnect} text={'Connect Wallet'} color={'blue'} w={111} h={32} fontSize={10} />
        )}
      </RightArea>

      <Drawer
        drawerStyle={{ borderBottom: '1px solid #c9c9c9' }}
        footerStyle={{ marginLeft: 20, marginRight: 20, paddingTop: 30, paddingBottom: 30 }}
        footer={<Footer />}
        closable={false}
        width={'100%'}
        placement="right"
        open={openMenu}
        onClose={closeMenu}
      >
        <View className="menu-header" pl={20} pr={25} justify="space-between" align="center" block>
          <NftMoaLogo width={113} height={30} />
          <View spacing={18} align="center" flex={0}>
            <Button onClick={() => navigate('/nft/item/create')} weight="normal" text={'NFT Create'} color={'borderThinGray'} w={84} h={30} fontSize={10} />
            {profile?.photoUri ? <ProfileImg src={profile.photoUri} /> : <DefaultProfileSvg />}

            <CloseIcon onClick={closeMenu} />
          </View>
        </View>

        <View className="menu-body" direction="column" spacing={40} pt={40} pl={30} pr={35}>
          {menuList.map((item, idx) => (
            <MenuItemComponent key={idx} {...item} setOpenMenu={setOpenMenu} onClick={item.label === 'Log out' && logoutCall} />
          ))}
        </View>
      </Drawer>
    </MainHeader>
  );
};

const MainHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  order: 0;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background: #fff;
  z-index: 50;
`;

const LogoTxt = styled.h1`
  display: block;
  position: relative;
  padding: 10px 0;
  font-size: 0;

  & a {
    font-size: 0;

    & img:first-of-type {
      width: 30px;
    }

    & img + img {
      margin-left: 4px;
      width: auto;
    }
  }
`;

const RightArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MintBtn = styled.div``;
const SignUp = styled.div`
  margin-left: 10px;

  button + button {
    margin-left: 6px;
  }
`;

const HexagonLabel = styled.div`
  position: absolute;
  right: 33px;
  top: 50%;
  transform: translateY(-50%);

  .hexagon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    padding: 0px 6px;
    height: 16px;
    background-color: #f900d4;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: -0.02em;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      left: 100%;
      border-left: 8px solid #f900d4;
    }
  }
`;

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 8px;
`;
