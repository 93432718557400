import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SubHeader from 'components/Header/SubHeader';
import { View, Text, PublicKey, Tab, FilterSearch, SelectLabel } from 'styles';
import BackgroundUI from 'ui/background/Background';
import AvatarUI from 'ui/avatar/Avatar';
import { ReactComponent as CopyIcon } from 'assets/icon/copy.svg';
import { ReactComponent as SelectArrowIcon } from 'assets/icon/arrow-down.svg';
import Copy from 'ui/copy/Copy';
import CollectedComponent from '../components/collected/Collected';
import CreatedComponent from '../components/created/Created';
import SearchUI from 'ui/search/Search';
import { Select } from 'antd';
import NftCardItemComponent from 'components/v2/nft-card-item/NftCardItem';
import { NFT_ITEMS } from 'api/mock/nft';
import SelectButtonUI from 'ui/select-button/SelectButton';
import ModalStyles from 'styles/modal/Modal';
import { SORT_LIST } from 'api/mock/ctg';
import { shortText } from 'utils';
import { useInfiniteScroll } from 'hook/useInfiniteScroll';
import { useSelector, useDispatch } from 'react-redux';
import APIService from 'api';
import qs from 'query-string';
const apiService = new APIService();

const ProfilePage: React.FC = () => {
  const { Option } = Select;

  const navigate = useNavigate();
  const { search } = useLocation();
  const { tab } = qs.parse(search);
  const copyRef = React.useRef<any>(null);

  const [openSortModal, setOpenSortModal] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<'Collected' | 'Created'>(tab === 'Created' ? 'Created' : 'Collected');
  const [searchValue, setSearchValue] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<string>('recently');
  const [collected, setCollected] = React.useState<any[]>([]);
  const [created, setCreated] = React.useState<any[]>([]);

  const { profile } = useSelector((store: IStore) => store.System);

  const ME = React.useMemo(() => {
    return {
      name: profile?.name || 'User name',
      avatarUrl: profile?.photoUri || 'https://i.seadn.io/gcs/files/a59eec7ac207e35611c3fbd7526a4037.png?auto=format&w=384',
      address: profile?.walletAddress
    };
  }, [profile]);

  console.log(ME, 'ME');

  const getMarketItemList = async ({ page, size = 20 }: any) => {
    setLoading(true);
    const resultMyItem =
      activeTab === 'Collected' ? await apiService.getCollectedItemList({ page, size, sort, name: searchValue }) : await apiService.getMarketCreatedItemList({ page, size, sort, name: searchValue });

    if (activeTab === 'Collected') {
      if (collected.length > 0) {
        collected.at(-1).uid !== resultMyItem.list.at(-1).uid && setCollected([...collected, ...resultMyItem.list]);
      } else {
        setCollected(resultMyItem.list);
      }
    } else {
      if (created.length > 0) {
        created.at(-1).uid !== resultMyItem.list.at(-1).uid && setCreated([...collected, ...resultMyItem.list]);
      } else {
        setCreated(resultMyItem.list);
      }
    }

    setLoading(false);
  };

  const changeActiveTab = (value: 'Collected' | 'Created') => {
    if (activeTab !== value) {
      navigate(`/profile?tab=${value}`);
      setSort('recently');
      setActiveTab(value);
    }
  };

  const moveToDetail = () => {
    navigate(`/nft/item/:id`);
  };

  const moveToAccount = () => {
    navigate(`/account/edit`);
  };

  const closeModal = () => {
    setOpenSortModal(false);
  };

  const onChangeSort = (value: string) => {
    setSort(value);
    setOpenSortModal(false);
  };

  React.useEffect(() => {
    getMarketItemList({ page: 1, sort });
  }, [activeTab, sort]);

  React.useEffect(() => {}, [profile]);

  return (
    <StyledWrap>
      <SubHeader title="MY NFT" />
      <View direction="column" justify="space-between">
        <BackgroundUI h={120} />
        <View direction="column" pt={49} block>
          {profile && (
            <>
              <ProfileImage imgUrl={profile?.photoUri || ME.avatarUrl} extra="setting" onClick={moveToAccount} />
              <View direction="column" spacing={6} block>
                <Text size="xl" weight="bold" align="center" block>
                  {ME.name}
                </Text>
                <View align="center" justify="center" block>
                  <View flex={0} border="1px solid #eaeaea" padding="2px 10" align="center" justify="space-between" radius={10}>
                    <Text size="xs" color="#808080">
                      {ME.address && shortText(ME.address, 6, 6)}
                    </Text>
                    <Copy copyValue={ME.address} ref={copyRef}>
                      <View ml={11} onClick={() => copyRef.current.copy()}>
                        <CopyIcon />
                      </View>
                    </Copy>
                  </View>
                </View>
              </View>
            </>
          )}

          <View mt={30} block direction="column">
            <Tab active={activeTab} block>
              <Tab.Item value="Collected" onClick={() => changeActiveTab('Collected')} />
              <Tab.Item value="Created" onClick={() => changeActiveTab('Created')} />
            </Tab>

            {/* 검색 */}
            <View mt={20} mb={20} direction="column" padding="0 20" block justify="center">
              <SearchUI className="search" placeholder={'Search by name'} />
              <View justify="flex-end" block mt={8}>
                <SelectButtonUI bgColor="#F9F9F9" fontSize={14} weight="700" w={180} h={40} onClick={() => setOpenSortModal(true)}>
                  {SORT_LIST.filter(item => item.value === sort)[0].name}
                </SelectButtonUI>
              </View>
            </View>

            {activeTab === 'Collected' && (
              <View padding="0 20" block wrap="wrap" spacing={10}>
                {collected.map((item, idx) => (
                  <NftCardItemComponent key={idx} {...item} linkState={activeTab} />
                ))}
              </View>
            )}
            {activeTab === 'Created' && (
              <View padding="0 20" block wrap="wrap" spacing={10}>
                {created.map((item, idx) => (
                  <NftCardItemComponent key={idx} {...item} linkState={activeTab} />
                ))}
              </View>
            )}
          </View>
        </View>
      </View>

      <ModalStyles w={'90%'} open={openSortModal} isCenter={true} handleCancle={closeModal} type="select">
        {SORT_LIST.map((item, idx) => (
          <StyledOptionItem key={idx} active={sort === item.value} className="select-item" onClick={() => onChangeSort(item.value)}>
            {item.name}
          </StyledOptionItem>
        ))}
      </ModalStyles>
    </StyledWrap>
  );
};

export default ProfilePage;

const StyledWrap = styled.div`
  padding-bottom: 100px;
`;
const ProfileImage = styled(AvatarUI)`
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
`;

const StyledPublickey = styled.div``;
const StyledOptionItem = styled.div<{ active?: boolean }>`
  color: ${p => (p.active ? '#0075FF' : '#808080')} !important;
`;
