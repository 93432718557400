import produce from 'immer';
import APIService from 'api';

import { createAction, handleActions } from 'redux-actions';
import cookie from 'js-cookie';
const apiService = new APIService();

// ActionsTypes
export const actionTypes = {
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
  RESET: 'RESET',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  PROFILE_UPDATE: 'PROFILE_UPDATE',
  ALERT: 'ALERT'
};

// Actions
export const incrementCount = createAction(actionTypes.INCREMENT);
export const decrementCount = createAction(actionTypes.DECREMENT);
export const resetCount = createAction(actionTypes.RESET);
export const login = createAction(actionTypes.LOGIN);
export const logout = createAction(actionTypes.LOGOUT);
export const profileUpdate = createAction(actionTypes.PROFILE_UPDATE);
export const alertMessage = createAction(actionTypes.ALERT);

// InitialState
const initialState = {
  routing: 'dkdkdkdkdk',
  count: 0,
  isLoggedIn: false,
  profile: null,
  alertMessage: {
    type: undefined,
    msg: undefined
  }
};

// Reducers
export const reducer = handleActions(
  {
    [actionTypes.INCREMENT]: (state, action) =>
      produce(state, draftState => {
        draftState.count += 1;
      }),
    [actionTypes.DECREMENT]: (state, action) =>
      produce(state, draftState => {
        draftState.count -= 1;
      }),
    [actionTypes.RESET]: (state, action) =>
      produce(state, draftState => {
        draftState.count = 0;
      }),
    [actionTypes.LOGIN]: (state, action) =>
      produce(state, draftState => {
        draftState.isLoggedIn = true;
      }),
    [actionTypes.LOGOUT]: (state, action) =>
      produce(state, draftState => {
        draftState.profile = null;
        draftState.isLoggedIn = false;
        cookie.remove('accessToken');
        cookie.remove('refreshToken');
        cookie.remove('expired');
        window.location.reload();
      }),
    [actionTypes.PROFILE_UPDATE]: (state, action) =>
      produce(state, draftState => {
        console.log(action, 'action2');
        draftState.profile = action.payload.profile;
        draftState.isLoggedIn = true;
      }),
    [actionTypes.ALERT]: (state, action: any) =>
      produce(state, draftState => {
        draftState.alertMessage.type = action.payload.type;
        draftState.alertMessage.msg = action.payload.msg;
      })
  },
  initialState
);
