import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as DownArrowIcon } from 'assets/svg/downArrow2.svg';
import { Collapse } from 'antd';
import { View, Text, CommentItem } from 'styles';
import styled from 'styled-components';
import BoardItem from 'components/boardItem';
import FullItemComponent from 'components/nftsItem/fullItem';

import { useInfiniteScroll } from 'hook/useInfiniteScroll';

import moment from 'moment';
import APIService from 'api';
const apiService = new APIService();

const InformationComponent: React.FC<any> = ({ desc, chart, history, community }) => {
  const { Panel } = Collapse;
  const { marketUid, uid } = useParams();
  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [commentList, setCommentList] = React.useState<any[]>([]);
  const [communityList, setCommunityList] = React.useState<any[]>([]);

  const getCommentList = async ({ offsetUid }: any) => {
    setLoading(true);
    const resultMyItem = marketUid ? await apiService.getItemMarketComment({ marketUid, uid, offsetUid }) : '';

    if (commentList.length > 0) {
      commentList.at(-1).uid !== resultMyItem.list.at(-1).uid && setCommentList([...commentList, ...resultMyItem.list]);
    } else {
      setCommentList(resultMyItem.list);
    }

    setLoading(false);
  };

  const getGroup = async () => {
    const resultMyItem = await apiService.getCommunityMyGroup({});

    if (resultMyItem.list.length > 0) {
      setCommunityList(resultMyItem.list);
    }
  };

  React.useEffect(() => {
    getCommentList({});
    getGroup();
  }, []);

  React.useEffect(() => {
    if (isScroll && commentList.length > 0) getCommentList({ offsetUid: commentList.at(-1).uid });
  }, [isScroll]);

  return (
    <StyledCollapse expandIcon={() => <DownArrowIcon />} expandIconPosition={'right'}>
      <Panel header={'Description'} key="1">
        <Text className="panel-desc" size="xs">
          {desc}
        </Text>
      </Panel>
      <Panel header={'Comment'} key="3">
        {commentList && commentList.length > 0 && (
          <CommentList>
            {commentList.map((item, idx) => (
              <CommentItem key={idx} content={item.comment} timestamp={moment(item.createdAt).fromNow()} avatar={item.memberPhoto} />
            ))}
          </CommentList>
        )}
        <div ref={senserRef} className={`observer ${!isLoading ? 'completed' : ''}`} />
      </Panel>
      <Panel
        header={
          <View align="center" spacing={10}>
            <span>Community</span>
            <Text size="xxs" color={'#808080'}>
              Community by{' '}
              <Text size="xxs" weight="bold" color="#000">
                NVIA.
              </Text>
            </Text>
          </View>
        }
        key="4"
      >
        <CommentList>
          {communityList.length > 0 &&
            communityList.slice(0, 2).map((item, idx) => <BoardItem key={idx} uid={item.communityChannelUid} name={item.communityChannelName} profileIcon={item.communityChannelProfileIcon} />)}
        </CommentList>

        {/* <WrapCommunity>
          <View direction="column">
            {community.map((item: any, idx: number) => (
              <CommentItem
                key={idx}
                className={'wrap-comment'}
                channelName={item.channelName}
                name={
                  <View direction="column">
                    <Text size="sm" isLh={false} weight="semi-bold" color={'#000'}>
                      {item.name}
                    </Text>
                    <Text size="xs" color={'#000'}>
                      {item.channelName}
                    </Text>
                  </View>
                }
                isOnline={item.isOnline}
                avatar={item.avatar}
                content={<FullItemComponent className={'wrap-nft'} {...item.nftData} />}
                emojis={item.emojis}
                reply={item.reply}
                timestamp={item.timestamp}
              />
            ))}
          </View>
        </WrapCommunity> */}
      </Panel>
    </StyledCollapse>
  );
};

export default React.memo(InformationComponent);

const StyledCollapse = styled(Collapse)`
  .panel-desc {
    padding: 20px;
    line-height: 20px;
    white-space: pre-wrap;
  }
`;

const WrapHistory = styled(View)`
  padding: 20px;
`;

const WrapCommunity = styled.div`
  padding: 0 20px 20px;
  .comment-timestamp {
    position: absolute;
    top: 0;
    right: 40px;
  }
`;

const CommentList = styled.div`
  padding: 0 15px;
`;
