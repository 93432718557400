import React from 'react';
import View from 'ui/view/View';
import Text from 'ui/text/Text';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-right-news.svg';
import { ReactComponent as DownArrowIcon } from 'assets/svg/arrow-more.svg';
import moment from 'moment';
import APIService from 'api';
const apiService = new APIService();
const ArticleItem: React.FC<any> = ({ more = false, usedFlag = true, ...props }) => {
  const moveToUrl = () => {
    window.open(props.sourceUrl, '_blank');
  };

  const [isMore, setIsMore] = React.useState(false);

  return (
    <>
      {usedFlag && (
        <StyledWrap className="article-item-container" radius={'r-10'} shadow={'sm'} direction={'column'}>
          {/* 타이틀 */}
          <View gradient direction={'column'} align={'flex-end'} block>
            <img className="item-bg" src={props.imageUrl} />
            <div className="item-title">
              <Text color={'#fff'} w={155} h={146} size="2xl" weight={'bold'}>
                {props.title}
              </Text>
              <Text block align="right" color={'rgba(255, 255, 255, 0.7)'}>
                {moment(props.createdAt).format('MM/DD/YY')}
              </Text>
            </div>
          </View>

          <View direction={'column'} padding={['py-3', 'px-4']}>
            {/* 추가 내용 */}
            <Text className={'text-line'} margin={['mb-1']} eclipse={isMore ? undefined : 3} size={'lg'}>
              {props.detailContent.replace(/<[^>]*>?/g, '')}
            </Text>
            {more && !isMore && (
              <View direction={'column'} align={'flex-end'} block>
                <SpanMore onClick={() => setIsMore(true)}>
                  More <DownArrowIcon />
                </SpanMore>
              </View>
            )}
            {/* 태그 */}
            <View>
              {/* {props.tags.map((tag: string, idx: number) => (
              <Text color="rgba(0, 0, 0, 0.5)" key={idx}>
                #{tag} {props.tags.length - 1 === idx ? '' : ','}
              </Text>
            ))} */}
            </View>
            {/* 링크 */}
            <View direction={'column'} margin={['mt-5']} block>
              <Text size="md" weight={'medium'}>
                Copyright All Rights Reserved.
              </Text>
              <View align="center" justify={'space-between'} block>
                <Text className={'text-line'} onClick={moveToUrl} underline size="md" color={more ? 'rgba(0, 117, 255, 1)' : 'rgba(0, 0, 0, 0.5)'}>
                  {props.sourceUrl}
                </Text>
                <ArrowIcon />
              </View>
            </View>
          </View>
        </StyledWrap>
      )}
    </>
  );
};

export default ArticleItem;

const StyledWrap = styled(View)`
  width: 305px;
  min-width: 305px;
  height: 378px;

  .item-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .item-title {
    padding: 14px 18px;
    font-size: 0;
    line-height: 0;
    z-index: 10;
  }

  .text-line {
    word-break: break-all;
  }
`;

const SpanMore = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  svg {
    position: relative;
    top: 3px;
    opacity: 0.5;
    transform: rotate(90deg) scale(0.8);
  }
`;
