import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { MoaLogo, Tab, View, Text, Button, PublicKey, Price } from 'styles';
import { View as View2, Text as Text2, Display } from 'ui';
import BackgroundUI from 'ui/background/Background';
import { ReactComponent as HeartIcon } from 'assets/icon/heart-lined.svg';
import { ReactComponent as HeartFilledIcon } from 'assets/icon/heart-filled.svg';
import { ReactComponent as SirenIcon } from 'assets/svg/siren-lined.svg';
import { ReactComponent as SelectDownArrowIcon } from 'assets/svg/downArrow3.svg';

import { cryptoMarket, mappingNftTypeIcon, mappingNftTypeName, setDigit, shortText } from 'utils';
import NftTypeComponent from 'components/v2/nft-card-item/components/nft-type/NftType';
import IconButtonUI from 'ui/icon-button/IconButton';
import ButtonUI from 'ui/button/Button';

import InfoComponent from 'views/market/detail/components/Info';
import { BottomSheet } from 'react-spring-bottom-sheet';
import PriceStyles from 'styles/price';
import Copy from 'ui/copy/Copy';
import moment from 'moment';
import APIService from 'api';

import Input from 'ui/input/Input';
import { useSelector, useDispatch } from 'react-redux';

import { abi } from 'views/nft-item/components/price-item/abi';
import { ethers } from 'ethers';
import { marketAbi } from './components/price-item/marketAbi';

import qs from 'query-string';
import ModalStyles from 'styles/modal/Modal';
import { useNftItem } from './useNftItem';
import { Select } from 'antd';
import SelectUI from 'ui/select/Select';
import NftLoadingComponent from 'styles/nft-loading/NftLoading';

import { alertMessage } from 'store/reducers/System';

const apiService = new APIService();

const NftItemPage: React.FC<any> = () => {
  const { profile } = useSelector((store: IStore) => store.System);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { linkState, collectedUid } = qs.parse(search);
  const { uid } = useParams();
  const isMe = true;
  const pubkeyRef = React.useRef<any>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openBuySheet, setOpenBuySheet] = React.useState(false);
  const [openOfferSheet, setOpenOfferSheet] = React.useState(false);
  const [nftDetail, setNftDetail] = React.useState<any>();
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [reportCtgs, setReportCtgs] = React.useState([]);
  const [reportCtg, setReportCtg] = React.useState();

  const { openModal, setOpenModal, closeModal, reportOptionList, changeReportOption, selectReport } = useNftItem();
  const { Option } = Select;

  const [quantity, setQuantity] = React.useState<number | string>(1);
  const reg = /^[0-9]*$/gi;
  const changeQuantity = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (reg.test(value)) {
      const numberChange = Number(value);
      setQuantity(numberChange || '');
    } else {
      alert('only number please');
    }
  };

  const getReportCtg = async () => {
    const result = await apiService.getReportCtg();

    setReportCtgs(result.list);
  };

  const postReport = async () => {
    const result = await apiService.postReport(uid, { commonCodeUid: selectReport });
    dispatch(alertMessage({ type: 'default', msg: `Report is done.` }));
    closeModal();
  };

  const getNftDetail = async () => {
    const resultNftDetail =
      linkState === 'Created'
        ? await apiService.getMarketItemCreatedDetail({ uid })
        : linkState === 'Collected'
        ? await apiService.getMarketItemCollectedDetail({ uid, collectedUid })
        : await apiService.getMarketItemDetail({ uid });
    setNftDetail(resultNftDetail);
    setQuantity(resultNftDetail.currentQuantity);
  };

  const ITEM_DETAIL = {
    id: 123,
    nftUrl: 'https://user-images.githubusercontent.com/45615584/197339093-1aeb5611-5340-4343-8c6a-930a4a192101.png',
    isFavorite: true,
    favoriteCount: 0,
    name: 'ITEM NAME KOREA TREE',
    type: 'art',
    typeUrl: 'https://user-images.githubusercontent.com/45615584/197333348-0bc6639d-9815-4832-a797-f0ee46df2080.png',
    coinIcon: 1,
    pubkey: '0xC59A2093CB4E27d82921CaF8495173f46ea6d3E1',
    price: 0.009,
    usd: 11.51,
    information: {
      description: `Created by 
  Smircs
  1/1 Art Pieces (10K resolution unlockable content)
  
  Smircs is a non generative collection handcrafted by combrisi X flatizy.
  
  In accepting one, you accept citizenship in Smircia and may be called to serve in its many and highly renowned vodka induced party-wars with the planet Yolo.
  
  The legions are armed, the battle is nigh - the entire Smircian race now stands in silence, for the dawn of their time may finally have come to arrive. With highest reverence and awe, they await the prophesied answer, one the old oracle once determined would yield the outcome for the Smircian race for millenia to come. The prophecy has told of the birth of an elite, the 'exceptional few', members of the human race, a select group uniquely able to provide an answer to a singular question that has echoed through the annals of Smircian history. In the words of the oracle of old - the question is now put to you:
  
  Which Smirc, are you?
  
  `,
      chart: [],
      history: [
        {
          id: 0,
          amount: 1.1,
          tokenIcon: 'https://user-images.githubusercontent.com/45615584/173285266-338438c3-bfff-4649-9407-40d4c0d3a003.png',
          stockTrend: 5.02,
          nftName: 'Parallel Smircs',
          timestamp: '23 Days ago'
        },
        {
          id: 1,
          amount: 0.9,
          tokenIcon: 'https://user-images.githubusercontent.com/45615584/173285266-338438c3-bfff-4649-9407-40d4c0d3a003.png',
          stockTrend: 2.98,
          nftName: 'Smirc META-GAMING-4829',
          timestamp: '12 Days ago'
        },
        {
          id: 2,
          amount: 0.8,
          tokenIcon: 'https://user-images.githubusercontent.com/45615584/173285266-338438c3-bfff-4649-9407-40d4c0d3a003.png',
          stockTrend: -6,
          nftName: 'Smirc ArtBlock Admin',
          timestamp: '2 Days ago'
        }
      ],
      community: [
        {
          id: 0,
          avatar: 'https://user-images.githubusercontent.com/45615584/171994034-ceef1dd0-053a-4101-b89d-159309e272e7.png',
          name: 'justin',
          channelName: 'Bored Ape Yacht Club',
          timestamp: '1hr ago',
          isOnline: false,
          nftData: {
            isOwner: true,
            imageUrl: 'https://user-images.githubusercontent.com/45615584/171992890-e2dd0046-3ea6-433a-8797-3a911fd6db0d.png',
            market: 'The Nature 1/1',
            nftName: 'the nature #125',
            price: 88.069,
            usd: 493201.03,
            timestamp: '12 hours ago',
            author: 'justin',
            cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
          },
          emojis: [
            {
              imageUrl: 'https://user-images.githubusercontent.com/45615584/172003725-905449f1-8e20-4966-8eee-a93681085de4.png',
              name: 'thumbs-up',
              count: 12
            }
          ],
          reply: {
            total: 3,
            timestamp: 'Today at 1.:50AM',
            data: [
              { avatar: 'https://user-images.githubusercontent.com/45615584/172000252-9e51249a-6ab5-47f1-91a3-927ff8ebdc4d.png' },
              { avatar: 'https://user-images.githubusercontent.com/45615584/172000257-04570497-7a8e-4c4c-9b07-a2015af46614.png' },
              { avatar: 'https://user-images.githubusercontent.com/45615584/172000260-53b5102c-1842-4573-bd70-7d94fb67414b.png' }
            ]
          }
        }
      ]
    }
  };

  const moveToEdit = () => {
    navigate(`/nft/item/edit/${uid}`);
  };

  const moveToSell = () => {
    navigate(`/nft/item/sell/${uid}`);
  };

  const actionBuy = () => {
    setOpenBuySheet(true);
  };

  const actionOffer = () => {
    setOpenOfferSheet(true);
  };
  /**
   *
   * @param mintingContractAddress market의 contract addresss를 넣어줍니다.
   * 현재 민팅되고 판매등록된 NFT의 판매정보들을 가져옵니다.
   *
   * * @example
   * const mintingContractAddress = '0xCf04ddF3D4b7C61664215566F3065487A8dC1CAB';
   * getSaleList(mintingContractAddress)
   */
  const getSaleList = async (mintingContractAddress: string) => {
    try {
      let metaProvider;
      if (window.web3) {
        metaProvider = window.web3.currentProvider;
      } else if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.send('eth_requestAccounts');
        metaProvider = window.ethereum;
      } else {
        throw new Error('No web3 provider detected');
      }
      const provider = new ethers.providers.Web3Provider(metaProvider);
      const signer = await provider.getSigner();
      const token = new ethers.Contract(mintingContractAddress, marketAbi, signer);
      const saleList = await token.saleList();
      console.log(saleList);
      console.log(saleList[0].price.toString());
      console.log(saleList[0].saleId.toString());
      console.log(ethers.utils.formatEther(saleList[0].nftId._hex), '///////////////');
      console.log(ethers.utils.formatEther(saleList[0].saleId), '///////////////');
      // console.log(ethers.utils.formatEther(saleList[2]?.price));
      return saleList;
    } catch (e: any) {
      console.log(e);
    }
  };

  // const marketContractAddress = '0x32fB9f1E3C267800C6190a882040B8995Ae5a0B9';
  //
  // const testContractAddress = '0x3C533802c0292150AE866796Dc119A9f312B8216';

  /** contract 주소 변경 내역 **/
  // Besu Ver1 : minting contract address
  // const mintingContractAddress = '0xC9Bc439c8723c5c6fdbBE14E5fF3a1224f8A0f7C';

  // Besu Ver2 : minting contract address
  const mintingContractAddress = '0xa50a51c09a5c451C52BB714527E1974b686D8e77';

  // Besu Ver1 : buy contract address
  // const buyContractAddress = '0xe135783649BfA7c9c4c6F8E528C7f56166efC8a6';

  // Besu Ver2 : buy contract address
  const buyContractAddress = '0x42699A7612A82f1d9C36148af9C77354759b210b';
  /** contract 주소 변경 내역 **/

  /**
   * /**
   *
   * @param mintingContractAddress market의 contract addresss를 넣어줍니다.
   * @param saleId 조회하고싶은 판매정보를 넣어줍니다.
   * 현재 민팅되고 판매등록된 NFT의 판매정보를 가져옵니다.
   *
   * * @example
   * const mintingContractAddress = '0xCf04ddF3D4b7C61664215566F3065487A8dC1CAB';
   * getSaleList(mintingContractAddress)
   */
  const getSale = async (mintingContractAddress: string, saleId: string) => {
    try {
      let metaProvider;
      if (window.web3) {
        metaProvider = window.web3.currentProvider;
      } else if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.send('eth_requestAccounts');
        metaProvider = window.ethereum;
      } else {
        throw new Error('No web3 provider detected');
      }
      const provider = new ethers.providers.Web3Provider(metaProvider);
      const signer = await provider.getSigner();
      const token = new ethers.Contract(mintingContractAddress, marketAbi, signer);
      const saleList = await token.getListInfo(saleId);
      console.log(saleList);
      console.info('[get Sale Success] ', saleList);
      return saleList;
    } catch (e: any) {
      console.info('[get Sale Error] ');
      console.log(e);
    }
  };

  /**
   *
   * @param mintingContractAddress market의 contract addresss를 넣어줍니다.
   * @param contractAddress nft를 민팅한 cotract의 address를 넣어줍니다.
   * @param saleId 위의 nft판매가 등록된 판매 id를 넣어줍니다.
   * @param quantity 구매할 nft의 양만큼 넣어줍니다.
   *
   * @example
   * const mintingContractAddress = '0xCf04ddF3D4b7C61664215566F3065487A8dC1CAB';
   * const buyContractAddress = '0xCf04ddF3D4b7C61664215566F3065487A8dC1CAB';
   * buyNFT(mintingContractAddress, buyContractAddress, '1', 3)
   */
  const buyNFTItem = async (mintingContractAddress: string, buyContractAddress: string, quantity: number) => {
    if (profile && quantity > 0) {
      setIsLoading(true);
      try {
        let metaProvider;
        if (window.web3) {
          metaProvider = window.web3.currentProvider;
        } else if (typeof window.ethereum !== 'undefined') {
          await window.ethereum.send('eth_requestAccounts');
          metaProvider = window.ethereum;
        } else {
          throw new Error('No web3 provider detected');
        }

        const saleId = nftDetail.saleId;

        const provider = new ethers.providers.Web3Provider(metaProvider);
        const signer = await provider.getSigner();
        const token = new ethers.Contract(buyContractAddress, marketAbi, signer);
        const saleInfo: any = await getSale(buyContractAddress, saleId);
        console.log(saleInfo, 'sale');

        const etherPrice: any = ethers.utils.formatEther(saleInfo.price);
        console.log(etherPrice, 'etherPrice');
        console.log(buyContractAddress, 'contractAddress');
        console.log(token, ' token');
        console.log(ethers.utils.parseEther((etherPrice * quantity).toString()), ' ethers.utils.parseEther((etherPrice * quantity).toString())');
        const buyHash = await token.buyNFT(mintingContractAddress, saleId, quantity, { value: ethers.utils.parseEther((etherPrice * quantity).toString()) });
        console.log(buyHash);
        const resultBuy = await apiService.buyComplete(nftDetail.marketItem2Uid, {
          price: nftDetail.price,
          quantity,
          buyContractAddress,
          marketItem2SellUid: Number(uid),
          tokenId: Number(nftDetail.nftId)
        });

        setIsLoading(false);

        console.log(resultBuy, 'resultBuy');

        navigate('/profile');
      } catch (e: any) {
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  const handleLike = async () => {
    const resultMessage = await apiService.postNftFavorite({ uid });

    if (resultMessage === 'Success') {
      setIsFavorite(true);
    }
  };

  const handleUnLike = async () => {
    const resultMessage = await apiService.postNftUnFavorite({ uid });
    if (resultMessage === 'Success') {
      setIsFavorite(false);
    }
  };

  React.useEffect(() => {
    getNftDetail();
    getReportCtg();
  }, [uid]);

  const regexSpace = / /gi;

  return (
    <StyledWrap>
      {nftDetail && (
        <>
          {linkState === 'Created' && profile?.memberUid === nftDetail.memberUid && (
            <View block spacing={19} padding="0 20px 16" align="center">
              <Button text="Edit" full rect color="border" h={40} onClick={moveToEdit} disabled={!nftDetail.editFlag} />
              <Button text="Sell" full rect color="black" h={40} onClick={moveToSell} />
            </View>
          )}
          <View block direction="column" padding="0 20">
            <View block mb={16}>
              <BackgroundUI h={'auto'} radius={6} bgUrl={nftDetail.imageUrl} />
              <View position="absolute" direction={'column'} right={17} bottom={18} align="center" justify="center" spacing={2}>
                {!ITEM_DETAIL.isFavorite ? <HeartFilledIcon width={28} height={28} onClick={handleUnLike} /> : <HeartIcon onClick={handleLike} width={28} height={28} />}
                <Text color={'#fff'} size="sm" weight="bold">
                  {ITEM_DETAIL.favoriteCount}
                </Text>
              </View>
            </View>

            <View direction="column" block spacing={16}>
              <View direction="column" block spacing={8}>
                <NftTypeComponent type={nftDetail.categoryName.toLocaleLowerCase().replace(regexSpace, '')} />
                {/* 제목 */}
                <View block h={19} isOverflow>
                  <Text eclipse={1} size="3xl" weight="bold">
                    {nftDetail.name}
                  </Text>
                </View>
              </View>

              <View block justify="space-between" align="center">
                <View spacing={4} block>
                  <View flex={0}>
                    <Text size="xs" color="#808080" block>
                      Owned by :
                    </Text>
                  </View>
                  <Text size="xs" color="#0075FF" underline>
                    {nftDetail.walletAddress && shortText(nftDetail.walletAddress, 6, 6)}
                  </Text>
                  <View flex={0}>
                    <Copy ref={pubkeyRef} copyValue={ITEM_DETAIL.pubkey}>
                      <IconButtonUI w={24} h={24} reserved="copy" onClick={() => pubkeyRef.current.copy()} />
                    </Copy>
                  </View>
                </View>

                <View spacing={10} align="center" justify="flex-end" flex={0}>
                  <IconButtonUI onClick={() => setOpenModal({ report: true })} w={40} h={40} icon={<SirenIcon />} />
                </View>
              </View>

              <View direction="column" block radius={10} border="1px solid #eaeaea" padding="16px 21" shadow="0px 0px 14px 0px #0000000F">
                <Text size="xs" weight="semi-bold" color={'#808080'}>
                  Current price
                </Text>
                <Price
                  fontSize={30}
                  icon={cryptoMarket(nftDetail.priceKind)}
                  price={setDigit(nftDetail.price, 5, true, false)}
                  suffixStyle={{ marginLeft: 16 }}
                  suffix={<Text size="xs">${setDigit(nftDetail.priceUsd, 2, true, true) || ITEM_DETAIL.usd}</Text>}
                />
              </View>
              <View direction="column" block>
                <Input block label="Item Count" value={`${quantity}`} placeholder="Please enter a quantity" onChange={changeQuantity} />
              </View>
            </View>

            <View block spacing={17} mt={24} mb={32}>
              <ButtonUI full h={48} color="black" disabled={profile?.memberUid === nftDetail.memberUid ? true : false} onClick={actionBuy}>
                Buy Now
              </ButtonUI>
              <ButtonUI full h={48} color="borderBlack" disabled={nftDetail.editFlag} onClick={actionOffer}>
                Make Offer
              </ButtonUI>
            </View>
          </View>

          <InfoComponent {...ITEM_DETAIL?.information} {...nftDetail} desc={nftDetail.description} />

          <BottomSheet
            open={openBuySheet}
            onDismiss={() => setOpenBuySheet(false)}
            blocking={false}
            header={
              <Text size="3xl" weight="bold" align="left" block>
                Buy Now
              </Text>
            }
            snapPoints={({ minHeight }) => minHeight}
          >
            <View align="center" spacing={10} padding={'50px 0 30'} margin="0 20" borderPos="bottom">
              <BackgroundUI bgUrl={nftDetail.imageUrl} w={50} h={50} radius={5} />
              <View direction="column">
                <Text size="xs" weight="bold">
                  {nftDetail.name}
                </Text>
                <Text size="xxs" lh={13}>
                  {mappingNftTypeName[nftDetail.categoryName.toLocaleLowerCase()]}
                </Text>
                <Text size="xxs" lh={13} color={'#808080'}>
                  Creator fee: 0%
                </Text>
              </View>
            </View>

            <View block justify="space-between" align="center" pt={8} pl={20} pr={20} mb={40}>
              <Text size="xs" weight="bold">
                Total price
              </Text>
              <PriceStyles
                extra={
                  <Text size="xxs" color="#808080">
                    ${nftDetail.priceUsd || ITEM_DETAIL.usd}
                  </Text>
                }
                icon={cryptoMarket(1)}
                price={setDigit(nftDetail.price, 3, true, false)}
                suffixStyle={{ paddingLeft: 5 }}
                suffix={
                  <Text size="md" weight="bold">
                    ETH
                  </Text>
                }
              />
            </View>

            <View spacing={7} padding={'0 20px 20'}>
              <ButtonUI fontSize={14} full h={48} color="borderBlack" onClick={() => setOpenBuySheet(false)}>
                Cancel
              </ButtonUI>
              <ButtonUI
                className={!profile ? 'wallet-button' : ''}
                fontSize={14}
                full
                h={48}
                color="black"
                disabled={false}
                onClick={() => buyNFTItem(mintingContractAddress, buyContractAddress, Number(quantity))}
              >
                Complete purchase
              </ButtonUI>
            </View>
          </BottomSheet>
        </>
      )}

      {isLoading && <NftLoadingComponent />}

      <ModalStyles w={'90%'} open={openModal.report} isCenter={true} handleCancle={closeModal} type="confirm">
        <View block direction={'column'}>
          <Display size="xl" weight="bold">
            Report this item
          </Display>
          <Text2 size="xl" margin={['mt-4', 'mb-2']}>
            I think this item is…
          </Text2>

          <SelectUI>
            <Select
              className="select-cross"
              popupClassName="option-menu-cross"
              suffixIcon={<SelectDownArrowIcon />}
              bordered={false}
              defaultValue={'Select a reason'}
              onChange={v => changeReportOption(v)}
            >
              {reportCtgs.length > 0 &&
                reportCtgs.map((option: any, index: number) => (
                  <Option key={index} value={`${option.uid}`}>
                    <Text2 className="item-option-value" size="lg" weight="bold">
                      {option.name}
                    </Text2>
                  </Option>
                ))}
              {/* {reportOptionList.map((option: any, index: number) => (
                <Option key={index} value={`${option.value}`}>
                  <Text2 className="item-option-value" size="lg" weight="bold">
                    {option.name}
                  </Text2>
                </Option>
              ))} */}
            </Select>
          </SelectUI>
          <MarginWrap direction={'column'} align={'center'} block>
            <ButtonUI h={52} color={'black'} onClick={postReport}>
              Report
            </ButtonUI>
          </MarginWrap>
        </View>
      </ModalStyles>
    </StyledWrap>
  );
};

export default NftItemPage;

const StyledWrap = styled.div`
  padding-top: 16px;
  padding-bottom: 80px;
`;

const MarginWrap = styled(View)`
  margin-top: 25px;
`;
