export const FOOTER_NOTICE = [
  {
    id: 0,
    title: `Ethereum Hits 500,000 Validator Milestone`,
    datestamp: 'Jan. 13, 2023',
    url: 'https://blockworks.co/news/ethereum-to-reach-500000-validators'
  },
  {
    id: 1,
    title: `JPMorgan CEO slams Bitcoin as ‘a hyped-up fraud’ and ‘pet rock’`,
    datestamp: 'Jan. 19, 2023',
    url: 'https://finbold.com/jpmorgan-ceo-slams-bitcoin-as-a-hyped-up-fraud-and-pet-rock/'
  },
  {
    id: 2,
    title: `Bitcoin Falls Below $21K; Genesis Nears Bankruptcy Filing; Bitzlato Founder Arrested`,
    datestamp: 'Jan. 19, 2023',
    url: 'https://www.coindesk.com/markets/2023/01/18/crypto-markets-today-bitcoin-falls-below-21k-genesis-nears-bankruptcy-filing-bitzlato-founder-arrested/'
  },
  {
    id: 3,
    title: `Rarible Users Can Now Create Collections on Polygon`,
    datestamp: 'Jan. 23, 2023',
    url: 'https://egamers.io/rarible-users-can-now-create-collections-on-polygon/'
  },
  {
    id: 4,
    title: 'Crypto Markets Today: Bitcoin Moves Sideways at $23K',
    datestamp: 'Jan. 25, 2023',
    url: 'https://www.coindesk.com/markets/2023/01/24/crypto-markets-today-bitcoin-moves-sideways-at-23k/'
  },
  { id: 5, title: 'China starts taxing crypto investors and Bitcoin miners 20%', datestamp: 'Jan. 26, 2023', url: 'https://finbold.com/china-starts-taxing-crypto-investors-and-bitcoin-miners-20/' },
  {
    id: 6,
    title: `Keep an eye out for major company NFT trademark filings this year`,
    datestamp: 'Jan. 26, 2023',
    url: 'https://www.investing.com/news/cryptocurrency-news/keep-an-eye-out-for-major-company-nft-trademark-filings-this-year-2989465'
  },
  {
    id: 7,
    title: 'Amazon NFT Initiative Coming Soon: Exclusive',
    datestamp: 'Jan. 27, 2023',
    url: 'https://blockworks.co/news/amazon-nft-marketplace-web3?utm_source=Sailthru&utm_medium=email&utm_campaign=Daily%20NL%201.26.23&utm_term=Daily%20Newsletter'
  }
];
