import React from 'react';
import { ReactComponent as Badge } from 'assets/svg/certBadge.svg';
import styled, { css } from 'styled-components';
import noNft from 'assets/common/noNft.png';
const AuthorProfileStyles: React.FC<any> = ({ border = false, icon, w, h, iconW, iconH, mark = true, ...props }: any) => {
  const handleImgError = (e: any) => {
    e.target.onerror = null;
    e.target.src = noNft;
  };

  return (
    <AuthorProfile {...props}>
      <img className="img-icon" src={icon} style={{ borderRadius: 50, width: w ? w : 50, height: h ? h : 50, border: border ? '2px solid #fff' : 'none' }} onError={handleImgError} />
      {mark && <Badge width={iconW ? iconW : 20} height={iconH ? iconH : 20} style={{ position: 'absolute', right: 0, bottom: 0 }} />}
    </AuthorProfile>
  );
};

export default AuthorProfileStyles;

const AuthorProfile = styled.div`
  position: relative;

  .img-icon {
    background-color: #fff;
    object-fit: cover;
  }
`;
