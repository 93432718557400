import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SubHeader from 'components/Header/SubHeader';
import styled from 'styled-components';
import { View, Text, FilterSearch, Tab, Pagination } from 'styles';
import ListCardComponent from './components/ListCard';
import { SCAMS_LIST, SEARCH_RESURLT } from 'api/mock/scam';
import { ReactComponent as RefreshSVG } from 'assets/svg/refresh.svg';
import { useDevice } from 'hook/useDevice';
import qs from 'query-string';
import APIService from 'api';
const apiService = new APIService();

const ScamPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const { search, hash } = useLocation();
  const { name, searchValue } = qs.parse(search);
  const { total } = SEARCH_RESURLT;
  const [query, setQuery] = React.useState({ filter: 0, text: '', activityTab: 0 });
  const [isSort, setIsSort] = React.useState(false);
  const [sort, setSort] = React.useState<string>('latest');

  const sortValues = ['latest', 'oldest', 'view'];

  const { isMobileSmall } = useDevice();

  const changeTab = React.useCallback(
    (idx: number) => {
      setSort(sortValues[idx]);
      setQuery({ ...query, activityTab: idx });
    },
    [query.activityTab]
  );

  const [scamAlert, setScamAlert] = React.useState<any>(null);

  const getScamAlert = async (page: number) => {
    const result = await apiService.getScamList({ searchValue, page, order: sort });
    setScamAlert(result);
  };

  const getSearchPaging = (page: number, pageSize: number) => {
    window.scrollTo(0, 0);
    navigate(`/scam?searchValue=${searchValue}&page=${page}&order=${sort}`);
    getScamAlert(page);
  };

  React.useEffect(() => {
    getScamAlert(1);
  }, []);

  React.useEffect(() => {
    navigate(`/scam?searchValue=${query.text}&page=${1}&order=${sort}`);
    getScamAlert(1);
  }, [query.text, searchValue, sort]);

  React.useEffect(() => {
    setIsSort(false);
    setSort('latest');
  }, [query.text, searchValue]);

  return (
    <Wrap>
      <SubHeader title={'Scam Alerts'} isBackHome />

      <View direction="column" spacing={50} block>
        <View direction="column" spacing={30} block>
          <FilterSearch className="search" placeholder={'NFT ITEM / Collection Search'} query={query} setQuery={setQuery} />

          {searchValue ? (
            <View block className="search-result" justify="space-between" align="center">
              <Text size={isMobileSmall ? 'sm' : 'md'} weight="bold">
                Total of{' '}
                <Text size={isMobileSmall ? 'sm' : 'md'} color={'#0075FF'} weight={'bold'}>
                  {scamAlert?.totalCount}
                </Text>{' '}
                search results.
              </Text>
              <View align="center" justify="flex-end" onClick={() => setQuery({ filter: 0, text: '', activityTab: 0 })}>
                <Text size={isMobileSmall ? 'xxs' : 'xs'} color="#0075FF" underline>
                  Reset of Search
                </Text>
                <RefreshSVG />
              </View>
            </View>
          ) : (
            <View direction="column" align={'center'} block>
              <Tab active={query.activityTab} padding={false}>
                <Tab.Item text="Latest" value={0} onClick={() => changeTab(0)} />
                <Tab.Item text="Oldest" value={1} onClick={() => changeTab(1)} />
                <Tab.Item padding={false} text="Most Viewed" value={2} onClick={() => changeTab(2)} />
              </Tab>
            </View>
          )}
        </View>

        <div className="list-container">{scamAlert && scamAlert.list.map((item: any, idx: number) => <ListCardComponent key={idx} {...item} view />)}</div>
      </View>

      <Pagination total={scamAlert?.totalCount} defaultPageSize={10} pageSize={10} currentPage={scamAlert?.currentPage} onChange={getSearchPaging} />
    </Wrap>
  );
};

export default ScamPage;

const Wrap = styled.div`
  padding: 30px 20px;

  .search {
    width: 100%;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
    & > div {
      border-radius: 8px !important;
    }
  }

  .search-result {
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
  }
`;
