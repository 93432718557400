import React from 'react';
import styled from 'styled-components';
import { Text, View } from 'styles';

const ReactionComponent: React.FC<any> = ({ value, bgUrl, count, ...props }) => {
  return (
    <Wrap {...props}>
      <View className="inner" spacing={12} align={'center'}>
        <img src={bgUrl} className={'img-emoji'} />
        <Text size="xxs" color="#7D7D7D">
          {count}
        </Text>
      </View>
    </Wrap>
  );
};

export default ReactionComponent;

const Wrap = styled.div`
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 60px;

  &:active {
    background-color: #bdbdbd;
  }

  .inner {
    padding: 2px 8px;
  }

  .img-emoji {
    width: 12px;
    height: 12px;
  }
`;
