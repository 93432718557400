import React from 'react';
import styled, { css } from 'styled-components';

const SelectLabelStyle: React.FC<any> = React.forwardRef(({ label, children, ...props }, ref) => {
  const inputRef: any = React.useRef();
  React.useImperativeHandle(
    ref,
    () => ({
      getValue: () => inputRef?.current.value
    }),
    [inputRef]
  );

  return (
    <Wrap className={'nftmoa-select-container'}>
      {label && <Label>{label}</Label>}
      <StyledSelect>{children}</StyledSelect>
    </Wrap>
  );
});

export default SelectLabelStyle;

const border = css`
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 12px;
`;
const Wrap = styled.div``;
const Label = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin: 0px 0px 12px;
`;
const StyledSelect = styled.div`
  .css-1s2u09g-control {
    border-radius: 9px;
    border: 1px solid #e4e4e4;
  }

  .css-1s88qud-menu {
    border: 1px solid #000000;
  }
  .css-1rhbuit-multiValue {
    border: 1px solid #efefef;
    background-color: #fff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
    border-radius: 80px;
    color: #000000;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
  }
  .css-1n7v3ny-option {
    color: #0085ff;
    background: transparent;
  }

  .css-1jqbu51-MenuList {
    & > div {
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      text-align: center;

      &:nth-child(even) {
        background: #f4f4f4;
      }
    }
  }
`;
