import React from 'react';
import styled from 'styled-components';
import { Text, View } from 'styles';
import { ReactComponent as ArrowSVG } from 'assets/svg/arrow-right-news.svg';
import { ReactComponent as ShareSvg } from 'assets/svg/copy.svg';

import { EMOJI, IMOTION_LIST } from 'api/mock/scam';
import EmotionStyles from 'styles/emotion/Emotion';

interface NewsItemProps {
  datetime?: any;
  headline?: string;
  subject?: string;
  desc?: string;
  link?: string;
  sourceLink?: string;
}

const NewsItemComponent: React.FC<NewsItemProps> = ({ datetime, headline, subject, desc, link, sourceLink }) => {
  return (
    <Wrap>
      <div className="contents">
        <View direction="column" spacing={4}>
          <Text size="xl" className="subject" weight="semi-bold">
            {subject}
          </Text>
          <Text align="right" size="xs">
            {datetime}
          </Text>
        </View>

        <View direction="column" spacing={10}>
          <Text size="sm">{desc}</Text>

          <View direction="column" spacing={10}>
            <View justify="space-between" align="center">
              <Text onClick={() => window.open(link)} className={'link-source'} size="sm" color="#808080">
                {link}
              </Text>
              <ArrowSVG />
            </View>

            <View align="center" justify="space-between">
              <View className="reaction-container" spacing={15}>
                {IMOTION_LIST.map((item, idx) => (
                  <EmotionStyles key={idx} {...item} onClick={() => console.log('counting')} />
                ))}
              </View>
              <StyledCopy align="center" justify="center">
                <ShareSvg />
              </StyledCopy>
            </View>
          </View>
        </View>
      </div>
    </Wrap>
  );
};

export default NewsItemComponent;

const Wrap = styled.div`
  background: #ffffff;
  overflow: hidden;
  border-bottom: 1px solid #efefef;
  margin-bottom: 15px;

  & + div {
    margin-top: 14px;
  }

  .contents {
    padding: 16px;
  }

  .link-source {
    max-width: 278px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
  }

  .reaction-container {
    border-top: 0.1px solid #efefef;
    flex-wrap: wrap;
  }
`;

const StyledCopy = styled(View)`
  width: 24px;
  height: 24px;
  border: 1px solid #eae9e9;
  border-radius: 8px;
`;
