import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Text, View } from 'styles';

interface EmotionProps extends HTMLAttributes<HTMLDivElement> {
  value?: any;
  imageUrl?: any;
  count?: any;
}

const EmotionStyles: React.FC<EmotionProps> = ({ value, imageUrl, count }) => {
  return (
    <Wrap>
      <View className="inner" spacing={12} align={'center'}>
        <img src={imageUrl} className={'img-emoji'} />
        <Text size="xxs" color="#7D7D7D">
          {Math.floor(Math.random() * 100)}
        </Text>
      </View>
    </Wrap>
  );
};

export default EmotionStyles;

const Wrap = styled.div`
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 60px;

  &:active {
    background-color: #bdbdbd;
  }

  .inner {
    padding: 2px 8px;
  }

  .img-emoji {
    width: 12px;
    height: 12px;
  }
`;
