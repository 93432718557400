import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { profileUpdate } from 'store/reducers/System';
import APIService from 'api';

const apiService = new APIService();
export const useOtp = () => {
  const navigate = useNavigate();
  const moveToBack = () => navigate(-1);
  const dispatch = useDispatch();

  const [qrcodeInfo, setQrcodeInfo] = React.useState({ barcode: '', uri: '', secret: '' });
  const [otpValue, setOtpValue] = React.useState<any>('');

  const [isOpen, setIsOpen] = React.useState<{ empty: boolean; error: boolean; complete: boolean }>({ empty: false, error: false, complete: false });
  const checkOtp = async () => {
    try {
      const result = await apiService.postOtpActivate({ code: otpValue });
      if (result.data?.activate === false) {
        setIsOpen({ empty: false, error: true, complete: false });
        return;
      }
      if (result.message === 'Success') {
        const profileResult = await apiService.getMember();
        dispatch(profileUpdate({ profile: profileResult }));
        setIsOpen({ empty: false, error: false, complete: true });
      } else {
        setIsOpen({ empty: false, error: true, complete: false });
      }
    } catch (e) {
      setIsOpen({ empty: false, error: true, complete: false });
    }
  };

  const changeInputOtp = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setOtpValue(value.trim());
  };

  const closeModal = () => {
    setIsOpen({ empty: false, error: false, complete: false });
  };

  const getQrcodeInfo = async () => {
    const result = await apiService.postOtpCreate();
    setQrcodeInfo(result);
  };

  const otpComplete = () => {
    navigate('/setting');
  };

  React.useEffect(() => {
    getQrcodeInfo();
  }, []);

  return { qrcodeInfo, moveToBack, isOpen, checkOtp, closeModal, changeInputOtp, otpComplete, setIsOpen, setOtpValue };
};
