import { Popover } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { View, Text } from 'styles';
import { ReactComponent as MoreIcon } from 'assets/icon/more.svg';
import { ReactComponent as MoreFillIcon } from 'assets/icon/more-fill.svg';
import { ReactComponent as ShopIcon } from 'assets/icon/shop.svg';
import { ReactComponent as LinkIcon } from 'assets/icon/link.svg';
import { ReactComponent as EditIcon } from 'assets/icon/edit.svg';
import Copy from 'ui/copy/Copy';
import { cryptoMarket, mappingNftTypeIcon, mappingNftTypeName, setDigit } from 'utils';
import NftTypeComponent from './components/nft-type/NftType';

export interface NftCardItemProps extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'id'> {
  id: number | string;
  name: string;
  type: string;
  typeUrl?: string;
  coinIcon: number;
  price?: number;
  count?: number;
  link?: string;
  nftUrl?: string;

  uid?: number;
  imageUrl?: string;
  categoryName?: string;
  commonCodeUid?: number;
  createdAt?: string;
  deletedFlag?: boolean;
  description?: string;
  editFlag?: boolean;
  externalUrl?: string;
  keyword?: string;
  lockFlag?: boolean;
  memberUid?: number;
  priceKind?: number;
  quantity?: number;
  usedFlag?: boolean;
  currentQuantity?: number;
  linkState?: string;
  collectedUid?: number;
  moreMenu?: boolean;
}

const NftCardItemComponent: React.FC<NftCardItemProps> = ({
  uid,
  imageUrl,
  categoryName = 'art',
  commonCodeUid,
  createdAt,
  deletedFlag,
  description,
  editFlag,
  externalUrl,
  keyword,
  lockFlag,
  memberUid,
  priceKind = 1,
  quantity,
  usedFlag,
  id,
  name,
  type,
  link,
  typeUrl,
  coinIcon,
  price,
  count,
  nftUrl,
  currentQuantity,
  linkState,
  collectedUid,
  moreMenu = true,
  ...props
}) => {
  const navigate = useNavigate();

  const linkRef = React.useRef<any>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const content = (
    <StyledMenuContent direction="column" block>
      <View cursor="pointer" spacing={9} align="center" block onClick={() => navigate(`/nft/item/sell/${uid}`)}>
        <ShopIcon />
        <Text size="xs" weight="bold">
          List for sale
        </Text>
      </View>
      <Copy copyValue={link} target={`link-${uid}`}>
        <View cursor="pointer" spacing={9} align="center" onClick={() => linkRef.current.copy()} block>
          <LinkIcon />
          <Text size="xs" weight="bold">
            Copy link
          </Text>
        </View>
      </Copy>
      <View cursor="pointer" spacing={9} align="center" onClick={() => navigate(`/nft/item/edit/${uid}`)} block>
        <EditIcon />
        <Text size="xs" weight="bold">
          Edit
        </Text>
      </View>
    </StyledMenuContent>
  );

  const moveToDetail = () => {
    const linkUrl = `/nft/item/${uid}${linkState ? `?linkState=${linkState}${collectedUid ? `&collectedUid=${collectedUid}` : ''}` : ''}`;
    navigate(linkUrl);
  };

  const regexSpace = / /gi;

  return (
    <StyledWrap {...props}>
      {/* NFT 수량 */}
      <View flex={0} flexBasis={0} position="absolute" top={6} left={6} zIndex={1} onClick={moveToDetail}>
        <View bgColor="#fff" flex={0} flexBasis={0} border="1px solid #EAEAEA" opacity={0.8} padding="3px 6" radius={6}>
          <Text size="xxs" weight="bold">
            X {currentQuantity || quantity}
          </Text>
        </View>
      </View>

      <StyledCard onClick={moveToDetail}>
        <div className="inner">
          <View direction="column" spacing={4} block>
            {/* NFT 이미지 */}
            <img className="nft-img" src={imageUrl || nftUrl} />

            {/* NFT 제목 */}
            <Text size="sm" weight="bold" eclipse={1}>
              {name}
            </Text>
          </View>

          <View direction="column" spacing={10}>
            {/* NFT Type 컴포넌트 */}
            <NftTypeComponent type={categoryName!.toLocaleLowerCase().replace(regexSpace, '')} />

            {price && price > 0 && (
              <View direction="column">
                <Text size="xxs" color="#808080" weight="bold">
                  Price
                </Text>

                <View align="center" spacing={8}>
                  <img className="nft-chip" src={cryptoMarket(priceKind! || 1)} />
                  <Text size="md" weight="bold">
                    {/* {setDigit(price, 3, true, true)} */}
                    {setDigit(price, 6, true, true)}
                  </Text>
                </View>
              </View>
            )}
            {/* Price */}
          </View>
        </div>
      </StyledCard>

      {moreMenu && (
        <StyledMenu>
          <Popover open={open} onOpenChange={open => setOpen(open)} trigger={'click'} content={content} placement="bottomLeft">
            {!open ? <MoreIcon cursor="pointer" fill="#8B8B8B" /> : <MoreFillIcon cursor="pointer" fill="#8B8B8B" />}
          </Popover>
        </StyledMenu>
      )}
      {/* 더보기 버튼 */}
    </StyledWrap>
  );
};

export default NftCardItemComponent;

const StyledWrap = styled.div`
  position: relative;
  flex: 0 1 calc(50% - 5px);
  min-width: 122px;
  height: 288px;
`;

const StyledCard = styled.div`
  border: 1px solid #efefef;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  overflow: hidden;
  height: 100%;

  .inner {
    padding: 10px;
    overflow: hidden;
  }

  .nft-img {
    width: 100%;
    height: 142px;
    object-fit: cover;
    border-radius: 6px;
    overflow: hidden;
  }

  .nft-type {
    width: 15px;
    height: 18px;
    object-fit: contain;
  }

  .nft-chip {
    width: 9px;
    height: 16px;
    object-fit: contain;
  }
`;

const StyledMenuContent = styled(View)`
  & > div + div {
    padding-top: 8px;
    border-top: 1px solid #eaeaea;
  }
  & > div {
    padding: 8px 16px;
  }
`;

const StyledMenu = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  line-height: 0;
`;
