import { SORT_LIST } from 'api/mock/ctg';
import { NFT_ITEMS } from 'api/mock/nft';
import SubHeader from 'components/Header/SubHeader';
import NftCardItemComponent from 'components/v2/nft-card-item/NftCardItem';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { View, Text } from 'styles';
import ModalStyles from 'styles/modal/Modal';
import AvatarUI from 'ui/avatar/Avatar';
import BackgroundUI from 'ui/background/Background';
import SelectButtonUI from 'ui/select-button/SelectButton';
import APIService from 'api';
const apiService = new APIService();

const MarketCollectionListPage: React.FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  console.log(uid, 'uid');
  const { pathname, search } = useLocation();
  const [userInfo, setUserInfo] = React.useState<any>();
  const [marketList, setMarketList] = React.useState<any[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<string>('recently');
  const [openSortModal, setOpenSortModal] = React.useState(false);

  const getUserMarketInfo = async () => {
    const result = await apiService.getMembers(uid!);

    if (result.uid) {
      setUserInfo(result);
    }
  };

  const getMarketItemList = async ({ page, size = 20 }: any) => {
    setLoading(true);
    const resultMyItem = await apiService.getMarketMemberItemList({ page, size, sort, uid });
    if (marketList.length > 0) {
      marketList.at(-1).uid !== resultMyItem.list.at(-1).uid && setMarketList([...marketList, ...resultMyItem.list]);
    } else {
      setMarketList(resultMyItem.list);
    }

    setLoading(false);
  };

  const onChangeSort = (value: string) => {
    if (sort !== value) {
      setSort(value);
      setMarketList([]);
    }
    setOpenSortModal(false);
  };

  const closeModal = () => {
    setOpenSortModal(false);
  };

  const moveToDetail = (id: number) => {
    navigate(`/nft/item/${id}`);
  };

  React.useEffect(() => {
    getUserMarketInfo();
  }, []);

  React.useEffect(() => {
    getMarketItemList({ page: 1, sort });
  }, [sort]);

  return (
    <StyledWrap>
      {userInfo && (
        <>
          <SubHeader title={userInfo.name} />

          <View direction="column" block padding="0 0 60">
            <BackgroundUI bgUrl={userInfo.backgroundImage} />
            <View direction="column" position="relative" block>
              <View align="center" position="absolute" spacing={8} top={-20} left={20}>
                <AvatarUI imgUrl={userInfo.photoUri} />
                <View direction="column" spacing={4} pt={10} block>
                  <Text eclipse={1} size="xl" weight="bold">
                    {userInfo.name}
                  </Text>
                  <Text eclipse={1} size="xxs">
                    {userInfo.description}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View mt={20} mb={20} direction="column" padding="0 20" block justify="center">
            <View justify="flex-end" block mt={8}>
              <SelectButtonUI bgColor="#F9F9F9" fontSize={14} weight="700" w={180} h={40} onClick={() => setOpenSortModal(true)}>
                {SORT_LIST.filter(item => item.value === sort)[0].name}
              </SelectButtonUI>
            </View>
          </View>

          <View padding="0 20" block wrap="wrap" spacing={10}>
            {marketList.length > 0 && marketList.map((item, idx) => <NftCardItemComponent key={idx} {...item} />)}
          </View>

          <ModalStyles w={'90%'} open={openSortModal} isCenter={true} handleCancle={closeModal} type="select">
            {SORT_LIST.map((item, idx) => (
              <StyledOptionItem key={idx} active={sort === item.name} className="select-item" onClick={() => onChangeSort(item.name)}>
                {item.name}
              </StyledOptionItem>
            ))}
          </ModalStyles>
        </>
      )}
    </StyledWrap>
  );
};

export default MarketCollectionListPage;

const StyledWrap = styled.div`
  padding-bottom: 100px;
`;
const StyledOptionItem = styled.div<{ active?: boolean }>`
  color: ${p => (p.active ? '#0075FF' : '#808080')} !important;
`;
