import React from 'react';
import styled from 'styled-components';

interface LabelboxProps {
  gap?: number;
  label?: any;
  suffix?: any;
  prefix?: any;
  style?: any;
  direction?: 'row' | 'column';
  justify?: 'baseline' | 'center' | 'end' | 'first baseline' | 'flex-end' | 'flex-start' | 'last baseline' | 'left' | 'right' | 'safe' | 'space-around' | 'space-between';
  align?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch' | 'inherit' | 'initial' | 'unset';
  color?: string;
  fontSize?: number;
  isUpper?: boolean;
}

const LabelboxStyles: React.FC<LabelboxProps> = ({ label, suffix, prefix, children, ...props }) => {
  return (
    <Wrap {...props}>
      <StyledLabel className="wrap-list" {...props}>
        {prefix && prefix}
        <Label {...props}>{label}</Label>
        {suffix && suffix}
      </StyledLabel>
      {children}
    </Wrap>
  );
};

export default LabelboxStyles;

LabelboxStyles.defaultProps = {
  direction: 'column',
  color: '#000',
  fontSize: 16,
  isUpper: false
};

const Wrap = styled.div<LabelboxProps>`
  display: flex;
  flex-direction: ${p => p.direction} !important;
  justify-content: ${p => p.justify} !important;
  align-items: ${p => p.align} !important;
`;

const StyledLabel = styled.div<LabelboxProps>`
  margin-bottom: 12px;
  ${p => p.gap && `gap: ${p.gap}px`};
`;

const Label = styled.div<LabelboxProps>`
  font-weight: 700;
  font-size: ${p => p.fontSize}px;
  color: ${p => p.color};

  ${p => p.isUpper && `text-transform: uppercase`}
`;
