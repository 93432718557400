import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import SubHeader from 'components/Header/SubHeader';
import { MSG_ROOM_LIST } from 'api/mock/community';
import { Button, CommentItem } from 'styles';
import APIService from 'api';
const apiService = new APIService();

import moment from 'moment';

const MessageRoomView: React.FC<any> = () => {
  const navigate = useNavigate();
  const { uname }: any = useParams();
  const [dmList, setDmList] = React.useState<any>([]);

  const getDm = async () => {
    const resultDm = await apiService.getDmDetail({ fromMemberUid: uname });
    setDmList(resultDm.list);
  };

  const handleAccepted = async ({ communityChannelUid, communityGroupUid, shortUrl }: any) => {
    const resultInvite = await apiService.postInviteUrlConfirm({ uid: shortUrl });

    if (resultInvite === 200) {
      alert('Invite Success');
      navigate(`/community/${communityChannelUid}/group/${communityGroupUid}`);
    }
  };

  React.useEffect(() => {
    getDm();
  }, []);
  return (
    <Wrap>
      {dmList.length > 0 && (
        <>
          <SubHeader
            title={
              <PageTitle>
                <p className="name">{dmList[0].memberName}</p>
                {/* {introComment && <sub>{introComment}</sub>} */}
              </PageTitle>
            }
          />
          {dmList.map((item: any, idx: number) => (
            <div key={idx}>
              <CommentItem name={item.memberName} avatar={item.memberPhoto} isOnline={false} content={item.message} timestamp={moment(item.createdAt).fromNow()} />
              {item.type === 2 && (
                <StyledButton>
                  <Button
                    rect
                    w={230}
                    h={40}
                    text={'ACCEPTED'}
                    color={'blue'}
                    onClick={() => handleAccepted({ communityChannelUid: item.communityChannelUid, communityGroupUid: item.communityGroupUid, shortUrl: item.shortUrl })}
                  />
                </StyledButton>
              )}
            </div>
          ))}
        </>
      )}
    </Wrap>
  );
};

export default MessageRoomView;

const Wrap = styled.div`
  padding: 14px 20px;
`;

const PageTitle = styled.div`
  line-height: 0;
  font-size: 0;

  .name {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
  }

  sub {
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #000000;
  }
`;

const StyledButton = styled.div`
  padding-left: 40px;
`;
