import axios from 'axios';
import cookie from 'js-cookie';

export const apiBaseUrl = 'https://api.cantena.io';

const api = axios.create({ baseURL: apiBaseUrl, timeout: 60 * 1000 });

const tokenIn = () => {
  api.defaults.headers.common['Authorization'] = `Bearer ${cookie.get('accessToken') || ''}`;
};

const tokenDelete = () => {
  api.defaults.headers.common['Authorization'] = ``;
};

const tokenInFile = () => {
  api.defaults.headers.common['Authorization'] = `Bearer ${cookie.get('accessToken') || ''}`;
  api.defaults.headers.common['Content-Type'] = 'multipart/form-data';
};

const errorHandler = (error: any) => {
  // 서버에러로 인한 에러
  // if (error.response.data.code === '__under_maintenance__') {
  // }
};

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status >= 500) errorHandler(error);
    else return Promise.reject(error);
  }
);

class APIService {
  private cache: { [key: string]: { timestamp: number; data: any } } = {};

  async usdtEth() {
    const { data } = await api.get('/api/exchange/USDT_ETH').then(res => res.data);
    return data;
  }

  async emailCheck(email: any) {
    const { data } = await api.get(`/api/signup?email=${email}`).then(res => res.data);
    return data;
  }

  async signUp(json: any) {
    const data = await api.post('/api/signup', json).then(res => res.data);
    return data;
  }

  async signIn(json: any) {
    const { data } = await api.post('/api/signin', json).then(res => res.data);
    return data;
  }

  async refreshToken(json: any) {
    try {
      const { data } = await api.put('/api/signin/refresh', json).then(res => res.data);

      return data;
    } catch {
      cookie.remove('accessToken');
      cookie.remove('refreshToken');
      cookie.remove('expired');
      window.location.href = window.location.origin;
    }
  }

  async signInRefresh(json: any) {
    const { data } = await api.put('/api/signin/refresh', json).then(res => res.data);
    return data;
  }

  async putMember(json: any) {
    await tokenIn();
    const result = await api.put('/api/v1/member', json).then(res => res.data);
    return result;
  }

  async searchMintable(query: any) {
    const { data } = await api.get(`/api/search/mintable.app?query=${query}`).then(res => res.data);
    return data;
  }

  async getCollection({ name, keyword }: any) {
    const { data } = await api.get(`/api/collection?name=${name ? name : ''}&keyword=${keyword ? keyword : ''}`).then(res => res.data);
    return data;
  }

  async getToken({ page = 1, size = 10, address = '' }) {
    const { data } = await api.get(`/api/token?page=${page}&size=${size}&address=${address}`).then(res => res.data);
    return data;
  }

  async getArticleList({ page = 1, size = 10, offsetUid = '' }) {
    const { data } = await api.get(`/api/article2?page=${page}&size=${size}&offsetUid=${offsetUid}`).then(res => res.data);
    return data;
  }

  async getArticleDetail({ uid }: any) {
    const { data } = await api.get(`/api/article2/${uid}`).then(res => res.data);
    return data;
  }

  async putArticleDetailCount({ uid }: any) {
    const { data } = await api.put(`/api/article2/${uid}/viewCount`).then(res => res.data);
    return data;
  }

  async putScamDetailCount(uid: any) {
    const { data } = await api.put(`/api/scamBoard/${uid}/viewCount`).then(res => res.data);
    return data;
  }

  async getFaqCtg() {
    const { data } = await api.get('/api/commonCode?commonCodeUid=2').then(res => res.data);
    return data;
  }

  async getFaqList({ uid, page, size = 10 }: any) {
    const { data } = await api.get(`/api/faq?commonCodeUid=${uid}&page=${page}&size=${size}`).then(res => res.data);
    return data;
  }

  async getCommunityChannelTag() {
    const { data } = await api.get(`/api/commonCode?commonCodeUid=4`).then(res => res.data);
    return data;
  }

  async getCommunityChannelList({ communityChannelUid, searchKey, searchValue }: any) {
    const { data } = await api
      .get(`/api/community/channel?${communityChannelUid ? `communityChannelUid=${communityChannelUid}` : ''}${searchValue ? `&searchKey=introduce&searchValue=${searchValue}` : ''}`)
      .then(res => res.data);
    return data;
  }

  async getCommunityChannelCtg() {
    const { data } = await api.get('/api/commonCode?commonCodeUid=5').then(res => res.data);
    return data;
  }

  async createCommunityChannel(json: any) {
    await tokenIn();
    const { data } = await api.post('/api/community/channel', json).then(res => res.data);
    return data;
  }

  async editCommunityChannel({ uid, json }: any) {
    await tokenIn();
    const { data } = await api.put(`/api/community/channel/${uid}`, json).then(res => res.data);
    return data;
  }

  async getCommunityChannelView({ uid }: any) {
    const { data } = await api.get(`/api/community/channel/${uid}`).then(res => res.data);
    return data;
  }

  async createCommunityGroup(uid: any, json: any) {
    await tokenIn();
    const { data } = await api.post(`/api/community/channel/${uid}/group`, json).then(res => res.data);
    return data;
  }

  async getCommunityGroupList({ uid }: any) {
    const { data } = await api.get(`/api/community/channel/${uid}/group`).then(res => res.data);
    return data;
  }

  async getCommunityGroupView({ uid, gid }: any) {
    const { data } = await api.get(`/api/community/channel/${uid}/group/${gid}`).then(res => res.data);
    return data;
  }

  async searchMember({ keyword, memberInfoUid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/member/search?${keyword ? `searchValue=${keyword}` : ''}${memberInfoUid ? `memberInfoUid=${memberInfoUid}` : ''}`).then(res => res.data);
    return data;
  }

  async sendInvite({ uid, gid, json }: any) {
    await tokenIn();
    const { status } = await api.post(`/api/community/channel/${uid}/group/${gid}/invite`, json).then(res => res.data);
    return status;
  }

  async postInviteUrl(json: any) {
    await tokenIn();
    const { data } = await api.post(`/api/community/shortUrl`, json).then(res => res.data);
    return data;
  }

  async getInviteUrl({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/community/shortUrl/${uid}`).then(res => res.data);
    return data;
  }

  async postInviteUrlConfirm({ uid }: any) {
    await tokenIn();
    const { status } = await api.post(`/api/community/shortUrl/${uid}`).then(res => res.data);
    return status;
  }

  async getCommunityMember({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/community/channel/${uid}/member`).then(res => res.data);
    return data;
  }

  async getCommunityValidate({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/community/channel/${uid}/member/validate`).then(res => res.data);
    return data;
  }

  async getCommunityGroupValidate({ uid, gid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/community/channel/${uid}/group/${gid}/member/validate`).then(res => res.data);
    return data;
  }

  async getCommunityGroupMember({ uid, gid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/community/channel/${uid}/group/${gid}/member`).then(res => res.data);
    return data;
  }

  async getCommunityBoardList({ uid, gid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/community/channel/${uid}/group/${gid}/board`).then(res => res.data);
    return data;
  }

  async getCommunityBoardPost({ uid, gid, json }: any) {
    await tokenIn();
    const { status } = await api.post(`/api/community/channel/${uid}/group/${gid}/board`, json).then(res => res.data);
    return status;
  }

  async getCommunityMyGroup({ offsetUid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/community/myGroup${offsetUid ? `offsetUid=${offsetUid}` : ''}`).then(res => res.data);
    return data;
  }

  // async getCommunityView({ uid }: any) {
  //   const { data } = await api.get(`/api/community/board/${uid}`).then(res => res.data);
  //   return data;
  // }

  // async putCommunityCount({ uid }: any) {
  //   const { data } = await api.put(`/api/community/board/${uid}/viewCount`).then(res => res.data);
  //   return data;
  // }

  async postCommunity(json: any) {
    await tokenIn();
    const { data } = await api.post('/api/community/board', json).then(res => res.data);
    return data;
  }

  async getCollectionCategory() {
    const { data } = await api.get('/api/commonCode?commonCodeUid=6').then(res => res.data);
    return data;
  }

  async getCollectionList({ searchValue, nftCollectionUid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/nft/collection?searchValue=${encodeURIComponent(searchValue)}${nftCollectionUid ? `&nftCollectionUid=${nftCollectionUid}` : ''}`).then(res => res.data);
    return data;
  }

  async getCollectionItemList({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/nft/collection/${uid}/item`).then(res => res.data);
    return data;
  }

  async getNftList({ searchValue, nftUid }: any) {
    // console.log(encodeURI(searchValue), 'encodeURI(searchValue)');
    const { data } = await api.get(`/api/nft?searchValue=${encodeURIComponent(searchValue)}${nftUid ? `&nftUid=${nftUid}` : ''}`).then(res => res.data);
    return data;
  }

  async getNftListPage({ searchValue, page }: any) {
    // console.log(encodeURI(searchValue), 'encodeURI(searchValue)');
    const { data } = await api.get(`/api/nft?searchValue=${encodeURIComponent(searchValue)}${page ? `&page=${page}` : ''}`).then(res => res.data);
    return data;
  }

  async getNftRelatedList({ uid, size = 10 }: any) {
    const { data } = await api.get(`/api/nft/${uid}/related?size=${size}`).then(res => res.data);
    return data;
  }

  async getNftView({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/nft/${uid}`).then(res => res.data);
    return data;
  }

  async postNftFavorite({ uid }: any) {
    await tokenIn();
    const { message } = await api.post(`/api/nft/${uid}/favorite`).then(res => res.data);
    return message;
  }
  async postNftUnFavorite({ uid }: any) {
    await tokenIn();
    const { message } = await api.delete(`/api/nft/${uid}/favorite`).then(res => res.data);
    return message;
  }

  async postNftUpload(json: any) {
    await tokenIn();
    const { data } = await api.post('/api/market', json).then(res => res.data);
    return data;
  }

  async getDm() {
    await tokenIn();
    const { data } = await api.get('/api/dm').then(res => res.data);
    return data;
  }

  async getDmDetail({ fromMemberUid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/dm/${fromMemberUid}`).then(res => res.data);
    return data;
  }

  async postDm(json: any) {
    await tokenIn();
    const { data } = await api.post('/api/dm', json).then(res => res.data);
    return data;
  }

  async getMarketCtg() {
    const { data } = await api.get('/api/commonCode?commonCodeUid=6').then(res => res.data);
    return data;
  }

  async createMarket(json: any) {
    await tokenIn();
    const { data } = await api.post('/api/market', json).then(res => res.data);
    return data;
  }

  async getMarketList({ self, uid }: any) {
    if (self === true) await tokenIn();
    else await tokenDelete();
    const { data } = await api.get(`/api/market?searchKey=all${uid ? `marketUid=${uid}` : ''}`).then(res => res.data);
    return data;
  }

  async getMarketView({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/market/${uid}`).then(res => res.data);
    return data;
  }

  async getItem({ self, uid }: any) {
    if (self === true) await tokenIn();
    else await tokenDelete();
    const { data } = await api.get(`/api/market/item${uid ? `?marketItemUid=${uid}` : ''}`).then(res => res.data);
    return data;
  }

  async getItemMarket({ marketUid, uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/market/${marketUid}/item${uid ? `?marketItemUid=${uid}` : ''}`).then(res => res.data);
    return data;
  }

  async getItemMarketView({ marketUid, uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/market/${marketUid}/item/${uid}`).then(res => res.data);
    return data;
  }

  async getItemMarketComment({ marketUid, uid, offsetUid }: any) {
    const { data } = await api.get(`/api/market/${marketUid}/item/${uid}/comment?${offsetUid ? `offsetUid=${offsetUid}` : ''}`).then(res => res.data);
    return data;
  }

  async postItemMarketComment({ marketUid, uid, json }: any) {
    await tokenIn();
    const data = await api.post(`/api/market/${marketUid}/item/${uid}/comment`, json).then(res => res.status);
    return data;
  }

  async putItemMarketComment({ marketUid, uid, json }: any) {
    await tokenIn();
    const { data } = await api.put(`/api/market/${marketUid}/item/${uid}/comment`, json).then(res => res.data);
    return data;
  }

  async delItemMarketComment({ marketUid, uid, json }: any) {
    await tokenIn();
    const { data } = await api.delete(`/api/market/${marketUid}/item/${uid}/comment`, json).then(res => res.data);
    return data;
  }

  async createItem({ uid, json }: any) {
    await tokenIn();
    const { message } = await api.post(`/api/market/${uid}/item`, json).then(res => res.data);
    return message;
  }

  async getMyCommunity() {
    await tokenIn();
    const { data } = await api.get('/api/community/board').then(res => res.data);
    return data;
  }

  async getRankCategory() {
    const { data } = await api.get('/api/commonCode?commonCodeUid=8').then(res => res.data);
    return data;
  }

  async getRankItem({ uid, size }: any) {
    const { data } = await api.get(`/api/rank/topItem?categoryCodeUid=${uid}${size ? `&size=${size}` : ''}`).then(res => res.data);
    return data;
  }

  async getRankSeller({ uid, size }: any) {
    const { data } = await api.get(`/api/rank/topSeller?categoryCodeUid=${uid}${size ? `&size=${size}` : ''}`).then(res => res.data);
    return data;
  }

  async connectWalletLogin(json: any) {
    const { data } = await api.post('/api/signin/metamask', json).then(res => res.data);
    return data;
  }

  async getMarketCreatedItemList({ page, size = 20, editFlag, sort, name }: any) {
    let queryString = `?page=${page}`;

    if (size) queryString += `&size=${size}`;
    if (editFlag === false || editFlag === true) queryString += `&editFlag=${editFlag}`;
    if (sort) queryString += `&sort=${sort}`;
    if (name) queryString += `&searchValue=${name}`;

    await tokenIn();
    const { data } = await api.get(`/api/marketItem2${queryString}`).then(res => res.data);
    return data;
  }

  async getMarketCategoryItemList({ page, size = 20, editFlag, sort, name, commonCodeUid }: any) {
    let queryString = `?page=${page}`;

    if (size) queryString += `&size=${size}`;
    if (editFlag === false || editFlag === true) queryString += `&editFlag=${editFlag}`;
    if (sort) queryString += `&sort=${sort}`;
    if (name) queryString += `&name=${name}`;
    if (commonCodeUid) queryString += `&commonCodeUid=${commonCodeUid}`;

    await tokenIn();
    const { data } = await api.get(`/api/marketItem2/member${queryString}`).then(res => res.data);
    return data;
  }

  async getCollectedItemList({ page, size = 20, editFlag, sort, name }: any) {
    let queryString = `?page=${page}`;

    if (size) queryString += `&size=${size}`;
    if (editFlag === false || editFlag === true) queryString += `&editFlag=${editFlag}`;
    if (sort) queryString += `&sort=${sort}`;
    if (name) queryString += `&searchValue=${name}`;

    await tokenIn();
    const { data } = await api.get(`/api/marketItem2/collected${queryString}`).then(res => res.data);
    return data;
  }

  async getMarketItemList({ page, size = 20, editFlag, sort, searchValue }: any) {
    let queryString = `?page=${page}`;

    if (size) queryString += `&size=${size}`;
    if (editFlag === false || editFlag === true) queryString += `&editFlag=${editFlag}`;
    if (sort) queryString += `&sort=${sort}`;
    if (searchValue) queryString += `&searchValue=${searchValue}`;

    await tokenIn();
    const { data } = await api.get(`/api/v1/market${queryString}`).then(res => res.data);
    return data;
  }

  async getMarketItemCreatedDetail({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/marketItem2/${uid}`).then(res => res.data);
    return data;
  }

  async getMarketItemCollectedDetail({ uid, collectedUid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/marketItem2/collected/${uid}/${collectedUid}`).then(res => res.data);
    return data;
  }

  async getMarketItemDetail({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/v1/market/${uid}`).then(res => res.data);
    return data;
  }

  async createNft(json: any) {
    const { data } = await api.post('/api/marketItem2', json).then(res => res.data);
    return data;
  }

  async editNft(uid: string, json: any) {
    const { data } = await api.put(`/api/marketItem2/${uid}`, json).then(res => res.data);
    return data;
  }

  async uploadNftImage(file: File) {
    await tokenIn();
    const formData = new FormData();
    formData.append('image', file);
    const { data } = await api.post('/api/marketItem2/upload', formData).then(res => res.data);
    return data;
  }

  async getMember() {
    await tokenIn();
    const { data } = await api.get('/api/member').then(res => res.data);
    return data;
  }

  async getMembers(uid: string) {
    await tokenIn();
    const { data } = await api.get(`/api/member/${uid}`).then(res => res.data);
    return data;
  }

  async getMarketMemberItemList({ uid, page, size = 20, sort, name }: any) {
    let queryString = `?page=${page}`;

    if (size) queryString += `&size=${size}`;
    if (sort) queryString += `&sort=${sort}`;
    if (name) queryString += `&name=${name}`;

    await tokenIn();
    const { data } = await api.get(`/api/v1/market/member/${uid}/item${queryString}`).then(res => res.data);
    return data;
  }

  async uploadProfileImage(file: File) {
    await tokenIn();
    const formData = new FormData();
    formData.append('photoFile', file);
    const { data } = await api.post('/api/member/photo', formData).then(res => res.data);
    return data;
  }

  async uploadBackgroundImage(file: File) {
    await tokenIn();
    const formData = new FormData();
    formData.append('imageFile', file);
    const { data } = await api.post('/api/member/backgroundImage', formData).then(res => res.data);
    return data;
  }

  async mintingComplete(uid: any, json: any) {
    // quantity, endAt, price, transactionHash
    await tokenIn();
    const { data } = await api.post(`/api/marketItem2/${uid}/sell`, json).then(res => res.data);
    return data;
  }

  async buyComplete(uid: any, json: any) {
    // quantity, endAt, price, transactionHash
    await tokenIn();
    const { data } = await api.post(`/api/marketItem2/${uid}/buy`, json).then(res => res.data);
    return data;
  }

  async getSellItemList({ uid }: any) {
    await tokenIn();
    const { data } = await api.get(`/api/marketItem2/${uid}/sell`).then(res => res.data);
    return data;
  }

  async getScamList({ searchValue, commonCodeUid, page = 1, size = 10, order, name }: any) {
    let queryString = `?page=${page}`;

    if (size) queryString += `&size=${size}`;
    if (name) queryString += `&name=${name}`;
    if (searchValue) queryString += `&searchValue=${searchValue}`;
    if (commonCodeUid) queryString += `&commonCodeUid=${commonCodeUid}`;
    if (order) queryString += `&order=${order}`;

    await tokenIn();
    const { data } = await api.get(`/api/scamBoard${queryString}`).then(res => res.data);
    return data;
  }

  async putScamOpinion(uid: any, json: any) {
    const { data } = await api.put(`/api/scamBoard/${uid}/opinion`, json).then(res => res.data);
    return data;
  }

  async getReportCtg() {
    await tokenIn();
    const { data } = await api.get(`/api/commonCode?commonCodeUid=66`).then(res => res.data);
    return data;
  }

  async postReport(uid: any, json: any) {
    // quantity, endAt, price, transactionHash
    await tokenIn();
    const { data } = await api.post(`/api/v1/market/${uid}/report`, json).then(res => res.data);
    return data;
  }

  async getSmsNations() {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    const { data } = await api.get('/api/staticData/1?orderName=countryDisplayEngName').then(res => res.data);
    return data;
  }

  async postVerifyEmail(json: any) {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data } = await api.post('/api/member/verifyEmail', json).then(res => res.data);
    return data;
  }

  async postVerifyEmailCheck(token: string, json: any) {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data } = await api.put(`/api/member/verifyEmail/${token}`, json).then(res => res.data);
    return data;
  }

  async postVerifySms(json: any) {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data, message } = await api.post('/api/member/sendVerifySms', json).then(res => res.data);
    return { data, message };
  }

  async postVerifySmsCheck(json: any) {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data, message } = await api.put(`/api/member/verifySms`, json).then(res => res.data);
    return { data, message };
  }

  async putUpdateEtcInfo(uid: any, json: any) {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data } = await api.put(`/api/member/${uid}/updateEtcInfo`, json).then(res => res.data);
    return data;
  }

  async postOtpCreate() {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data } = await api.post('/api/totp').then(res => res.data);
    return data;
  }

  async postOtpActivate(json: any) {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data, message } = await api.put('/api/totp/activate', json).then(res => res.data);
    return { data, message };
  }

  async deleteOtp() {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data } = await api.delete('/api/totp').then(res => res.data);
    return data;
  }

  async postOtpVerify(json: any) {
    api.defaults.baseURL = 'https://api.pbcninc.com';
    await tokenIn();
    const { data } = await api.post('/api/totp/verify', json).then(res => res.data);
    return data;
  }
}
export default APIService;
