import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';

export interface SearchProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  shape?: 'rect' | 'round' | 'circle';
  prefixStyle?: React.ReactNode;
  iconPos?: 'start' | 'end';
  fontSize?: number;
  fontColor?: string;
  w?: string | number;
  h?: string | number;
}

const SearchUI: React.FC<SearchProps> = ({ w, iconPos, ...props }) => {
  const [q, setQ] = React.useState('');

  return (
    <StyledWrap htmlFor="search" w={w} h={props.h}>
      <StyledWrapInput>
        {iconPos === 'start' && <SearchIcon />}
        <StyledInput type="search" name="search" value={q} onChange={e => setQ(e.target.value)} {...props} />
        {iconPos === 'end' && <SearchIcon />}
      </StyledWrapInput>
    </StyledWrap>
  );
};

export default SearchUI;

SearchUI.defaultProps = {
  iconPos: 'start',
  fontSize: 14,
  fontColor: '#9B9B9B',
  w: '100%',
  h: 40
};

const StyledWrap = styled.label<{ w?: string | number; h?: string | number }>`
  width: ${p => (typeof p.w === 'number' ? p.w + 'px' : p.w)} !important;
  height: ${p => p.h}px;
`;
const StyledWrapInput = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  background-color: #f9f9f9;
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  gap: 7px;
`;
const StyledInput = styled.input<SearchProps>`
  width: 100%;
  height: 100%;
  font-size: ${p => p.fontSize}px;
  color: ${p => p.fontColor};
  background: transparent;
  outline: none !important;
  border: none !important;
`;
