import { ALL_CTG_LIST, SORT_LIST } from 'api/mock/ctg';
import { NFT_ITEMS } from 'api/mock/nft';
import SubHeader from 'components/Header/SubHeader';
import NftTypeComponent from 'components/v2/nft-card-item/components/nft-type/NftType';
import NftCardItemComponent from 'components/v2/nft-card-item/NftCardItem';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { View, Text } from 'styles';
import ModalStyles from 'styles/modal/Modal';
import SearchUI from 'ui/search/Search';
import SelectButtonUI from 'ui/select-button/SelectButton';
import { mappingNftTypeName } from 'utils';

import { useInfiniteScroll } from 'hook/useInfiniteScroll';
import { useSelector, useDispatch } from 'react-redux';
import APIService from 'api';
import qs from 'query-string';
const apiService = new APIService();
const MarketListPage: React.FC = () => {
  const { search } = useLocation();
  const { tab, name } = qs.parse(search);
  const navigate = useNavigate();

  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);

  const [curCtg, setCurCtg] = React.useState('all');

  const [openCtgModal, setOpenCtgModal] = React.useState(false);
  const [openSortModal, setOpenSortModal] = React.useState(false);

  const [marketList, setMarketList] = React.useState<any[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState(name || undefined);
  const [category, setCategory] = React.useState([]);

  const [sort, setSort] = React.useState<string>('recently');
  const getMarketItemList = async ({ page, size = 20 }: any) => {
    setLoading(true);
    const resultMyItem = await apiService.getMarketItemList({ page, size, sort, searchValue });
    if (marketList.length > 0) {
      marketList.at(-1).uid !== resultMyItem.list.at(-1).uid && setMarketList([...marketList, ...resultMyItem.list]);
    } else {
      setMarketList(resultMyItem.list);
    }

    setLoading(false);
  };

  const getCategory = async () => {
    const result = await apiService.getCollectionCategory();
    if (result.list.length > 0) {
      setCategory(result.list);
    }
  };

  const onChangeCtg = (value: string) => {
    // setCurCtg(value);
    setOpenCtgModal(false);
    if (value === 'All') return;
    navigate(`/market/category/${value}`);
  };

  const onChangeSort = (value: string) => {
    if (sort !== value) {
      setSort(value);
      setMarketList([]);
    }
    setOpenSortModal(false);
  };

  const closeModal = () => {
    setOpenCtgModal(false);
    setOpenSortModal(false);
  };

  React.useEffect(() => {
    getCategory();
  }, []);
  React.useEffect(() => {
    getMarketItemList({ page: 1, sort });
  }, [sort]);

  const handleSearch = (e: any) => {
    let searchWord;
    if (e.keyCode && e.keyCode === 13) {
      searchWord = e.target.value;

      navigate(`/market?&name=${searchWord}`);
      window.scrollTo(0, 0);
      window.location.reload();
    } else if (!e.keyCode) {
      const getValue = (document.getElementById('nft-search') as HTMLInputElement).value;
      searchWord = getValue;

      navigate(`/market?&name=${searchWord}`);
      window.scrollTo(0, 0);
      window.location.reload();
    }
  };

  return (
    <StyledWrap>
      <SubHeader title="MARKET" isBackHome />

      {/* 검색 */}
      <View mt={20} mb={20} direction="column" padding="0 20" block justify="center">
        <View mb={24} block>
          <SelectButtonUI onClick={() => setOpenCtgModal(true)} color="black" weight="bold">
            <NftTypeComponent type={curCtg} color="white" fontColor="#fff" fontSize="md" fontWeight="bold" />
          </SelectButtonUI>
        </View>

        <SearchUI h={40} className="search" placeholder={'Search by name'} onKeyDown={handleSearch} />
        <View justify="flex-end" block mt={8}>
          <SelectButtonUI bgColor="#F9F9F9" fontSize={14} weight="700" w={180} h={40} onClick={() => setOpenSortModal(true)}>
            {SORT_LIST.filter(item => item.value === sort)[0].name}
          </SelectButtonUI>
        </View>
      </View>

      <View padding="0 20" block wrap="wrap" spacing={10}>
        {marketList.map((item, idx) => (
          // <NftCardItemComponent key={idx} {...item} onClick={() => navigate(`/nft/item/${item.id}`)} />
          <NftCardItemComponent key={idx} {...item} moreMenu={false} />
        ))}
        <div ref={senserRef} className={`observer ${!isLoading ? 'completed' : ''}`} />
      </View>

      <ModalStyles w={'90%'} open={openCtgModal} isCenter={true} handleCancle={closeModal} type="select">
        <StyledOptionItem active={curCtg === 'All'} className="select-item" onClick={() => onChangeCtg('All')}>
          All
        </StyledOptionItem>
        {category.length > 0 &&
          category.map((item: any, idx) => (
            <StyledOptionItem key={idx} active={curCtg === item.name} className="select-item" onClick={() => onChangeCtg(item.uid)}>
              {item.name}
            </StyledOptionItem>
          ))}
      </ModalStyles>

      <ModalStyles w={'90%'} open={openSortModal} isCenter={true} handleCancle={closeModal} type="select">
        {SORT_LIST.map((item, idx) => (
          <StyledOptionItem key={idx} active={sort === item.value} className="select-item" onClick={() => onChangeSort(item.value)}>
            {item.name}
          </StyledOptionItem>
        ))}
      </ModalStyles>
    </StyledWrap>
  );
};

export default MarketListPage;

const StyledWrap = styled.div`
  padding-bottom: 40px;
`;
const StyledOptionItem = styled.div<{ active?: boolean }>`
  color: ${p => (p.active ? '#0075FF' : '#808080')} !important;
`;
