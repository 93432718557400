import React from 'react';

const SearchSvg: React.FC<any> = ({ active, ...props }) => {
  return (
    <svg width="53" height="30" viewBox="0 0 53 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {active ? (
        <>
          <g clipPath="url(#clip0_3637_37811)">
            <rect width="53" height="30" fill="white" />
            <path d="M31.6712 7.67634L39.2695 15.2709L36.702 25.7L26.5361 28.5346L18.9377 20.9401L21.5053 10.5109L31.6712 7.67634Z" fill="#E4E4E4" />
            <path d="M32.3633 19.3271L37.686 24.6499L35.5652 26.9511L30.2425 21.6284L32.3633 19.3271Z" fill="#401698" />
            <path
              d="M18.5114 7.81367L27.3856 5.33925L34.0185 11.9688L31.7676 21.1115L22.8934 23.5859L16.2606 16.9564L18.5114 7.81367Z"
              stroke="url(#paint0_linear_3637_37811)"
              strokeWidth="4"
              fill="transparent"
            />
          </g>
          <defs>
            <linearGradient id="paint0_linear_3637_37811" x1="17.214" y1="4.71911" x2="36.2402" y2="21.732" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF0000" />
              <stop offset="0.473958" stopColor="#FF00D6" />
              <stop offset="1" stopColor="#001D84" />
            </linearGradient>
            <clipPath id="clip0_3637_37811">
              <rect width="53" height="30" fill="white" />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <g clipPath="url(#clip0_1548_23788)">
            <rect width="53" height="30" fill="white" />
            <path d="M31.6712 7.67634L39.2695 15.2709L36.702 25.7L26.5361 28.5346L18.9377 20.9401L21.5053 10.5109L31.6712 7.67634Z" fill="#E4E4E4" />
            <path d="M32.3633 19.3271L37.686 24.6499L35.5652 26.9511L30.2425 21.6284L32.3633 19.3271Z" fill="#401698" />
            <path d="M32.3633 19.3271L37.686 24.6499L35.5652 26.9511L30.2425 21.6284L32.3633 19.3271Z" fill="#505050" />
            <path d="M18.5114 7.81367L27.3856 5.33925L34.0185 11.9688L31.7676 21.1115L22.8934 23.5859L16.2606 16.9564L18.5114 7.81367Z" stroke="#505050" strokeWidth="4" fill="transparent" />
          </g>
          <defs>
            <clipPath id="clip0_1548_23788">
              <rect width="53" height="30" fill="white" />
            </clipPath>
          </defs>
        </>
      )}
    </svg>
  );
};

export default SearchSvg;
