import { useState, useRef, useEffect, MutableRefObject } from 'react';

export function useInfiniteScroll(
  ref: MutableRefObject<Element | null>, // null일 경우 document의 루트 요소를 기준으로 해당 요소를 관측함
  options: any = {},
  forward = true // 무한 스크롤 여부 => true: 무한 스크롤 중지
) {
  const [element, setElement] = useState<Element | null>(null); // DOM Ref
  const [isIntersecting, setIsIntersecting] = useState(false); // 감시자 체크 후 반응 여부
  const observer = useRef<null | IntersectionObserver>(null); // 감시자 이벤트

  const cleanOb = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  useEffect(() => {
    setElement(ref.current);
  }, [ref]);

  useEffect(() => {
    if (!element) return; // ref 방지
    cleanOb();
    const ob = (observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        if (!forward) {
          // 이벤트 동작
          setIsIntersecting(isElementIntersecting);
        } else if (forward && !isIntersecting && isElementIntersecting) {
          // 이벤트 중지
          setIsIntersecting(isElementIntersecting);
          cleanOb();
        }
      },
      { ...options }
    ));
    ob.observe(element);

    return () => {
      cleanOb();
    };
  }, [element, options]);

  return isIntersecting;
}
