import React from 'react';
import { PreviewType } from './Upload';
import { ReactComponent as UploadIcon } from 'assets/icon/upload-purple.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg';
import IconButtonUI from 'ui/icon-button/IconButton';
import bg_banner from 'assets/bg/bg_default_banner.png';
import { ReactComponent as DefaultProfileSvg } from 'assets/profile/default.svg';
import AvatarUI from 'ui/avatar/Avatar';

interface UploadOptionProps {
  maxSize?: number;
  fileChange: (file: File | null) => void;
  fileRef: React.RefObject<HTMLInputElement>;
  accept?: string;
  imageUrl?: string;
  isDefaultImage?: boolean;
  defaultImage?: 'profile' | 'bg' | string;
  previewIconType?: PreviewType;
}

const useUpload = ({ fileChange, maxSize, fileRef, accept, imageUrl, defaultImage, isDefaultImage, previewIconType }: UploadOptionProps) => {
  const [previewImg, setPreviewImg] = React.useState<string | undefined>(imageUrl);
  const [hasError, setHasError] = React.useState(false);
  const [fileType, setFileType] = React.useState('');

  const handleClick = () => {
    fileRef.current?.click();
  };

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const limitSize = maxSize! * 1024 * 1024;
    const file: File | undefined = e.target.files?.[0];

    if (!file) {
      return;
    }

    const isValid =
      file?.size > limitSize ||
      ![
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'image/svg+xml',
        'audio/ogg',
        'audio/webm',
        'audio/mp3',
        'audio/mp4',
        'audio/x-wav',
        'video/mp3',
        'video/mp4',
        'video/webm',
        'model/gltf-binary',
        'model/gltf+json'
      ].includes(file.type);
    if (isValid) {
      setHasError(true);
      return;
    }

    setFileType(file.type);
    setHasError(false);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = data => {
      setPreviewImg(data?.target?.result?.toString());
    };
    fileChange(file);
  };

  const hasUploadButton = React.useMemo(() => (imageUrl && isDefaultImage) || (!imageUrl && !previewImg), [imageUrl, isDefaultImage, previewImg]);

  const renderIcon = () => {
    switch (previewIconType) {
      case 'edit':
        return <EditIcon onClick={handleClick} />;
      default:
        return <IconButtonUI w={60} h={60} icon={<UploadIcon />} onClick={handleClick} />;
    }
  };

  const renderDefaultImage = (defaultImage: any) => {
    switch (defaultImage) {
      case 'profile':
        return (
          <div className="img-default img-default-profile">
            <AvatarUI size="3xl" isBorder={false} />
          </div>
        );
      case 'bg':
        return <img className="img-default" src={bg_banner} />;
    }
  };

  React.useEffect(() => {
    if (!previewImg) {
      setPreviewImg(imageUrl);
    }
  }, [imageUrl]);

  return {
    renderDefaultImage,
    fileType,
    previewImg,
    onChangeImage,
    hasError,
    handleClick,
    hasUploadButton,
    renderIcon
  };
};

export default useUpload;
