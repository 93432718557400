import React from 'react';
import styled from 'styled-components';
import { Text, View } from 'styles';
import { ReactComponent as DownArrowIcon } from 'assets/svg/downArrow.svg';

interface CoveredViewProps {
  content?: any;
}

const CoveredViewStyles: React.FC<CoveredViewProps> = ({ content, children, ...props }) => {
  const [visible, setVisible] = React.useState(content.length > 100 ? false : true);
  const visibleContent = () => {
    setVisible(prev => !prev);
  };

  return (
    <Wrap visible={visible}>
      <Text size="xxs" color="#717171">
        {content}
      </Text>
      {content.length > 100 && <DownArrowIcon className="btn-more" onClick={visibleContent} />}
    </Wrap>
  );
};

export default CoveredViewStyles;

const Wrap = styled.div<{ visible?: boolean }>`
  position: relative;
  max-height: 80px;
  height: 100%;
  overflow: hidden;

  ${p =>
    p.visible &&
    `
      margin-bottom: 20px;
      overflow:visible;
      max-height: fit-content;
  `}

  svg {
    path {
      stroke: #000;
    }
  }

  .btn-more {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: ${p => (p.visible ? -20 : 30)}px;
    z-index: 99;
    display: flex;
    justify-content: center;

    ${p => p.visible && `transform: rotate(-180deg)`}
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    display: ${p => (p.visible ? 'none' : 'block')};
  }
`;
