import { SocialList } from 'ui/socialButton/SocialButton';

export const SOCIAL_LIST: { name: SocialList; isConnect: boolean }[] = [
  {
    name: 'twitter',
    isConnect: false
  },
  {
    name: 'instagram',
    isConnect: false
  },
  {
    name: 'facebook',
    isConnect: false
  }
];
