import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CancleIcon } from 'assets/svg/deleteCircle.svg';
import { useNavigate } from 'react-router-dom';
import { View, Text } from 'styles';
import { TextSizeType, TextWeightType } from 'styles/text';

interface PublicKeyProps extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'address'> {
  address: string;
  isUnderline?: boolean;
  color?: string;
  fontSize?: TextSizeType;
  weight?: TextWeightType;
}

const PublickKeyStyles: React.FC<PublicKeyProps> = ({ weight, isUnderline, color, fontSize, address, ...props }) => {
  const shortening = (v = '') => address.slice(0, 4) + '....' + v.slice(v.length - 4, v.length);
  return (
    <StyledWrap {...props}>
      <Text size={fontSize} underline={isUnderline} weight={weight} color={color}>
        {shortening(address)}
      </Text>
    </StyledWrap>
  );
};

export default PublickKeyStyles;

PublickKeyStyles.defaultProps = {
  isUnderline: true,
  fontSize: 'sm',
  color: '#000',
  weight: 'default'
};

const StyledWrap = styled.div``;
