import React from 'react';
import styled from 'styled-components';
import { View, Text } from 'styles';
import AvatarUI from 'ui/avatar/Avatar';
import BackgroundUI from 'ui/background/Background';

interface NftCreatorProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  name?: string;
  backgroundImage?: string;
  photoUri?: string;
}

const NftCreatorComponent: React.FC<NftCreatorProps> = ({ name, backgroundImage, photoUri, ...props }) => {
  return (
    <StyledWrap {...props}>
      <BackgroundUI bgUrl={backgroundImage} h={160} radius={9} />
      <View h={64} bgColor="#fff" position="absolute" bottom={0} block>
        <View align="flex-end" spacing={6} position="absolute" top={-25} left={20}>
          <AvatarUI size="xl" imgUrl={photoUri} borderSize={2} />
          <Text eclipse={1} size="sm" weight="bold">
            {name}
          </Text>
        </View>
      </View>
    </StyledWrap>
  );
};

export default NftCreatorComponent;

const StyledWrap = styled.div`
  position: relative;
  border-radius: 9px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 14px 0px #0000000f;
  border: 1px solid #efefef;
`;
