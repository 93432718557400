import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CTG } from 'api/mock/home';
import { PROFILE_CREATE, PROFILE_OWNED } from 'api/mock/profile';
import { AD_LIST, BANNER_IMG, USER_LIST } from 'api/mock/search';
import SearchHeader from 'components/Header/SearchHeader';
import AuthorItemComponent from 'components/authorItem';
import Section from 'components/common/section';
import ScrollWrap from 'components/common/slideWrap';
import NftProductCompoent from 'components/nftProductItem';
import UserItem from 'components/userItem';
import styled from 'styled-components';
import { Banners, Tabs, ThumbTitle, Rollings, View, Text, Pagination } from 'styles';
import Filter from 'styles/filter';
import { ReactComponent as HistoryIcon } from 'assets/svg/history.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/deleteCircle.svg';
import qs from 'query-string';
import APIService from 'api/index';
import googleIcon from 'assets/icon/google.png';

import { useInfiniteScroll } from 'hook/useInfiniteScroll';

import ListCardComponent from 'views/scam/components/ListCard';
import ArticlesListCardComponent from 'views/articles/components/ListCard';

import ItemSmallType from 'components/cardType01Item/small';
import ArticleItem from 'components/v2/article-item/ArticleItem';
import NewsItemComponent from 'components/v2/news-item/NewsItem';

import { nftMoaBest, nftMoaPower, nftMedia, nftBestAd, adSlide, communityList, scamList, articlesList, marketList } from './dummy';

const apiService = new APIService();

const SearchResultView: React.FC<any> = ({ mediaShow = true, commuinityShow = true, scamShow = true, newsShow = true, marketShow = true }) => {
  const { search, hash } = useLocation();
  const navigate = useNavigate();
  const { name, page, keyword } = qs.parse(search);

  let searchKeyword = name || hash;
  if (name && hash) {
    searchKeyword = `${decodeURIComponent(name as string)}${hash}`;
  }

  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState('All');
  const [tagList, setTagList] = React.useState(['3,000 ETH', 'Art']);
  const [categories, setCategories] = React.useState({ art: false, music: false, games: false, photography: false, domains: false });
  const [saleType, setSaleType] = React.useState({ timedAuction: false, userOnly: false, fixedPrice: false, openFor: false });
  const [visibleHistory, setVisibleHistory] = React.useState(false);

  const [nftList, setNftList] = React.useState<any[]>([]);
  const [nftFullList, setNftFullList] = React.useState<any>();
  const [collectionList, setCollectionList] = React.useState<any[]>([]);
  const [token, setToken] = React.useState<any[]>([]);

  const handleSearchWatch = () => {
    const nftmoaSearch = document.getElementById('nftmoa-search') as HTMLInputElement;
    if (nftmoaSearch?.value.length > 0) {
      // setVisibleHistory(true);
    } else {
      setVisibleHistory(false);
    }
  };

  const getSearch = async (page?: number) => {
    setLoading(true);

    const searchValue = searchKeyword.includes('#gsc.tab=') ? (searchKeyword as string).split('#gsc.tab=')[0] : searchKeyword;

    const resultNftList = await apiService.getNftListPage({ searchValue, page });
    setNftFullList(resultNftList);
    setLoading(false);
    // setToken([]);
  };

  const getSearchPaging = (page: number, pageSize: number) => {
    handleTabMove('Items');
    navigate(`/search/result?name=${searchKeyword}&page=${page}`);
    getSearch(page);
  };

  const getCollection = async (nftUid?: number) => {
    setLoading(true);
    const resultCollectionList = await apiService.getCollectionList({ searchValue: name, nftCollectionUid: nftUid });

    if (collectionList.length > 0) {
      collectionList.at(-1).uid !== resultCollectionList.list.at(-1).uid && setCollectionList([...collectionList, ...resultCollectionList.list]);
    } else {
      setCollectionList(resultCollectionList.list);
    }

    setLoading(false);
    // setToken([]);
  };

  const getGoogle = async () => {
    (function () {
      const cx = '647afc5dcdf884aac';
      const gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx + '&gsc.q=' + `${name} %26 NFT`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode?.insertBefore(gcse, s);
    })();

    setTimeout(() => {
      if (window.location.href.indexOf('gsc.tab') > 0 && window.location.href.indexOf('gsc.q') < 0) {
        window.location.href = window.location.href + '&gsc.q=' + `${name} %26 NFT`;
      }
    }, 1000);
  };

  React.useEffect(() => {
    (page || name || keyword) && getSearch(page ? Number(page) : 0);

    if (page || name || keyword || hash) {
      if (activeTab === 'Items') {
        getSearch(page ? Number(page) : 0);
      }

      if (activeTab === 'Collections') {
        getCollection();
      }

      if (activeTab === 'Google') {
        getGoogle();
      }
    }

    window.addEventListener('keydown', handleSearchWatch);

    return () => {
      window.removeEventListener('keydown', handleSearchWatch);
    };
  }, [name, keyword, activeTab, hash, page]);

  const handleTabMove = (value: string) => {
    setActiveTab(value);

    document.getElementById(`${value}-move`)?.scrollIntoView();
  };

  return (
    <Wrap>
      <WrapScroll>
        <Tabs active={activeTab}>
          <Tabs.Item value="All" onClick={() => handleTabMove('All')}>
            <a href={'#'}>ALL</a>
          </Tabs.Item>
          <Tabs.Item value="Items" onClick={() => handleTabMove('Items')}>
            <a href={'#item-move'}>Items</a>
          </Tabs.Item>
          <Tabs.Item value="Google" imgText={googleIcon} imgSize={'m'} onClick={() => setActiveTab('Google')}>
            Google
          </Tabs.Item>
          <Tabs.Item value="Community" onClick={() => handleTabMove('Community')}>
            COMMUNITY
          </Tabs.Item>
          <Tabs.Item value="Contents" onClick={() => handleTabMove('Contents')}>
            CONTENTS
          </Tabs.Item>
          {/* <Tabs.Item value="Collections" onClick={() => setActiveTab('Collections')}>
          Collections
        </Tabs.Item> */}
        </Tabs>
      </WrapScroll>

      {activeTab === 'Google' && (
        <Section className="wrap-google">
          <div className="gcse-searchresults-only"></div>
        </Section>
      )}

      {activeTab !== 'Google' && (
        <ContentWrap>
          <Section title="NVIA BEST" className="best-wrap">
            {nftMoaBest.map((item, idx) => (
              <BestItem key={idx} onClick={() => window.open(item.ad)}>
                <Text size="md" weight="semi-bold" color="#0075FF" eclipse={1}>
                  {item.title}
                </Text>
                <Text size="sm" eclipse={2} color="#808080">
                  {item.desc}
                </Text>
                <Text size="xxs" eclipse={1} weight="semi-bold">
                  AD:{' '}
                  <Text size="xxs" underline weight="semi-bold">
                    {item.ad}
                  </Text>
                </Text>
              </BestItem>
            ))}
          </Section>

          {nftFullList && (
            <Section title="ITEM" className={'best-item-wrap'} id={'Items-move'}>
              <View className={'item-list'} block>
                {nftFullList.list.map((item: any, idx: number) => (
                  // <NftProductCompoent more key={idx} onClick={() => window.open(item.url)} {...item} />
                  <NftProductCompoent popover key={idx} rate={true} url={true} buy={false} {...item} />
                ))}
              </View>
              <View align="center" justify="center" block>
                {nftFullList && <Pagination total={nftFullList.totalCount} defaultPageSize={20} pageSize={20} currentPage={nftFullList.currentPage} onChange={getSearchPaging} />}
              </View>
            </Section>
          )}

          <Section title="NVIA POWER">
            {nftMoaPower.map((item, idx) => (
              <PowerItem key={idx} onClick={() => window.open(`${item.url}`)}>
                <img src={item.photo} />
                <Text size="sm" weight="semi-bold" underline eclipse={1}>
                  {item.title}
                </Text>
                <View>
                  <Text size="xxs" weight="semi-bold">
                    AD:
                  </Text>
                  <Text size="xxs" eclipse={1} underline weight="semi-bold">
                    {item.ad}
                  </Text>
                </View>
                <Text size="sm" eclipse={1} color="#808080">
                  {item.desc}
                </Text>
              </PowerItem>
            ))}
          </Section>
          {mediaShow && (
            <Section title="MEDIA" className={'media-wrap'} moreLink="/">
              <WrapScroll>
                {nftMedia.map((item, idx) => (
                  <MediaItem key={idx} onClick={() => window.open(item.url)}>
                    <img src={item.photo} />
                    <Text size="sm" eclipse={1} weight="bold">
                      {item.title}
                    </Text>
                    <Text size="xs" lh={12} eclipse={1} color="#808080">
                      {item.desc}
                    </Text>
                  </MediaItem>
                ))}
              </WrapScroll>
            </Section>
          )}

          <Section title="BEST ITEM AD" moreLink="/" className={'best-item-ad-wrap'}>
            <WrapScroll>
              {nftBestAd.map((item, idx) => (
                <NftProductCompoent key={idx} {...item} />
              ))}
            </WrapScroll>
          </Section>

          <div className="ad-baner-wrap">
            <Rollings list={adSlide} className="wrap-adSlide-list" />
            <p className="ad-flag">AD</p>
          </div>

          {commuinityShow && (
            <Section title={'COMMUNITY'} moreLink="/community" className="wrap-community-list" id={'Community-move'}>
              {communityList.map((item: any, idx: number) => (
                <ArticleItem key={idx} {...item} onClick={() => navigate(`/community/${item.uid}`)} />
              ))}
            </Section>
          )}

          {scamShow && (
            <Section title={'SCAM ALERT'} className="wrap-scam-list" id={'Contents-move'} moreLink="/">
              {scamList.map((item: any, idx: number) => (
                <NewsItemComponent key={idx} {...item} />
              ))}
            </Section>
          )}

          {newsShow && (
            <Section title={'Article & Disclosure'} moreLink="/">
              {articlesList.map((item: any, idx: number) => (
                <ArticlesListCardComponent key={idx} {...item} onClick={() => window.open(item.sourceLink)} />
              ))}
            </Section>
          )}

          {marketShow && (
            <Section moreLink="/" title={'NVIA MARKET'} className={'wrap-market-list'}>
              <View justify="space-between">
                {marketList.map((item: any, idx: number) => (
                  <ItemSmallType key={idx} {...item} />
                ))}
              </View>
            </Section>
          )}
        </ContentWrap>
      )}
    </Wrap>
  );
};

export default SearchResultView;

const Wrap = styled.div`
  position: relative;
  padding-top: 0px;

  & .ad-baner-wrap {
    position: relative;

    .ad-flag {
      position: absolute;
      top: 34px;
      left: 34px;
      background: #fff;
      border-radius: 4px;
      font-size: 10px;
      color: #000;
      opacity: 0.8;
      padding: 5px;
    }
  }

  & .best-wrap {
    border-bottom: 1px solid #eaeaea;
  }

  & .wrap-market-list {
    padding: 24px 0;
    background: #fff;

    & > h2 {
      margin-bottom: 10px !important;
    }

    .small-items {
      min-width: calc(50% - 20px);
      width: calc(50% - 20px);
    }

    .box-section {
      > div {
        flex-wrap: wrap;
      }
    }

    & > h2 {
      padding: 0 18px;
      margin-bottom: 0;
    }
  }

  & .wrap-scam-list {
    & > h2 {
      padding: 0 18px;
      margin-bottom: 0;
    }

    .subject {
      width: 100%;
      height: 36px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    padding: 24px 0;

    background: #fff;
  }

  & .wrap-adSlide-list {
    padding: 24px 0;

    background: #fff;
  }

  & .wrap-community-list {
    padding: 24px 0;
    margin-bottom: 30px;

    background: #fff;

    & > h2 {
      padding: 0 18px;
      margin-bottom: 0;
    }
  }

  & .best-item-wrap {
    padding: 24px 20px 26px;
    background: #fff;
    & > div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      h2 {
        width: 100%;
        height: 26px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .item-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .product-image {
        height: 142px;
      }
    }
  }

  .media-wrap {
    background: #fff;
  }

  .best-item-ad-wrap {
    .box-section {
      > div > div {
        min-width: 130px;
        width: 130px;
        max-width: 130px;

        .product-image {
          height: 110px;
        }

        .price-container {
          flex-direction: column !important;
          align-items: start !important;
        }
      }
    }
  }
`;

const WrapScroll = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  padding-bottom: 19px;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
`;

const ContentWrap = styled.div`
  background: #f5f5f5;
`;

const BestItem = styled.div`
  position: relative;
  left: -20px;
  padding: 10px 20px;
  width: calc(100% + 40px);
  background: #fff;

  + div {
    margin-top: 4px;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-decoration-line: underline;
    color: #0075ff;
  }

  .desc {
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
  }

  .ad {
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    color: #000000;
  }
`;

const PowerItem = styled.div`
  position: relative;
  padding: 0 0 0 98px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
  }

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 0.01em;
    text-decoration-line: underline;

    color: #101010;
  }

  .ad {
    font-weight: 600;
    font-size: 10px;
    line-height: 26px;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #000000;
  }

  .desc {
    width: 100%;
    height: 26px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
  }

  + div {
    margin-top: 20px;
  }
`;

const MediaItem = styled.div`
  min-width: 151px;
  width: 151px;

  + div {
    margin-left: 8px;
  }

  img {
    height: 85px;
  }

  h2 {
    width: 90%;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;

    color: #000000;
  }

  .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;

    /* Secondary black */

    color: rgba(0, 0, 0, 0.5);
  }
`;
