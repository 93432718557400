import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as SelectDownArrowIcon } from 'assets/svg/downArrow.svg';
import { TextSizeType } from 'styles/text';

interface SelectButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  open?: boolean;
  label?: string | React.ReactNode;
  infoPos?: 'top' | 'bottom';
  infoNode?: React.ReactNode;
  required?: boolean;
  color?: 'black' | 'default';
  icon?: React.ReactNode;
  weight?: any;
  isBorder?: boolean;
  bgColor?: string;
  fontSize?: TextSizeType | number;
  w?: number | string;
  h?: number;
}

const SelectButtonUI: React.FC<SelectButtonProps> = ({ icon, placeholder, label, infoPos, infoNode, children, ...props }) => {
  return (
    <StyledWrap w={props.w}>
      {label && <StyledLabel required={props.required}>{label}</StyledLabel>}
      {infoPos === 'top' && infoNode && infoNode}
      <StyledButton {...props} placeholder={placeholder}>
        {icon && icon}
        {children}
        <SelectDownArrowIcon className="icon-arrow" />
      </StyledButton>
      {infoPos === 'bottom' && infoNode && infoNode}
    </StyledWrap>
  );
};

export default SelectButtonUI;

SelectButtonUI.defaultProps = {
  color: 'default',
  weight: 400,
  w: '100%',
  h: 45,
  fontSize: 16,
  bgColor: '#fff',
  isBorder: true,
  open: false
};

const ColorBlack = css`
  background-color: #000;
  color: #fff;

  .icon-arrow {
    path {
      stroke: #fff !important;
    }
  }
`;

const StyledWrap = styled.div<{ w?: number | string }>`
  width: 100%;
  width: ${p => (typeof p.w ? p.w + 'px' : p.w)} !important;
`;

const StyledLabel = styled.label<{ required?: boolean; infoNode?: boolean }>`
  display: block;
  margin-bottom: 8px;
  color: #000 !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;

  ${p =>
    p.required &&
    css`
      &::after {
        content: ' *';
        color: #ab3fff;
      }
    `}
`;

const StyledButton = styled.button<SelectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  height: ${p => p.h && p.h} !important;
  padding: 10px 15px;
  background-color: ${p => p.bgColor} !important;
  border-radius: 12px;
  border: 1px solid #e6e8ec;
  ${p => !p.isBorder && 'border:none !important'};
  text-align: left;
  color: #000;
  font-size: ${p => p.fontSize}px;
  font-weight: ${p => p.weight};
  line-height: 26px;

  ${p => p.color === 'black' && ColorBlack};

  &::placeholder {
    color: #000;
  }

  .icon-arrow {
    ${p =>
      p.open &&
      `
      transform: rotate(-180deg);
    `}
    path {
      stroke: #000;
    }
  }
`;
