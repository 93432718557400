import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CTG } from 'api/mock/home';
import { PROFILE_CREATE, PROFILE_OWNED } from 'api/mock/profile';
import { AD_LIST, BANNER_IMG, USER_LIST } from 'api/mock/search';
import SearchHeader from 'components/Header/SearchHeader';
import AuthorItemComponent from 'components/authorItem';
import Section from 'components/common/section';
import ScrollWrap from 'components/common/slideWrap';
import NftProductCompoent from 'components/nftProductItem';
import UserItem from 'components/userItem';
import styled from 'styled-components';
import { Banners, Tabs, ThumbTitle } from 'styles';
import Filter from 'styles/filter';
import { ReactComponent as HistoryIcon } from 'assets/svg/history.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/deleteCircle.svg';
import qs from 'query-string';
import APIService from 'api/index';
import googleIcon from 'assets/icon/google.png';

import { useInfiniteScroll } from 'hook/useInfiniteScroll';

import V2_Result from './v2';

const apiService = new APIService();

const SearchResultView: React.FC<any> = () => {
  const { search, hash } = useLocation();
  const navigate = useNavigate();
  const { name, keyword } = qs.parse(search);

  let searchKeyword = name || hash;
  if (name && hash) {
    searchKeyword = `${decodeURIComponent(name as string)}${hash}`;
  }

  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState('Items');
  const [tagList, setTagList] = React.useState(['3,000 ETH', 'Art']);
  const [categories, setCategories] = React.useState({ art: false, music: false, games: false, photography: false, domains: false });
  const [saleType, setSaleType] = React.useState({ timedAuction: false, userOnly: false, fixedPrice: false, openFor: false });
  const [visibleHistory, setVisibleHistory] = React.useState(false);

  const [nftList, setNftList] = React.useState<any[]>([]);
  const [collectionList, setCollectionList] = React.useState<any[]>([]);
  const [token, setToken] = React.useState<any[]>([]);

  const handleSearchWatch = () => {
    const nftmoaSearch = document.getElementById('nftmoa-search') as HTMLInputElement;
    if (nftmoaSearch?.value.length > 0) {
      // setVisibleHistory(true);
    } else {
      setVisibleHistory(false);
    }
  };

  const getSearch = async (nftUid?: number) => {
    setLoading(true);
    const resultNftList = await apiService.getNftList({ searchValue: searchKeyword, nftUid });

    if (nftList.length > 0) {
      nftList.at(-1).uid !== resultNftList.list.at(-1).uid && setNftList([...nftList, ...resultNftList.list]);
    } else {
      setNftList(resultNftList.list);
    }

    setLoading(false);
    // setToken([]);
  };

  const getCollection = async (nftUid?: number) => {
    setLoading(true);
    const resultCollectionList = await apiService.getCollectionList({ searchValue: name, nftCollectionUid: nftUid });

    if (collectionList.length > 0) {
      collectionList.at(-1).uid !== resultCollectionList.list.at(-1).uid && setCollectionList([...collectionList, ...resultCollectionList.list]);
    } else {
      setCollectionList(resultCollectionList.list);
    }

    setLoading(false);
    // setToken([]);
  };

  const getGoogle = async () => {
    (function () {
      const cx = '647afc5dcdf884aac';
      const gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx + '&gsc.q=' + `${name} %26 NFT`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode?.insertBefore(gcse, s);
    })();

    setTimeout(() => {
      if (window.location.href.indexOf('gsc.tab') > 0 && window.location.href.indexOf('gsc.q') < 0) {
        window.location.href = window.location.href + '&gsc.q=' + `${name} %26 NFT`;
      }
    }, 1000);
  };

  const moveToItem = async (uid: any) => {
    navigate(`/item/${uid}`);
  };

  const moveToExplore = async (uid: any) => {
    navigate(`/explore/${uid}`);
  };

  React.useEffect(() => {
    (name || keyword) && getSearch();

    if (name || keyword || hash) {
      if (activeTab === 'Items') {
        getSearch();
      }

      if (activeTab === 'Collections') {
        getCollection();
      }

      if (activeTab === 'Google') {
        getGoogle();
      }
    }

    window.addEventListener('keydown', handleSearchWatch);

    return () => {
      window.removeEventListener('keydown', handleSearchWatch);
    };
  }, [name, keyword, activeTab, hash]);

  React.useEffect(() => {
    if (activeTab === 'Items') {
      if (isScroll && nftList.length > 0) getSearch(nftList.at(-1).uid);
    }

    if (activeTab === 'Collections') {
      if (isScroll && collectionList.length > 0) getCollection(collectionList.at(-1).uid);
    }
  }, [isScroll]);

  return (
    <Wrap>
      <SearchHeader />
      {visibleHistory ? (
        <History>
          <div>
            <dl>
              <dt>
                <HistoryIcon width={14} height={14} />
              </dt>
              <dd>nft</dd>
            </dl>
            <DeleteIcon onClick={() => console.log('delete')} />
          </div>
        </History>
      ) : (
        <V2_Result />
      )}
    </Wrap>
  );
};

export default SearchResultView;

const Wrap = styled.div`
  position: relative;
  padding-top: 26px;

  & > .wrap-items {
    padding: 24px 20px 0;
    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  & > .wrap-collections {
    & .box-section > div {
      margin-top: 20px;
    }
  }

  & > .wrap-google {
    padding: 0;
  }

  & > .wrap-users {
    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  & > .wrap-ad {
    margin-bottom: 30px;
  }

  .observer {
    width: 100% !important;
    height: 20px !important;
    margin-top: -20px !important;
    transform: translateY(-1000px);

    &.completed {
      transform: translateY(0);
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    animation: loading 0.7s linear infinite;
  }

  .gsc-input-box {
    padding: 0;
    height: 40px;
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #000;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const History = styled.div`
  padding: 0 20px;
  height: 100vh;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > dl {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      dt {
        display: flex;
        margin-right: 12px;
      }

      dd {
        flex: 1;
        font-family: 'Apple SD Gothic Neo';
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #000000;
      }
    }
  }
`;

const NoResult = styled.div`
  padding: 50px 0;
  width: 100%;
  text-align: center;

  p {
    margin-bottom: 10px;
  }
`;
