import React from 'react';

const MarketSvg: React.FC<any> = ({ active, ...props }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" fill="white" />
      {active ? (
        <>
          <path d="M18 7H21H27L30 22H25V29H18H11V22H6L9 7H15H18Z" fill="#E4E4E4" />
          <path d="M21.8604 6L24.0604 17H21.5H19.5V19V24H14.5H9.5V19V17H7.5H4.93961L7.13961 6H11.5H14.5H17.5H21.8604Z" stroke="url(#paint0_linear_722_18676)" strokeWidth="4" fill="none" />
          <defs>
            <linearGradient id="paint0_linear_722_18676" x1="3.5" y1="6.71429" x2="25.0475" y2="21.2625" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF0000" />
              <stop offset="0.473958" stopColor="#FF00D6" />
              <stop offset="1" stopColor="#001D84" />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <path d="M18 7H21H27L30 22H25V29H18H11V22H6L9 7H15H18Z" fill="#E4E4E4" />
          <path d="M21.8604 6L24.0604 17H21.5H19.5V19V24H14.5H9.5V19V17H7.5H4.93961L7.13961 6H11.5H14.5H17.5H21.8604Z" stroke="#505050" strokeWidth="4" fill="none" />
        </>
      )}
    </svg>
  );
};

export default MarketSvg;
