import React from 'react';
import styled from 'styled-components';

import noNft from 'assets/common/noNft.png';
import { AuthorName, AuthorProfile, Price, ProfileImage, View } from 'styles';

interface CardBgAvatarProps {
  bg?: string;
  name?: any;
  avatar?: string;
  content?: any;
  isRadius?: boolean;
  isMark?: boolean;
  key?: number;
  cryptoIcon?: any;
  className?: any;
  onClick?: any;
}

const CardBgAvatarStyles: React.FC<CardBgAvatarProps> = ({ bg, name, avatar, content, isMark, ...props }) => {
  const handleImgError = (e: any) => {
    e.target.onerror = null;
    e.target.src = noNft;
  };
  return (
    <Wrap {...props}>
      <div className="author-banner">
        <img src={bg} onError={handleImgError} />
      </div>
      <div className="author-card">
        {avatar && (
          <View className={'avatar'} justify="center">
            <AuthorProfile icon={avatar} w={60} h={60} mark={isMark} border />
          </View>
        )}
        <Name>{name}</Name>
        {content && <Content>{content}</Content>}
      </div>
    </Wrap>
  );
};

export default CardBgAvatarStyles;

const Wrap = styled.div`
  width: 100%;
  min-width: 276px;
  max-width: 276px;
  min-height: 280px;
  height: 280px;
  max-height: 280px;
  position: relative;
  text-align: center;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  .author-banner {
    height: 148px;
    margin-bottom: 26px;
    border-radius: 9px 9px 0px 0px;
    overflow: hidden;

    & > img {
      object-fit: cover;
    }
  }

  .author-card {
    & > h2 {
      margin-bottom: 7px;
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #000000;
    }

    .avatar {
      position: absolute;
      top: 109px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    & > p {
      width: 257px;
      height: 32px;
      margin: 0 auto;
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #717171;
      padding-bottom: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 28px;
`;

const Content = styled.div`
  margin-top: 15px;
`;
