import React from 'react';
import { View, Text } from 'styles';
import eff_loading_01 from 'assets/common/eff_loading_01.gif';

const NoDataComponent = () => {
  return (
    <View direction="column" align="center" justify="center" spacing={12}>
      <img src={eff_loading_01} style={{ width: 60, height: 60 }} />
      <Text size="md" textSpacing={-0.8}>
        Coming Soon
      </Text>
    </View>
  );
};

export default NoDataComponent;
