import React from 'react';
import SubHeader from 'components/Header/SubHeader';
import styled from 'styled-components';
import { Button, Tab, Labelbox, Price, Text, View, CommentItem, Input } from 'styles';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as MsgIcon } from 'assets/svg/speech-bubble.svg';

import { cryptoMarket } from 'utils';
import DetailItemComponent from 'components/detailItem';
import InfoComponent from 'views/item/detail/components/Info';

import BoardItem from 'components/boardItem';

import { useInfiniteScroll } from 'hook/useInfiniteScroll';

import Section from 'components/common/section';
import googleIcon from 'assets/icon/google.png';
import APIService from 'api';

const apiService = new APIService();
const MarketItemDetailView = () => {
  const navigate = useNavigate();
  const { marketUid, uid } = useParams();

  const [itemtDetail, setItemDetail] = React.useState<any>();
  const [commentInput, setCommentInput] = React.useState<string>('');
  const [isComment, setIsComment] = React.useState<boolean>(false);
  const [communityList, setCommunityList] = React.useState<any[]>([]);

  const [activeTab, setActiveTab] = React.useState(1);

  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const commentInputRef: any = React.useRef(null);

  const { profile } = useSelector((store: IStore) => store.System);

  const getItemDetail = async () => {
    const resultNftDetail = await apiService.getItemMarketView({ marketUid, uid });
    setItemDetail(resultNftDetail);
  };

  const getCommunityList = async ({ offsetUid }: any) => {
    setLoading(true);
    const resultMyGroup = await apiService.getCommunityMyGroup({ offsetUid });

    if (communityList.length > 0) {
      communityList.at(-1).uid !== resultMyGroup.list.at(-1).uid && setCommunityList([...communityList, ...resultMyGroup.list]);
    } else {
      setCommunityList(resultMyGroup.list);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    if (isScroll && communityList.length > 0) getCommunityList({ offsetUid: communityList.at(-1).uid });
  }, [isScroll]);

  const handleCommentSend = async (e: any) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value.length > 0) {
      const comment = commentInput;
      const sendResult = await apiService.postItemMarketComment({ marketUid, uid, json: { comment } });

      if (sendResult === 200) {
        setCommentInput('');
        setIsComment(false);
      }
    }
  };

  const HeaderSuffix = () => {
    return (
      <View minH={32} align="center">
        <MsgIcon onClick={() => setIsComment(!isComment)} />
      </View>
    );
  };

  const getGoogle = async () => {
    (function () {
      const cx = '647afc5dcdf884aac';
      const gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx + '&gsc.q=' + `${itemtDetail.name} %26 NFT`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode?.insertBefore(gcse, s);
    })();

    setTimeout(() => {
      if (window.location.href.indexOf('gsc.tab') > 0 && window.location.href.indexOf('gsc.q') < 0) {
        window.location.href = window.location.href + '&gsc.q=' + `${itemtDetail.name} %26 NFT`;
      }
    }, 1000);
  };

  React.useEffect(() => {
    getItemDetail();
    getCommunityList({});
  }, []);

  React.useEffect(() => {
    if (activeTab === 2 && itemtDetail) {
      getGoogle();
    }
  }, [activeTab]);

  return (
    <Wrap>
      <SubHeader title="Items" suffix={<HeaderSuffix />} />
      {itemtDetail && <DetailItemComponent {...itemtDetail} />}
      <StyledTab>
        <Tab active={activeTab}>
          <Tab.Item value={1} text={'Information'} onClick={() => setActiveTab(1)} />
          <Tab.Item value={2} imgText={googleIcon} imgSize={'m'} onClick={() => setActiveTab(2)} />
          <Tab.Item value={3} text={'Community'} onClick={() => setActiveTab(3)} />
        </Tab>
      </StyledTab>
      {itemtDetail && <>{activeTab === 1 && <InfoComponent desc={itemtDetail.description} />}</>}
      {activeTab === 2 && (
        <Section className="wrap-google">
          <div className="gcse-searchresults-only"></div>
        </Section>
      )}
      {activeTab === 3 && (
        <Section className="my-group">
          <CommentList>
            {communityList.length > 0 &&
              communityList.map((item, idx) => <BoardItem key={idx} uid={item.communityChannelUid} name={item.communityChannelName} profileIcon={item.communityChannelProfileIcon} />)}
          </CommentList>
          <div ref={senserRef} className={`observer ${!isLoading ? 'completed' : ''}`} />
        </Section>
      )}

      {itemtDetail && (
        <View direction="column" spacing={38}>
          <View direction="column" spacing={20}>
            {/* <View direction="column" spacing={14}>
              <img className="thumbsnail" src={itemtDetail.imageUrl} alt={itemtDetail.name} />
              <Text size="xl" weight="semi-bold" line={2}>
                {itemtDetail.name}
              </Text>
            </View>

            <Labelbox fontSize={12} color="#808080" label="Price">
              <View align="baseline">
                <Price iconW={16} iconH={27} fontSize={30} icon={cryptoMarket(itemtDetail.priceKind)} price={itemtDetail.price} />
                <Text size="xxs">($ {usd})</Text>
              </View>
            </Labelbox> */}
          </View>

          {/* <Button text="BUY NOW" full rect color="black" h={50} /> */}
        </View>
      )}
      {profile && isComment && (
        <BottomSheet open={true} scrollLocking={false} blocking={false}>
          <Input ref={commentInputRef} value={commentInput} placeholder={'Comment Enter'} onChange={() => setCommentInput(commentInputRef?.current.getValue())} onKeyDown={handleCommentSend} />
        </BottomSheet>
      )}
    </Wrap>
  );
};

export default MarketItemDetailView;
const Wrap = styled.div`
  padding: 20px 0;

  .thumbsnail {
    height: 365px;
    object-fit: cover;
    border-radius: 6px;
  }

  .my-group {
    padding: 0;
  }
`;

const StyledTab = styled.div`
  margin-top: 28px;
  padding-bottom: 30px;
`;
const StyledComment = styled.div`
  padding: 20px;

  .ant-comment-actions {
    top: 10px !important;
  }

  .comment-timestamp {
    position: absolute;
    top: 0;
    right: 0px;
  }
`;

const CommentList = styled.div``;
