import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/common/logo.png';

interface MoaLogoProps {
  w?: number;
  route?: string;
  fontSize?: number;
  color?: string;
  text?: string;
  logoW?: number;
  logoH?: number;
}
const MoaLogoStyles: React.FC<MoaLogoProps> = ({ text, route, ...props }) => {
  const navigate = useNavigate();
  const moveToRoute = () => {
    if (route) navigate(route);
    else return false;
  };
  return (
    <Wrap {...props} onClick={moveToRoute}>
      <img src={logo} />
      <span>{text}</span>
    </Wrap>
  );
};

export default MoaLogoStyles;

MoaLogoStyles.defaultProps = {
  logoW: 15,
  logoH: 15,
  text: 'MOA Wallet',
  fontSize: 12
};

const Wrap = styled.div<MoaLogoProps>`
  ${p => p.w && `width:${p.w}px`};
  display: flex;
  align-items: center;

  img {
    width: ${p => p.logoW}px;
    height: ${p => p.logoH}px;
    margin-right: 5px;
  }

  & > span {
    display: inline-block;
    font-weight: 700;
    font-size: ${p => p.fontSize}px;
    color: ${p => p.color};
  }
`;
