import React from 'react';
import styled from 'styles/theme';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Filter, ThumbTitle, Banners } from 'styles';
import AllComponent from './components/all';
import FollowingComponent from './components/following';
import { ALL_LIST, FOLLOWING_LIST } from 'api/mock/explore';
import ScrollWrap from 'components/common/slideWrap';
import { AD_LIST } from 'api/mock/search';
import { CTG } from 'api/mock/home';

import { useInfiniteScroll } from 'hook/useInfiniteScroll';
import NftProductCompoent from 'components/nftProductItem';
import SubHeader from 'components/Header/SubHeader';
import APIService from 'api/index';
const apiService = new APIService();

const ExploreView: React.FC<any> = ({ user, ...props }) => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [nftList, setNftList] = React.useState<any[]>([]);
  const [activeTab, setActiveTab] = React.useState('All');
  const [tagList, setTagList] = React.useState(['3,000 ETH', 'Art']);
  const [categories, setCategories] = React.useState({ art: false, music: false, games: false, photography: false, domains: false });
  const [saleType, setSaleType] = React.useState({ timedAuction: false, userOnly: false, fixedPrice: false, openFor: false });

  const getSearch = async (nftUid?: number) => {
    setLoading(true);
    const resultNftList = await apiService.getCollectionItemList({ uid, nftUid });

    if (nftList.length > 0) {
      nftList.at(-1).uid !== resultNftList.list.at(-1).uid && setNftList([...nftList, ...resultNftList.list]);
    } else {
      setNftList(resultNftList.list);
    }

    setLoading(false);
    // setToken([]);
  };

  const moveToItem = async (uid: any) => {
    navigate(`/item/${uid}`);
  };

  React.useEffect(() => {
    getSearch();
  }, []);

  React.useEffect(() => {
    if (activeTab === 'Items') {
      if (isScroll && nftList.length > 0) getSearch(nftList.at(-1).uid);
    }
  }, [isScroll]);

  return (
    <Wrap>
      <SubHeader title={'Collection'} />
      {/* <Tab active={activeTab}>
        <Tab.Item value="All" onClick={() => setActiveTab('All')} />
        <Tab.Item badge={99} value="Following" onClick={() => setActiveTab('Following')} />
      </Tab> */}

      {/* <Filter
        tagList={tagList}
        setTagList={setTagList}
        categories={categories}
        setCategories={setCategories}
        saleType={saleType}
        setSaleType={setSaleType}
        filterList={{ price: true, category: true, sale: true }}
      /> */}

      <List>
        {activeTab === 'All' && (
          <>
            {nftList.length > 0 && nftList.map((item, idx) => <NftProductCompoent key={idx} {...item} onClick={() => moveToItem(item.uid)} />)}
            {nftList.length === 0 && <NoResult>No items found for this search.</NoResult>}
            {}
          </>
        )}
        {activeTab === 'Following' && <FollowingComponent list={FOLLOWING_LIST} />}
        <div ref={senserRef} className={`observer ${!isLoading ? 'completed' : ''}`} />
      </List>

      <ScrollWrap title="Browse by Category" style={{ background: '#F5F5F5', marginBottom: 30 }}>
        {CTG.map((item, index) => (
          <ThumbTitle key={index} {...item} />
        ))}
      </ScrollWrap>

      <Banners list={AD_LIST} className="wrap-ad" />
    </Wrap>
  );
};

export default ExploreView;

const Wrap = styled.div`
  position: relative;
  padding-top: 26px;
  margin-bottom: 11px;
`;

const List = styled.div`
  padding: 30px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const NoResult = styled.div`
  padding: 50px 0;
  width: 100%;
  text-align: center;
`;
