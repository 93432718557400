import MainHeader from 'layouts/Header/MainHeader';
import React from 'react';
import styled from 'styled-components';
import ItemSmallType from 'components/cardType01Item/small';
import ItemLargeType from 'components/cardType01Item/large';
import { View } from 'styles';
import { useInfiniteScroll } from 'hook/useInfiniteScroll';

import APIService from 'api';

const apiService = new APIService();

const MarketListView = () => {
  const [viewType, setViewType] = React.useState('row');
  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [marketList, setMarketList] = React.useState<any[]>([]);

  const getMyMarket = async ({ self, uid }: any) => {
    setLoading(true);
    const resultMyItem = await apiService.getMarketList({ self: false, uid });

    if (marketList.length > 0) {
      marketList.at(-1).uid !== resultMyItem.list.at(-1).uid && setMarketList([...marketList, ...resultMyItem.list]);
    } else {
      setMarketList(resultMyItem.list);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getMyMarket({ self: false });
  }, []);

  React.useEffect(() => {
    if (isScroll && marketList.length > 0) getMyMarket({ self: false, uid: marketList.at(-1).uid });
  }, [isScroll]);

  return (
    <Wrap>
      <MainHeader />
      <List>
        {viewType === 'row' && <View justify="space-between">{marketList.length > 0 && marketList.map((item, idx) => <ItemSmallType key={idx} {...item} />)}</View>}
        {viewType === 'column' && (
          <View direction="column" padding="20px 10" justify="space-between" spacing={20}>
            {marketList.length > 0 && marketList.map((item, idx) => <ItemLargeType key={idx} {...item} />)}
          </View>
        )}
      </List>
      <div ref={senserRef} className={`observer ${!isLoading ? 'completed' : ''}`} />
    </Wrap>
  );
};

export default MarketListView;

const Wrap = styled.div`
  padding: 0 10px;
`;
const List = styled.div`
  & > div {
    flex-wrap: wrap;
  }
`;
