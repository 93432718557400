import React from 'react';
import { timestamp } from 'rxjs';
import styled from 'styled-components';
import View from 'styles/view';
import { ReactComponent as HistoryIcon } from 'assets/svg/history.svg';

interface TimestampProps {
  timestamp: any;
  className?: string;
}

const TimestampStyles: React.FC<TimestampProps> = ({ timestamp, ...props }) => {
  return (
    <View direction="row" align="center" spacing={1} {...props}>
      <HistoryIcon width={8} height={8} />
      <Timestamp>{timestamp}</Timestamp>
    </View>
  );
};

export default TimestampStyles;

const Wrap = styled.div``;
const Timestamp = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.5);
`;
