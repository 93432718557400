import { Select } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { View, Text } from 'styles';
import Input from 'ui/input/Input';
import SelectUI from 'ui/select/Select';
import { cryptoMarket, setDigit } from 'utils';
import { ReactComponent as SelectDownArrowIcon } from 'assets/svg/downArrow.svg';
import ButtonUI from 'ui/button/Button';
import SelectButtonUI from 'ui/select-button/SelectButton';
import ModalStyles from 'styles/modal/Modal';
import { DURATION_LIST, METHOD_LIST } from 'api/mock/ctg';

const AuctinoItemComponent: React.FC<any> = ({ usd, ...props }) => {
  const { Option } = Select;
  const [curDuration, setCurDuration] = React.useState('1 month');
  const [curMethod, setCurMethod] = React.useState('Sell to highest bidder');
  const [openDurationModal, setOpenDurationModal] = React.useState(false);
  const [openMethodModal, setOpenMethodModal] = React.useState(false);

  const closeModal = () => {
    setOpenDurationModal(false);
  };

  const onChangeDuration = (value: string) => {
    setCurDuration(value);
    setOpenDurationModal(false);
  };

  const onChangeMethod = (value: string) => {
    setCurMethod(value);
    setOpenMethodModal(false);
  };

  return (
    <StyledWrap>
      <View spacing={32} direction="column" block padding="0 20">
        <SelectButtonUI label="Method" onClick={() => setOpenMethodModal(true)}>
          {curMethod}
        </SelectButtonUI>

        <Input
          type="number"
          label="Starting price"
          block
          placeholder="Amount"
          usd={<Text size="xs">${setDigit(usd, 2, true, true)}</Text>}
          prefix={<img style={{ marginRight: 20, width: 15, height: 26, objectFit: 'cover' }} src={cryptoMarket(1)} />}
          suffix={
            <View pl={16} flex={0}>
              <Text size="sm" weight="bold">
                ETH
              </Text>
            </View>
          }
        />

        <SelectButtonUI label="Duration" onClick={() => setOpenDurationModal(true)}>
          {/* <Select suffixIcon={<SelectDownArrowIcon className="icon-select-arrow" />} defaultValue={1} bordered={false}>
            <Option value={1}>1 month</Option>
          </Select> */}
          {curDuration}
        </SelectButtonUI>

        <Input
          type="number"
          label="Include reserve price"
          block
          placeholder="Amount"
          usd={<Text size="xs">${setDigit(usd, 2, true, true)}</Text>}
          prefix={<img style={{ marginRight: 20, width: 15, height: 26, objectFit: 'cover' }} src={cryptoMarket(1)} />}
          suffix={
            <View pl={16} flex={0}>
              <Text size="sm" weight="bold">
                ETH
              </Text>
            </View>
          }
        />
      </View>

      <View border="1px solid #E6E8EC" borderPos="top" mt={32} padding="8px 20px 20" direction="column">
        <View direction="column" mb={32}>
          <Text size="xs" weight="bold">
            Fees
          </Text>
          <Text size="xxs" color="#7D7D7D">
            Service Fee : 0 %, Listing is free. Once sold, the following fees will be deducted.
          </Text>
          <Text size="xxs" color="#7D7D7D">
            Creator Fee: 0 %
          </Text>
        </View>

        <ButtonUI full color="black">
          Complete listing
        </ButtonUI>
      </View>

      <ModalStyles w={'90%'} open={openMethodModal} isCenter={true} handleCancle={closeModal} type="select">
        {METHOD_LIST.map((item, idx) => (
          <StyledOptionItem key={idx} active={curMethod === item.text} className="select-item" onClick={() => onChangeMethod(item.text)}>
            {item.text}
          </StyledOptionItem>
        ))}
      </ModalStyles>

      <ModalStyles w={'90%'} open={openDurationModal} isCenter={true} handleCancle={closeModal} type="select">
        {DURATION_LIST.map((item, idx) => (
          <StyledOptionItem key={idx} active={curDuration === item.text} className="select-item" onClick={() => onChangeDuration(item.text)}>
            {item.text}
          </StyledOptionItem>
        ))}
      </ModalStyles>
    </StyledWrap>
  );
};

export default AuctinoItemComponent;

const StyledWrap = styled.div`
  width: 100%;
`;

const StyledOptionItem = styled.div<{ active?: boolean }>`
  color: ${p => (p.active ? '#0075FF' : '#808080')} !important;
`;
