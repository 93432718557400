import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { View, Text, Price, Button } from 'styles';
import { cryptoMarket } from 'utils';
const ItemsComponent: React.FC<any> = ({ uid, name, imageUrl, price, priceKind, usd, icon }) => {
  const navigate = useNavigate();
  const { marketUid } = useParams();

  return (
    <Wrap onClick={() => navigate(`/market/${marketUid}/item/detail/${uid}`)}>
      <div className="item">
        <View align="center" spacing={16}>
          <img className="thumbnail" src={imageUrl} alt={name} />
          <View direction="column" block spacing={23}>
            <View>
              <Text size="sm" weight="bold">
                {name}
              </Text>
            </View>
            <View justify="space-between" align="center">
              <View align="baseline" spacing={4}>
                <Price price={price} icon={cryptoMarket(priceKind)} />
                <Text size="xxs">($ {usd})</Text>
              </View>
              <Button text={'Buy now'} onlyText={true} fontSize={12} fontColor="#0085FF" shadow={false} />
            </View>
          </View>
        </View>
      </div>
    </Wrap>
  );
};

export default ItemsComponent;

const Wrap = styled.div`
  & + & {
    .item {
      border-top: 1px solid #eaeaea;
    }
  }
  .item {
    padding: 20px 0;
    margin: 0 20px;
  }

  .thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
`;
