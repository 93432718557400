import React from 'react';

const CommunitySvg: React.FC<any> = ({ active, ...props }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" fill="white" />
      {active ? (
        <>
          <path d="M18 10H29V24.6486V29L20 24.6486H18H13.3289H10.5V10H18Z" fill="#E4E4E4" />
          <path d="M10.3289 19.6486H6V6H15H24V21.6486V22.8116L17.8706 19.8481L17.4581 19.6486H17H15H10.3289Z" stroke="url(#paint0_linear_662_20053)" strokeWidth="4" fill="none" />
          <defs>
            <linearGradient id="paint0_linear_662_20053" x1="4" y1="6.27143" x2="27.3288" y2="19.9679" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF0000" />
              <stop offset="0.473958" stopColor="#FF00D6" />
              <stop offset="1" stopColor="#001D84" />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <path d="M18 10H29V24.6486V29L20 24.6486H18H13.3289H10.5V10H18Z" fill="#E4E4E4" />
          <path d="M10.3289 19.6486H6V6H15H24V21.6486V22.8116L17.8706 19.8481L17.4581 19.6486H17H15H10.3289Z" stroke="#505050" strokeWidth="4" fill="none" />
        </>
      )}
    </svg>
  );
};

export default CommunitySvg;
