import SubHeader from 'components/Header/SubHeader';
import React from 'react';
import styled from 'styled-components';
import { View, Text, Image, TinyButton, Display, Input, Button, Modal, Copy } from 'ui';
import appstore_android from 'assets/bg/appstore-android.png';
import appstore_apple from 'assets/bg/appstore-apple.png';
import { useOtp } from './useOtp';
import { ReactComponent as CopyIcon } from 'assets/icon/copy.svg';

const SettingOtpPage = () => {
  const { qrcodeInfo, moveToBack, checkOtp, closeModal, isOpen, changeInputOtp, otpComplete, setIsOpen } = useOtp();
  return (
    <StyledWrap>
      <SubHeader title="Settings" />

      <Text size="lg" margin={['mb-8']} weight={'bold'}>
        To protect valuable assets please carry out the OTP service.
      </Text>

      <Text margin={['mb-5']} size="lg">
        1. Please set up a Google Authenticator to use OTP.
      </Text>
      <View margin={['mb-1']} spacing={32}>
        <a href={'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'} target={'_blank'} rel="noreferrer">
          <Image style={{ width: 135, height: 40 }} src={appstore_android} />
        </a>
        <a href={'https://apps.apple.com/kr/app/id388497605'} target={'_blank'} rel="noreferrer">
          <Image style={{ width: 135, height: 40 }} src={appstore_apple} />
        </a>
      </View>
      <Text margin={['mb-5']} size={'md'} color={'#808080'}>
        You can choose Google Play or App Store and download it accordingly.
      </Text>
      <Text size={'lg'}>
        2.{' '}
        <Text size={'lg'} weight={'bold'} color={'#FF00D6'}>
          [TOP Important]
        </Text>{' '}
        Run the OTP app and scan the QR code by "Scanning the QR code" at the bottom, or select the "Enter the setting key" button and insert the login account and encryption key.
      </Text>
      <StyledQRCode>
        <img style={{ width: 170, height: 170 }} src={qrcodeInfo?.barcode} />
      </StyledQRCode>
      <Copy copyValue={qrcodeInfo?.secret} target={'qrcode'}>
        <View margin={['mb-1']} spacing={8} align="center" justify="center" block>
          <Display size={'sm'}>{qrcodeInfo?.secret}</Display>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 26, height: 26, border: '1px solid #EAE9E9', borderRadius: '50%' }}>
            <CopyIcon />
          </div>
        </View>
      </Copy>

      <ul className="notice-list">
        <li>Please add an account by scanning the QR code with the authentication app or entering the provided encryption key directly.</li>
        <li>Please be careful not to expose the QR code and key value to others.</li>
        <li>Please set the time of the mobile device to the same time as the current time.</li>
        <li>OTP generates a new number every 30 seconds and can only be used once.</li>
      </ul>

      <Text margin={['mb-3']} weight={'medium'} size={'lg'}>
        3. Enter the 6 digits OTP authentication number in the app.
      </Text>
      <Input type={'number'} onChange={changeInputOtp} placeholder="123456" />
      <View spacing={8} margin={['mt-8']} block>
        <Button block color="borderBlack" onClick={() => setIsOpen({ empty: true, error: false, complete: false })}>
          Cancel
        </Button>
        <Button onClick={checkOtp} color={'black'} block>
          Continue
        </Button>
      </View>

      <Modal w={'90%'} open={isOpen.empty} isCenter={true} handleCancel={closeModal} type="confirm">
        <StyledModalInner>
          <Text size="lg">
            Please complete OTP authentication. <br />
            <br />
            Otherwise, you can’t make the purchase and resale due to security issue.
          </Text>
          <View spacing={8} margin={['mt-8']} block>
            <Button onClick={moveToBack} block color="borderBlack">
              NO
            </Button>
            <Button onClick={closeModal} color={'black'} block>
              YES
            </Button>
          </View>
        </StyledModalInner>
      </Modal>

      <Modal w={'90%'} open={isOpen.error} isCenter={true} handleCancel={closeModal} type="confirm">
        <StyledModalInner>
          <View direction={'column'} spacing={20}>
            <Text size="lg">OTP (Google Authenticator) the 6 digits of the authentication number are incorrect. Please recheck them and enter the correct one.</Text>
            <Text size="lg" color={'#FF0000'}>
              The OTP authentication digits changes every 30 seconds.
            </Text>
          </View>
          <View spacing={8} margin={['mt-7']} block>
            <Button onClick={closeModal} color={'black'} block>
              OK
            </Button>
          </View>
        </StyledModalInner>
      </Modal>

      <Modal w={'90%'} open={isOpen.complete} isCenter={true} handleCancel={closeModal} type="confirm">
        <StyledModalInner>
          <View direction={'column'} spacing={20}>
            <Text size="lg">OTP authentication is completed.</Text>
            <Text size="lg" color={'#AB3FFF'}>
              Please change your password every 3 months to protect your assets.
            </Text>
          </View>
          <View spacing={8} margin={['mt-7']} block>
            <Button onClick={otpComplete} color={'black'} block>
              OK
            </Button>
          </View>
        </StyledModalInner>
      </Modal>
    </StyledWrap>
  );
};

export default SettingOtpPage;
const StyledWrap = styled.div`
  padding: 28px 16px;

  .notice-list {
    padding: 0 20px;
    margin-bottom: 50px;
    list-style: disc;

    & > li {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.8;
      color: #808080;
    }
  }
`;

const StyledQRCode = styled.div`
  padding: 20px;
  text-align: center;
`;

const StyledModalInner = styled.div`
  padding: 16px 12px;
`;
