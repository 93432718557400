import { SORT_LIST } from 'api/mock/ctg';
import { NFT_CREATOR_LIST } from 'api/mock/nft';
import SubHeader from 'components/Header/SubHeader';
import NftCreatorComponent from 'components/v2/nft-creator-item/NftCreator';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { View, Text } from 'styles';
import ModalStyles from 'styles/modal/Modal';
import SelectButtonUI from 'ui/select-button/SelectButton';
import APIService from 'api';
import qs from 'query-string';
const apiService = new APIService();
const MarketCategoryListPage: React.FC = () => {
  const navigate = useNavigate();
  const { ctg } = useParams();

  const { pathname, search } = useLocation();
  const [sort, setSort] = React.useState<string>('recently');
  const [openSortModal, setOpenSortModal] = React.useState(false);
  const [category, setCategory] = React.useState<any>();
  const [marketList, setMarketList] = React.useState<any[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const getMarketItemList = async ({ page, size = 20 }: any) => {
    setLoading(true);
    const resultMyItem = await apiService.getMarketCategoryItemList({ page, size, sort, commonCodeUid: ctg });
    if (marketList.length > 0) {
      marketList.at(-1).uid !== resultMyItem.list.at(-1).uid && setMarketList([...marketList, ...resultMyItem.list]);
    } else {
      setMarketList(resultMyItem.list);
    }

    setLoading(false);
  };

  const getCategory = async () => {
    const result = await apiService.getCollectionCategory();

    const curCategory = result.list.filter((item: any) => item.uid === Number(ctg));

    if (curCategory.length > 0) {
      setCategory(curCategory[0]);
    }
  };

  const onChangeSort = (value: string) => {
    if (sort !== value) {
      setSort(value);
      setMarketList([]);
    }
    setOpenSortModal(false);
  };

  const closeModal = () => {
    setOpenSortModal(false);
  };

  const moveToDetail = (uid: number) => {
    navigate(`/market/collection/${uid}`);
  };
  React.useEffect(() => {
    getCategory();
    getMarketItemList({ page: 1, sort });
  }, [sort]);

  console.log(category, 'category');

  return (
    <StyledWrap>
      {category && <SubHeader title={category?.name} />}

      <View mt={20} mb={20} direction="column" padding="0 20" block justify="center">
        <View justify="flex-end" block mt={8}>
          <SelectButtonUI bgColor="#F9F9F9" fontSize={14} weight="700" w={180} h={40} onClick={() => setOpenSortModal(true)}>
            {SORT_LIST.filter(item => item.value === sort)[0].name}
          </SelectButtonUI>
        </View>
      </View>

      <View padding="0 20" block wrap="wrap" spacing={16}>
        {marketList.length > 0 && marketList.map((item, idx) => <NftCreatorComponent key={idx} {...item} onClick={() => moveToDetail(item.uid)} />)}
      </View>

      <ModalStyles w={'90%'} open={openSortModal} isCenter={true} handleCancle={closeModal} type="select">
        {SORT_LIST.map((item, idx) => (
          <StyledOptionItem key={idx} active={sort === item.name} className="select-item" onClick={() => onChangeSort(item.name)}>
            {item.name}
          </StyledOptionItem>
        ))}
      </ModalStyles>
    </StyledWrap>
  );
};

export default MarketCategoryListPage;

const StyledWrap = styled.div`
  padding-bottom: 100px;
`;
const StyledOptionItem = styled.div<{ active?: boolean }>`
  color: ${p => (p.active ? '#0075FF' : '#808080')} !important;
`;
