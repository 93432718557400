export const SCAMS_LIST = [
  {
    headline: 'Head Line Text',
    subject: `Fake NFT Stores`,
    desc: `Scammers often replicate popular NFT marketplaces, like OpenSea, to create fake NFT stores. These sites can look almost identical to the originals and can trick even an experienced NFT buyer into spending large amounts of money on a fake artwork that is, in reality, worth nothing.`,
    hashTags: ['#Big', '#ReleasesNFT', '#GOODNFT', '#BigPrice'],
    sourceLink: 'www.aaasdsas.com/ssasasas/22222-121212/22222348923049284092840923804',
    datetime: '01.01. 2022',
    link: 'https://user-images.githubusercontent.com/45615584/190144125-bc06b3fe-c10d-4539-ba28-c1dace3594ac.png'
  },
  {
    headline: 'Head Line Text',
    subject: `Social Media Impersonation`,
    desc: `Scammers often replicate popular NFT marketplaces, like OpenSea, to create fake NFT stores. These sites can look almost identical to the originals and can trick even an experienced NFT buyer into spending large amounts of money on a fake artwork that is, in reality, worth nothing.`,
    hashTags: ['#Big', '#ReleasesNFT', '#GOODNFT', '#BigPrice'],
    sourceLink: 'www.aaasdsas.com/ssasasas/22222-121212/22222348923049284092840923804',
    datetime: '01.01. 2022',
    link: 'https://user-images.githubusercontent.com/45615584/190144125-bc06b3fe-c10d-4539-ba28-c1dace3594ac.png'
  },
  {
    headline: 'Head Line Text',
    subject: `Customer Support Impersonation`,
    desc: `Scammers often replicate popular NFT marketplaces, like OpenSea, to create fake NFT stores. These sites can look almost identical to the originals and can trick even an experienced NFT buyer into spending large amounts of money on a fake artwork that is, in reality, worth nothing.`,
    hashTags: ['#Big', '#ReleasesNFT', '#GOODNFT', '#BigPrice'],
    sourceLink: 'www.aaasdsas.com/ssasasas/22222-121212/22222348923049284092840923804',
    datetime: '01.01. 2022',
    link: 'https://user-images.githubusercontent.com/45615584/190144125-bc06b3fe-c10d-4539-ba28-c1dace3594ac.png'
  }
];

export const EMOJI = [
  {
    value: 'smile',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/190357958-0d281447-a57e-4b85-85aa-7f3a8da2704a.png',
    count: 17
  },
  {
    value: 'love',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/190357945-3d682118-ac37-4867-8d0c-f675eb1def39.png',
    count: 24
  },
  {
    value: 'angry',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/190357913-c094af1d-9c3b-4965-8da5-8bec35a8d8c2.png',
    count: 105
  },
  {
    value: 'cry',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/190357896-c8500b61-8b23-4d93-9e8e-6d06de3077f0.png',
    count: 96
  }
];
export const EMOJI2 = [
  {
    value: 'smile',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/190357958-0d281447-a57e-4b85-85aa-7f3a8da2704a.png',
    count: 36
  },
  {
    value: 'love',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/190357945-3d682118-ac37-4867-8d0c-f675eb1def39.png',
    count: 23
  },
  {
    value: 'angry',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/190357913-c094af1d-9c3b-4965-8da5-8bec35a8d8c2.png',
    count: 309
  },
  {
    value: 'cry',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/190357896-c8500b61-8b23-4d93-9e8e-6d06de3077f0.png',
    count: 207
  }
];

export const IMOTION_LIST = [
  {
    value: 'smile',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/190357958-0d281447-a57e-4b85-85aa-7f3a8da2704a.png',
    count: 11
  },
  {
    value: 'love',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/190357945-3d682118-ac37-4867-8d0c-f675eb1def39.png',
    count: 22
  },
  {
    value: 'angry',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/190357913-c094af1d-9c3b-4965-8da5-8bec35a8d8c2.png',
    count: 21
  },
  {
    value: 'cry',
    imageUrl: 'https://user-images.githubusercontent.com/45615584/190357896-c8500b61-8b23-4d93-9e8e-6d06de3077f0.png',
    count: 22
  }
];

export const SEARCH_RESURLT = {
  // total: 0
  total: 42
};
