import React from 'react';
import styled from 'styled-components';

interface AvatarsProps {
  avatar?: any;
  w?: number;
  h?: number;
  gap?: number;
}

const AvatarsStyles: React.FC<AvatarsProps> = ({ avatar, ...props }) => {
  return (
    <Wrap {...props}>
      <div>
        <img src={avatar} />
      </div>
    </Wrap>
  );
};

export default AvatarsStyles;

AvatarsStyles.defaultProps = {
  w: 27,
  h: 27,
  gap: -10
};

const Wrap = styled.div<AvatarsProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: ${p => p.w}px;
    height: ${p => p.h}px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 50%;
    overflow: hidden;
  }

  & + & {
    margin-left: ${p => p.gap}px;
  }
`;
