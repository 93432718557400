import React from 'react';
import { View } from 'styles';
import { ReactComponent as TwitterLogo } from 'assets/logo/twitter-circle-gray.svg';
import { ReactComponent as InstagramLogo } from 'assets/logo/instagram-circle-gray.svg';
import { ReactComponent as FacebookLogo } from 'assets/logo/facebook-circle-gray.svg';
import { ReactComponent as DiscordLogo } from 'assets/logo/discord-circle-gray.svg';
import { ReactComponent as TelegramLogo } from 'assets/logo/telegram-circle-gray.svg';
import styled from 'styled-components';

const FooterComponent: React.FC = () => {
  const socialIcons = [
    {
      name: 'twitter',
      logo: <TwitterLogo />
    },
    {
      name: 'instagram',
      logo: <InstagramLogo />
    },
    {
      name: 'facebook',
      logo: <FacebookLogo />
    },
    {
      name: 'Discord',
      logo: <DiscordLogo />
    },
    {
      name: 'telegram',
      logo: <TelegramLogo />
    }
  ];
  return (
    <StyledSocialIcon spacing={30} align="center" justify="center" block>
      {socialIcons.map(({ logo }, idx) => (
        <View key={idx}>{logo}</View>
      ))}
    </StyledSocialIcon>
  );
};

export default FooterComponent;

const StyledSocialIcon = styled(View)`
  opacity: 0.4;
`;
