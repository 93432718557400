import React from 'react';
import styled from 'styled-components';

interface SwitchProps {}

const SwitchUI: React.FC<SwitchProps> = ({ children, ...props }) => {
  return <StyledSwitch>{children}</StyledSwitch>;
};

export default SwitchUI;

const StyledSwitch = styled.div`
  .ant-switch {
    min-width: 39px;
    height: 24px;
  }

  .ant-switch-handle {
    width: 20px;
    height: 20px;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 20px - 2px);
  }

  .ant-click-animating-node {
    box-shadow: none !important;
  }
`;
