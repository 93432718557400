import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/svg/deleteCircle.svg';

import { Modal as AntModal } from 'antd';
import styled, { css } from 'styled-components';
import { useModal } from './useModal';

type ModalTypeUnion = 'empty' | 'default' | 'select' | 'confirm' | 'block';

export interface ModalProps {
  open?: boolean;
  handleCancel?: VoidFunction;
  type?: ModalTypeUnion;
  children?: React.ReactNode;
  header?: React.ReactNode;
  isCenter?: boolean;
  w?: number | string;
  maxW?: number | string;
  offsetTop?: number;
  footer?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ ...props }) => {
  const { renderClosable } = useModal(props);
  return (
    <StyledModal {...props}>
      <AntModal
        onOk={e => {
          e.stopPropagation();
        }}
        width={props.w}
        style={{ top: props.offsetTop, borderRadius: 16, overflow: 'hidden' }}
        bodyStyle={{ padding: 0 }}
        wrapClassName={`modal-type-${props.type}`}
        closable={renderClosable}
        centered={props.isCenter}
        closeIcon={<CloseIcon />}
        open={props.open}
        onCancel={props.handleCancel}
        footer={false}
      >
        <StyleModalInner>
          {props.header && <div className="header">{props.header}</div>}
          <StyledContent type={props.type}>{props.children}</StyledContent>
          {props.footer && <div className="footer">{props.footer}</div>}
        </StyleModalInner>
      </AntModal>
    </StyledModal>
  );
};

export default Modal;

Modal.defaultProps = {
  type: 'default',
  isCenter: false
};

const StyleSelect = css`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 44px;

    &:nth-child(even) {
      background: var(--bg-color-200);
    }
  }

  .active {
    color: var(--brand-color-300);
  }
`;

const StyledModal = styled.div<ModalProps>`
  max-width: '328px';
`;

const StyleModalInner = styled.div``;

const StyledContent = styled.div<{ type?: ModalTypeUnion }>`
  ${p => p.type === 'select' && StyleSelect};
`;
