import { Checkbox, Switch } from 'antd';
import SubHeader from 'components/Header/SubHeader';
import React from 'react';
import styled from 'styled-components';
import { View, Text, Button } from 'styles';
import ButtonUI from 'ui/button/Button';
import { Display, Image, Text as TextUI, View as ViewUI } from 'ui/';
import Input from 'ui/input/Input';
import SwitchUI from 'ui/switch/Switch';
import { CountryPhoneInputValue } from 'antd-country-phone-input';
import { ReactComponent as UnLockIcon } from 'assets/icon/unlock.svg';
import { ReactComponent as LockIcon } from 'assets/icon/lock.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';

import PhoneInputUI from 'ui/phone-input/PhoneInput';
import CheckboxUI from 'ui/checkbox/Checkbox';
import { BottomSheet } from 'react-spring-bottom-sheet';
import InputStyles from 'styles/input';
import ModalStyles from 'styles/modal/Modal';
import SelectButtonUI from 'ui/select-button/SelectButton';
import { useNavigate } from 'react-router-dom';
import { useSetting } from './useSetting';

const SettingPage: React.FC = () => {
  const {
    profile,
    myWallet,
    accountToCertification,
    smsCountryList,
    smsSearch,
    changeEmail,
    changePhone,
    changeSmsSearch,
    selectCountry,
    curCountry,
    emailVerify,
    phoneVerify,
    otpVerify,
    sendVerifyEmail,
    verifyToggleEmail,
    sendVerifyPhone,
    verifyTogglePhone,
    changeSmsVerify,
    changeNewsLetter,
    newsLetter,
    otpDelete,
    saveSetting,
    isOpen,
    openModal,
    closeModal,
    sendSMSCode,
    moveToEnablePage
  } = useSetting();

  return (
    <StyledWrap>
      <SubHeader title="Settings" />

      <View direction="column" pt={32} pl={20} pr={20} pb={16} borderPos="bottom" bgColor="#F5F5F5">
        <Text size="xs" weight="bold">
          Email / SMS / OTP Authentication
        </Text>
        <Text size="xxs" color={'#7B7B7B'}>
          Only authorized email account is provided transaction notifications.
        </Text>
      </View>

      <View bgColor="#fff" direction="column" borderPos="bottom" padding="24px 20" spacing={24} block>
        <Input placeholder={profile?.email || 'Enter your email address.'} onChange={changeEmail} block />
        <View direction={'column'} spacing={10}>
          <View align="center" justify="space-between" block>
            <ButtonUI maxW={200} h={48} color="borderBlack" border="1px solid #000" onClick={sendVerifyEmail} disabled={emailVerify}>
              Email Verify
            </ButtonUI>
            <SwitchUI>
              <Switch checked={emailVerify} defaultChecked={emailVerify} onClick={verifyToggleEmail} />
            </SwitchUI>
          </View>
          <View align={'flex-start'} spacing={18} block>
            <CheckboxUI>
              <Checkbox disabled={!emailVerify} checked={newsLetter} onClick={changeNewsLetter}>
                <Text size={'sm'}>I agree to receive marketing updates and newsletter from NVIA.</Text>
              </Checkbox>
            </CheckboxUI>
          </View>
        </View>

        <View direction="column" spacing={8} block>
          {/* <PhoneInputUI phoneNumber={value} setPhoneNumber={setValue} /> */}
          <View spacing={10} align={'center'} justify={'space-between'}>
            <SelectButtonUI w={120} isBorder={false} bgColor="transparent" onClick={() => openModal('code')}>
              <View w={64} align={'center'} spacing={4}>
                <Image style={{ width: 20, height: 20 }} fit={'contain'} src={`https://cantena.io/assets/country/${curCountry.countryDisplayEngName.replace('/', '-')}_${curCountry.isoCode}.png`} />
                <Text>+{curCountry.countryCallingCode}</Text>
              </View>
            </SelectButtonUI>
            <Input placeholder={profile?.phoneNumber || 'Please enter phone number.'} onChange={changePhone} />
          </View>
          <View align="center" justify="space-between" block>
            <ButtonUI maxW={200} h={48} color="borderBlack" border="1px solid #000" onClick={sendVerifyPhone} disabled={phoneVerify}>
              Send SMS with code
            </ButtonUI>
            <SwitchUI>
              <Switch checked={phoneVerify} defaultChecked={phoneVerify} onClick={verifyTogglePhone} />
            </SwitchUI>
          </View>
        </View>
      </View>

      <View borderPos="bottom" spacing={8} padding={'24px 20'} bgColor="#fff" direction="column" block>
        <Text size="xs" weight="bold">
          OTP Authentication
        </Text>
        <View align={'center'} spacing={8} block>
          {profile?.otpFlag ? (
            <>
              <LockIcon />
              <Text size={'lg'}>Status : In Use</Text>
            </>
          ) : (
            <>
              <UnLockIcon />
              <Text size={'lg'}>Status : Not in Use</Text>
            </>
          )}
        </View>
        {profile?.otpFlag ? (
          <ButtonUI h={48} color="borderBlack" border="1px solid #000" full onClick={otpDelete}>
            Authenticated
          </ButtonUI>
        ) : (
          <ButtonUI h={48} color="borderBlack" border="1px solid #000" full onClick={moveToEnablePage}>
            Enable
          </ButtonUI>
        )}
      </View>

      <View block mt={52} pl={20} pr={20}>
        <ButtonUI full color="black" onClick={saveSetting}>
          Save settings
        </ButtonUI>
      </View>

      <BottomSheet open={isOpen.sms} onDismiss={closeModal} snapPoints={({ minHeight }) => minHeight}>
        <View direction="column" padding="20">
          <View block margin={'0 0 30px 0'}>
            <Text size="3xl" weight="bold">
              SMS Verification
            </Text>
          </View>

          <View direction={'column'} spacing={30} block>
            <View direction={'column'} spacing={12} block>
              <Text size="md">Code have been sent to your phone.</Text>
              <Input type={'number'} placeholder="123456" onChange={changeSmsVerify} />
            </View>

            <ButtonUI full color={'black'} onClick={sendSMSCode}>
              VERIFY
            </ButtonUI>
          </View>
        </View>
      </BottomSheet>

      <ModalStyles
        w={'90%'}
        open={isOpen.verifyCheck}
        isCenter={true}
        handleCancle={closeModal}
        type="empty"
        isClose={false}
        footer={
          <View spacing={8}>
            <ButtonUI color={'borderBlack'} full onClick={closeModal}>
              NO
            </ButtonUI>
            <ButtonUI color={'black'} full>
              YES
            </ButtonUI>
          </View>
        }
      >
        <Text size="sm">
          The verifying code is not valid.
          <br />
          <br />
          Do you want to receive the valid one now?
        </Text>
      </ModalStyles>

      <ModalStyles open={isOpen.code} isCenter={true} handleCancle={closeModal} type="empty">
        <ViewUI direction="column" block>
          <Display margin={['mb-7']} size="xl" weight="bold">
            Select Country Code
          </Display>

          <Input innerPrefix={<SearchIcon />} placeholder={'Search'} onChange={changeSmsSearch} block />
          <StyledList>
            {smsCountryList.length > 0 &&
              smsCountryList
                .filter(item => item.countryDisplayEngName.toLowerCase().includes(smsSearch.toLowerCase()))
                .map((item, idx, array) => (
                  <ViewUI direction={'column'} spacing={12} margin={['mb-4']} border={['bt-1']} borderColor={'line-color-100'} key={idx} onClick={() => selectCountry(item)} block>
                    {array[idx - 1]?.countryDisplayEngName.charAt(0) !== item.countryDisplayEngName.charAt(0) && <TextUI margin={['mt-1']}>{item.countryDisplayEngName.charAt(0)}</TextUI>}
                    <View key={idx} justify={'space-between'} align={'center'} block>
                      <View align="center" spacing={12}>
                        <Image style={{ width: 40, height: 40 }} fit={'contain'} src={`https://cantena.io/assets/country/${item.countryDisplayEngName.replace('/', '-')}_${item.isoCode}.png`} />
                        <Text size="md">{item.countryDisplayEngName}</Text>
                      </View>
                      <Text size="md">+{item.countryCallingCode}</Text>
                    </View>
                  </ViewUI>
                ))}
          </StyledList>
        </ViewUI>
      </ModalStyles>
    </StyledWrap>
  );
};

export default SettingPage;

const StyledWrap = styled.div`
  background-color: #f9f9f9;
  padding-bottom: 45px;
`;

const StyledList = styled.div`
  width: 100%;
  border-top: 1px solid #e6e8ec;
  height: 400px;
  margin-top: 28px;
  overflow-y: scroll;
`;
