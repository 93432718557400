export const ITEM_DETAIL = {
  groupLogo: 'https://user-images.githubusercontent.com/45615584/173243427-c1161921-9d71-48a0-8376-97cc0aa58af7.png',
  pubkey: '3xY1K fsdfCoACVj',
  title: 'the nature #125',
  avatar: 'https://user-images.githubusercontent.com/45615584/173232248-01c2b0df-d2e7-4cb6-8058-45f0726ca9a4.png',
  nftImg: 'https://user-images.githubusercontent.com/45615584/173228907-86e2e434-aa14-4867-8e52-b31229005ea7.png',
  groupName: 'NFT World by Justin',
  tags: ['nature', 'photography', 'peace'],
  view: '1.5k',
  like: '1.5k',
  priceRate: 7,
  isLike: true,
  tokenIcon: 'https://user-images.githubusercontent.com/45615584/173232253-21659764-df21-4f55-b445-06445f069c7f.png',
  amount: 0.7,
  owned: [
    {
      avatar: 'https://user-images.githubusercontent.com/45615584/173232256-11f23c54-d1e4-485d-bb23-98f7a176e70a.png'
    },
    {
      avatar: 'https://user-images.githubusercontent.com/45615584/173232260-573d78f7-2270-4019-a4eb-25ffb97d7072.png'
    },
    {
      avatar: 'https://user-images.githubusercontent.com/45615584/173232265-5829c6d9-eda5-48cd-b5f8-5603e98c6f72.png'
    }
  ],
  information: {
    desc: `Created by 
Smircs
1/1 Art Pieces (10K resolution unlockable content)

Smircs is a non generative collection handcrafted by combrisi X flatizy.

In accepting one, you accept citizenship in Smircia and may be called to serve in its many and highly renowned vodka induced party-wars with the planet Yolo.

The legions are armed, the battle is nigh - the entire Smircian race now stands in silence, for the dawn of their time may finally have come to arrive. With highest reverence and awe, they await the prophesied answer, one the old oracle once determined would yield the outcome for the Smircian race for millenia to come. The prophecy has told of the birth of an elite, the 'exceptional few', members of the human race, a select group uniquely able to provide an answer to a singular question that has echoed through the annals of Smircian history. In the words of the oracle of old - the question is now put to you:

Which Smirc, are you?

`,
    chart: [],
    history: [
      {
        id: 0,
        amount: 1.1,
        tokenIcon: 'https://user-images.githubusercontent.com/45615584/173285266-338438c3-bfff-4649-9407-40d4c0d3a003.png',
        stockTrend: 5.02,
        nftName: 'Parallel Smircs',
        timestamp: '23 Days ago'
      },
      {
        id: 1,
        amount: 0.9,
        tokenIcon: 'https://user-images.githubusercontent.com/45615584/173285266-338438c3-bfff-4649-9407-40d4c0d3a003.png',
        stockTrend: 2.98,
        nftName: 'Smirc META-GAMING-4829',
        timestamp: '12 Days ago'
      },
      {
        id: 2,
        amount: 0.8,
        tokenIcon: 'https://user-images.githubusercontent.com/45615584/173285266-338438c3-bfff-4649-9407-40d4c0d3a003.png',
        stockTrend: -6,
        nftName: 'Smirc ArtBlock Admin',
        timestamp: '2 Days ago'
      }
    ],
    community: [
      {
        id: 0,
        avatar: 'https://user-images.githubusercontent.com/45615584/171994034-ceef1dd0-053a-4101-b89d-159309e272e7.png',
        name: 'justin',
        channelName: 'Bored Ape Yacht Club',
        timestamp: '1hr ago',
        isOnline: false,
        nftData: {
          isOwner: true,
          imageUrl: 'https://user-images.githubusercontent.com/45615584/171992890-e2dd0046-3ea6-433a-8797-3a911fd6db0d.png',
          market: 'The Nature 1/1',
          nftName: 'the nature #125',
          price: 88.069,
          usd: 493201.03,
          timestamp: '12 hours ago',
          author: 'justin',
          cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
        },
        emojis: [
          {
            imageUrl: 'https://user-images.githubusercontent.com/45615584/172003725-905449f1-8e20-4966-8eee-a93681085de4.png',
            name: 'thumbs-up',
            count: 12
          }
        ],
        reply: {
          total: 3,
          timestamp: 'Today at 1.:50AM',
          data: [
            { avatar: 'https://user-images.githubusercontent.com/45615584/172000252-9e51249a-6ab5-47f1-91a3-927ff8ebdc4d.png' },
            { avatar: 'https://user-images.githubusercontent.com/45615584/172000257-04570497-7a8e-4c4c-9b07-a2015af46614.png' },
            { avatar: 'https://user-images.githubusercontent.com/45615584/172000260-53b5102c-1842-4573-bd70-7d94fb67414b.png' }
          ]
        }
      }
    ]
  },
  relatedData: [
    {
      market: 'NFT World by justin',
      nftName: 'smirc 106',
      price: 88.069,
      usd: 49320103,
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/173295334-bfd358f6-83ce-47e7-91bf-18a300cbb1c7.png',
      timestamp: '12 hours ago',
      creator: 'justin',
      imageUrl: 'https://user-images.githubusercontent.com/45615584/171992887-bae9ca04-df79-47aa-ab36-b30068be07f2.png'
    },
    {
      market: 'NFT World by justin',
      nftName: 'smirc 106',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/173295334-bfd358f6-83ce-47e7-91bf-18a300cbb1c7.png',
      price: 88.069,
      usd: 49320103,
      timestamp: '12 hours ago',
      creator: 'justin',
      imageUrl: 'https://user-images.githubusercontent.com/45615584/171992887-bae9ca04-df79-47aa-ab36-b30068be07f2.png'
    }
  ]
};
