import { useMediaQuery } from 'react-responsive';

const breakpoint = {
  mobile: 0,
  tablet: 768,
  desktop: 1920,
  desktopMedium: 1240,
  desktopSmall: 992
};

export const device = {
  mobileSmall: `(max-width: 320px)`,
  mobile: `(min-width: ${breakpoint.mobile}px) and (max-width: ${breakpoint.tablet - 1}px)`,
  tablet: `(min-width: ${breakpoint.tablet}px) and (max-width: ${breakpoint.desktopSmall - 1}px)`,
  desktopSmall: `(min-width: ${breakpoint.desktopSmall}px) and (max-width: ${breakpoint.desktopMedium - 1}px)`,
  desktop: `(min-width: ${breakpoint.desktopMedium}px)`
};

export function useDevice() {
  const isDesktop = useMediaQuery({ query: `${device.desktop}` });
  const isDesktopSmall = useMediaQuery({ query: `${device.desktopSmall}` });
  const isTablet = useMediaQuery({ query: `${device.tablet}` });
  const isMobile = useMediaQuery({ query: `${device.mobile}` });
  const isMobileSmall = useMediaQuery({ query: `${device.mobileSmall}` });
  return { isDesktop, isDesktopSmall, isTablet, isMobile, isMobileSmall };
}
