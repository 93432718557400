import React from 'react';
import styled, { css } from 'styled-components';
import { View } from 'styles';

import useInput, { MessageTypes } from './useInput';

export type InputStyle = 'text' | 'border';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  label?: string;
  block?: boolean;
  prefix?: React.ReactNode;
  innerPrefix?: React.ReactNode;
  /** @default 'border' */
  prefixStyle?: InputStyle;
  suffix?: React.ReactNode;
  /** @default 'border' */
  suffixStyle?: InputStyle;
  value?: string;
  error?: string;
  warning?: string;
  info?: string;
  infoNode?: React.ReactNode;
  infoPos?: 'top' | 'bottom';
  isViewLength?: boolean;
  placeholderColor?: string;
  icon?: React.ReactNode;
  usd?: React.ReactNode;
}

export const Input: React.FC<InputProps> = ({
  usd,
  infoNode,
  infoPos,
  label,
  block,
  prefix,
  prefixStyle,
  innerPrefix,
  suffix,
  suffixStyle,
  disabled,
  error,
  warning,
  info,
  isViewLength,
  placeholderColor,
  icon,
  ...props
}) => {
  const { message, messageType, isMaxLength, lengthCount, isDefault } = useInput({ error, info, maxLength: props.maxLength, value: props.value, warning, prefix, prefixStyle, icon });

  return (
    <StyledWrap block={block}>
      {label && (
        <StyledLabel infoNode={Boolean(infoNode)} required={props.required}>
          {label}
        </StyledLabel>
      )}
      {infoPos === 'top' && infoNode && infoNode}
      <StyledInputWrap>
        {prefix && prefix}
        {icon && <StyledIconWrap>{icon}</StyledIconWrap>}
        <StyledInputInner isPrefix={!!innerPrefix} isDefault={isDefault} disabled={disabled}>
          {innerPrefix && innerPrefix}
          <StyledInput {...props} />
          {usd && <StyledUsd>{usd}</StyledUsd>}
        </StyledInputInner>
        {isViewLength && <StyledCount isMaxLength={isMaxLength}>{lengthCount}</StyledCount>}
        {suffix && suffix}
      </StyledInputWrap>
      {infoPos === 'bottom' && infoNode && infoNode}
      <StyledMessage type={messageType}>{message}</StyledMessage>
    </StyledWrap>
  );
};

export default Input;

Input.defaultProps = {
  isViewLength: false,
  prefixStyle: 'border',
  suffixStyle: 'border'
};

const StyledIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
`;

const StyledWrap = styled.label<{ block?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  gap: 2px;
  ${p => p.block && 'width: 100%'};
`;

const StyledLabel = styled.label<{ required?: boolean; infoNode?: boolean }>`
  margin-bottom: ${p => (p.infoNode ? 2 : 8)}px;
  color: #000 !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  ${p =>
    p.required &&
    css`
      &::after {
        content: ' *';
        color: #ab3fff;
      }
    `}
`;

const StyledInputWrap = styled.div<{ error?: string; warning?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledInputInner = styled.div<{ isPrefix?: boolean; isDefault: boolean; disabled?: boolean }>`
  position: relative !important;
  flex: 1 !important;
  padding: 0 16px !important;
  height: 48px !important;
  padding-left: ${p => (p.isDefault ? '0px !important' : '16px !important')};
  transition: border-color 0.15s;
  background-color: #fff;
  border-radius: 12px !important;
  border: 1px solid #e6e8ec !important;

  ${p =>
    p.isPrefix &&
    `
      display: flex;
      align-items: center;
      gap: 8px;
    `}

  ${p => p.disabled && 'background-color:#eaeaea !important'};
`;

const StyledCount = styled.div<{ isMaxLength: boolean }>`
  display: flex !important;
  align-items: center !important;
  padding-right: 16px !important;
  color: ${p => (p.isMaxLength ? 'var(--red-color-400)' : 'var(--gray-color-300)')};
`;

const StyledMessage = styled.p<{ type?: MessageTypes | null }>`
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 18px !important;
  color: var(--gray-color-300);
  color: ${p => p.type === 'error' && 'var(--red-color-400) !important;'};
  color: ${p => p.type === 'warning' && 'var(--yellow-color-300) !important;'};
`;

const StyledInput = styled.input<{ placeholderColor?: GrayColorTypes }>`
  width: 100% !important;
  height: 100% !important;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: normal !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  &::placeholder {
    color: ${p => (p.placeholderColor ? `var(--${p.placeholderColor}) !important` : '#BFBFBF !important')};
  }

  &:disabled {
    background-color: #eaeaea !important;
    color: #757575 !important;
  }
`;

const StyledUsd = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 0 -20px;
`;
