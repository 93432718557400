import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LinkUrlSVG } from 'assets/svg/link-url.svg';

import APIService from 'api';

const BestDrop: React.FC<any> = () => {
  const navigate = useNavigate();
  React.useEffect(() => {}, []);

  return (
    <Wrap onClick={() => window.open('https://www.cathsimard.com/nfts-2')}>
      <Sup>NVIA Choice BEST DROP</Sup>
      <BackImg src={'https://user-images.githubusercontent.com/6345000/198439346-09360dd8-725f-4de4-a333-c5d9c9631a1a.png'} />
      <Content>
        <h2>
          Own a new world <LinkUrlSVG />
        </h2>
        <p>{`<Cath Simard> fashion model, photographer, traveler and teacher. To create a single image, she’s walked as much as 250 kilometers, trekked 18 days in the mountains and shot at an elevation as high as 5,900 meters. (About 186 miles and 19,356 feet.). Due to her lengthy creative process, Cath only creates and releases 6-10 images a year.`}</p>
      </Content>
      <ExtraInfo>
        <img src={'https://user-images.githubusercontent.com/6345000/198457788-581d0275-34d1-4e95-83fd-4e52cdc3d090.png'} />
        <h2>CATH SIMARD</h2>
        <p>‘‘I DON’T CAPTURE WHAT MY EYES SEE, I CAPTURE WHAT MY MIND IMAGINES.’’</p>
      </ExtraInfo>
    </Wrap>
  );
};

export default BestDrop;

const Wrap = styled.div`
  position: relative;
  min-height: 412px;
`;

const Sup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 12px;
  gap: 8px;

  position: absolute;
  width: 165px;
  height: 20px;
  left: 0px;
  top: 0px;

  background: #000000;
  border-radius: 0px 0px 10px 0px;

  /* DisplayBold_10 */

  font-family: 'Noto Sans';
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;

  color: #ffffff;
  z-index: 10;
`;

const BackImg = styled.img`
  width: 100%;
  height: 340px;
  object-fit: cover;
`;

const Content = styled.div`
  position: absolute;
  bottom: 72px;
  left: 0;
  padding: 0 20px 12px;
  width: 100%;
  z-index: 8;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 52.08%);
  h2 {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;

    color: #ffffff;

    svg {
      margin-left: 4px;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    /* or 160% */

    display: flex;
    align-items: center;

    /* Primary white */

    color: #ffffff;
  }
`;

const ExtraInfo = styled.div`
  position: relative;
  padding: 12px 0 0 76px;

  img {
    position: absolute;
    left: 20px;
    top: 12px;

    width: 40px;
    height: 40px;
    border-radius: 10px;
  }

  h2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
  }

  p {
    margin-top: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
`;
