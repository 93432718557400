import React from 'react';
import styled from 'styled-components';
import { Upload, Button as UploadButton, Switch, UploadProps, Select } from 'antd';
import { ReactComponent as CameraIcon } from 'assets/svg/camera.svg';
import { ReactComponent as GalleryIcon } from 'assets/svg/gallery.svg';
import { ReactComponent as UploadIcon } from 'assets/svg/upload.svg';
import { ReactComponent as StarIcon } from 'assets/svg/star.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as SelectPictureIcon } from 'assets/svg/select_picture.svg';
import { ReactComponent as SelectDownArrowIcon } from 'assets/svg/downArrow.svg';

import { Button, Input, Labelbox, Link, StepItem, Textarea, View, WrapIcon, Text, SelectLabel } from 'styles';
import { useNavigate } from 'react-router-dom';
import GalleryItemComponent from 'components/GalleryItem';
import cookie from 'js-cookie';
import SubHeader from 'components/Header/SubHeader';
import APIService, { apiBaseUrl } from 'api';
const apiService = new APIService();

const FollowListItemComponent: React.FC<any> = ({ uploadData, setUploadData, setStep }) => {
  const { Option } = Select;
  const navigate = useNavigate();
  const [marketCtg, setMarketCtg] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const marketNameRef: any = React.useRef(null);
  const descriptionRef: any = React.useRef(null);
  const urlRef: any = React.useRef(null);
  const [inputs, setInputs] = React.useState({ name: '', categoryCodeUid: '', description: '', metadata: '', imageUrl: '', communityUrl: '' });

  const getMarketCtg = async () => {
    const resultCtg = await apiService.getMarketCtg();

    setMarketCtg(resultCtg.list);

    setInputs({ ...inputs, categoryCodeUid: resultCtg.list[0].uid });
  };

  const uploadStatus = isLoading ? <LoadingOutlined /> : <UploadIcon />;

  const handleMarketUpload = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      const tempUpload = { ...inputs, imageUrl: info.file.response.data.image };

      setInputs(tempUpload);

      setIsLoading(false);
    }
  };

  const createMarketUploadProps: UploadProps = {
    name: 'image',
    listType: 'picture-card',
    showUploadList: false,
    action: `${apiBaseUrl}/api/market/upload`,
    onChange: (info: any) => handleMarketUpload(info),
    headers: {
      Authorization: `Bearer ${cookie.get('accessToken') || ''}`
    }
  };

  const handleChangeCtg = (value: string) => {
    setInputs({ ...inputs, categoryCodeUid: value });
  };

  const handleStep = async () => {
    const resultCreate = await apiService.createMarket(inputs);

    if (resultCreate?.uid) {
      window.scrollTo(0, 0);
      navigate(`/profile/create/${resultCreate.uid}/item`);
    }
    return;
  };

  React.useEffect(() => {
    getMarketCtg();
  }, []);

  return (
    <Wrap>
      <SubHeader title={'Create'} />
      <View direction="column" spacing={12}>
        <View block pl={20}>
          <StepItem num={1} title={'Create Market'} />
        </View>
        <View direction="column" spacing={20}>
          <View align="flex-start" spacing={4} pl={20}>
            <Text size="xs" lh={10} weight="bold" color="#7B7B7B">
              MARKET IMAGE
            </Text>
            <StarIcon />
          </View>
          <StyledUpload height={170}>
            <Upload {...createMarketUploadProps}>
              <UploadButton
                icon={
                  <div>
                    <WrapIcon w={60} h={60}>
                      {uploadStatus}
                    </WrapIcon>
                    <p>
                      Market Display, We recommend an
                      <br />
                      image of at least 750x340px.
                    </p>
                    {inputs.imageUrl && <PreviewLarge src={inputs.imageUrl} />}
                  </div>
                }
              />
            </Upload>
          </StyledUpload>
        </View>
      </View>

      <View direction="column" spacing={30} padding="0 20">
        <Input
          label={
            <View align="flex-start" spacing={4}>
              <Text size="xs" lh={10} weight="bold" color="#7B7B7B">
                Market name
              </Text>
              <StarIcon />
            </View>
          }
          bar={false}
          ref={marketNameRef}
          onChange={() => setInputs({ ...inputs, name: marketNameRef?.current.getValue() })}
        />
        <StyledCategory>
          <SelectLabel
            label={
              <View align="flex-start" spacing={4}>
                <Text size="xs" lh={10} weight="bold" color="#7B7B7B">
                  CATEGORY
                </Text>
                <StarIcon />
              </View>
            }
          >
            {marketCtg.length > 0 && (
              <Select suffixIcon={<SelectDownArrowIcon className="icon-select-arrow" />} defaultValue={'25'} bordered={false} onChange={handleChangeCtg}>
                {marketCtg.map((item: any, index: number) => (
                  <Option key={index} value={`${item.uid}`}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </SelectLabel>
        </StyledCategory>
        {/* <Input label="community url" bar={false} ref={urlRef} onChange={() => setInputs({ ...inputs, communityUrl: urlRef?.current.getValue() })} /> */}

        <Textarea label="description" ref={descriptionRef} onChange={() => setInputs({ ...inputs, description: descriptionRef?.current.getValue() })} />
      </View>

      <View block padding="40px 20px 0">
        <Button color="border" full radius={10} text="NEXT" h={52} onClick={handleStep} />
      </View>
    </Wrap>
  );
};

export default FollowListItemComponent;

const Wrap = styled.div`
  padding: 20px 0;
  background: #f5f5f5;

  dl {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: flex-start;
    margin-bottom: 20px;

    dt {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000;
      color: #fff;
      border-radius: 50%;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      margin-right: 15px;
    }
    dd {
      h2 {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #000000;
        margin-bottom: 4px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #6f6f6f;
      }
    }
  }

  .link-notice {
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
    color: #666;
  }
`;

const StyledUpload = styled.div<{ height?: number }>`
  position: relative;
  margin-bottom: 16px;

  .ant-upload {
    width: 100%;
    height: ${p => (p.height ? p.height : 335)}px;
    margin-bottom: 30px;

    .ant-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-sizing: border-box;
      border: none;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > p {
          margin-top: 21px;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.01em;
          color: #6f6f6f;
        }
      }
    }
  }
`;

const StyledGalleryList = styled.div`
  margin-bottom: 16px;

  & > ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
    flex-wrap: wrap;

    & > li {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 74px;
      width: 74px;
      flex-shrink: 0;
      height: 74px;
      border-radius: 10px;
      overflow: hidden;
      background: #ab3fff;
    }
  }
`;

const PreviewLarge = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  max-height: 100%;
  border-radius: 17px;
  z-index: 0;
  opacity: 0.3;
  object-fit: cover;
`;

const StyledCategory = styled.div`
  width: 100%;

  .ant-select {
    display: block;
    font-size: 16px;
  }

  .ant-select-selection-item {
    line-height: 45px !important;
  }

  .ant-select-single .ant-select-selector {
    border: 1px solid #e4e4e4 !important;
    border-radius: 9px;
  }

  .ant-select-single .ant-select-selector {
    height: 45px;
    background: #f9f9f9 !important;

    input {
      height: 45px !important;
    }
  }

  .icon-select-arrow {
    path {
      stroke: #000;
    }
  }
`;
