import React from 'react';
import { ReactComponent as DownArrowIcon } from 'assets/icon/arrow-down.svg';
import { Collapse } from 'antd';
import { View, Text, CommentItem } from 'styles';
import styled from 'styled-components';
import FullItemComponent from 'components/nftsItem/fullItem';
import NoDataComponent from './NoData';

const InformationComponent: React.FC<any> = ({ desc, chart, history, community, keyword, externalUrl }) => {
  const { Panel } = Collapse;

  if (!desc || !chart || !history || !community) return <Text>Wrong Data.</Text>;

  return (
    <StyledCollapse expandIcon={() => <DownArrowIcon />} expandIconPosition={'right'}>
      <Panel header={'Description'} key="1">
        {desc ? (
          <>
            {desc}
            {keyword && <Keyword>{keyword}</Keyword>}
            {externalUrl && (
              <ExternalUrl href={externalUrl} target={'_blank'}>
                {externalUrl}
              </ExternalUrl>
            )}
          </>
        ) : (
          <NoDataComponent />
        )}
      </Panel>

      <Panel header={'Price History'} key="2">
        <NoDataComponent />
      </Panel>

      <Panel header={'Offers'} key="3">
        <NoDataComponent />
      </Panel>

      <Panel header={'Details'} key="4">
        <NoDataComponent />
      </Panel>

      <Panel header={'Item Activity'} key="5">
        <NoDataComponent />
      </Panel>

      <Panel header={'Community'} key="6">
        <NoDataComponent />
        {/* {community ? (
          <WrapCommunity>
            <View direction="column">
              {community.map((item: any, idx: number) => (
                <CommentItem
                  key={idx}
                  className={'wrap-comment'}
                  channelName={item.channelName}
                  name={
                    <View direction="column">
                      <Text size="sm" isLh={false} weight="semi-bold" color={'#000'}>
                        {item.name}
                      </Text>
                      <Text size="xs" color={'#000'}>
                        {item.channelName}
                      </Text>
                    </View>
                  }
                  isOnline={item.isOnline}
                  avatar={item.avatar}
                  content={<FullItemComponent className={'wrap-nft'} {...item.nftData} />}
                  emojis={item.emojis}
                  reply={item.reply}
                  timestamp={item.timestamp}
                />
              ))}
            </View>
          </WrapCommunity>
        ) : (
          <NoDataComponent />
        )} */}
      </Panel>
    </StyledCollapse>
  );
};

export default React.memo(InformationComponent);

const StyledCollapse = styled(Collapse)`
  background-color: #fff;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;

  .ant-collapse-header-text {
    font-size: 14px;
    font-weight: 600;
  }

  .ant-collapse-content-box {
    padding: 20px !important;
    line-height: 20px;
    white-space: pre-wrap;
    background: #fbfbfb;
  }
`;

const WrapHistory = styled(View)`
  padding: 20px;
`;

const WrapCommunity = styled.div`
  .comment-timestamp {
    position: absolute;
    top: 0;
    right: 40px;
  }
`;

const Keyword = styled.a`
  display: block;
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.5);
`;

const ExternalUrl = styled.a`
  display: block;
  margin-top: 24px;
  color: #0075ff;
`;
