/* eslint-disable react/react-in-jsx-scope */
import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as RequiredIcon } from 'assets/svg/star.svg';
import View from 'styles/view';

export type TextSizeType = '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
export type TextWeightType = 'default' | 'bold' | 'semi-bold' | 'black';
export type TextAlignType = 'center' | 'end' | 'justify' | 'left' | 'right' | 'start' | 'inherit' | 'initial' | 'unset';
export interface TextProps extends HTMLAttributes<HTMLDivElement> {
  flex?: number;
  basis?: number;
  color?: any;
  size?: TextSizeType;
  fontSize?: number;
  weight?: TextWeightType;
  align?: TextAlignType;
  block?: boolean;
  className?: string;
  isLh?: boolean;
  lh?: number;
  line?: number;
  underline?: boolean;
  link?: string;
  eclipse?: number;
  required?: 'left' | 'right';
  textSpacing?: number;
}

const Text: React.FC<TextProps> = ({ required, link, children, ...props }) => {
  return (
    <StyledText {...props} required={required}>
      {required === 'left' && <RequiredIcon />}
      {children}
      {required === 'right' && <RequiredIcon />}
    </StyledText>
  );
};

export default Text;

Text.defaultProps = {
  size: 'md',
  weight: 'default',
  align: 'left',
  isLh: true,
  underline: false
};

const XL3 = css`
  font-size: 24px !important;
  line-height: 30px !important;
`;

const XL2 = css`
  font-size: 22px !important;
  line-height: 28px !important;
`;

const XL = css`
  font-size: 20px !important;
  line-height: 28px !important;
`;

const LG = css`
  font-size: 18px !important;
  line-height: 26px !important;
`;

const MD = css`
  font-size: 16px !important;
  line-height: 24px !important;
`;

const SM = css`
  font-size: 14px !important;
  line-height: 24px !important;
`;

const XS = css`
  font-size: 12px !important;
  line-height: 26px !important;
`;

const XXS = css`
  font-size: 10px !important;
  line-height: 16px !important;
`;

const StyledText = styled.div<TextProps>`
  display: ${p => (p.block ? 'block' : 'inline-block')};
  ${p => p.block && ' width: 100%'};
  ${p => p.flex && `flex: ${p.flex}`};
  ${p => p.basis && `flex-basis: ${p.basis}px`};
  text-align: ${p => p.align} !important;
  color: ${p => (p.color ? `${p.color}` : 'inherit')} !important ;
  /* word-break: break-all; */

  ${p => {
    switch (p.size) {
      case '3xl':
        return XL3;
      case '2xl':
        return XL2;
      case 'xl':
        return XL;
      case 'lg':
        return LG;
      case 'sm':
        return SM;
      case 'xs':
        return XS;
      case 'xxs':
        return XXS;
      case 'md':
      default:
        return MD;
    }
  }}

  ${p => p.fontSize && `font-size: ${p.fontSize}`};
  ${p => {
    switch (p.weight) {
      case 'bold':
        return css`
          font-weight: 700 !important;
        `;
      case 'black':
        return css`
          font-weight: 900 !important;
        `;
      case 'semi-bold':
        return css`
          font-weight: 500 !important;
        `;
      case 'default':
      default:
        return css`
          font-weight: 400 !important;
        `;
    }
  }};
  ${p => !p.isLh && `line-height: 1!important`};
  ${p => p.lh && `line-height: ${p.lh}px!important`};
  ${p => p.underline && `text-decoration: underline !important`};
  ${p => (p.required === 'left' || p.required === 'right') && 'display:flex !important; align-items: flex-start; gap: 4px'};
  ${p => p.textSpacing && `letter-spacing: ${p.textSpacing}px`};

  ${p =>
    p.line &&
    `overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: ${p.line};
      -webkit-box-orient: vertical;`}

  ${p =>
    p.eclipse &&
    `
    word-break: break-all; 
        overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: ${p.eclipse};
-webkit-box-orient: vertical;
      `}
`;
