import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styles/theme';
import { ReactComponent as HistoryIcon } from 'assets/svg/history.svg';
import { Avatars, Text, View } from 'styles';

const CommunityItem: React.FC<any> = ({ id, name, isOnline, content, title, thumbnail, datestamp, timestamp, comments, ...props }) => {
  const navigate = useNavigate();

  return (
    <Wrap {...props} onClick={() => navigate(`/community/${id}`)}>
      <View align="center" spacing={5}>
        <h2>{name}</h2>
        <OnlineIcon isOnline={isOnline} />
      </View>

      <View direction="column" spacing={15}>
        <View direction="column" spacing={5} block>
          <View className={'wrap-title'} block>
            <Text size={'xs'} block>
              {title}
            </Text>
            <View align="center" className={'timestamp'} spacing={2}>
              <HistoryIcon width={8} height={8} />
              <Text size="xxs" color="#808080">
                {timestamp}
              </Text>
            </View>
          </View>
          <View spacing={10}>
            <Text lh={18} className={'contents'} size={'xs'}>
              {content}
            </Text>
            <img className={'thumbnail'} src={thumbnail} />
          </View>
        </View>

        <View align="center">
          {comments?.data.map((item: any, idx: number) => (
            <Avatars key={idx} avatar={item.avatar} />
          ))}
          <View align="center">
            <Text className={'replies'} size="xs" color="#0075FF">
              {comments?.total} replies
            </Text>
            <Text className={'dateStamp'} size="xxs" color="#808080">
              {datestamp}
            </Text>
          </View>
        </View>
      </View>
    </Wrap>
  );
};

export default CommunityItem;

const Wrap = styled.div`
  + div {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eaeaea;
  }

  .wrap-title {
    position: relative;
  }

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }

  .timestamp {
    position: absolute;
    right: 0;
    top: 0;
    color: #808080;
  }

  .contents {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 55px;
  }

  .thumbnail {
    max-width: 98px;
    height: 50px;
  }

  .replies,
  .dateStamp {
    margin-left: 7px;
  }
`;

const OnlineIcon = styled.div<{ isOnline: boolean }>`
  display: ${p => (p.isOnline ? 'block' : 'none')};
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #00ff66;
`;
