import React from 'react';
import styled from 'styled-components';
import { GroupItem } from 'views/community/detail/components';
import { BOARD_DETAIL } from 'api/mock/community';

const CommunityComponent = () => {
  const { title, bg, online, members, welcome, group, author, level, profileImage } = BOARD_DETAIL;
  return (
    <Wrap>
      <StyledGroup>
        <div className={'header'}>
          <h3># Group</h3>
        </div>
        {group.map((item, idx) => (
          <GroupItem key={idx} name={item.name} isPrivate={false} />
        ))}
      </StyledGroup>
    </Wrap>
  );
};

export default CommunityComponent;

const Wrap = styled.div`
  background-color: #f9f9f9;
  padding-bottom: 30px;
`;
const StyledGroup = styled.div`
  border-top: 1px solid #eaeaea;
  padding: 28px 20px;

  .header {
    position: relative;

    h3 {
      font-weight: 700;
      font-size: 14px;
      line-height: 12px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 25px;
    }
  }
`;
