import React from 'react';

export const useNftItem = () => {
  const [openModal, setOpenModal] = React.useState({ report: false });
  const [selectReport, setSelectReport] = React.useState<any>(null);
  const closeModal = () => {
    setOpenModal({ report: false });
    setSelectReport(null);
  };

  const changeReportOption = (v: string) => {
    setSelectReport(v);
  };

  const reportOptionList = [
    {
      name: 'Fake collection or possible scam',
      value: 0
    },
    {
      name: 'Explicit and sensitive content',
      value: 1
    },
    {
      name: 'Spam',
      value: 2
    },
    {
      name: 'ETC',
      value: 3
    }
  ];

  return { openModal, setOpenModal, closeModal, reportOptionList, selectReport, changeReportOption };
};
