import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CancleIcon } from 'assets/svg/deleteCircle.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg';
import { ReactComponent as PlusGradientRedIcon } from 'assets/svg/plusGradientRed.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/instagram.svg';
import { ReactComponent as TwitterIcon } from 'assets/svg/twitter.svg';

import { useNavigate } from 'react-router-dom';
import { Upload, UploadProps } from 'antd';
import { Button, Input, Password, View } from 'styles';
import { BottomSheet } from 'react-spring-bottom-sheet';
import PasswordFoundComponent from 'components/modal/passwordFound';
import VerifiedCodeComponent from 'components/modal/passwordFound/verify';
import PasswordResetComponent from 'components/modal/passwordFound/reset';
import WalletListComponent from 'components/modal/wallet/list';
import WalletScanQRComponent from 'components/modal/wallet/scan';
import { EDIT_ME } from 'api/mock/profile';
import { url } from 'inspector';

import cookie from 'js-cookie';

import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as SelectPictureIcon } from 'assets/svg/select_picture.svg';
import { useSelector, useDispatch } from 'react-redux';
import { profileUpdate } from 'store/reducers/System';

import APIService, { apiBaseUrl } from 'api';

const apiService = new APIService();

const ProfileEditView: React.FC<any> = () => {
  const navigate = useNavigate();
  const { profile } = useSelector((store: IStore) => store.System);
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = React.useState(false);
  const nicknameRef: any = React.useRef(null);
  const emailRef: any = React.useRef(null);
  const passwordRef: any = React.useRef(null);
  const rePasswordRef: any = React.useRef(null);
  const currentPasswordRef: any = React.useRef(null);
  const instagramRef: any = React.useRef(null);
  const twitterRef: any = React.useRef(null);
  const [open, setOpen] = React.useState({ wallet: false, password: false });
  const [modalPage, setModalPage] = React.useState('WALLETLIST');
  // const [inputs, setInputs] = React.useState<any>({ name: '', email: '', password: '', rePassword: '', currentPassword: '', instagram: '', twitter: '' });
  const [inputs, setInputs] = React.useState<any>({ name: '', password: '', rePassword: '', currentPassword: '' });

  const submitEdit = async () => {
    if (inputs.currentPassword.length < 1) {
      alert('Please enter Password.');
      return;
    }

    let inputResult = { ...inputs, name: profile.name };
    if (inputs.rePassword.length < 1 && inputs.password.length < 1) {
      inputResult = { name: inputResult.name, currentPassword: inputResult.currentPassword };
    } else if (inputs.password.length > 0) {
      if (inputs.rePassword.length < 1) {
        alert('Please enter Confirm Password.');
        return;
      }

      if (inputs.rePassword !== inputs.password) {
        alert('Passwords does not match.');
        return;
      }
    }

    if (inputs.name.length > 0) {
      inputResult = { ...inputResult, name: inputs.name };
    }

    const resultPut = await apiService.putMember(inputResult);

    if (resultPut) {
      if (resultPut?.message === 'Success') {
        alert('update completed.');
        window.location.reload();
      } else {
        alert('Please confirm password.');
      }
    }
  };

  const uploadButton = isUploading ? <LoadingOutlined /> : <SelectPictureIcon />;

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsUploading(true);
      return;
    }
    if (info.file.status === 'done') {
      dispatch(profileUpdate({ profile: { ...profile, photoUri: info.file.response.data.uri } }));
    }
  };

  const myProfileUploadProps: UploadProps = {
    name: 'photoFile',
    listType: 'picture-card',
    showUploadList: false,
    action: `${apiBaseUrl}/api/member/photo`,
    onChange: (info: any) => handleChange(info),
    headers: {
      Authorization: `Bearer ${cookie.get('accessToken') || ''}`
    }
  };

  if (inputs.email === '') {
    setInputs({ ...profile });
  }

  console.log(inputs, 'inputs');

  return (
    <Wrap>
      <Header>
        <h2>Edit Profile</h2>
        <CancleIcon onClick={() => navigate(-1)} />
      </Header>

      {profile?.name && (
        <>
          <View direction="column" spacing={16}>
            <ProfileImage avatar={profile.photoUri}>
              <dl className="wrap-list">
                <dt>
                  <Upload {...myProfileUploadProps}>
                    <EditIcon />
                  </Upload>
                </dt>
                <dd>
                  <p>
                    We recommend an image of at least
                    <br /> 800x800px.
                  </p>
                  {/* <button className="wrap-list" onClick={() => setOpen({ wallet: true, password: false })}>
                    <PlusGradientRedIcon style={{ marginRight: 7 }} />
                    <span>Connect Wallet</span>
                  </button> */}
                </dd>
              </dl>
            </ProfileImage>

            <View direction="column" spacing={23}>
              <Input ref={nicknameRef} label={'Name'} placeholder={profile.name} onChange={() => setInputs({ ...inputs, name: nicknameRef?.current.getValue() })} />
              <Input ref={emailRef} label={'Email'} value={profile.email ? profile.email : ''} className={'disabled'} disabled={true} />
              <View direction="column">
                <Password last={true} ref={currentPasswordRef} label={'Password'} onChange={() => setInputs({ ...inputs, currentPassword: currentPasswordRef?.current.getValue() })} />
                {/* <p className="notice" onClick={() => setOpen({ wallet: false, password: true })}>
                Forgot Password?
              </p> */}
              </View>
              <View direction="column">
                <Password ref={passwordRef} placeholder={'New Password'} label={'Reset Password'} onChange={() => setInputs({ ...inputs, password: passwordRef?.current.getValue() })} />
                <Password last={true} ref={rePasswordRef} placeholder={'Confirm Password'} onChange={() => setInputs({ ...inputs, rePassword: rePasswordRef?.current.getValue() })} />
                {/* <p className="notice" onClick={() => setOpen({ wallet: false, password: true })}>
                Forgot Password?
              </p> */}
              </View>
              {/* <Input
              ref={instagramRef}
              value={instagram ? instagram : ''}
              pl={28}
              label={'instagram'}
              suffix={<InstagramIcon />}
              onChange={() => setInputs({ ...inputs, instagram: instagramRef?.current.getValue() })}
            />
            <Input
              ref={twitterRef}
              value={twitter ? twitter : ''}
              pl={28}
              label={'twitter'}
              suffix={<TwitterIcon width={24} height={17} />}
              onChange={() => setInputs({ ...inputs, twitter: twitterRef?.current.getValue() })}
            /> */}
            </View>
          </View>

          <StyledButton>
            <Button color={'border'} full text={'OK'} radius={10} onClick={submitEdit} h={52} />
          </StyledButton>

          <BottomSheet open={open.password || open.wallet} onDismiss={() => setOpen({ wallet: false, password: false })} snapPoints={({ minHeight }) => minHeight}>
            {open.password && (
              <>
                {modalPage === 'PWFOUND' && <PasswordFoundComponent />}
                {modalPage === 'PWCODE' && <VerifiedCodeComponent />}
                {modalPage === 'PWRESET' && <PasswordResetComponent />}
              </>
            )}
            {open.wallet && (
              <>
                {modalPage === 'WALLETLIST' && <WalletListComponent />}
                {modalPage === 'WALLETSCANQR' && <WalletScanQRComponent />}
              </>
            )}
          </BottomSheet>
        </>
      )}
    </Wrap>
  );
};

export default ProfileEditView;

const Wrap = styled.div`
  padding: 22px 20px;

  .notice {
    margin-top: 13px;
    margin-bottom: 20px;
    padding-left: 17px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-decoration-line: underline;
    color: rgba(0, 0, 0, 0.5);
  }

  .ntfmoa-input-container {
    > div {
      background: #fff !important;
    }

    > .disabled {
      background: #f9f9f9 !important;
    }
  }

  .nftmoa-input-password {
    > div {
      background: #fff !important;
    }
  }
`;

const Header = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 20px;
  z-index: 99;

  & > h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
`;

const ProfileImage = styled.div<{ avatar?: string }>`
  dl {
    align-items: center;

    dt {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      background: ${p => (p.avatar ? `url(${p.avatar}) no-repeat center center` : 'linear-gradient(180deg, #00d1ff 0%, #ff0000 100%)')};
      background-size: 100%;
      border-radius: 120px;
      :before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 120px;
        z-index: 0;
      }

      span {
        position: relative;
        z-index: 1;
      }

      .ant-upload {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    dd {
      margin-left: 16px;

      & > p {
        margin-bottom: 14px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.5);
      }

      & > button {
        & > span {
          display: block;
          font-weight: 700;
          font-size: 14px;
          line-height: 26px;
          letter-spacing: 0.01em;
          background: linear-gradient(94.15deg, #ff0005 -5.83%, #f80089 45.18%, #091b84 95.14%);
          background: -ms-linear-gradient(94.15deg, #ff0005 -5.83%, #f80089 45.18%, #091b84 95.14%);
          background: -moz-linear-gradient(94.15deg, #ff0005 -5.83%, #f80089 45.18%, #091b84 95.14%);
          background: -webkit-linear-gradient(94.15deg, #ff0005 -5.83%, #f80089 45.18%, #091b84 95.14%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }
  }
`;

const StyledButton = styled.div`
  margin: 30px 0;
`;
