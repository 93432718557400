import React from 'react';
import styled from 'styled-components';
import { Text, View } from 'styles';
import { ReactComponent as ArrowSVG } from 'assets/svg/moreArrow.svg';

const ListCardComponent: React.FC<any> = ({ bgUrl, headline, subject, desc, hashTags, sourceLink, createAt, ...props }) => {
  return (
    <Wrap {...props}>
      <Background>
        <GradientBackground />
        <img className={'bg'} src={bgUrl} />
        <div className={'title'}>
          {headline && <h2>"{headline}"</h2>}
          {subject && <h1>{subject}</h1>}
        </div>
        <Text className="create" size="xs" color="#fff">
          {createAt}
        </Text>
      </Background>
      <div className="contents">
        <View direction="column" spacing={24}>
          <View spacing={4} direction={'column'}>
            <Text size="sm" eclipse={3}>
              {desc}
            </Text>
            <HashTag>
              {hashTags.map((item: any, idx: number) => (
                <Text size="xs" key={idx} color={'#808080'} style={{ marginRight: '5px' }}>
                  {item}
                </Text>
              ))}
            </HashTag>
          </View>

          <View direction="column">
            <Text size="sm">Copyright All Rights Reserved.</Text>
            <View justify="space-between" align="center">
              <Text className={'link-source'} size="sm" color={'#808080'}>
                {sourceLink}
              </Text>
              <ArrowSVG />
            </View>
          </View>
        </View>
      </div>
    </Wrap>
  );
};

export default ListCardComponent;

const Wrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  overflow: hidden;

  & + div {
    margin-top: 14px;
  }

  .contents {
    padding: 16px;
  }

  .link-source {
    max-width: 278px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
  }
`;

const Background = styled.div`
  position: relative;
  height: 190px;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
  }

  .title {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 5;
    width: 176px;
    height: 146px;

    h1 {
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      color: #fff;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;

      &:first-child {
        margin-top: 16px;
      }
    }

    h2 {
      margin-top: 16px;
      font-size: 12px;
      color: #bebdbd;
    }
  }

  .create {
    position: absolute;
    bottom: 14px;
    right: 20px;
    z-index: 2;
  }
`;

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  background: linear-gradient(270deg, rgba(33, 33, 33, 0.7) 71.56%, rgba(0, 0, 0, 0) 100%);
`;

const HashTag = styled.div`
  line-height: 18px !important;

  div {
    line-height: 18px !important;
  }
`;
