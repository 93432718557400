import React from 'react';
import styled from 'styled-components';

interface BadgeProps {
  color?: any;
  count?: number;
  w?: number;
  h?: number;
  fontSize?: number;
  className?: any;
  style?: any;
}

const BadgeStyles: React.FC<BadgeProps> = ({ count, color, children, ...props }) => {
  const colorset: any = {
    white: { backgroundColor: '#fff', color: '#000', border: '1px solid #C9C9C9' },
    black: { backgroundColor: '#000', color: '#fff', border: '1px solid #C9C9C9' }
  };

  return (
    <Wrap>
      {children}
      <Badge {...props} style={colorset[color]}>
        {count}
      </Badge>
    </Wrap>
  );
};

export default BadgeStyles;

BadgeStyles.defaultProps = {
  w: 20,
  h: 20,
  color: 'white',
  fontSize: 9
};

const Wrap = styled.div`
  position: relative;

  .wrap-badge {
    position: absolute;
    top: -3px;
    right: -3px;
  }
`;

const Badge = styled.div<BadgeProps>`
  ${p => p.w && `width: ${p.w}px`};
  ${p => p.h && `height: ${p.h}px`};
  color: ${p => p.color} !important;
  font-weight: 700;
  font-size: ${p => p.fontSize}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -3px;
  right: -3px;
`;
