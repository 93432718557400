import React, { useState, useEffect } from 'react';
import styled from 'styles/theme';
import Marquee from 'react-fast-marquee';
import { View } from 'styles';
import { FOOTER_NOTICE } from 'api/mock/info';
export default () => {
  return (
    <FlashNew>
      <h2>
        News <br /> Flash
      </h2>
      <Location gradient={false}>
        <View>
          {FOOTER_NOTICE.map((item, idx) => (
            <dl key={idx} onClick={() => window.open(item.url)}>
              <dt>{item.title}</dt>
              <dd>{item.datestamp}</dd>
            </dl>
          ))}
        </View>
      </Location>
    </FlashNew>
  );
};

const FlashNew = styled.div`
  display: flex;
  padding: 0 0 0 0;
  background: #f5f5f5;
  align-items: center;
  h2 {
    position: relative;
    padding: 0 20px;
    width: 67px;
    color: #ff00d6;
    font-size: 10px;
    line-height: 12px;
    font-weight: 800;

    :after {
      content: '';
      position: absolute;
      top: 2px;
      right: 14px;
      width: 2px;
      height: 20px;
      background: #ff00d6;
    }
  }
`;

const Location = styled(Marquee)`
  padding: 16px 20px;

  dl {
    position: relative;
    margin: 0 20px;
    padding-right: 81px;

    dt,
    dd {
      color: #000;
    }

    dt {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 288px;
      font-size: 11px;
      font-weight: 500;
      height: 40px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    dd {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -12px;
      font-size: 9px;

      :before {
        content: '';
        position: absolute;
        top: 50%;
        left: -4px;
        margin-top: -4px;
        width: 1px;
        height: 10px;
        background: #000;
      }
    }
  }
`;
