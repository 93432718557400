import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import ClipboardJs from 'clipboard';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { alertMessage } from 'store/reducers/System';

interface CopyHandler {
  copy: () => void;
}

export interface CopyProps {
  copyValue?: any;
  target?: string;
}

const Copy = React.forwardRef<CopyHandler, React.PropsWithChildren<CopyProps>>(({ copyValue, target = 'target-copy', children }, ref) => {
  const dispatch = useDispatch();
  const textRef = React.useRef<HTMLTextAreaElement | null>(null);
  const clipboard = new ClipboardJs('.btn-copy');

  const copy = () => {
    clipboard.on('success', () => {
      dispatch(alertMessage({ type: 'default', msg: `Copied` }));
    });

    clipboard.on('error', () => {
      dispatch(alertMessage({ type: 'default', msg: `Copy Error. Try again.` }));
    });
  };

  //ref 없을경우 사용 할 수 있게 추가
  const onCopy = () => !ref && copy();

  React.useImperativeHandle(ref, () => ({ copy }), [textRef]);

  return (
    <StyledWrap className="btn-copy" data-clipboard-target={`#${target}`} onClick={onCopy}>
      {children}
      <StyledCopyText id={target} ref={textRef} defaultValue={copyValue} />
    </StyledWrap>
  );
});

export default Copy;
const StyledWrap = styled.div`
  position: relative;
  width: 100%;
`;

const StyledCopyText = styled.textarea`
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  z-index: -1;
`;
