import { MY_WALLET } from 'api/mock/wallet';
import SubHeader from 'components/Header/SubHeader';
import AssetItem from 'components/v2/asset-item/AssetItem';
import React from 'react';
import styled from 'styled-components';
import { Price, View, Text } from 'styles';
import AvatarUI from 'ui/avatar/Avatar';
import ButtonUI from 'ui/button/Button';
import SelectButtonUI from 'ui/select-button/SelectButton';
import { mappingWalletLogo, mappingWalletName, setDigit, shortText } from 'utils';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';
import { ReactComponent as LogoutIcon } from 'assets/icon/logout-thin.svg';
import { ReactComponent as RefreshIcon } from 'assets/icon/refresh.svg';
import { ReactComponent as CheckedIcon } from 'assets/icon/checked-purple.svg';

const MyWalletPage: React.FC = () => {
  const { profile } = useSelector((store: IStore) => store.System);
  const [open, setOpen] = React.useState<boolean>(false);
  const { name, profileImage, wallet, assets, totalBalance } = MY_WALLET;

  const content = (
    <StyledMenuContent direction="column" block>
      <View cursor="pointer" spacing={9} align="center" block onClick={() => console.log('meta mask')}>
        <img className="img-logo" src={mappingWalletLogo(wallet)} />
        <Text lh={22} size="xs" weight="bold" color="#000">
          {mappingWalletName(wallet)}
        </Text>
        <CheckedIcon />
      </View>
      <View cursor="pointer" spacing={9} align="center" onClick={() => console.log('log out')} block>
        <LogoutIcon />
        <Text size="xs" weight="bold" color="#000">
          Log out
        </Text>
      </View>
      <View cursor="pointer" spacing={9} align="center" onClick={() => console.log('Refresh funds')} block>
        <RefreshIcon />
        <Text size="xs" weight="bold" color="#000">
          Refresh funds
        </Text>
      </View>
    </StyledMenuContent>
  );

  return (
    <StyledWrap>
      <SubHeader title="My Wallet" />

      <View align="center" padding="0 20">
        <View align="center">
          <AvatarUI size="md" imgUrl={profileImage} />
          <SelectButtonUI open={open} w={145} isBorder={false}>
            <Popover open={open} onOpenChange={open => setOpen(open)} trigger={'click'} content={content} placement="bottomLeft">
              <Text size="md" weight="semi-bold" eclipse={1}>
                {name}
              </Text>
            </Popover>
          </SelectButtonUI>
        </View>
        <Text color={'#808080'} size="xs">
          {profile?.walletAddress && shortText(profile?.walletAddress, 6, 6)}
        </Text>
      </View>

      <View padding="24px 20px 8" block>
        <View direction="column" block radius={10} border="1px solid #eaeaea" padding="16px 21" shadow="0px 0px 14px 0px #0000000F">
          <Text size="xs" weight="semi-bold" color={'#808080'}>
            Total balance
          </Text>
          <Price weight={600} fontSize={30} prefix="$ " price={setDigit(totalBalance, 2, true, true)} preSuffix=" USD" />
        </View>
      </View>

      <View padding="0 20" block>
        <ButtonUI color="black" full>
          Add Funds
        </ButtonUI>
      </View>

      <View borderPos="top" block mt={28}>
        {assets.map((asset, idx) => (
          <AssetItem key={idx} {...asset} />
        ))}
      </View>
    </StyledWrap>
  );
};

export default MyWalletPage;

const StyledWrap = styled.div``;

const StyledMenuContent = styled(View)`
  .img-logo {
    width: 24px;
    height: 22px;
    object-fit: contain;
  }

  & > div + div {
    padding-top: 8px;
    border-top: 1px solid #eaeaea;
  }
  & > div {
    padding: 8px 16px;
  }
`;
