import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { MARKET_DETAIL, MARKET_DETAIL_ITEMS } from 'api/mock/market';
import SubHeader from 'components/Header/SubHeader';
import { Button, CardBgAvatar, View, Text, AuthorName, Price, CoveredView, Tab } from 'styles';
import CommunityComponent from '../components/community';
import ItemsComponent from '../components/items';
import { useInfiniteScroll } from 'hook/useInfiniteScroll';

import APIService from 'api';
const apiService = new APIService();

const MarketHomeView: React.FC<any> = () => {
  const { marketUid } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState('Items');
  const [marketDetail, setMarketDetail] = React.useState<any>();
  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [marketItem, setMarketItem] = React.useState<any[]>([]);

  const getMarketDetail = async () => {
    const resultNftDetail = await apiService.getMarketView({ uid: marketUid });
    setMarketDetail(resultNftDetail);
  };

  const getMarketItem = async ({ uid }: any) => {
    setLoading(true);
    const resultItem = await apiService.getItemMarket({ marketUid, uid });

    if (marketItem.length > 0) {
      marketItem.at(-1).uid !== resultItem.list.at(-1).uid && setMarketItem([...marketItem, ...resultItem.list]);
    } else {
      setMarketItem(resultItem.list);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getMarketItem({});
    getMarketDetail();
  }, []);

  React.useEffect(() => {
    if (activeTab === 'Items') {
      if (isScroll && marketItem.length > 0) getMarketItem(marketItem.at(-1).uid);
    }
    // if (activeTab === 'Community') {
    //   if (isScroll && collectionList.length > 0) getCollection(collectionList.at(-1).uid);
    // }
  }, [isScroll]);

  return (
    <div>
      <SubHeader title="Market" suffix={<Button h={30} w={70} fontSize={12} color="borderThinBlue" text="Buy now" />} />

      {marketDetail && (
        <Wrap>
          <CardBgAvatar
            className={'wrap-card'}
            bg={marketDetail.imageUrl}
            isMark={false}
            avatar={marketDetail.memberPhoto}
            name={
              <Text weight="bold" size="sm" align="center">
                {marketDetail.name}
              </Text>
            }
          />

          <View className={'wrap-summary'} block spacing={40} align="center" justify="center">
            {/* <View direction="column">
              <Price icon={cryptoIcon} price={price} />
              <Text size="xs" color="#747474" align="center">
                Floor Price
              </Text>
            </View>

            <View direction="column">
              <Text size="md" weight="bold" align="center">
                {items}
              </Text>
              <Text size="xs" color="#747474" align="center">
                Items
              </Text>
            </View>

            <View direction="column">
              <Price icon={cryptoIcon} price={total} />
              <Text size="xs" color="#747474" align="center">
                Total Volume
              </Text>
            </View> */}
          </View>

          <View direction="column" padding="20px 32">
            <CoveredView content={marketDetail.description} />
          </View>

          {/* <Tab shape="bar" active={activeTab}>
            <Tab.Item value="Items" onClick={() => setActiveTab('Items')} />
            <Tab.Item value="Community" onClick={() => setActiveTab('Community')} />
          </Tab> */}

          {/* {activeTab === 'Items' ? marketItem && marketItem.length > 0 && marketItem.map((item, idx) => <ItemsComponent key={idx} {...item} />) : <CommunityComponent />} */}
          {activeTab === 'Items' ? marketItem && marketItem.length > 0 && marketItem.map((item, idx) => <ItemsComponent key={idx} {...item} />) : <CommunityComponent />}
          <div ref={senserRef} className={`observer ${!isLoading ? 'completed' : ''}`} />
        </Wrap>
      )}
    </div>
  );
};

export default MarketHomeView;

const Wrap = styled.div`
  padding-top: 29px;

  .wrap-card {
    height: 278px;
    border: none;
    border-radius: unset;
    box-shadow: unset;
    min-height: auto;
    padding-bottom: 18px;

    .img-icon {
      width: 78px !important;
      height: 70px !important;
    }

    .author-banner {
      margin-bottom: 46px;
      border-radius: unset;
    }
  }

  .wrap-summary {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 20px 0;

    .nftmoa-price-container {
      .logo {
        width: 9px;
        margin-right: 4px;
      }
    }
  }
`;
