import React from 'react';
import styled from 'styled-components';
import { View, Text } from 'styles';
import NftLoadingGif from 'assets/logo/eff_progress_logo3.gif';

const NftLoadingComponent: React.FC<any> = () => {
  return (
    <NftLoading>
      <View w={'200px'} direction="column" align={'center'} justify={'center'} spacing={10}>
        <LoadingImg src={NftLoadingGif} />
        <TextLine>
          Click your MetaMask popup
          <br /> to proceed your transaction
        </TextLine>
      </View>
    </NftLoading>
  );
};

export default NftLoadingComponent;

const NftLoading = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000000000;
`;

const LoadingImg = styled.img`
  width: 183px;
  height: auto;
`;

const TextLine = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #fff;
`;
