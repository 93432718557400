import React from 'react';
import styled from 'styled-components';
import { CHANNEL_CTG, CHANNEL_LIST } from 'api/mock/community';
import { useNavigate } from 'react-router-dom';
import APIService, { apiBaseUrl } from 'api';
import cookie from 'js-cookie';
import { getBase64 } from 'utils';
import Select, { components } from 'react-select';

import { Upload, UploadProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { Button, Input, Label, WrapIcon, Link, Textarea, SelectLabel } from 'styles';
import ChannelListComponent from 'components/channelList';
import ChannelCtgItem from 'components/walletItem';
import { ReactComponent as SelectDownArrowIcon } from 'assets/svg/downArrow.svg';
import SubHeader from 'components/Header/SubHeader';
import { ReactComponent as SelectPictureIcon } from 'assets/svg/select_picture.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as ValueRemoveIcon } from 'assets/svg/remove.svg';
import { useSelector, useDispatch } from 'react-redux';

const apiService = new APIService();
const DefaultLayout: React.FC<any> = ({ children }) => {
  const { profile } = useSelector((store: IStore) => store.System);
  const navigate = useNavigate();
  // const { Option } = Select;
  const nameRef: any = React.useRef();
  const descRef: any = React.useRef();
  const [isUploading, setIsUploading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [channelCtg, setChannelCtg] = React.useState([]);
  const [channelTag, setChannelTag] = React.useState<any[]>([]);
  const [resultData, setResultData]: any = React.useState({ categoryCodeUid: null, profileIconFile: null, name: null, introduce: null, commonCodeUid: null });

  const changeHashTag = (v: any) => {
    const tempUid: any[] = [];

    v.forEach((item: any) => {
      tempUid.push(item.value);
    });

    setResultData({ ...resultData, commonCodeUid: tempUid });
  };

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsUploading(true);
      return;
    }
    if (info.file.status === 'done') {
      setResultData({ ...resultData, profileIconFile: info.file.response.data.image });
      setIsUploading(false);
    }
  };

  const onSuccess = (body: any) => {
    console.log(body, 'body');
  };

  const uploadButton = isUploading ? <LoadingOutlined /> : <SelectPictureIcon />;

  const getChannel = async () => {
    const channelCtg = await apiService.getCommunityChannelCtg();

    setChannelCtg(channelCtg.list);
  };

  const getChannelTag = async () => {
    const channelTag = await apiService.getCommunityChannelTag();

    const valueTemp: any[] = [];

    channelTag.list.forEach((item: any) => {
      valueTemp.push({ value: item.uid, label: `#${item.name}` });
    });

    setChannelTag(valueTemp);
  };

  const onCreate = async () => {
    if (!resultData.profileIconFile) {
      alert('Please enter Channel Profile icon.');
      return;
    }

    if (!resultData.name) {
      alert('Please enter Channel Name. ');
      return;
    }

    if (!resultData.introduce) {
      alert('Please enter Description.');
      return;
    }

    if (!resultData.commonCodeUid) {
      alert('Please select Hash tag.');
      return;
    }

    if (resultData.profileIconFile && resultData.name && resultData.introduce && resultData.commonCodeUid) {
      const createChannel = await apiService.createCommunityChannel(resultData);
      navigate(`/community/${createChannel.uid}`);
    }
  };

  const onOpen = () => {
    setOpen(true);
    setResultData({ categoryCodeUid: null, profileIconFile: null, name: null });
  };

  const onClose = () => {
    setOpen(false);
    setResultData({ categoryCodeUid: null, profileIconFile: null, name: null });
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectDownArrowIcon />
      </components.DropdownIndicator>
    );
  };

  const MultiValueRemove = (props: any) => {
    return (
      <components.MultiValueRemove {...props}>
        <ValueRemoveIcon />
      </components.MultiValueRemove>
    );
  };

  const channelProfileUploadProps: UploadProps = {
    name: 'image',
    listType: 'picture-card',
    showUploadList: false,
    action: `${apiBaseUrl}/api/community/channel/upload`,
    onChange: (info: any) => handleChange(info),
    headers: {
      Authorization: `Bearer ${cookie.get('accessToken') || ''}`
    }
  };

  React.useEffect(() => {
    if (channelCtg.length === 0) {
      getChannel();
      getChannelTag();
    }
  }, []);

  const style = {
    menu: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #E4E4E4'
    }),
    multiValue: (styles: any) => ({
      ...styles,
      position: 'relative',
      paddingBottom: 6,
      paddingRight: 13,
      paddingTop: 6,
      paddingLeft: 30,
      borderRadius: 80,
      background: '#fff',
      boxShadow: '0px 0px 14px rgba(0,0,0,0.06)',
      border: '1px solid #efefef'
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      padding: 0,
      lineHeight: 1.2
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      position: 'absolute',
      top: '50%',
      left: 6,
      transform: 'translateY(-50%)'
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      svg: {
        path: {
          stroke: '#000'
        }
      }
    })
  };

  return (
    <div>
      <SubHeader
        title={'Community'}
        suffix={
          <FixedHeader>
            {profile?.name && (
              <button onClick={onOpen}>
                <WrapIcon w={40} h={40}>
                  <PlusIcon />
                </WrapIcon>

                <HexagonLabel>
                  <div className="hexagon">Create Channel</div>
                </HexagonLabel>
              </button>
            )}
          </FixedHeader>
        }
      />

      {/* <ChannelListComponent list={CHANNEL_LIST} /> */}

      {children}

      {open && (
        <BottomSheet
          open={open}
          onDismiss={onClose}
          header={
            <Header>
              <dt>Create Channel</dt>
              <dd>Your channel is where you and your friends hang out. make yours and start talking</dd>
            </Header>
          }
          footer={<Button rect color={'border'} full={true} text={'NEXT'} />}
          snapPoints={({ minHeight }) => minHeight}
        >
          <StyledChannelCtg>
            {channelCtg.map((item: any, idx: number) => (
              <ChannelCtgItem key={idx} name={item.name} iconUrl={item.image} onClick={() => setResultData({ ...resultData, categoryCodeUid: item.uid })} />
            ))}
          </StyledChannelCtg>
        </BottomSheet>
      )}

      {resultData.categoryCodeUid && (
        <BottomSheet
          open={!!resultData.categoryCodeUid}
          onDismiss={onClose}
          header={
            <Header>
              <dt>Customize Your Channel</dt>
              <dd>Give your new channel a personality with a name and an icon, You can always change it later.</dd>
            </Header>
          }
          footer={<Button rect color={'border'} full={true} text={'CREATE'} onClick={onCreate} />}
          snapPoints={({ minHeight }) => minHeight}
        >
          <StyledForm>
            <FormItem>
              <Label label={'channel profile icon'}>
                <Upload className="avatar-uploader" {...channelProfileUploadProps}>
                  {resultData.profileIconFile ? <img src={resultData.profileIconFile} /> : uploadButton}
                </Upload>
              </Label>
            </FormItem>
            <FormItem>
              <Input ref={nameRef} label={'channel name'} placeholder={''} onChange={() => setResultData({ ...resultData, name: nameRef?.current.getValue() })} />
              <span className={'link-notice'}>By creating a group, you agree to NVIA’s Community guidelines.</span>
              {/* <Link className={'link-notice'} to={'/'}>
                By creating a group, you agree to nftmoa’s Community guidelines.
              </Link> */}
            </FormItem>
            <FormItem>
              <Textarea ref={descRef} label={'description'} placeholder={''} bar={true} onChange={() => setResultData({ ...resultData, introduce: descRef?.current.getValue() })} />
            </FormItem>
            <FormItem>
              <SelectLabel label={'hash tags'}>
                <Select
                  components={{ MultiValueRemove, DropdownIndicator }}
                  styles={style}
                  isMulti={true}
                  options={channelTag}
                  menuPlacement={'top'}
                  maxMenuHeight={200}
                  onChange={v => changeHashTag(v)}
                />
              </SelectLabel>
            </FormItem>
          </StyledForm>
        </BottomSheet>
      )}
    </div>
  );
};

export default DefaultLayout;

const Header = styled.dl`
  text-align: left;

  dt {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 5px;
    color: #000000;
  }

  dd {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #6f6f6f;
  }
`;

const StyledChannelCtg = styled.div`
  padding: 20px;
`;

const StyledForm = styled.div`
  padding: 0 20px;
  margin-bottom: 36px;

  .link-notice {
    display: inline-block;
    padding: 5px 20px;
    font-size: 12px;
    text-decoration: underline;
    color: #666;
  }

  .avatar-uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 20px;

    .ant-upload.ant-upload-select-picture-card {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;

      &:hover {
        border: none;
      }
    }
  }
`;

const ChannelItem = styled.li<{ msgCount?: number }>`
  position: relative;

  &::after {
    content: '${p => p.msgCount}';
    position: absolute;
    right: 0;
    top: 0;
    background: #000;
    border-radius: 50%;
    padding: 4px;
    font-weight: 700;
    font-size: 10px;
    color: #ffffff;

    ${p => !p.msgCount && `display: none;`}
  }
`;

const HexagonLabel = styled.div`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);

  .hexagon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    padding: 4px 10px 5px 5px;
    background-color: #f900d4;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: -0.02em;
    white-space: wrap;
    text-align: left;

    &::after {
      content: '';
      position: absolute;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      left: 100%;
      border-left: 8px solid #f900d4;
    }
  }
`;

const FixedHeader = styled.div`
  background: transparent;
  position: fixed;
  top: 5px;
  right: 20px;
  z-index: 99;
`;

const StyledChannel = styled.div`
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;

  ul {
    display: flex;
    flex: 1;
    height: 39px;
    border-right: 1px solid #eaeaea;
    margin-right: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 17px;

    & > li {
      img {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }

  svg.active {
    rect {
      fill: #000;
      fill-opacity: 1;
    }
  }
`;

const FormItem = styled.div`
  & + & {
    margin-top: 40px;
  }
`;
