import React from 'react';
import styled from 'styled-components';

interface MarketplaceProps {
  logoUrl: any;
}

const MarketplaceStyles: React.FC<MarketplaceProps> = ({ logoUrl, ...props }) => {
  return (
    <Marketplace {...props}>
      <img src={logoUrl} />
    </Marketplace>
  );
};

export default MarketplaceStyles;

const Marketplace = styled.div<{ semiBold?: boolean; market?: string }>`
  display: flex;
  align-items: center;
  justify-items: center;
  min-height: 18.5px;
  & > img {
    object-fit: cover;
  }
`;
