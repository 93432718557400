import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { profileUpdate, alertMessage } from 'store/reducers/System';
import APIService from 'api';
import cookie from 'js-cookie';
import { shortText } from 'utils';

const apiService = new APIService();

export const useSetting = () => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneRegex = /[^0-9]/g;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((store: IStore) => store.System);
  const [isOpen, setIsOpen] = React.useState<{ code: boolean; sms: boolean; verifyCheck: boolean }>({ code: false, sms: false, verifyCheck: false });
  const [hasPass, setHasPass] = React.useState(false);
  const [emailVerify, setEmailVerify] = React.useState(profile?.emailVerifiedFlag);
  const [phoneVerify, setPhoneVerify] = React.useState(profile?.phoneVerifiedFlag);
  const [otpVerify, setOtpVerify] = React.useState(profile?.otpFlag);
  const [newsLetter, setNewsLetter] = React.useState(profile?.emailNewsletterFlag);
  const [smsCountryList, setSmsCountryList] = React.useState<any[]>([]);
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [smsSearch, setSmsSearch] = React.useState<string>('');
  const [verifyCode, setVerifyCode] = React.useState<string>('');
  const [curCountry, setCurCountry] = React.useState({
    countryCallingCode: '82',
    countryDisplayEngName: 'South Korea',
    countryDisplayKorName: '대한민국',
    isoCode: 'KR',
    supportType: 'FULL',
    useTemplate: false
  });

  const myWallet = React.useMemo(() => [profile?.walletAddress, profile && shortText(profile?.walletAddress, 6, 6)], [profile]);

  const accountToCertification = () => {
    dispatch(alertMessage({ type: 'default', msg: 'If you need more assistance, please contact with administrator.' }));
  };

  const changeEmail = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setEmail(value.trim());
  };

  const changePhone = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setPhone(value.trim());
  };

  const changeSmsSearch = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setSmsSearch(value.trim());
  };

  const changeSmsVerify = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setVerifyCode(value.trim());
  };

  const changeNewsLetter = () => {
    !newsLetter && dispatch(alertMessage({ type: 'default', msg: 'We will send you latest updates with possible profits and your transaction notifications.' }));
    newsLetter && dispatch(alertMessage({ type: 'default', msg: 'Please note newsletter and transaction notifications will not be updated.' }));
    setNewsLetter(!newsLetter);
  };

  const sendVerifyEmail = async () => {
    if (email === '' || email.match(emailRegex)) {
      const result = await apiService.postVerifyEmail({ email });
      console.log(result);
      dispatch(alertMessage({ type: 'default', msg: 'Please check email and proceed verification.' }));
    } else dispatch(alertMessage({ type: 'error', msg: 'Invalid email format please recheck.' }));
  };

  const verifyToggleEmail = () => {
    if (profile.emailVerifiedFlag) {
      setEmailVerify(!emailVerify);
    } else {
      dispatch(alertMessage({ type: 'default', msg: 'Please proceed authentication first.' }));
    }
  };

  const sendVerifyPhone = async () => {
    if (phone) {
      const result = await apiService.postVerifySms({ nationalCode: curCountry.countryCallingCode, phoneNumber: phone.replace(phoneRegex, '') });
      console.log(result, 'result');
      result.message === 'Success' ? openModal('sms') : dispatch(alertMessage({ type: 'error', msg: 'SMS authentication already completed' }));
    } else dispatch(alertMessage({ type: 'error', msg: 'Please enter phone number.' }));
  };

  const verifyTogglePhone = () => {
    if (profile.phoneVerifiedFlag) {
      setPhoneVerify(!phoneVerify);
    } else {
      dispatch(alertMessage({ type: 'default', msg: 'Please proceed authentication first.' }));
    }
  };

  const getSmsList = async () => {
    const result = await apiService.getSmsNations();
    result.dataJson && setSmsCountryList(result.dataJson);
  };

  const openModal = (name: string) => {
    if (name === 'code') setIsOpen({ code: true, sms: false, verifyCheck: false });
    else if (name === 'sms') setIsOpen({ code: false, sms: true, verifyCheck: false });
  };
  const closeModal = () => {
    setIsOpen({ code: false, sms: false, verifyCheck: false });
  };

  const selectCountry = (item: any) => {
    setCurCountry(item);
    closeModal();
  };

  const sendSMSCode = async () => {
    console.log('send');
    try {
      const result = await apiService.postVerifySmsCheck({ nationalCode: curCountry.countryCallingCode, phoneNumber: phone.replace(phoneRegex, ''), verifyCode });
      console.log(result, 'result');
      if (result.message === 'Success') {
        const profileResult = await apiService.getMember();
        setPhoneVerify(profileResult.phoneVerifiedFlag);
        dispatch(profileUpdate({ profile: profileResult }));
        closeModal();
      } else {
        dispatch(alertMessage({ type: 'error', msg: 'You enter invalid code please recheck.' }));
      }
    } catch (e) {
      console.log(e);
    }

    // if (hasPass) {
    //   closeModal();
    // } else {
    //   setIsOpen({ code: false, sms: false, verifyCheck: true });
    // }
  };

  const otpDelete = async () => {
    try {
      const result = await apiService.deleteOtp();
      const profileResult = await apiService.getMember();
      setOtpVerify(profileResult.otpFlag);
      dispatch(profileUpdate({ profile: profileResult }));
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  const saveSetting = async () => {
    !emailVerify && dispatch(alertMessage({ type: 'default', msg: 'Please note only authorized email account is provided transaction notifications.' }));

    try {
      const result = await apiService.putUpdateEtcInfo(profile.uid, { emailVerifiedFlag: emailVerify, phoneVerifiedFlag: phoneVerify, emailNewsletterFlag: newsLetter });
      const profileResult = await apiService.getMember();
      dispatch(profileUpdate({ profile: profileResult }));
    } catch (e) {
      console.log(e);
    }
  };

  const moveToEnablePage = () => {
    navigate('/setting/otp');
  };

  React.useEffect(() => {
    getSmsList();
  }, []);

  React.useEffect(() => {
    setEmailVerify(profile?.emailVerifiedFlag);
    setPhoneVerify(profile?.phoneVerifiedFlag);
    setOtpVerify(profile?.otpFlag);
    setNewsLetter(profile?.emailNewsletterFlag);
  }, [profile]);

  return {
    profile,
    myWallet,
    accountToCertification,
    changeEmail,
    changePhone,
    smsCountryList,
    smsSearch,
    changeSmsSearch,
    selectCountry,
    curCountry,
    emailVerify,
    phoneVerify,
    otpVerify,
    sendVerifyEmail,
    verifyToggleEmail,
    sendVerifyPhone,
    verifyTogglePhone,
    changeSmsVerify,
    changeNewsLetter,
    newsLetter,
    otpDelete,
    saveSetting,
    isOpen,
    openModal,
    closeModal,
    sendSMSCode,
    moveToEnablePage
  };
};
