import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AuthorName, Price, Text } from 'styles';
import { setDigit, nftMarketSite, cryptoMarket } from 'utils';

const FullItemComponent: React.FC<any> = ({ market, site, imageUrl, isOwner, owner, creator, name, nftThumb, cryptoUrl, price, timestamp, author, usd, ...props }) => {
  const navigate = useNavigate();

  return (
    <Wrap {...props}>
      <img className={'bg'} src={imageUrl || nftThumb} />
      <Info>
        {/* <h3>
          <img src={nftMarketSite(site)} />
        </h3> */}
        <h2>{name}</h2>

        {price && (
          <div className={'wrap-list s-b line-section'}>
            <Price icon={cryptoMarket(market)} price={price} color={'#fff'} suffix={<div className={'usd'}>(${setDigit(usd, 0, false, true)})</div>} />
            <p className={'timestamp'}>{timestamp}</p>
          </div>
        )}

        {/* <div className={'wrap-list s-b line-section'}>
          <p>{isOwner ? 'Owner' : 'Creator'}</p>
          <AuthorName underline name={creator ? creator : owner ? owner : author} fontSize={12} color={'#fff'} />
        </div> */}

        <Button>Buy now</Button>
      </Info>
    </Wrap>
  );
};

export default FullItemComponent;

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 298px;
  border-radius: 10px;
  overflow: hidden;

  img.bg {
    object-fit: cover;
  }
`;

const Info = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 17px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0) 0%, #000000 100%);

  * {
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
  }

  h3 {
    font-weight: 700;
    font-size: 12px;

    img {
      width: auto !important;
      height: 20px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 16px;
  }

  .timestamp {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
  }

  .usd {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    margin-left: 5px;
  }

  .line-section {
    margin-top: 9px;
  }
`;

const Button = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  color: #0085ff;
  text-align: right;
  margin: 9px 0 10px 0;
`;
