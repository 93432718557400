import React from 'react';
import styled from 'styled-components';
import { Comment, Popover } from 'antd';
import { ReactComponent as HistoryIcon } from 'assets/svg/history.svg';
import { ReactComponent as MoreIcon } from 'assets/svg/more-dot.svg';
import { ReactComponent as EmojiIcon } from 'assets/svg/emoji.svg';
import { ReactComponent as ShareIcon } from 'assets/svg/share.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg';
import { AvatarName, Avatars, PopMenu, Text, Timestamp, View } from 'styles/';

const CommentStyle: React.FC<any> = ({ cId, name, avatar, channelName, content, emojis, isOnline = false, timestamp, reply, actions, ...props }) => {
  const [openMore, setOpenMore] = React.useState(false);
  const [me, setMe] = React.useState(true);

  const moveToReplyPage = () => {
    // navigator('/');
    console.log('move to go');
  };

  const moveToEditPage = () => {
    // navigator('/');
    console.log('move to go');
  };

  const toggleMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setOpenMore(true);
    if (openMore) setOpenMore(false);
  };

  const defaultAction = [
    <BtnMore key={'more'} onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => toggleMenu(e)}>
      <Popover
        placement="bottom"
        trigger={'click'}
        content={
          <PopMenu>
            <li>
              <EmojiIcon />
            </li>
            <li>
              <ShareIcon className={'icon-share'} />
            </li>
            <li onClick={moveToEditPage}>
              <EditIcon className={'icon-edit'} />
            </li>
          </PopMenu>
        }
      >
        {/* <MoreIcon className={'icon-more'} /> */}
      </Popover>
    </BtnMore>
  ];

  return (
    <StyledComment {...props}>
      <Comment
        actions={actions ? actions : defaultAction}
        author={name}
        avatar={<AvatarName avatar={avatar} dot={isOnline} dotW={8} dotH={8} />}
        content={<p className={'content'}>{content}</p>}
        datetime={timestamp ? <Timestamp className={'comment-timestamp'} timestamp={timestamp} /> : false}
      />
      {emojis && (
        <View align="center" spacing={4} padding={'0 0 10px 45'}>
          {emojis.map((item: any, idx: number) => (
            <StyledEmoji key={idx}>
              <img src={item.imageUrl} />
              <span>{item.count}</span>
            </StyledEmoji>
          ))}
        </View>
      )}
      {reply && (
        <View align="center" spacing={7} padding={'0 0 0 45'}>
          <View>
            {reply?.data?.map((item: any, idx: number) => (
              <Avatars key={idx} avatar={item.avatar} />
            ))}
          </View>
          <View align="center" onClick={moveToReplyPage}>
            <Text className={'replies'} size="xs" color="#0075FF">
              {reply?.total} replies
            </Text>
          </View>
          <View>
            <Text className={'dateStamp'} size="xs" color="#808080">
              {reply?.timestamp}
            </Text>
          </View>
        </View>
      )}
    </StyledComment>
  );
};

export default CommentStyle;

const StyledComment = styled.div`
  .ant-comment-content-author {
    align-items: center;
    justify-content: space-between;
  }
  .ant-comment-content-author-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }

  /* .ant-comment-content {
    padding-right: 45px;
  } */

  .ant-comment-actions {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;

    & > li {
      cursor: pointer;
    }
  }

  .content {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    word-break: break-all;
    white-space: pre-line;
  }

  .timestamp {
    text-align: right;
    span {
      display: inline-block;
      margin-left: 3px;
      font-weight: 400;
      font-size: 10px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const BtnMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  .icon-more {
    margin-right: 10px;
    path {
      fill: #808080;
    }
  }

  .icon-edit {
    path {
      fill: #000;
    }
  }
`;

const MoreUtil = styled.ul`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 9px;
  z-index: 90;

  & > li {
    padding: 15px 17px;
    line-height: 0;
  }
  & > li + li {
    border-top: 1px solid #eaeaea;
  }

  .icon-share {
    path {
      fill-opacity: 1;
    }
  }
`;

const StyledEmoji = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: row;
  align-items: center;
  border: 1px solid #bdbdbd;
  line-height: 0;
  padding: 5px 4px;
  border-radius: 60px;

  & > img {
    width: 10px;
    height: 10px;
    object-fit: contain;
  }

  & > span {
    font-size: 10px;
    color: #7d7d7d;
  }
`;
