import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LinkUrlSVG } from 'assets/svg/link-url.svg';

import APIService from 'api';

const DropItem: React.FC<any> = ({ imgUrl, content, url }) => {
  const navigate = useNavigate();
  React.useEffect(() => {}, []);

  return (
    <Wrap onClick={() => window.open(url)}>
      <Img src={imgUrl} />
      <LinkIcon>
        <LinkUrlSVG />
      </LinkIcon>
      <Content>{content}</Content>
    </Wrap>
  );
};

export default DropItem;

const Wrap = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  overflow: hidden;

  + div {
    margin-top: 16px;
  }
`;

const Img = styled.img`
  width: 100%;
  min-height: 120px;
  object-fit: cover;
  vertical-align: top;
`;

const Content = styled.div`
  padding: 16px 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
`;

const LinkIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 4px;
  top: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  z-index: 5;
`;
