import React from 'react';
import styled from 'styled-components';
import { View } from 'styles';
import bg_default from 'assets/bg/bg_default_banner.png';

export interface BackgroundProps {
  bgUrl?: string | null | undefined;
  w?: number | string;
  h?: number | string;
  radius?: number;
  objectFit?: 'cover' | 'contain' | 'unset' | 'fill' | 'inherit' | 'scale-down ' | 'none';
}

const BackgroundUI: React.FC<BackgroundProps> = ({ bgUrl, ...props }) => {
  return <StyledWrap {...props}>{bgUrl ? <img className="bg-img" src={bgUrl} /> : <img src={bg_default} />}</StyledWrap>;
};

export default BackgroundUI;

BackgroundUI.defaultProps = {
  h: 120,
  radius: 0,
  objectFit: 'cover'
};

const StyledWrap = styled.div<BackgroundProps>`
  width: 100%;
  width: ${p => (typeof p.w === 'number' ? p.w + 'px' : p.w)};
  height: ${p => p.h}px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  ${p => p.radius && `border-radius: ${p.radius}px;`};

  .bg-img {
    width: 100%;
    height: 100%;
    object-fit: ${p => p.objectFit};
  }

  .bg-empty {
    width: 100%;
    height: 100%;
    background-color: #e4e4e4;
  }
`;
