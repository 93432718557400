import bg_banner from 'assets/common/bg_banner.png';

export const BANNER_IMG = [
  {
    id: 0,
    imgUrl: 'https://user-images.githubusercontent.com/6345000/186358532-c931c456-e8ff-4711-b2ee-2d3101575e7c.png',
    url: 'https://www.larvalabs.com/cryptopunks'
  }
];

export const POST_LIST = [
  {
    id: 0,
    thumb: 'https://user-images.githubusercontent.com/45615584/175958571-dadb0c71-b1d2-4c2f-a900-7d056e09129a.png',
    title: 'Art NFT Market, Solanart',
    at: 'solanart.io',
    content: 'Solanart is a fully-fledged NFT marketplace',
    url: 'https://solanart.io/'
  },
  {
    id: 1,
    thumb: 'https://user-images.githubusercontent.com/45615584/175958605-7718c18d-3d9b-4387-aa17-82c720aca306.png',
    title: 'Rarible is a multichain marketplace',
    at: 'rarible.com',
    content: 'Rarible is a multichain marketplace, marketplace marketplace',
    url: 'https://rarible.com/'
  },
  {
    id: 2,
    thumb: 'https://user-images.githubusercontent.com/45615584/175958624-8568045d-6214-4c0a-acff-c7df9e71513a.png',
    title: 'Illustration of Microinvestors',
    at: 'greemy.com',
    content: 'Grimmy is a brand name that means "my marketplace marketplace',
    url: 'https://greemy.com/'
  }
];

export const MARKET_LIST = [
  {
    id: 0,
    name: 'Women Unite - 10k Assemble',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/175954525-4f78b7df-4375-43bf-bdea-cdbc2f72b66a.png',
    avatarUrl: 'https://user-images.githubusercontent.com/45615584/175954589-e29420fb-fbc7-44bb-9f3b-c30ed0e5110e.png',
    displayDecimal: 1,
    cryptoIcon: 'https://user-images.githubusercontent.com/45615584/171037837-383cebd1-c8cb-4108-ac0d-e10e3b3b342b.png',
    price: '95,2',
    items: '89K',
    volume: '141,8'
  },
  {
    id: 0,
    name: `Bored Ape Chemistry Club`,
    bgUrl: 'https://user-images.githubusercontent.com/45615584/175959742-4682e653-6b86-4713-b534-fefc24710bbc.png',
    avatarUrl: 'https://user-images.githubusercontent.com/45615584/175961254-e07a40bf-8906-4035-b758-f9b0f5714646.png',
    displayDecimal: 1,
    cryptoIcon: 'https://user-images.githubusercontent.com/45615584/171037837-383cebd1-c8cb-4108-ac0d-e10e3b3b342b.png',
    price: '303,2M',
    items: '109.7K',
    volume: '49M'
  }
];

export const AD_LIST2 = [{ imgUrl: 'https://user-images.githubusercontent.com/6345000/189045725-45d8b802-5b56-4e88-a680-5c1b8dba7e87.png', url: 'https://doodles.app/' }];

export const USER_LIST = [
  {
    id: 0,
    profileImage: 'https://user-images.githubusercontent.com/45615584/175959683-52908047-b16c-4022-92b7-c95f77674db9.png',
    author: 'Smirc 109',
    itemAmount: '9.3K',
    ownerAmount: '2.4K',
    isFollow: false
  },
  {
    id: 1,
    profileImage: 'https://user-images.githubusercontent.com/45615584/175959694-2f3d1103-c4b5-4209-8042-3079550e2e6c.png',
    author: 'Smirc 109',
    itemAmount: '9.3K',
    ownerAmount: '2.4K',
    isFollow: false
  },
  {
    id: 2,
    profileImage: 'https://user-images.githubusercontent.com/45615584/175959704-948166a5-5657-463d-81d9-d9efe7e9e920.png',
    author: 'Smirc 109',
    itemAmount: '9.3K',
    ownerAmount: '2.4K',
    isFollow: false
  },
  {
    id: 3,
    profileImage: 'https://user-images.githubusercontent.com/45615584/175959713-626a84a4-8c2a-4bf3-a599-c2cdde4395db.png',
    author: 'Smirc 109',
    itemAmount: '9.3K',
    ownerAmount: '2.4K',
    isFollow: false
  },
  {
    id: 4,
    profileImage: 'https://user-images.githubusercontent.com/45615584/175959717-8421c651-ae1d-4eb2-907b-d0c8e748e475.png',
    author: 'Smirc 109',
    itemAmount: '9.3K',
    ownerAmount: '2.4K',
    isFollow: false
  }
];

export const AD_LIST = [
  {
    id: 0,
    imgUrl: 'https://user-images.githubusercontent.com/6345000/196579320-a60cd821-7ed3-4e45-a522-e668e66413a7.png',
    url: 'https://www.binance.com/en/nft/event/nft-event-collections'
  }
];
