import React from 'react';
import styled from 'styled-components';

interface CheckboxProps {}

const CheckboxUI: React.FC<CheckboxProps> = ({ children, ...props }) => {
  return <StyledCheckbox>{children}</StyledCheckbox>;
};

export default CheckboxUI;

const StyledCheckbox = styled.div`
  .ant-checkbox-wrapper {
    margin: 0;
    display: flex;
    align-items: flex-start;
  }

  .ant-checkbox + span {
    padding-left: 18px;
    padding-right: 50px;
  }

  .ant-checkbox-inner {
    border: 2px solid #7e7e7e;
    border-radius: 2px;
  }

  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }
`;
