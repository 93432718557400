import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SettingIcon } from 'assets/icon/setting.svg';
import { ReactComponent as CopyIcon } from 'assets/icon/copy.svg';
import { ReactComponent as ShareIcon } from 'assets/icon/share-fill.svg';

export interface IconButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  w?: number | string;
  h?: number | string;
  icon?: React.ReactNode;
  reserved?: 'setting' | 'official' | 'online' | 'copy' | 'share';
  isBorder?: boolean;
  borderColor?: string;
  bgColor?: string;
  shape?: 'circle' | 'round' | 'rect';
}

const IconButtonUI: React.FC<IconButtonProps> = ({ icon, reserved, ...props }) => {
  const renderIcon = () => {
    switch (reserved) {
      case 'setting':
        return <SettingIcon widths={17} height={17} stroke="#B8B8B8" />;
      case 'copy':
        return <CopyIcon className="icon-copy" widths={9.43} height={12} />;
      case 'share':
        return <ShareIcon />;
    }
  };

  return (
    <StyledIconButton {...props}>
      {reserved && renderIcon()}
      {icon && icon}
    </StyledIconButton>
  );
};

export default IconButtonUI;

IconButtonUI.defaultProps = {
  w: 36,
  h: 36,
  shape: 'circle',
  borderColor: '#c9c9c9',
  bgColor: '#fff'
};

const StyledIconButton = styled.button<IconButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.w}px;
  height: ${p => p.h}px;
  background-color: ${p => p.bgColor};
  border: 1px solid ${p => p.borderColor};
  overflow: hidden;

  ${p => p.shape === 'circle' && `border-radius: 50% !important`};

  .icon-copy {
    path {
      fill: #808080;
      fill-opacity: 1;
    }
  }
`;
