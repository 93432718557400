import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HashIcon } from 'assets/svg/hash.svg';
import { ReactComponent as InviteIcon } from 'assets/svg/follow.svg';
import { ListItem, CommentItem, Button, FilterSearch, Label, Link, Input } from 'styles';
import { COMMENT_LIST, GROUP_LIST, INVITE_LIST } from 'api/mock/community';
import { Avatar, Divider } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';
import DrawerLayout from '../layout/Drawer';
import APIService from 'api';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import moment from 'moment';

const apiService = new APIService();

const CommunityGroupListView: React.FC<any> = () => {
  const navigate = useNavigate();
  const { uid, gid } = useParams();
  const [openInvite, setOpenInvite] = React.useState(false);
  const [shortUrl, setShortUrl] = React.useState<string>('');
  const [findMember, setFindMember] = React.useState<any[]>([]);
  const [channelView, setChannelView] = React.useState<any>();
  const [groupView, setGroupView] = React.useState<any>();
  const [query, setQuery] = React.useState({ filter: 0, text: '' });
  const [chatList, setChatList] = React.useState<any>([]);
  const [groupValidate, setGroupValidate] = React.useState<any>(null);
  const [member, setMember] = React.useState<any[]>([]);

  const [inputs, setInputs] = React.useState({ content: '' });

  const chatInputRef: any = React.useRef(null);

  const getCommunityGroupView = async () => {
    const resultChannel = await apiService.getCommunityChannelView({ uid });
    setChannelView(resultChannel);

    const memberCheck = await apiService.getCommunityGroupValidate({ uid, gid });
    setGroupValidate(memberCheck);

    const getGroupView = await apiService.getCommunityGroupView({ uid, gid });
    setGroupView(getGroupView);

    const memberList = await apiService.getCommunityGroupMember({ uid, gid });
    setMember(memberList.list);
  };

  const getBoardList = async ({ scroll = true }: any) => {
    const getBoardList = await apiService.getCommunityBoardList({ uid, gid });

    const newArray = chatList.concat(getBoardList.list).reduce(function (acc: any, current: any) {
      if (acc.findIndex(({ uid }: any) => uid === current.uid) === -1) {
        acc.push(current);
      }
      return acc;
    }, []);

    setChatList(newArray.sort((a: any, b: any) => b.uid - a.uid));
    setTimeout(() => {
      const drawerWrapEl = document.getElementById('drawerWrap');

      if (drawerWrapEl && scroll) {
        window.scrollTo(0, drawerWrapEl.scrollHeight);
      }
    }, 100);
    // setGroupView(getGroupView);
  };

  const getMember = async () => {
    const getMember = await apiService.searchMember({ keyword: query.text });

    setFindMember(getMember.list);
  };

  const SendInvite = async (toMemberUid: number) => {
    await apiService.postDm({ shortUrl: shortUrl, communityChannelUid: `${uid}`, communityGroupUid: `${gid}`, type: '2', toMemberUid: `${toMemberUid}`, message: 'welcom Group Invite 메세지' });
    // await apiService.postDm({ type: 1, toMemberUid, message: 'welcom Group Invite 메세지' });

    const resultInvite = await apiService.sendInvite({ uid, gid, json: { toMemberUid } });

    if (resultInvite === 200) {
      await apiService.postDm({ shortUrl: shortUrl, communityChannelUid: uid, communityGroupUid: gid, type: 2, toMemberUid, message: 'welcom Group Invite 메세지' });
      alert('Invite Success');
      handleFocusMove();
      setOpenInvite(false);
    }
  };

  const handleInviteOpen = async () => {
    const resultInvite = await apiService.postInviteUrl({ communityChannelUid: uid, communityGroupUid: gid });
    setShortUrl(resultInvite.shortUrl);
    setOpenInvite(true);
  };

  const handleChatSend = async (e: any) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value.length > 0) {
      const sendResult = await apiService.getCommunityBoardPost({ uid, gid, json: inputs });
      if (sendResult === 200) {
        getBoardList({});
        setInputs({ content: '' });
      }
    }
  };

  const handleClose = () => {
    setOpenInvite(false);
  };

  const handleFocusMove = () => {
    if (chatInputRef) {
      chatInputRef.current.getNode().focus();
    }
  };

  React.useEffect(() => {
    getCommunityGroupView();
    getBoardList({});

    const footerEl = document.getElementById('footer');
    const navEl = document.getElementById('Nav');

    if (footerEl) {
      footerEl.style.display = 'none';
    }

    if (navEl) {
      navEl.style.display = 'none';
    }

    setInterval(() => {
      getBoardList({ scroll: false });
    }, 3000);

    return () => {
      if (footerEl) {
        footerEl.style.display = 'block';
      }

      if (navEl) {
        navEl.style.display = 'flex';
      }
    };
  }, []);

  React.useEffect(() => {
    // getCommunityGroupView();
    if (query.text.length > 0) {
      getMember();
    }
  }, [query]);

  return (
    <DrawerLayout channelName={channelView?.name} member={member}>
      <StyledGroup>
        <div className={'header'}>
          <h3>#{groupView?.name}</h3>
        </div>
        {/* <ListItem prefix={<HashIcon />} suffix={<InviteIcon />} key={0} title={groupView?.name} /> */}
        {/* <ListItem prefix={<HashIcon />} suffix={<InviteIcon />} key={0} title={groupView?.name} /> */}
      </StyledGroup>

      <StyledComment>
        {COMMENT_LIST.map((item: any, idx: number) =>
          item.type === 'WELCOME' && groupValidate?.validate ? (
            <>
              <CommentItem
                key={idx}
                name={item.name}
                avatar={item.avatar}
                content={
                  <>
                    <p>
                      Welcome to {groupView?.name}. This is your brand new, shiny channel, Here are some steps to help you get started. For more, Check out our{' '}
                      <Link to="/">Getting Started guide</Link>
                    </p>
                  </>
                }
              />
              {groupValidate?.validate ? (
                <StyledButton>
                  <Button onClick={handleInviteOpen} color={'black'} rect full h={50} fontSize={12} text={'Invite your friends'} />
                  {/* {groupValidate?.groupJoinInfo.kind === 1 && (
                    <Button onClick={() => navigate(`/community/channel/edit/${gid}`)} color={'black'} rect full h={50} fontSize={12} text={'Personalize your channel with an icon'} />
                  )} */}
                  <Button color={'black'} rect full h={50} fontSize={12} text={'Send your first message'} onClick={handleFocusMove} />
                </StyledButton>
              ) : (
                <StyledButton>
                  <Button rect color={'black'} h={40} fontSize={12} text={'Verify'} />
                </StyledButton>
              )}
            </>
          ) : (
            <>{/* <CommentItem key={idx} name={item.name} avatar={item.avatar} content={item.content} timestamp={item.timestamp} /> */}</>
          )
        )}
        {chatList &&
          chatList.length > 0 &&
          chatList
            .map((item: any, index: number) => (
              <DateCheckWrap key={index} className={moment(item.createdAt).format('MMMDDYYYY')} dateCheck={moment(item.createdAt).format('MMMDDYYYY')}>
                <Divider plain>{moment(item.createdAt).format('MMM DD YYYY')}</Divider>
                <CommentItem name={item.memberName} avatar={item.memberPhoto} content={item.content} timestamp={moment(item.createdAt).fromNow()} />
              </DateCheckWrap>
            ))
            .reverse()}
      </StyledComment>

      {/* TODO: Comment editor 추가 */}
      {groupValidate?.validate && (
        <BottomSheet open={true} scrollLocking={false} blocking={false}>
          <Input ref={chatInputRef} value={inputs.content} placeholder={'send message'} onChange={() => setInputs({ content: chatInputRef?.current.getValue() })} onKeyDown={handleChatSend} />
        </BottomSheet>
      )}

      {openInvite && (
        <BottomSheet
          open={openInvite}
          onDismiss={handleClose}
          header={
            <Header>
              <dt>Invite friends</dt>
              <dd># {groupView?.name}</dd>
            </Header>
          }
          snapPoints={({ minHeight }) => minHeight}
        >
          <StyledSearch>
            <FilterSearch className="search" rect placeholder={'MOA_'} query={query} setQuery={setQuery} />
          </StyledSearch>

          <InviteList>
            {findMember.length > 0 &&
              findMember.map((item, idx) => (
                <ListItem
                  key={idx}
                  prefix={<Avatar src={item.photoUri} />}
                  title={item.name}
                  suffix={<Button h={30} w={100} fontSize={12} rect color={'blue'} text={'INVITE'} onClick={() => SendInvite(item.memberUid)} />}
                />
              ))}
          </InviteList>

          <StyledCopy>
            <Label label={'send a channel invite link to a friend'}>
              <Copy>
                <p>{window.location.origin + '/invite/' + shortUrl}</p>
                <CopyToClipboard text={window.location.origin + '/invite/' + shortUrl} onCopy={() => alert('Copied.')}>
                  <Button color={'black'} w={80} h={30} text={'COPY'} fontSize={10} rect />
                </CopyToClipboard>
              </Copy>
              {/* <Link className={'link-expire'} to={'/'}>
                Your invite link expires in 7 days.
              </Link> */}
              <span className={'link-expire'}>Your invite link expires in 7 days.</span>
            </Label>
          </StyledCopy>
        </BottomSheet>
      )}
    </DrawerLayout>
  );
};

export default CommunityGroupListView;

const StyledCopy = styled.div`
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  .link-expire {
    padding-left: 10px;
    font-size: 12px;
    text-decoration: underline;
    color: #666;
  }
`;

const StyledGroup = styled.div`
  border-top: 1px solid #eaeaea;
  padding: 28px 20px;

  .header {
    position: relative;

    h3 {
      font-weight: 700;
      font-size: 14px;
      line-height: 12px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 14px;
    }

    .btn-create {
      position: absolute;
      right: 0;
      top: -1px;
    }
  }
`;

const StyledComment = styled.div`
  border-top: 1px solid #eaeaea;
  padding: 9px 20px;
  background: #f9f9f9;
  position: relative;

  .ant-comment-actions {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;

    & > li {
      cursor: pointer;
    }
  }

  .ant-divider-inner-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.5);
  }

  .ant-divider-plain.ant-divider-with-text {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const DateCheckWrap = styled.div<any>`
  &.${p => p.dateCheck} ~ .${p => p.dateCheck} {
    .ant-divider {
      display: none;
    }
  }
`;

const StyledButton = styled.div`
  padding: 0 0 10px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.dl`
  text-align: left;
  margin-bottom: 13px;

  dt {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 5px;
    color: #000000;
  }

  dd {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #6f6f6f;
  }
`;

const StyledSearch = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
`;

const InviteList = styled.div`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;

  .list-title {
    font-weight: 600;
  }
`;

const Copy = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-left: 15px;
  background: #f9f9f9;
  border: 1px solid #e6e8ec;
  border-radius: 12px;
  margin-bottom: 10px;

  & > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    color: #000000;
  }
`;
