import React from 'react';
import styled from 'styled-components';
import { Price, Text, View, WrapIcon } from 'styles';
import { cryptoMarket, setDigit } from 'utils';

interface AssetItemProps {
  name: string;
  symbol: string;
  amount: number;
  usd: number;
  coinIcon: number;
}

const AssetItemComponent: React.FC<AssetItemProps> = ({ name, symbol, amount, usd, coinIcon }) => {
  return (
    <StyledWrap justify="space-between" padding="24px 20" borderPos="bottom">
      <View spacing={14} align="center">
        <WrapIcon w={48} h={48}>
          <img src={cryptoMarket(coinIcon)} style={{ objectFit: 'contain' }} />
        </WrapIcon>
        <View direction="column">
          <Text size="lg" weight="bold">
            {symbol}
          </Text>
          <Text size="sm" color={'#808080'}>
            {name}
          </Text>
        </View>
      </View>

      <View direction="column" flex={0}>
        <Text size="lg" weight="bold">
          {setDigit(amount, 3, true, false)}
        </Text>
        <Price prefix={'$ '} price={setDigit(usd, 2, true, true)} preSuffix=" USD" fontSize={14} color="#808080" weight={400} />
      </View>
    </StyledWrap>
  );
};

export default AssetItemComponent;

const StyledWrap = styled(View)``;
