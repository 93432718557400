import React from 'react';
import styled from 'styled-components';
import { Text, View } from 'styles';
import { Text as Text2 } from 'ui/text/Text';
import { View as View2 } from 'ui/view/View';
import { ReactComponent as ArrowSVG } from 'assets/svg/moreArrow.svg';
import { ReactComponent as ShareIcon } from 'assets/svg/share-sns.svg';

import ReactionComponent from './Reaction';
import moment from 'moment';

import APIService from 'api';
const apiService = new APIService();

const ListCardComponent: React.FC<any> = ({ view = false, uid, emojinum, createdAt, headline, title, content, sourceUrl, sourceLink, opinions, opinionName }) => {
  const htmlTagRemoveRegex = /(<([^>]+)>)/gi;
  const reGexContent = content.replace(htmlTagRemoveRegex, '');

  const [emojiResult, setEmojiResult] = React.useState<any[]>([]);

  const emoji = [
    {
      value: 'smile',
      bgUrl: 'https://user-images.githubusercontent.com/45615584/190357958-0d281447-a57e-4b85-85aa-7f3a8da2704a.png',
      count: 0
    },
    {
      value: 'love',
      bgUrl: 'https://user-images.githubusercontent.com/45615584/190357945-3d682118-ac37-4867-8d0c-f675eb1def39.png',
      count: 0
    },
    {
      value: 'angry',
      bgUrl: 'https://user-images.githubusercontent.com/45615584/190357913-c094af1d-9c3b-4965-8da5-8bec35a8d8c2.png',
      count: 0
    },
    {
      value: 'cry',
      bgUrl: 'https://user-images.githubusercontent.com/45615584/190357896-c8500b61-8b23-4d93-9e8e-6d06de3077f0.png',
      count: 0
    }
  ];

  opinions &&
    Object.keys(opinions).forEach((item: string, index: number) => {
      emoji[Number(item) - 1].count = opinions[item];
    });

  const updateEmoji = async (index: number) => {
    const result = await apiService.putScamOpinion(uid, { opinion: index + 1, increment: 1 });

    const tempEmoji = [...emoji];

    result &&
      Object.keys(result.opinions).forEach((item: string, index: number) => {
        console.log(item, 'item');
        tempEmoji[Number(item) - 1].count = result.opinions[item];
      });
    setEmojiResult(tempEmoji);
  };

  const scamViewCount = async () => {
    console.log(uid, 'uid');
    const result = await apiService.putScamDetailCount(uid);
    console.log(result, 'result');
  };

  const shareMobile = async () => {
    try {
      await window.navigator.share({
        title,
        text: reGexContent,
        url: sourceUrl
      });
      console.log('공유 성공');
    } catch (e) {
      console.log('공유 실패');
    }

    window.navigator.share({
      title,
      text: reGexContent,
      url: sourceUrl
    });
  };

  React.useEffect(() => {
    setEmojiResult(emoji);
  }, []);

  return (
    <Wrap>
      <div className="contents">
        <View2 direction="column" spacing={4} margin={['mb-1']}>
          <Text size="xl" weight={'semi-bold'} className="subject">
            {title}
          </Text>
          <Text align="right" size="xs" block color={'rgba(0, 0, 0, 0.5)'}>
            {moment(createdAt).format('MM.DD.YY')}
          </Text>
        </View2>

        <View direction="column" spacing={10} block>
          {!view && (
            <Text2 h={144} eclipse={7} size="lg">
              {reGexContent}
            </Text2>
          )}
          {view && <div dangerouslySetInnerHTML={{ __html: `${content}` }} />}

          <View direction="column" spacing={26} block>
            <View justify="space-between" align="center" block>
              <Text className={view ? 'link-source-full' : 'link-source'} size="sm" color={'#808080'}>
                URL LINK:{' '}
                <a href={sourceUrl} target="_blank" rel="noreferrer" onClick={scamViewCount}>
                  {sourceUrl}
                </a>
              </Text>
              <ArrowSVG />
            </View>

            <View className="reaction-container" justify="space-between" align="center" spacing={15} block>
              {emojiResult.map((item: any, idx: number) => (
                <ReactionComponent key={idx} {...item} onClick={() => updateEmoji(idx)} />
              ))}
              <ShareIcon onClick={shareMobile} />
            </View>
          </View>
        </View>
      </div>
    </Wrap>
  );
};

export default ListCardComponent;

const Wrap = styled.div`
  background: #ffffff;
  overflow: hidden;
  border-bottom: 1px solid #efefef;
  margin-bottom: 15px;
  padding-bottom: 35px;

  & + div {
    margin-top: 14px;
  }

  .contents {
    padding: 16px 16px 0;
  }

  .link-source {
    max-width: 278px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
  }

  .link-source-full {
    text-decoration: underline;
  }
`;
