import React from 'react';
import styled, { css } from 'styled-components';
import { View, Text } from 'ui';
import { AllColorUnion } from 'ui/@types/colors';
import { TextSizeUnion, TextWeightUnion } from 'ui/@types/text';

type ButtonVariantUnion = 'outline' | 'filled';

interface TinyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  w?: number;
  h?: number;
  variant?: ButtonVariantUnion;
  bgColor?: AllColorUnion;
  color?: AllColorUnion;
  textSize?: TextSizeUnion;
  weight?: TextWeightUnion;
}

const TinyButton: React.FC<TinyButtonProps> = ({ children, ...props }) => {
  return (
    <StyledTinyButton {...props}>
      <Text size={props.textSize} weight={props.weight} color={props.color}>
        {children}
      </Text>
    </StyledTinyButton>
  );
};

export default TinyButton;

TinyButton.defaultProps = {
  color: 'white-color',
  bgColor: 'brand-color-300'
};

const StyleOutline = css`
  border: 1px solid #fff;
  padding: 2px 10px;
  background: transparent;
  transition: background 0.2s ease-in;

  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const StyleFilled = css<TinyButtonProps>`
  background: ${p => p.bgColor && `var(--${p.bgColor})`};
  border: 1px solid ${p => p.bgColor && `var(--${p.bgColor})`};
`;

const StyledTinyButton = styled.button<TinyButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.w && p.w}px;
  height: 24px;
  height: ${p => p.h && p.h}px;
  border-radius: 10px;

  ${p => p.variant === 'outline' && StyleOutline};
  ${p => p.variant === 'filled' && StyleFilled};
`;
