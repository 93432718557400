import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Avatars, ButtonLike, Marketplace, Price, View, Text } from 'styles';
import noPrice from 'assets/common/noprice.gif';
import { ReactComponent as MoreIcon } from 'assets/svg/more-dot-thick.svg';
import { ReactComponent as SirenLinedSvg } from 'assets/svg/siren-lined.svg';
import { ReactComponent as SirenFilledSvg } from 'assets/svg/siren-filled.svg';
import { ReactComponent as FavoriteLinedSvg } from 'assets/svg/favorite-folder-lined.svg';
import { ReactComponent as FavoriteFilledSvg } from 'assets/svg/favorite-folder-filled.svg';

import { nftMarketSite, cryptoMarket } from 'utils';
import noNft from 'assets/common/noNft.png';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import { alertMessage } from 'store/reducers/System';

const NftProductCompoent: React.FC<any> = ({
  animationUrl,
  bidders,
  imageUrl,
  nftThumb,
  name,
  price,
  isLike,
  likeAmount,
  market,
  site,
  cryptoIcon,
  metaInfo,
  popover,
  reactionCount,
  rate = false,
  siteUrl,
  buy = true,
  url,
  uid,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSiren, setIsSiren] = React.useState(false);
  const [isFavoriteFolder, setIsFavoriteFolder] = React.useState(false);
  const [selectRate, setSelectRate] = React.useState<any>({ name: null, count: null, imageUrl: null });
  const [openMenu, setOpenMenue] = React.useState<boolean>(false);
  const [visibleRateList, setVisibleRateList] = React.useState(false);
  const rateEmoji = [
    {
      name: 'smile',
      count: 11,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/193992310-94192d2a-5224-44ba-bb71-9408f35f7ac2.png'
    },
    {
      name: 'heart',
      count: 22,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/193992320-06e8be5f-14c2-482c-af55-5a90972b4408.png'
    },
    { name: 'angry', count: 22, imageUrl: 'https://user-images.githubusercontent.com/45615584/193992328-535aaf2c-fcc9-474e-acb4-4814c5b57a21.png' },
    { name: 'sad', count: 22, imageUrl: 'https://user-images.githubusercontent.com/45615584/193992333-5d9498b8-70af-4024-8f53-deef3d473c24.png' }
  ];

  const handleLike = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, action: string) => {
    e.preventDefault();
    action === 'off' ? console.log('좋아요 취소') : console.log('좋아요');
  };

  const toggleSiren = () => {
    setIsSiren(!isSiren);
  };

  const toggleFavoriteFolder = () => {
    setIsFavoriteFolder(!isFavoriteFolder);
  };

  const reportItem = () => {
    toggleSiren();
    if (!isSiren) dispatch(alertMessage({ type: 'default', msg: `Its's been reported.` }));
  };

  const addFavoriteItem = () => {
    toggleFavoriteFolder();
    if (!isFavoriteFolder) dispatch(alertMessage({ type: 'default', msg: `It’s been taken by favorited folder.` }));
  };

  const updateSelectRate = (name: string, count: number, imageUrl: string) => {
    setSelectRate({ name, count, imageUrl });
    setVisibleRateList(false);
  };

  const handleOpenMenu = (newOpen: boolean) => {
    setOpenMenue(newOpen);
  };

  const handleOpenEmotion = (newOpen: boolean) => {
    setVisibleRateList(newOpen);
  };

  const handleImgError = (e: any) => {
    e.target.onerror = null;
    e.target.src = noNft;
  };

  const content = (
    <StyledMenuContent direction="column">
      <View cursor="pointer" onClick={reportItem}>
        {!isSiren ? <SirenLinedSvg /> : <SirenFilledSvg />}
      </View>
      <View cursor="pointer" onClick={addFavoriteItem}>
        {!isFavoriteFolder ? <FavoriteLinedSvg /> : <FavoriteFilledSvg />}
      </View>
    </StyledMenuContent>
  );

  const rateListContent = (
    <StyledRateList direction="column">
      {rateEmoji.map((emoji, idx) => (
        <View cursor="pointer" key={idx} spacing={20} onClick={() => updateSelectRate(emoji.name, emoji.count, emoji.imageUrl)}>
          <View w={18} maxW={18}>
            <img src={emoji.imageUrl} />
          </View>
          <Text size="xxs">11</Text>
        </View>
      ))}
    </StyledRateList>
  );

  const moveToItemUrl = () => {
    if (url && !uid) {
      window.open(url);
      return;
    }
    if (uid) navigate(`/item/${uid}`);
  };

  return (
    <Wrap {...props} className="item-ntf">
      <div className="inner">
        <Product>
          {bidders && (
            <View className="wrap-bidder" direction="row">
              {bidders.map((item: any, idx: number) => (
                <Avatars key={idx} avatar={item.avatar} />
              ))}
            </View>
          )}
          <div className="product-image" onClick={moveToItemUrl}>
            {!(animationUrl && animationUrl.includes('embed')) && imageUrl?.includes('mp4') && <video src={imageUrl} preload={'auto'} />}
            {!(animationUrl && animationUrl.includes('embed')) && !imageUrl?.includes('mp4') && <img src={imageUrl || nftThumb} alt={name} onError={handleImgError} />}

            {animationUrl && animationUrl.includes('embed') && (
              <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" height="100%" sandbox="allow-scripts" src={animationUrl} />
            )}
            <div className="cover" />
          </div>

          {/* title */}
          <View justify="space-between" align="center" block>
            <View flex={1} pr={10}>
              <Text size="xs" weight="bold" eclipse={1}>
                {name}
              </Text>
            </View>
            {popover && (
              <Popover open={openMenu} onOpenChange={handleOpenMenu} trigger={'click'} content={content} placement="bottom">
                <MoreIcon cursor="pointer" fill="#8B8B8B" />
              </Popover>
            )}
          </View>

          {/* price */}
          <View className="price-container" justify="space-between" align="center" block>
            {price && <Price icon={cryptoMarket(market)} price={parseFloat(price).toFixed(4)} />}
            {!price && <img style={{ width: 61.2, height: 'auto' }} src={noPrice} />}

            <View w={60} maxW={60}>
              <Marketplace logoUrl={nftMarketSite(site)} />
            </View>
          </View>
        </Product>

        {buy && (
          <Transaction>
            <ButtonLike isLike={isLike} handleLike={handleLike} amount={likeAmount} />
            <ButtonBuy onClick={() => siteUrl && window.open(siteUrl)}>Buy now</ButtonBuy>
          </Transaction>
        )}

        {rate && (
          <StyledRate isSelected={!selectRate.name ? false : true} className="rate-wrap" block justify="space-between" align="center">
            <Popover open={visibleRateList} trigger={'click'} onOpenChange={handleOpenEmotion} content={rateListContent}>
              <View cursor="pointer" minW={55} flex={0} className="rate-total" spacing={7} align="center" justify="space-between">
                <img
                  style={{ width: 12, height: 12 }}
                  src={selectRate.imageUrl ? selectRate.imageUrl : 'https://user-images.githubusercontent.com/45615584/193991600-8c7f48bc-b32b-4897-9c96-8ad994ed4048.png'}
                />
                <Text block align="right" size="xxs">
                  {selectRate.count ? '+' + selectRate.count : 'Rate'}
                </Text>
              </View>
            </Popover>

            <View flex={0} align="center" justify="space-between">
              <View>
                {rateEmoji.map((avatar, idx) => (
                  <Avatars key={idx} avatar={avatar.imageUrl} w={16} h={16} gap={-5} />
                ))}
              </View>
              <View minW={22} block>
                <Text block align="right" size="xxs" color="#7d7d7d">
                  {reactionCount > 999 ? '+999' : reactionCount}
                </Text>
              </View>
            </View>
          </StyledRate>
        )}
      </div>
    </Wrap>
  );
};

export default NftProductCompoent;

const Wrap = styled.div`
  flex: 50%;
  max-width: 50%;
  margin-bottom: 15px;

  padding: 0 4px;

  .inner {
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
  }

  .wrap-bidder {
    margin-bottom: 12px;
  }
`;

const Product = styled.div`
  padding: 12px 12px 10px;

  & > .product-image {
    position: relative;
    width: 100%;
    height: 248px;
    margin-bottom: 10px;
    border-radius: 6px;
    overflow: hidden;

    img {
      object-fit: cover;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    iframe {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .wrap-list {
    div {
    }
  }

  & > h2 {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 5px;
  }
`;

const Transaction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.02);
  padding: 10px 20px;
`;

const ButtonBuy = styled.button`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #0085ff;
`;

const StyledRate = styled(View)<{ isSelected: boolean }>`
  padding: 10px;
  background-color: #f5f5f5;
  .rate-total {
    border: 1px solid #bdbdbd;
    padding: 2px 6px;
    border-radius: 60px;
    background-color: #fff;
    ${p => p.isSelected && `background-color:#7B7B7B; color:#fff`};
  }
`;

const StyledMenuContent = styled(View)`
  & > div + div {
    padding-top: 14px;
    border-top: 1px solid #eaeaea;
  }
  & > div {
    padding: 14px;
  }
`;

const StyledRateList = styled(View)`
  & > div + div {
    padding-top: 14px;
    border-top: 1px solid #eaeaea;
  }
  & > div {
    padding: 14px;

    /* &:hover,
    &:active {
      background-color: #7b7b7b;
      color: white !important;
    } */
  }
`;

const StyledToast = styled.div`
  position: fixed;
  bottom: 0;
  padding: 6px 20px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
`;
