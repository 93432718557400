import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { TwitterShareButton, FacebookShareButton, RedditShareButton, LineShareButton, FacebookIcon, TwitterIcon, RedditIcon, LineIcon } from 'react-share';

const SnsShareComponent: React.FC<any> = ({ size = 32 }) => {
  const { t } = useTranslation('common');
  const [baseUrl, setBaseUrl] = React.useState<string>('https://www.cantena.io');

  const handleClipboard = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(baseUrl!);

    message.success(t('shareCopied'));
  };

  React.useEffect(() => {
    const pathname = window.location.pathname;
    setBaseUrl(baseUrl + pathname);
  }, []);

  return (
    <Wrap>
      <TwitterShareButton url={baseUrl}>
        <TwitterIcon size={size} round />
      </TwitterShareButton>
      <FacebookShareButton url={baseUrl}>
        <FacebookIcon size={size} round />
      </FacebookShareButton>
      <RedditShareButton url={baseUrl}>
        <RedditIcon size={size} round />
      </RedditShareButton>
      <LineShareButton url={baseUrl}>
        <LineIcon size={size} round />
      </LineShareButton>
      <button type="button" onClick={handleClipboard}>
        <LinkOutlined style={{ fontSize: '24px' }} />
      </button>
    </Wrap>
  );
};

export default SnsShareComponent;

const Wrap = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
