import React from 'react';
import styled, { css } from 'styled-components';

export interface SelectProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  label?: string;
  w?: number | string;
  h?: number | string;
  required?: boolean;
  infoPos?: 'top' | 'bottom';
  infoNode?: React.ReactNode;
}

const SelectUI: React.FC<SelectProps> = ({ infoNode, infoPos, label, children, ...props }) => {
  return (
    <StyledWrap w={props.w} h={props.h}>
      {label && <StyledLabel required={props.required}>{label}</StyledLabel>}
      {infoPos === 'top' && infoNode && infoNode}
      <StyledSelect {...props}>{children}</StyledSelect>
      {infoPos === 'bottom' && infoNode && infoNode}
    </StyledWrap>
  );
};

export default SelectUI;

SelectUI.defaultProps = {
  w: '100%',
  h: 48
};

const StyledWrap = styled.div<{ w?: number | string; h?: number | string }>`
  ${p => (typeof p.w === 'number' ? `width: ${p.w}px` : `width: ${p.w}`)};
`;

const StyledLabel = styled.label<{ required?: boolean; infoNode?: boolean }>`
  display: block;
  margin-bottom: 8px;
  color: #000 !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  ${p =>
    p.required &&
    css`
      &::after {
        content: ' *';
        color: #ab3fff;
      }
    `}
`;

const StyledSelect = styled.div<SelectProps>`
  width: 100%;
  ${p => (typeof p.h === 'number' ? `height: ${p.h}px` : `height: ${p.h}`)};

  .ant-select {
    display: block;
    font-size: 16px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-single .ant-select-selector {
    border: 1px solid #e4e4e4 !important;
    border-radius: 12px;
  }

  .ant-select-single .ant-select-selector {
    height: 45px;
    background: #fff !important;

    input {
      height: 45px !important;
    }
  }

  .icon-select-arrow {
    path {
      stroke: #000;
    }
  }
`;
