import SubHeader from 'components/Header/SubHeader';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MsgIcon } from 'assets/svg/speech-bubble.svg';
import { ReactComponent as MenuIcon } from 'assets/svg/bar-3.svg';

import { MoaLogo, Tab, View, CommentItem, Input, Text } from 'styles';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { useSelector, useDispatch } from 'react-redux';

import DetailItemComponent from 'components/detailItem';
import { ITEM_DETAIL } from 'api/mock/item';

import InfoComponent from './components/Info';
import ScrollWrap from 'components/common/slideWrap';
import FullItemComponent from 'components/nftsItem/fullItem';
import googleIcon from 'assets/icon/google.png';
import Section from 'components/common/section';

import BoardItem from 'components/boardItem';

import { useInfiniteScroll } from 'hook/useInfiniteScroll';

import moment from 'moment';
import APIService from 'api';
const apiService = new APIService();

const ItemDetailView: React.FC<any> = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [activeTab, setActiveTab] = React.useState(1);
  const [nftDetail, setNftDetail] = React.useState<any>();
  const [nftRelated, setRelated] = React.useState<any>();
  const [myChat, setMyChat] = React.useState<any[]>([]);
  const [commentInput, setCommentInput] = React.useState<string>('');

  const [communityList, setCommunityList] = React.useState<any[]>([]);
  const senserRef = React.useRef<HTMLDivElement>(null);
  const isScroll = useInfiniteScroll(senserRef, { threshold: 0 }, false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const commentInputRef: any = React.useRef(null);

  const { profile } = useSelector((store: IStore) => store.System);

  const getNftDetail = async () => {
    const resultNftDetail = await apiService.getNftView({ uid });
    setNftDetail(resultNftDetail);
  };

  const getNftRelated = async () => {
    const result = await apiService.getNftRelatedList({ uid });
    setRelated(result.list);
  };

  const getMyChat = async () => {
    const resultMyChat = await apiService.getMyCommunity();

    if (resultMyChat?.list.length > 0) {
      setMyChat(resultMyChat.list);
    }
  };

  const getCommunityList = async ({ offsetUid }: any) => {
    setLoading(true);
    const resultMyGroup = await apiService.getCommunityMyGroup({ offsetUid });

    if (communityList.length > 0) {
      communityList.at(-1).uid !== resultMyGroup.list.at(-1).uid && setCommunityList([...communityList, ...resultMyGroup.list]);
    } else {
      setCommunityList(resultMyGroup.list);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    if (isScroll && communityList.length > 0) getCommunityList({ offsetUid: communityList.at(-1).uid });
  }, [isScroll]);

  const HeaderSuffix = () => {
    return (
      <View spacing={17} align="center">
        <MsgIcon />
        <MenuIcon />
      </View>
    );
  };

  const getGoogle = async () => {
    (function () {
      const cx = '647afc5dcdf884aac';
      const gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx + '&gsc.q=' + `${nftDetail.name} %26 NFT`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode?.insertBefore(gcse, s);
    })();

    setTimeout(() => {
      if (window.location.href.indexOf('gsc.tab') > 0 && window.location.href.indexOf('gsc.q') < 0) {
        window.location.href = window.location.href + '&gsc.q=' + `${nftDetail.name} %26 NFT`;
      }
    }, 1000);
  };

  const handleCommentSend = async (e: any) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value.length > 0) {
      const marketUid = 0;
      const json = {
        comment: commentInput
      };
      const sendResult = await apiService.postItemMarketComment({ marketUid, uid, json });
      if (sendResult === 200) {
        setCommentInput('');
      }
    }
  };

  React.useEffect(() => {
    if (activeTab === 2 && nftDetail) {
      getGoogle();
    }
  }, [activeTab]);

  React.useEffect(() => {
    getNftDetail();
    getNftRelated();
    getMyChat();
    getCommunityList({});
  }, [uid]);

  return (
    <Wrap>
      {nftDetail && (
        <>
          <SubHeader isBack={false} title={<MoaLogo logoW={30} logoH={30} text={nftDetail.nftCollectionName || 'Item details'} fontSize={14} />} suffix={<HeaderSuffix />} />
          <DetailItemComponent {...nftDetail} {...ITEM_DETAIL} />
          <StyledTab>
            <Tab active={activeTab}>
              <Tab.Item value={1} text={'Information'} onClick={() => setActiveTab(1)} />
              <Tab.Item value={2} imgText={googleIcon} imgSize={'m'} onClick={() => setActiveTab(2)} />
              <Tab.Item value={3} text={'Community'} onClick={() => setActiveTab(3)} />
            </Tab>
          </StyledTab>

          {activeTab === 1 && <InfoComponent {...ITEM_DETAIL?.information} desc={nftDetail.description} />}

          {activeTab === 2 && (
            <Section className="wrap-google">
              <div className="gcse-searchresults-only"></div>
            </Section>
          )}

          {activeTab === 3 && (
            <Section className="my-group">
              <CommentList>
                {communityList.length > 0 &&
                  communityList.map((item, idx) => <BoardItem key={idx} uid={item.communityChannelUid} name={item.communityChannelName} profileIcon={item.communityChannelProfileIcon} />)}
              </CommentList>
              <div ref={senserRef} className={`observer ${!isLoading ? 'completed' : ''}`} />
            </Section>
          )}

          {activeTab === 5 && (
            <StyledComment>
              {myChat.map((item: any, idx: number) => (
                <View direction="column" key={idx} padding={'0 0 0 50px'} onClick={() => navigate(`/community/${item.communityChannelUid}/group/${item.communityGroupUid}`)}>
                  <CommentItem
                    className={'wrap-comment'}
                    channelName={item.channelName}
                    name={
                      <View direction="column">
                        <Text size="sm" weight="semi-bold" color={'#000'}>
                          {item.memberName}
                        </Text>
                        <Text size="xs" color={'#000'}>
                          {item.channelName}
                        </Text>
                      </View>
                    }
                    isOnline={item.isOnline}
                    avatar={profile?.photoUri}
                    // content={<FullItemComponent className={'wrap-nft'} {...item.nftData} />}
                    content={item.content}
                    emojis={item.emojis}
                    reply={item.reply}
                    timestamp={moment(item.createdAt).format('LLL')}
                  />
                </View>
              ))}
            </StyledComment>
          )}

          {/* {profile && (
            <BottomSheet open={true} scrollLocking={false} blocking={false}>
              <Input ref={commentInputRef} value={commentInput} placeholder={'send message'} onChange={() => setCommentInput(commentInputRef?.current.getValue())} onKeyDown={handleCommentSend} />
            </BottomSheet>
          )} */}

          {nftRelated && nftRelated.length > 0 && (
            <ScrollWrap className={'wrap-related'} title="Related" bg="#F5F5F5">
              {nftRelated.map((item: any, idx: number) => (
                <FullItemComponent className={'item-related'} key={idx} {...item} onClick={() => navigate(`/item/${item.uid}`)} />
              ))}
            </ScrollWrap>
          )}
        </>
      )}
    </Wrap>
  );
};

export default ItemDetailView;

const Wrap = styled.div`
  .wrap-related {
    .item-related {
      min-width: 291px;

      & + div {
        margin-left: 24px;
      }
    }
  }

  .my-group {
    padding: 0;
  }
`;

const StyledTab = styled.div`
  margin-top: 28px;
  padding-bottom: 30px;
`;
const StyledComment = styled.div`
  padding: 20px;

  .ant-comment-actions {
    top: 10px !important;
  }

  .comment-timestamp {
    position: absolute;
    top: 0;
    right: 0px;
  }
`;
const CommentList = styled.div``;
