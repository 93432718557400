export const CTG_LIST = [
  {
    name: 'Art',
    value: 26
  },
  {
    name: 'Collectibles',
    value: 26
  },
  {
    name: 'Photography',
    value: 2
  },
  {
    name: 'Sports',
    value: 3
  },
  {
    name: 'Trading Cards',
    value: 4
  },
  {
    name: 'Character',
    value: 5
  },
  {
    name: 'Games',
    value: 6
  },
  {
    name: 'Membership',
    value: 7
  },
  {
    name: 'World',
    value: 8
  }
];

export const ALL_CTG_LIST = [
  {
    name: 'all'
  },
  {
    name: 'art'
  },
  {
    name: 'collectibles'
  },
  {
    name: 'photography'
  },
  {
    name: 'sports'
  },
  {
    name: 'tradingCard'
  },
  {
    name: 'character'
  },
  {
    name: 'games'
  },
  {
    name: 'fanArt'
  },
  {
    name: 'membership'
  },
  {
    name: 'world'
  }
];

export const SUPPLY_LIST = [
  {
    name: '1',
    value: 1
  },
  {
    name: '2',
    value: 2
  },
  {
    name: '3',
    value: 3
  },
  {
    name: '4',
    value: 4
  },
  {
    name: '5',
    value: 5
  },
  {
    name: '6',
    value: 6
  },
  {
    name: '7',
    value: 7
  },
  {
    name: '8',
    value: 8
  },
  {
    name: '9',
    value: 9
  },
  {
    name: '10',
    value: 10
  }
];

// recently : 최신 순,oldest : 등록순,priceLow : 가격 낮은 순, priceHigh: 가격 높은 순

export const SORT_LIST = [
  {
    name: 'Recently listed',
    value: 'recently'
  },
  {
    name: 'Oldest listed',
    value: 'oldest'
  },
  {
    name: 'Price low to high',
    value: 'priceLow'
  },
  {
    name: 'Price high to low',
    value: 'priceHigh'
  }
];

export const DURATION_LIST = [
  {
    text: '3 days',
    value: 3
  },
  {
    text: '1 week',
    value: 7
  },
  {
    text: '1 month',
    value: 30
  },
  {
    text: '3 month',
    value: 90
  },
  {
    text: '6 month',
    value: 180
  }
];

export const METHOD_LIST = [
  {
    text: 'Sell to highest bidder',
    value: 1
  }
];
