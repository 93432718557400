import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styles/theme';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { ReactComponent as LogoTextSvg } from 'assets/svg/logoText.svg';
import { ReactComponent as TwitterSvg } from 'assets/svg/social/twitter.svg';
import { ReactComponent as InstagramSvg } from 'assets/svg/social/instagram.svg';
import { ReactComponent as FacebookSvg } from 'assets/svg/social/facebook.svg';
import { ReactComponent as DicordSvg } from 'assets/svg/social/discord.svg';
import { ReactComponent as TelegramSvg } from 'assets/svg/social/telegram.svg';
import Marquee from 'react-fast-marquee';
import { Input, Button, View } from 'styles';
import { FOOTER_NOTICE } from 'api/mock/info';
import { useSelector, useDispatch } from 'react-redux';
export default () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const twoDepth = pathname.split('/')[2];
  const { profile } = useSelector((store: IStore) => store.System);
  useEffect(() => {}, []);

  const handleSignup = () => {
    alert('Welcome! Newst NFT info in your hands');
  };

  if (twoDepth) return <></>;

  const oneDepthRoute = ['/market', '/mynft', '/', '/search', '/community'];

  const hasFooter = oneDepthRoute.includes(pathname);
  if (!hasFooter) return <></>;

  return (
    // 하단 푸터
    <Footer id={'footer'}>
      <h2>Join NVIA community</h2>
      <Social>
        <TwitterSvg onClick={() => window.open('https://twitter.com/NftmoaN')} />
        <InstagramSvg onClick={() => window.open('https://www.instagram.com/nftmoa_official/')} />
        <FacebookSvg onClick={() => window.open('https://www.facebook.com/NFTMOA_Compass-of-NFT-100316896190120')} />
        <DicordSvg onClick={() => window.open('https://discord.gg/xB5PWdXJeb')} />
        <TelegramSvg onClick={() => window.open('https://t.me/NFTMOA')} />
      </Social>
      <NewsLetter>
        <h2>Sign up for latest NVIA newsletter</h2>
        <div className={'input-wrap'}>
          <Input bar={false} h={40} placeholder={'Your email address'} className={'signup-input'} />
          <Button color={'black'} radius={8} weight={600} text={'Sign up'} w={85} className={'signup'} onClick={handleSignup} />
        </div>
      </NewsLetter>
      <Copyright>
        <p>© 2023, M-Verse Co. Ltd.</p>
        <div className="utilNav">
          <a href={'https://url.kr/ns6bue'} target={'_blank'} rel="noreferrer">
            Privacy Policy
          </a>
          <span>|</span>
          <a href={'https://url.kr/uzw7nm'} target={'_blank'} rel="noreferrer">
            Terms of Service
          </a>
        </div>
      </Copyright>
    </Footer>
  );
};

const Footer = styled.div`
  padding: 30px 20px;
  border-top: 1px solid #eaeaea;
  background: #fff;

  h2 {
    color: #000;
    font-size: 14px;
    line-height: 18px;
  }
`;

const Social = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 10px;

  svg {
    width: 32px;
    height: 32px;
    filter: invert(1);
  }
`;

const NewsLetter = styled.div`
  margin-top: 24px;

  h2 {
    font-size: 10px;
    line-height: 14px;
  }

  .input-wrap {
    position: relative;
    margin-top: 6px;
    padding-right: 100px;

    .signup-input {
      background: #fff;

      input::placeholder {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .signup {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

const Copyright = styled.div`
  margin-top: 30px;
  p {
    font-size: 10px;
    line-height: 14px;
  }

  .utilNav {
    font-size: 10px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.5);

    span {
      display: inline-block;
      margin: 0 4px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const Location = styled(Marquee)`
  padding: 16px 20px;
  background-color: #404040;

  dl {
    position: relative;
    max-width: 200px;
    margin: 0 15px;

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: -16px;
      width: 4px;
      height: 60px;
      background-color: #fff;
    }

    dt,
    dd {
      color: #fff;
    }

    dt {
      width: 100%;
      font-size: 12px;
      margin-bottom: 7px;
      height: 45px;
      line-height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    dd {
      font-size: 10px;
    }
  }
`;
