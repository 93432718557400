import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { View, Text } from 'styles';
import Input from 'ui/input/Input';
import SelectUI from 'ui/select/Select';
import { cryptoMarket, setDigit } from 'utils';
import { ReactComponent as SelectDownArrowIcon } from 'assets/svg/downArrow.svg';
import ButtonUI from 'ui/button/Button';
import ModalStyles from 'styles/modal/Modal';
import { DURATION_LIST } from 'api/mock/ctg';
import SelectButtonUI from 'ui/select-button/SelectButton';
import { abi } from 'views/nft-item/components/price-item/abi';
import { ethers } from 'ethers';
import { useSelector, useDispatch } from 'react-redux';
import APIService from 'api';
import { marketAbi } from './marketAbi';
import moment from 'moment';
import NftLoadingComponent from 'styles/nft-loading/NftLoading';

const apiService = new APIService();
const PriceItemComponent: React.FC<any> = ({ usd, walletAddress, tokenUri, uid, quantity, ...props }) => {
  const navigate = useNavigate();
  const { profile } = useSelector((store: IStore) => store.System);

  const { Option } = Select;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [curDuration, setCurDuration] = React.useState(30);
  const [curDurationText, setCurDurationText] = React.useState('1 month');
  const [openDurationModal, setOpenDurationModal] = React.useState(false);
  const [price, setPrice] = React.useState('');

  const [usdt, setUsdt] = React.useState('0');

  const getUsdtEth = async () => {
    const result = await apiService.usdtEth();
    setUsdt(result.last);
  };

  const changePrice = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setPrice(value);
  };

  const closeModal = () => {
    setOpenDurationModal(false);
  };

  const onChangeDuration = (text: string, value: number) => {
    setCurDuration(value);
    setCurDurationText(text);
    setOpenDurationModal(false);
  };

  // const marketContractAddress = '0x32fB9f1E3C267800C6190a882040B8995Ae5a0B9';
  // const testContractAddress = '0x3C533802c0292150AE866796Dc119A9f312B8216';

  /** contract 주소 변경 내역 **/
  // Besu Ver1 : minting contract address
  // const mintingContractAddress = '0xC9Bc439c8723c5c6fdbBE14E5fF3a1224f8A0f7C';

  // Besu Ver2 : minting contract address
  const mintingContractAddress = '0xa50a51c09a5c451C52BB714527E1974b686D8e77';

  // Besu Ver1 : buy contract address
  // const testContractAddress = '0xe135783649BfA7c9c4c6F8E528C7f56166efC8a6';

  // Besu Ver2 : buy contract address
  const testContractAddress = '0x42699A7612A82f1d9C36148af9C77354759b210b';
  /** contract 주소 변경 내역 **/

  /**
   *
   * @param contractAddress nft를 민팅할 스마트 컨트랙트
   * @param tokenUri 토큰의 메타데이터를 담고있는 ipfs 주소이거나 url
   * @param price nft의 가격 1이상부터 가능합니다.
   * @param quantity 발행하는 nft의 개수
   *
   * * @example
   * const testContractAddress = '0xCf04ddF3D4b7C61664215566F3065487A8dC1CAB';
   * handleSellNFT(testContractAddress, 'tokenUri', '1', 10)
   */
  const handleSellNFT = async (contractAddress: string, tokenUri: string, price: string, quantity: number) => {
    if (profile.walletAddress === walletAddress && tokenUri) {
      setIsLoading(true);
      try {
        let metaProvider;
        if (window.web3) {
          metaProvider = window.web3.currentProvider;
        } else if (typeof window.ethereum !== 'undefined') {
          await window.ethereum.send('eth_requestAccounts');
          metaProvider = window.ethereum;
        } else {
          throw new Error('No web3 provider detected');
        }

        const endAt = moment().add(curDuration, 'days').format('YYYY-MM-DD');
        console.log(endAt);
        const provider = new ethers.providers.Web3Provider(metaProvider);
        const signer = await provider.getSigner();
        console.log(signer, 'signer');
        const token = new ethers.Contract(contractAddress, abi, signer);
        console.log(token, 'token');
        // console.log(ethers.utils.parseEther('12'), 'ethers.utils.parseEther(price)');
        console.info('[send data info - tokenUri] : ', tokenUri);
        console.info('[send data info - price] : ', price);
        console.info('[send data info - price] : ', ethers.utils.parseEther(`${price}`));
        console.info('[send data info - quantity] : ', quantity);
        const mintAndListTxHash = await token.mintAndList(tokenUri, ethers.utils.parseEther(`${price}`), quantity);
        console.log(mintAndListTxHash);

        if (mintAndListTxHash.hash) {
          const transactionHash = mintAndListTxHash.hash;

          // quantity, endAt, price, transactionHash
          const mintingResult = await apiService.mintingComplete(uid, { quantity, endAt, price, transactionHash });
          console.log(mintingResult, 'mintingResult');
          navigate('/profile');
        }
      } catch (e: any) {
        setIsLoading(false);
        console.log(e);
      }
    } else {
      setIsLoading(false);
      alert('해당 NFT Create가 아닙니다.');
    }
  };

  /**
   *
   * @param mintingContractAddress market의 contract addresss를 넣어줍니다.
   * 현재 민팅되고 판매등록된 NFT의 판매정보들을 가져옵니다.
   *
   * * @example
   * const mintingContractAddress = '0xCf04ddF3D4b7C61664215566F3065487A8dC1CAB';
   * getSaleList(mintingContractAddress)
   */
  const getSaleList = async (mintingContractAddress: string) => {
    try {
      let metaProvider;
      if (window.web3) {
        metaProvider = window.web3.currentProvider;
      } else if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.send('eth_requestAccounts');
        metaProvider = window.ethereum;
      } else {
        throw new Error('No web3 provider detected');
      }
      const provider = new ethers.providers.Web3Provider(metaProvider);
      const signer = await provider.getSigner();
      const token = new ethers.Contract(mintingContractAddress, marketAbi, signer);
      const saleList = await token.saleList();
      // console.log(saleList);
      // console.log(saleList[0].price);
      // console.log(ethers.utils.formatEther(saleList[0].price));
      return saleList;
    } catch (e: any) {
      console.log(e);
    }
  };

  /**
   *
   * @param mintingContractAddress market의 contract addresss를 넣어줍니다.
   * @param contractAddress nft를 민팅한 cotract의 address를 넣어줍니다.
   * @param saleId 위의 nft판매가 등록된 판매 id를 넣어줍니다.
   * @param quantity 구매할 nft의 양만큼 넣어줍니다.
   *
   * @example
   * const mintingContractAddress = '0xCf04ddF3D4b7C61664215566F3065487A8dC1CAB';
   * const testContractAddress = '0xCf04ddF3D4b7C61664215566F3065487A8dC1CAB';
   * buyNFT(mintingContractAddress, testContractAddress, '1', 3)
   */
  const buyNFT = async (mintingContractAddress: string, contractAddress: string, saleId: string, quantity: number) => {
    try {
      let metaProvider;
      if (window.web3) {
        metaProvider = window.web3.currentProvider;
      } else if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.send('eth_requestAccounts');
        metaProvider = window.ethereum;
      } else {
        throw new Error('No web3 provider detected');
      }
      const provider = new ethers.providers.Web3Provider(metaProvider);
      const signer = await provider.getSigner();
      const token = new ethers.Contract(mintingContractAddress, marketAbi, signer);
      const list: any = await getSaleList(mintingContractAddress);
      // const price = ethers.utils.formatEther(list[Number(saleId) - 1].price);
      // const buyHash = await token.buyNFT(contractAddress, saleId, quantity, { value: ethers.utils.parseEther('1.0') });
      const buyHash = await token.buyNFT(contractAddress, saleId, quantity, { value: list[Number(saleId) - 1].price * quantity });
      console.log(buyHash);
    } catch (e: any) {
      console.log(e);
    }
  };

  /**
   *
   * @param contractAddress nft를 민팅한 cotract의 address를 넣어줍니다.
   *
   * 자신의 소유인 nft들의 객체 입니다
   * @example {1: '1', 3: '1'} // {nftId : amount}
   */
  const myNFT = async (contractAddress: string) => {
    console.info('[contractAddress]', contractAddress);
    try {
      let metaProvider;
      if (window.web3) {
        console.info('[Type 01]');
        metaProvider = window.web3.currentProvider;
      } else if (typeof window.ethereum !== 'undefined') {
        console.info('[Type 02]');
        await window.ethereum.send('eth_requestAccounts');
        metaProvider = window.ethereum;
      } else {
        console.info('[Type 03]');
        throw new Error('No web3 provider detected');
      }
      const provider = new ethers.providers.Web3Provider(metaProvider);
      console.info('[provider] : ', provider);
      const signer = await provider.getSigner();
      console.info('[signer] : ', signer);
      const token = new ethers.Contract(contractAddress, abi, signer);
      console.info('[TOKEN] : ', token);
      // const price = ethers.utils.formatEther(list[Number(saleId) - 1].price);
      // const buyHash = await token.buyNFT(contractAddress, saleId, quantity, { value: ethers.utils.parseEther('1.0') });
      const allNFTHash = await token.allNFTs();
      console.info('[allNFTHash] : ', allNFTHash);
      const myNFT: any = {};
      allNFTHash.map((el: any, idx: number) => {
        if (Number(el.toString())) {
          myNFT[idx + 1] = el.toString();
        }
      });
      console.log('[myNFT] : ', myNFT);
    } catch (e: any) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    profile?.walletAddress && myNFT(mintingContractAddress);
    getUsdtEth();
  }, []);

  return (
    <StyledWrap>
      <View spacing={32} direction="column" block padding="0 20">
        <Input
          type="number"
          label="Price per unit"
          block
          value={price}
          onChange={changePrice}
          placeholder="Amount"
          usd={<Text size="xs">${setDigit(Number(usdt) * Number(price), 2, true, true)}</Text>}
          prefix={<img style={{ marginRight: 20, width: 15, height: 26, objectFit: 'cover' }} src={cryptoMarket(1)} />}
          suffix={
            <View pl={16} flex={0}>
              <Text size="sm" weight="bold">
                ETH
              </Text>
            </View>
          }
        />

        <SelectButtonUI label="Duration" onClick={() => setOpenDurationModal(true)}>
          {curDurationText}
        </SelectButtonUI>
      </View>

      <View border="1px solid #E6E8EC" borderPos="top" mt={32} padding="8px 20px 20" direction="column">
        <View direction="column" mb={32}>
          <Text size="xs" weight="bold">
            Fees
          </Text>
          <Text size="xxs" color="#7D7D7D">
            Service Fee : 0 %, Listing is free. Once sold, the following fees will be deducted.
          </Text>
          <Text size="xxs" color="#7D7D7D">
            Creator Fee: 0 %
          </Text>
        </View>

        <ButtonUI full color="black" onClick={() => handleSellNFT(mintingContractAddress, tokenUri, price, quantity)}>
          Complete listing
        </ButtonUI>
      </View>

      <ModalStyles w={'90%'} open={openDurationModal} isCenter={true} handleCancle={closeModal} type="select">
        {DURATION_LIST.map((item, idx) => (
          <StyledOptionItem key={idx} active={curDurationText === item.text} className="select-item" onClick={() => onChangeDuration(item.text, item.value)}>
            {item.text}
          </StyledOptionItem>
        ))}
      </ModalStyles>
      {isLoading && <NftLoadingComponent />}
    </StyledWrap>
  );
};

export default PriceItemComponent;

const StyledWrap = styled.div`
  width: 100%;
`;

const StyledOptionItem = styled.div<{ active?: boolean }>`
  color: ${p => (p.active ? '#0075FF' : '#808080')} !important;
`;
