import React from 'react';
import APIService from 'api';
const apiService = new APIService();

export const useHome = () => {
  const [scamAlert, setScamAlert] = React.useState<any[]>([]);

  const getScamAlert = async () => {
    const result = await apiService.getScamList({ size: 10 });
    setScamAlert(result.list);
  };

  React.useEffect(() => {
    getScamAlert();
  }, []);

  const dummyArticleList = [
    {
      id: 0,
      title: `These Bored Apes Will Meli in your mouth:
      M&M’s Releases NFT-Inspired Candy`,
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent placerat sem a consectetur eleifend.`,
      tags: ['Big', 'ReleasesNFT', 'GOODNFT', 'BigPrice'],
      url: 'www.aaasdsas.com/ssasasas/22222-121212/121212...',
      created: 'mm/dd/yy',
      weekend: 5,
      month: 'August'
    },
    {
      id: 1,
      title: `These Bored Apes Will Meli in your mouth:
      M&M’s Releases NFT-Inspired Candy`,
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent placerat sem a consectetur eleifend.`,
      tags: ['Big', 'ReleasesNFT', 'GOODNFT', 'BigPrice'],
      url: 'www.aaasdsas.com/ssasasas/22222-121212/121212...',
      created: 'mm/dd/yy',
      weekend: 4,
      month: 'May'
    }
  ];

  return { dummyArticleList, scamAlert };
};
