import React from 'react';
import styled, { css } from 'styled-components';
import { View, Text, Button } from 'styles';
import { mappingWalletLogo } from 'utils';

interface WalletItemProps extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'value' | 'name'> {
  value: WalletListUnion;
  name: string;
  curWallet: string;
  setCurWallet: any;
}

const WalletItemComponent: React.FC<WalletItemProps> = ({ name, value, curWallet, setCurWallet, ...props }) => {
  return (
    <>
      {curWallet !== value && (
        <StyledWrap {...props}>
          <View justify="space-between" align="center" spacing={17} block>
            <img className="img-logo" src={mappingWalletLogo(value)} />
            <Text size="md" weight="semi-bold">
              {name}
            </Text>
          </View>
        </StyledWrap>
      )}
    </>
  );
};

export default WalletItemComponent;

const StyledWrap = styled.div`
  padding: 0 25px;
  opacity: 0.25;

  .img-logo {
    width: 24px;
    height: 22px;
    object-fit: contain;
  }
`;
