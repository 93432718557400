import { Upload, Button as UploadButton, UploadProps } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Input, StepItem, View, WrapIcon, Text, Textarea } from 'styles';
import { ReactComponent as UploadIcon } from 'assets/svg/upload.svg';
import { LoadingOutlined } from '@ant-design/icons';

import APIService, { apiBaseUrl } from 'api';
import cookie from 'js-cookie';

const apiService = new APIService();

import SubHeader from 'components/Header/SubHeader';

const StepTwoView: React.FC<any> = () => {
  const navigation = useNavigate();
  const { uid } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState([{ imageUrl: '', price: '', description: '', priceKind: '1' }]);
  const uploadStatus = isLoading ? <LoadingOutlined /> : <UploadIcon />;

  const handleSubmit = async () => {
    const checkItems = items.filter(item => item.imageUrl === '' || item.price === '');

    if (checkItems.length === 0) {
      const resultItem = await apiService.createItem({ uid, json: { items } });

      if (resultItem === 'Success') {
        alert('Upload complete');
        navigation('/profile');
      }
    } else {
      alert('Please Image Upload & Price Enter');
    }
  };

  const handleChange = (info: any, itemIndex: number) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      const tempUpload = [...items];
      tempUpload[itemIndex].imageUrl = info.file.response.data.image;

      setItems(tempUpload);

      setIsLoading(false);
    }
  };

  const createFileUploadProps: UploadProps = {
    name: 'image',
    listType: 'picture-card',
    showUploadList: false,
    action: `${apiBaseUrl}/api/market/upload`,
    headers: {
      Authorization: `Bearer ${cookie.get('accessToken') || ''}`
    }
  };

  const handlePrice = (e: any, index: number) => {
    if (e.target.value) {
      const tempPrice = [...items];
      tempPrice[index].price = e.target.value;

      setItems(tempPrice);
    }
  };

  const handleDescription = (e: any, index: number) => {
    if (e.target.value) {
      const tempPrice = [...items];
      tempPrice[index].description = e.target.value;

      setItems(tempPrice);
    }
  };

  const handleAddItem = () => {
    const tempItems = [...items];
    tempItems.push({ imageUrl: '', price: '', description: '', priceKind: '1' });
    setItems(tempItems);
  };

  React.useEffect(() => {}, []);

  return (
    <Wrap>
      <SubHeader title={'Create'} />
      <StepItem num={2} title={'Item Upload'} />
      <View direction="column" spacing={26}>
        {items.map((item: any, index: number) => (
          <>
            <View direction="column">
              <StyledUpload>
                <Upload {...createFileUploadProps} onChange={(info: any) => handleChange(info, index)}>
                  <UploadButton
                    icon={
                      <div>
                        <WrapIcon w={60} h={60}>
                          {uploadStatus}
                        </WrapIcon>
                        <p>PNG, GIF, WEBP, MP4 or ....</p>
                        {items[index].imageUrl.length > 0 && <PreviewLarge src={items[index].imageUrl} />}
                      </div>
                    }
                  />
                </Upload>
              </StyledUpload>
            </View>

            <View direction="column">
              <StyledForm>
                <li>
                  <StyledPrice>
                    <View direction="column" align="center" spacing={10}>
                      <Text size="xs" color="#7B7B7B" weight="bold">
                        DESCRIPTION
                      </Text>
                      <Textarea
                        block
                        h={200}
                        last={true}
                        bar={true}
                        placeholder={'Please provide a detailed description more than 20 letters.'}
                        length={20}
                        onChange={(e: any) => handleDescription(e, index)}
                      />
                    </View>
                    <View direction="column" align="center" spacing={10}>
                      <Text size="xs" color="#7B7B7B" weight="bold">
                        PRICE
                      </Text>
                      <Input
                        block
                        last={true}
                        bar={false}
                        type="number"
                        onChange={(e: any) => handlePrice(e, index)}
                        prefix={
                          <img
                            style={{ width: 30, height: 30, marginRight: 9, borderRadius: '50%', overflow: 'hidden' }}
                            src="https://user-images.githubusercontent.com/45615584/182802711-f4516494-198d-46a5-b97c-0b836fe6652b.png"
                          />
                        }
                        suffix={<p style={{ fontWeight: 700, fontSize: 12, color: 'rgba(0,0,0,0.5)' }}>ETH</p>}
                      />
                    </View>
                  </StyledPrice>
                </li>
              </StyledForm>
              <div className={'add-item'}>
                <span onClick={handleAddItem}>+</span>
              </div>
            </View>
          </>
        ))}
        <Button className={'item-save'} color="border" full radius={10} text="SAVE" h={52} onClick={handleSubmit} />
      </View>
    </Wrap>
  );
};

export default StepTwoView;

const Wrap = styled.div`
  padding: 20px;
  background: #f5f5f5;

  .item-save {
    margin-top: 70px;
  }

  dl {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: flex-start;
    margin-bottom: 20px;

    dt {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000;
      color: #fff;
      border-radius: 50%;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      margin-right: 15px;
    }
    dd {
      h2 {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #000000;
        margin-bottom: 4px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #6f6f6f;
      }
    }
  }

  .add-item {
    position: relative;
    margin-top: -30px;
    text-align: center;
    & span {
      display: inline-block;
      width: 60px;
      height: 60px;
      font-size: 39px;
      color: #808080;
      text-align: center;
      cursor: pointer;
      border: 1px solid #eae9e9;
      background: #fff;
      border-radius: 50%;
    }
  }
`;

const StyledForm = styled.ul`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 50px;

  & > li {
    & + & {
      margin-top: 20px;
    }
  }
`;

const StyledPrice = styled.div`
  display: flex;
  gap: 30px;
  flex: 2;
  flex-direction: column;
`;

const StyledUpload = styled.div<{ height?: number }>`
  position: relative;

  .ant-upload {
    width: 100%;
    height: ${p => (p.height ? p.height : 335)}px;
    margin-bottom: 30px;

    .ant-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #ffffff;
      border: 1px solid #efefef;
      box-sizing: border-box;
      box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.1);
      border-radius: 17px;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > p {
          margin-top: 21px;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.01em;
          color: #6f6f6f;
        }
      }
    }
  }
`;

const PreviewLarge = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  max-height: 100%;
  border-radius: 17px;
  z-index: 0;
  opacity: 0.3;
  object-fit: cover;
`;
