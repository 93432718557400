import React from 'react';
import styled from 'styled-components';
import View from 'styles/view';

interface PriceProps {
  icon?: any;
  iconW?: number;
  iconH?: number | 'auto';
  price?: any;
  fontSize?: number;
  timestamp?: any;
  prefix?: any;
  suffix?: any;
  suffixStyle?: any;
  className?: any;
  color?: any;
  extra?: any;
  style?: any;
  weight?: number;
  preSuffix?: any;
  justify?: 'center' | 'flex-start';
}

const PriceStyles: React.FC<PriceProps> = ({ extra, preSuffix, suffixStyle, icon, price, timestamp, prefix, suffix, ...props }) => {
  return (
    <Price className={'nftmoa-price-container'} {...props}>
      <View align="flex-end" block>
        <View align="center">
          {icon && <img src={icon} className={'logo'} />}
          <p className={'price'} {...props}>
            {prefix && prefix}
            {price}
            {preSuffix && preSuffix}
          </p>
        </View>
        {timestamp && <span className={`timestamp ${timestamp.indexOf('up') >= 0 ? 'up' : ''}`}>({timestamp})</span>}
        {suffixStyle ? <div style={suffixStyle}>{suffix}</div> : suffix && suffix}
      </View>
      {extra && <div className="extra-item">{extra}</div>}
    </Price>
  );
};

export default PriceStyles;

PriceStyles.defaultProps = {
  fontSize: 16,
  iconW: 14,
  iconH: 'auto'
};

const Price = styled.div<PriceProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: ${p => (p.justify ? p.justify : 'flex-start')};
  height: 26px;

  img.logo {
    display: flex;
    margin-right: 5px;
    width: ${p => p.iconW}px;
    height: ${p => p.iconH}px;
    height: ${p => p.iconH === 'auto' && 'auto'};
    object-fit: cover;
  }

  .price {
    font-weight: 700;
    ${p => p.weight && `font-weight: ${p.weight}`};
    font-size: ${p => p.fontSize}px;
    color: ${p => (p.color ? p.color : '#000')};
    word-break: break-all;
    letter-spacing: -1px;
  }

  .extra-item {
    position: absolute;
    bottom: -15px;
    right: 0;
  }

  .timestamp {
    display: inline-block;
    bottom: 0;
    margin-left: 3px;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.01em;
    color: #0094ff;
    white-space: nowrap;
    display: flex;
    align-self: flex-end;

    &.up {
      color: #f01e2c;
    }
  }
`;
