import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import SubHeader from 'components/Header/SubHeader';
import { ARTICLES_DATE, ARTICLES_LIST } from 'api/mock/articles';
import ListCardComponent from './components/ListCard';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/arrow-right.svg';
import { View, Text } from 'styles';
import APIService from 'api/index';
import { View as View2 } from 'ui/view/View';
import { useHome } from 'views/Home/useHome';
import ArticleItem from './components/articleItem/ArticleItem';

const apiService = new APIService();
const ArticlesListPage = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [articleList, setArticleList] = React.useState<any>([]);
  const [articleDetail, setArticleDetail] = React.useState<any>();
  const [openDate, setOpenDate] = React.useState(false);
  const { dummyArticleList } = useHome();

  const dismissDateSelect = () => {
    setOpenDate(false);
  };

  const getData = async () => {
    await apiService.putArticleDetailCount({ uid });

    const articleDetail = await apiService.getArticleDetail({ uid });
    setArticleDetail(articleDetail);
  };

  const selectNews = (uid: number) => {
    navigate(`/articles/${uid}`);
    dismissDateSelect();
  };

  React.useEffect(() => {
    getData();
  }, [uid]);

  return (
    <Wrap>
      <SubHeader title={`Article & Disclosure`} isBack={false} isBackHome />
      <View align="center" justify="center" className={'navigate-news'} block>
        {articleDetail?.prevArticleUid && <ArrowLeftSVG onClick={() => navigate(`/articles/${articleDetail?.prevArticleUid}`)} />}
        <View className="view-date" onClick={setOpenDate} block align="center" justify="center">
          <Text size="md" align="center" weight="bold">
            {articleDetail?.title}
          </Text>
        </View>
        {articleDetail?.nextArticleUid && <ArrowRightSVG onClick={() => navigate(`/articles/${articleDetail?.nextArticleUid}`)} />}
      </View>
      <View2 direction={'column'} spacing={10} padding={['pt-8']}>
        {articleDetail && articleDetail?.detail.map((item: any, idx: number) => <ArticleItem type={'card'} key={idx} {...item} more />)}
      </View2>

      <BottomSheet open={openDate} onDismiss={dismissDateSelect} snapPoints={({ minHeight }) => minHeight}>
        <DateSheet>
          {articleDetail?.tenArticle2List?.length > 0 &&
            articleDetail.tenArticle2List.map((item: any, idx: number) => (
              <div className={'item-date'} key={idx} onClick={() => selectNews(item?.uid)}>
                <Text size="md" weight="bold" align="center">
                  {item?.title}
                </Text>
              </div>
            ))}
        </DateSheet>
      </BottomSheet>
    </Wrap>
  );
};

export default ArticlesListPage;

const Wrap = styled.div`
  padding: 30px 20px;

  .view-date {
    max-width: 275px;
    padding: 11px 0;
    border: 1px solid #e6e8ec;
    border-radius: 12px;
    background-color: #f9f9f9;
    cursor: pointer;
  }

  .article-item-container {
    width: 100%;
  }

  .navigate-news {
    svg {
      position: absolute;
      top: 12px;
      left: 0;
      &:last-child {
        left: auto;
        right: 0;
      }
    }
  }
`;

const DateSheet = styled.div`
  .item-date {
    padding: 11px 0;
    text-align: center;
    & + div {
      border-top: 1px solid #e4e4e4;
    }
  }
`;
