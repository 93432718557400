import React from 'react';
import styled from 'styled-components';
import { View, Text, AvatarName, Avatars, Labelbox, Price, Button, Tab } from 'styles';
import { ReactComponent as LikeIcon } from 'assets/svg/like-full.svg';
import { ReactComponent as UnLikeIcon } from 'assets/svg/unlike-full.svg';
import { ReactComponent as ViewIcon } from 'assets/svg/view.svg';
import { ReactComponent as FavoriteIcon } from 'assets/svg/favorite-full.svg';
import { ReactComponent as RankIcon } from 'assets/svg/rank-up.svg';
import { ReactComponent as ShareIcon } from 'assets/svg/share.svg';
import { setDigit, nftMarketSite, cryptoMarket } from 'utils';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal } from 'antd';
import SnsShare from 'components/SnsShare';
import APIService from 'api';
import noPrice from 'assets/common/noprice.gif';
import noNft from 'assets/common/noNft.png';
const apiService = new APIService();

interface DetailItemProps {
  nftImg?: string;
  groupName?: string;
  like?: number | string;
  view?: number | string;
  isLike?: boolean;
  usd?: number | string;
  title?: string;
  tags?: string[];
  avatar?: string;
  desc?: string;
  relatedData?: any;
  pubkey?: string;
  amount?: number | string;
  tokenIcon?: string;
  priceRate?: number;
  groupLogo?: string;
  siteUrl?: string;
  price?: string;
}

const DetailItemComponent: React.FC<any> = ({
  uid,
  imageUrl,
  name,
  memberName,
  site,
  siteUrl,
  market,
  groupLogo,
  amount,
  contract,
  priceRate,
  tokenIcon,
  isLike,
  title,
  pubkey,
  tags,
  avatar,
  view,
  like,
  usd,
  owned,
  groupName,
  price,
  myFavoriteFlag,
  animationUrl,
  priceKind,
  description,
  priceUsd,
  ...props
}) => {
  const [likeStatus, setLikeStatus] = React.useState<boolean>(myFavoriteFlag);
  const [shareOpen, setShareOpen] = React.useState<boolean>(false);
  const handleLike = async () => {
    const resultMessage = await apiService.postNftFavorite({ uid });

    if (resultMessage === 'Success') {
      setLikeStatus(true);
    }
  };

  const handleUnLike = async () => {
    const resultMessage = await apiService.postNftUnFavorite({ uid });
    if (resultMessage === 'Success') {
      setLikeStatus(false);
    }
  };

  const shortening = (v = '') => v.slice(0, 4) + '....' + v.slice(v.length - 4, v.length);
  // const shortening = (v = '') => v.slice(0, 8) + '....';
  const handleImgError = (e: any) => {
    e.target.onerror = null;
    e.target.src = noNft;
  };

  return (
    <Wrap {...props}>
      <View direction="column" padding="20px 20px 0" block>
        <View direction="column" spacing={14}>
          {imageUrl && (
            <StyledNftImage nftImg={imageUrl}>
              {!(animationUrl && animationUrl.includes('embed')) && imageUrl.includes('mp4') && <video src={imageUrl} preload={'auto'} />}
              {!(animationUrl && animationUrl.includes('embed')) && !imageUrl.includes('mp4') && <img src={imageUrl} alt={name} onError={handleImgError} />}

              {animationUrl && animationUrl.includes('embed') && (
                <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" height="100%" sandbox="allow-scripts" src={animationUrl} />
              )}
              <button className="btn-like">{likeStatus ? <LikeIcon onClick={handleUnLike} /> : <UnLikeIcon onClick={handleLike} />}</button>
            </StyledNftImage>
          )}
          <View direction="column" spacing={10}>
            <View align="stretch" spacing={7}>
              {groupLogo && <LogoIcon src={groupLogo} />}
              <h2 className={'nft-title'}>{name}</h2>
              <View align="center" spacing={20} className={'right-flex'}>
                <View align="center" spacing={4}>
                  <ViewIcon />
                  <Text size="xs">{26}</Text>
                </View>
                <View align="center" spacing={4}>
                  <FavoriteIcon />
                  <Text size="xs">{7}</Text>
                </View>
              </View>
            </View>
            <View spacing={10}>
              {tags?.map((item: any, idx: number) => (
                <Text size="xs" color={'rgba(0, 0, 0, 0.5)'} key={idx}>
                  #{item}
                </Text>
              ))}
            </View>
            <View spacing={10}>
              <Text size="xs" color={'rgba(0, 0, 0, 0.5)'}>
                {description}
              </Text>
            </View>
          </View>
        </View>
        {/* <View align="center" mt={20}>
          <AvatarName
            avatar={avatar}
            gap={13}
            name={name}
            w={50}
            h={50}
            desc={
              <View align="center" spacing={20}>
                <View align="center" spacing={4}>
                  <ViewIcon />
                  <Text size="xs">{view}</Text>
                </View>
                <View align="center" spacing={4}>
                  <FavoriteIcon />
                  <Text size="xs">{view}</Text>
                </View>
              </View>
            }
          />
        </View> */}
      </View>

      <OwnedList>
        <View justify="space-between">
          <Text color="#BFBFBF" size="xs">
            Owned by <em style={{ color: '#8F00FF' }}>{shortening(contract)}</em> &amp; 2 others
            <IconWrap>
              <CopyToClipboard text={`${contract}`} onCopy={() => alert('Copied.')}>
                <CopyOutlined style={{ fontSize: '16px', color: '#888' }} />
              </CopyToClipboard>
            </IconWrap>
          </Text>
          <View>{owned && owned.map((item: any, idx: number) => <Avatars key={idx} avatar={item.avatar} />)}</View>
        </View>
      </OwnedList>

      <View direction="column" padding={'0 20'} mt={18}>
        <Labelbox fontSize={12} color={'#808080'} label="current price">
          <View align="center" justify="space-between">
            <View>
              {price && (
                <Price
                  price={price}
                  icon={cryptoMarket(priceKind || market)}
                  iconW={16}
                  iconH={27}
                  fontSize={30}
                  suffix={
                    <View align="center" pl={6} spacing={7}>
                      <RankIcon width={16} height={16} />
                      <Text size="xs" color={'#0094FF'}>
                        {Math.sign(priceRate) === 1 ? '+' : '-'}
                        {priceRate}% Last week
                      </Text>
                    </View>
                  }
                />
              )}
              {/* {!price && (
                <a href={siteUrl} target={'_blank'} rel="noreferrer">
                  <Button text={'OPEN SITE'} rect color={'black'} h={50} />
                </a>
              )} */}
            </View>
            {price && priceUsd && (
              <Text size="xl" isLh={false}>
                ${setDigit(priceUsd, 0, false, true)}
              </Text>
            )}
            {!price && (
              <NoPrice>
                <img src={noPrice} />
              </NoPrice>
            )}
          </View>
        </Labelbox>

        <View align="center" spacing={9} mt={27}>
          <Button text={'BUY NOW'} rect color={'black'} full h={50} onClick={() => (siteUrl ? window.open(siteUrl) : alert('Will be launch that DEX each other personal trading '))} />
          <Button text={<ShareIcon />} rect color={'borderThin'} w={50} h={48} onClick={() => setShareOpen(true)} />
        </View>
      </View>
      <Modal title={'Share'} visible={shareOpen} onCancel={() => setShareOpen(false)} footer={[]}>
        <SnsShare />
      </Modal>
    </Wrap>
  );
};

export default DetailItemComponent;

const Wrap = styled.div`
  .nft-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .right-flex {
    margin-left: auto;
  }
`;

const StyledNftImage = styled.div<DetailItemProps>`
  position: relative;
  min-width: 335px;
  width: 100%;
  height: 365px;
  border-radius: 6px;
  background: url(${p => p.nftImg}) no-repeat center center;
  background-size: cover;

  img {
    object-fit: cover;
  }

  video,
  iframe {
    width: 100%;
    height: 100%;
  }

  .btn-like {
    position: absolute;
    right: 20px;
    bottom: 20px;
    filter: drop-shadow(2px 3px 3px #666);
  }
`;

const OwnedList = styled.div`
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  padding: 12px 20px;
  margin-top: 19px;
`;

const LogoIcon = styled.img`
  width: 20px;
  height: 24px;
  object-fit: contain;
`;

const IconWrap = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  border: 1px solid #eae9e9;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
`;

const NoPrice = styled.div`
  img {
    width: auto;
    height: 30px;
  }
`;
