import React, { useEffect } from 'react';
import styled from 'styles/theme';
import { AuthorName, Marketplace, Price } from 'styles';
import { useNavigate } from 'react-router-dom';
import APIService from 'api/index';

import { cryptoMarket } from 'utils';
const apiService = new APIService();

const RankItem: React.FC<any> = ({
  rank,
  beforeRank,
  siteName,
  name,
  seller,
  author,
  cryptoIcon,
  imageUrl,
  changeRate,
  nftmarket,
  nftmarketIcon,
  mainnet,
  mainnetIcon,
  price,
  timestamp,
  siteCodeUid,
  exlink,
  ...props
}) => {
  const navigate = useNavigate();

  const [uid, setUid] = React.useState<any>();

  const moveSearch = () => {
    navigate(`/search/result?name=${encodeURIComponent(name)}`);
    // navigate(`/item/${name}`);
    // navigate('/item/' + siteCodeUid);
  };

  // const getUid = async () => {
  //   const resultNftList = await apiService.getNftList({ searchValue: name });

  //   if (resultNftList.list && resultNftList.list.length > 0) {
  //     setUid(resultNftList.list[0].uid);
  //   }
  // };

  // useEffect(() => {
  //   getUid();
  // }, []);

  return (
    <Wrap {...props} onClick={!exlink ? moveSearch : props.onClick}>
      <Rank>
        <strong>{rank}</strong>
      </Rank>
      <Thumbnail>
        <img src={imageUrl} />
      </Thumbnail>
      <Info>
        <div>
          <h2>{name}</h2>
          <AuthorName name={seller} center={false} />
        </div>
        <ul className="wrap-list">
          <li>
            <Price icon={mainnetIcon || cryptoMarket(1)} price={price} iconW={7} iconH={12} fontSize={14} timestamp={changeRate} />
          </li>
          <li>{siteName}</li>
        </ul>
      </Info>
    </Wrap>
  );
};

export default RankItem;

const Wrap = styled.div`
  display: flex;
  min-height: 100px;
  background: #ffffff;
  box-sizing: border-box;

  & + div {
    border-top: 1px solid #eaeaea;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Rank = styled.div`
  flex-basis: 45px;

  strong {
    font-size: 16px;
    line-height: 26px;
    font-weight: 800;
  }

  > hr {
    width: 8px;
    height: 1px;
    background: #dddddd;
  }
`;

const Thumbnail = styled.div`
  flex-basis: 60px;

  img {
    width: 60px;
    height: 60px;
  }
`;

const Info = styled.div`
  align-items: start !important;
  justify-content: space-between !important;
  flex-basis: calc(100% - 105px);
  padding: 18px 12px;
  line-height: 18px;

  h2 {
    color: #000;
    font-size: 14px;
    font-weight: bold;
  }

  ul.wrap-list {
    display: flex;
    width: 100%;
    margin: 0 -8px;
    & > li {
      position: relative;
      padding: 0 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      .nftmoa-price-container {
        display: block;
        text-align: left;

        .price {
          text-align: left;
        }
        .timestamp {
          display: block;
          width: 100%;
        }
      }

      & + li {
        margin: 0 0 0 auto;
        font-size: 11px;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 1px;
          height: 7px;
          background-color: rgba(0, 0, 0, 0.2);
          transform: translateY(-50%);
        }
      }
    }
  }

  div.name {
    text-overflow: initial !important;
    white-space: initial;
    word-break: break-word;
  }
`;
