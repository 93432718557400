import React from 'react';
import styled, { css } from 'styled-components';
import avatar_default from 'assets/profile/default.png';
import IconButtonUI from 'ui/icon-button/IconButton';
import { ReactComponent as OfficialIcon } from 'assets/icon/official.svg';

export interface AvatarProps {
  imgUrl?: string;
  w?: string | number;
  h?: string | number;
  extra?: 'badge' | 'setting';
  isOnline?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  borderSize?: number;
  onClick?: VoidFunction;
  isBorder?: boolean;
  radius?: number;
}

const AvatarUI: React.FC<AvatarProps> = ({ size, isOnline, imgUrl, extra, onClick, ...props }) => {
  return (
    <StyledWrap {...props} size={size}>
      <div className="avatar">{imgUrl ? <img src={imgUrl} /> : <img src={avatar_default} />}</div>
      {extra === 'setting' && (
        <StyledSetting>
          <IconButtonUI reserved="setting" onClick={onClick && onClick} />
        </StyledSetting>
      )}
      {extra === 'badge' && (
        <StyledBadge>
          <OfficialIcon />
        </StyledBadge>
      )}
      {isOnline &&
        (isOnline ? (
          <StyledOnline size={size}>
            <div className="active" />
          </StyledOnline>
        ) : (
          <StyledOnline>
            <div />
          </StyledOnline>
        ))}
    </StyledWrap>
  );
};

export default AvatarUI;

AvatarUI.defaultProps = {
  size: '4xl',
  isOnline: false,
  isBorder: true,
  borderSize: 1
};

const SIZE_4XL = css`
  width: 80px !important;
  height: 80px !important;
`;

const SIZE_3XL = css`
  width: 70px !important;
  height: 70px !important;
`;

const SIZE_2XL = css`
  width: 60px !important;
  height: 60px !important;
`;

const SIZE_XL = css`
  width: 50px !important;
  height: 50px !important;
`;

const SIZE_LG = css`
  width: 40px !important;
  height: 40px !important;
`;

const SIZE_MD = css`
  width: 32px !important;
  height: 32px !important;
`;
const SIZE_SM = css`
  width: 26px !important;
  height: 26px !important;
`;
const SIZE_XS = css`
  width: 20px !important;
  height: 20px !important;
`;

const StyledWrap = styled.div<AvatarProps>`
  position: relative;
  ${p => p.size === '4xl' && SIZE_4XL};
  ${p => p.size === '3xl' && SIZE_3XL};
  ${p => p.size === '2xl' && SIZE_2XL};
  ${p => p.size === 'xl' && SIZE_XL};
  ${p => p.size === 'lg' && SIZE_LG};
  ${p => p.size === 'md' && SIZE_MD};
  ${p => p.size === 'sm' && SIZE_SM};
  ${p => p.size === 'xs' && SIZE_XS};

  .avatar {
    ${p => p.size === '4xl' && SIZE_4XL};
    ${p => p.size === '3xl' && SIZE_3XL};
    ${p => p.size === '2xl' && SIZE_2XL};
    ${p => p.size === 'xl' && SIZE_XL};
    ${p => p.size === 'lg' && SIZE_LG};
    ${p => p.size === 'md' && SIZE_MD};
    ${p => p.size === 'sm' && SIZE_SM};
    ${p => p.size === 'xs' && SIZE_XS};
    border: 1px solid #fff;
    ${p => p.borderSize! > 1 && `border: ${p.borderSize}px solid #fff`};
    ${p => !p.isBorder && 'border: none !important'};
    overflow: hidden;
    border-radius: 16px;

    ${p => p.size === '3xl' && 'border-radius: 14px !important'};
    ${p => p.size === '2xl' && 'border-radius: 12px !important'};
    ${p => p.size === 'lg' && 'border-radius: 10px !important'};
    ${p => p.size === 'md' && 'border-radius: 8px !important'};
    ${p => p.size === 'sm' && 'border-radius: 6px !important'};
    ${p => p.size === 'xs' && 'border-radius: 4px !important'};

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const StyledSetting = styled.div`
  font-size: 0;
  line-height: 0;
  position: absolute;
  right: -5px;
  bottom: 0;
`;

const StyledBadge = styled.div`
  font-size: 0;
  line-height: 0;
  position: absolute;
  right: 0px;
  bottom: 0;
`;

const StyledOnline = styled.div<AvatarProps>`
  font-size: 0;
  line-height: 0;
  position: absolute;
  right: 0px;
  bottom: 0;

  & > div {
    width: 12px;
    height: 12px;
    ${p => (p.size === 'md' || p.size === 'sm' || p.size === 'xs') && `width: 9px !important; height: 9px !important `};
    border: 1px solid #bababa;
    background-color: #bababa;
    border-radius: 50%;
    overflow: hidden;
  }

  .active {
    background-color: #00ff66;
  }
`;
