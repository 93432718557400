import React from 'react';

const HomeSvg: React.FC<any> = ({ active, ...props }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" fill="white" />
      {active ? (
        <>
          <path d="M19.5 7L29 13.087V29H19.5H10V13.087L19.5 7Z" fill="#E4E4E4" />
          <path d="M6 10.7932L15 5.33864L24 10.7932V24H15H6V10.7932Z" stroke="url(#paint0_linear_662_20043)" strokeWidth="4" fill="none" />
          <defs>
            <linearGradient id="paint0_linear_662_20043" x1="4" y1="6.71429" x2="25.5475" y2="21.2625" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF0000" />
              <stop offset="0.473958" stopColor="#FF00D6" />
              <stop offset="1" stopColor="#001D84" />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <path d="M19.5 7L29 13.087V29H19.5H10V13.087L19.5 7Z" fill="#E4E4E4" />
          <path d="M6 10.7932L15 5.33864L24 10.7932V24H15H6V10.7932Z" stroke="#505050" strokeWidth="4" fill="none" />
        </>
      )}
    </svg>
  );
};

export default HomeSvg;
