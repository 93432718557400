import React from 'react';
import styled from 'styled-components';

interface BlinderProps {
  h?: number | string;
  bgColor?: string;
  children?: React.ReactNode;
  gradient?: boolean;
}

const BlinderUI: React.FC<BlinderProps> = ({ children, ...props }) => {
  return <StyledWrap {...props}>{children}</StyledWrap>;
};

export default BlinderUI;

BlinderUI.defaultProps = {
  h: 100,
  bgColor: '#fff'
};

const StyledWrap = styled.div<BlinderProps>`
  position: relative;
  width: 100%;
  height: ${p => (typeof p.h === 'number' ? p.h + 'px' : p.h)};
  overflow: hidden;

  ${p =>
    p.gradient &&
    `
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(255, 0, 0, 0), rgba(255, 255, 255, 1));
    z-index: 10;
  }`}
`;
