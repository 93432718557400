import React from 'react';
import styled from 'styled-components';
import { ImageObjectFitUnion } from 'ui/@types/image';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fit?: ImageObjectFitUnion;
}

const Image: React.FC<ImageProps> = ({ children, ...props }) => {
  return <StyledImage {...props} />;
};

export default Image;

const StyledImage = styled.img<ImageProps>`
  ${p => p.fit && `object-fit: ${p.fit}`};
`;
