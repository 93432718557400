import { Checkbox } from 'antd';
import { SOCIAL_LIST } from 'api/mock/social';
import SubHeader from 'components/Header/SubHeader';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { View, Text } from 'styles';
import ButtonUI from 'ui/button/Button';
import CheckboxUI from 'ui/checkbox/Checkbox';
import Input from 'ui/input/Input';
import SocialButtonUI from 'ui/socialButton/SocialButton';
import TextareaUI from 'ui/textarea/Textarea';
import UploadUI from 'ui/upload/Upload';
import { shortText } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { profileUpdate } from 'store/reducers/System';
import APIService, { apiBaseUrl } from 'api';
import ClipboardJs from 'clipboard';

import Copy from 'ui/copy/Copy';

const apiService = new APIService();
const AccountEditPage: React.FC = () => {
  const { profile } = useSelector((store: IStore) => store.System);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  console.log(profile, 'profile');
  const [inputValue, setInputValue] = React.useState({
    name: profile?.name,
    bio: profile?.bio,
    website: profile?.webSite
  });

  console.log(inputValue, 'inputValue');
  const getFileProfile = React.useCallback(
    async (file: File | null) => {
      if (file) {
        setIsLoading(true);
        try {
          const resultUpload = await apiService.uploadProfileImage(file);

          dispatch(profileUpdate({ profile: { ...profile, photoUri: resultUpload.uri } }));
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          console.log(e, 'e');
        }
      }
    },
    [profile]
  );

  const getFileBackground = React.useCallback(
    async (file: File | null) => {
      if (file) {
        setIsLoading(true);
        try {
          const resultUpload = await apiService.uploadBackgroundImage(file);

          dispatch(profileUpdate({ profile: { ...profile, backgroundImage: resultUpload.backgroundImage } }));
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          console.log(e, 'e');
        }
      }
    },
    [profile]
  );

  const changeInputValue = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setInputValue({ ...inputValue, [e.currentTarget.name]: value.trim() });
  };

  const changeTextInputValue = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setInputValue({ ...inputValue, [e.currentTarget.name]: value.trim() });
  };

  const changeBioInputValue = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setInputValue({ ...inputValue, [e.currentTarget.name]: value });
  };

  const accountSave = async () => {
    if (inputValue.name || inputValue.bio || inputValue.website) {
      const result = await apiService.putMember(inputValue);

      dispatch(profileUpdate({ profile: { ...profile, ...inputValue } }));
      navigate('/mynft');
    }
  };

  React.useEffect(() => {}, [profile]);

  return (
    <StyledWrap>
      {profile && (
        <>
          <SubHeader title="Account" />

          <View direction="column" spacing={32}>
            <Input label="Username" placeholder="Please enter your username." value={inputValue.name || profile?.name} name="name" onChange={changeInputValue} block />
            <View direction="column" spacing={32} justify="space-between" block>
              <UploadUI
                style={{ border: '1px dashed #cbcbcb' }}
                label="Profile image"
                h={80}
                defaultImage="profile"
                radius
                imageUrl={profile?.photoUri}
                fileChange={getFileProfile}
                accept=".jpg,.png"
                maxSize={5}
                previewIconType="edit"
              />
              <UploadUI
                label="Profile Banner"
                block
                h={80}
                radius
                defaultImage="bg"
                imageUrl={profile?.backgroundImage}
                fileChange={getFileBackground}
                accept=".jpg,.png"
                maxSize={5}
                previewIconType="edit"
              />
            </View>

            <TextareaUI label="Bio" placeholder="Tell about your story and yourself!" value={inputValue.bio || profile?.bio} name="bio" onChange={changeBioInputValue} />

            <View direction="column" spacing={8} block>
              <Text size="xs" weight="bold">
                Social Connections
              </Text>
              {SOCIAL_LIST.map((social, idx) => (
                <SocialButtonUI social={social.name} isConnect={social.isConnect} key={idx} />
              ))}
            </View>

            <Input label="Website URL" placeholder="Please enter your website URL. Ex) cantena.io" value={inputValue.website || profile?.webSite} name="website" onChange={changeInputValue} block />

            <View direction="column" block>
              <View spacing={7} block align="flex-end">
                <View flex={1}>
                  <Input label="Wallet address" disabled block placeholder={shortText(profile?.walletAddress, 6, 6)} />
                </View>
                <View maxW={140} flex={0}>
                  <Copy copyValue={profile?.walletAddress}>
                    <ButtonUI h={48} fontSize={14} full color="black">
                      Address Copy
                    </ButtonUI>
                  </Copy>
                </View>
              </View>
              <Text size="xxs" color="#7B7B7B">
                This is your wallet address. Deletion and modification are not possible. If you use the address, please check the first 6 digits and the last 6 digits for sure.
              </Text>
            </View>

            <ButtonUI full color="black" onClick={accountSave}>
              Save settings
            </ButtonUI>
          </View>
        </>
      )}
    </StyledWrap>
  );
};

export default AccountEditPage;

const StyledWrap = styled.div`
  padding: 16px 20px 46px;
  background-color: #f5f5f5;
`;
