import React from 'react';

import { InputStyle } from './Input';

export type MessageTypes = 'error' | 'warning' | 'info';

interface InputOptionProps {
  error?: string;
  warning?: string;
  info?: string;
  value?: string;
  maxLength?: number;
  prefix?: React.ReactNode;
  prefixStyle?: InputStyle;
  icon?: React.ReactNode;
}

const useInput = ({ error, warning, info, value, maxLength, prefix, prefixStyle, icon }: InputOptionProps) => {
  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState<MessageTypes | null>(null);

  // 입력창 아래 보여지는 텍스트를 결정
  React.useEffect(() => {
    if (error) {
      setMessage(error);
      setMessageType('error');
      return;
    }
    if (warning) {
      setMessage(warning);
      setMessageType('warning');
      return;
    }
    if (info) {
      setMessage(info);
      setMessageType('info');
      return;
    }
    setMessage('');
    setMessageType(null);
  }, [info, warning, error]);

  // 작성된 글자수가 제한글자수를 넘었는지 검사
  const isMaxLength = React.useMemo(() => {
    const length = value?.length || 0;
    if (!maxLength || length < maxLength) {
      return false;
    }
    return true;
  }, [value, maxLength]);

  // 글자 카운팅
  const lengthCount = React.useMemo(() => {
    if (maxLength) {
      return maxLength - (value?.length || 0);
    }
    return null;
  }, [maxLength, value]);

  const isDefault = React.useMemo(() => (Boolean(prefix) && prefixStyle === 'text') || Boolean(icon), [prefix, prefixStyle, icon]);

  return {
    message,
    messageType,
    isMaxLength,
    lengthCount,
    isDefault
  };
};

export default useInput;
