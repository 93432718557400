import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthorProfile, Price, Text, View } from 'styles';

const CardTypeSmallComponent: React.FC<any> = ({ uid, name, imageUrl, memberPhoto, description, itemCount, price, createdAt, items, total, cryptoIcon }) => {
  const navigate = useNavigate();
  return (
    <Wrap onClick={() => navigate(`/market/${uid}`)} className={'small-items'}>
      <div className="bg-area">
        <img className="bg" src={imageUrl} alt={name} />
        <AuthorProfile className="avatar" icon={memberPhoto} w={50} h={50} />
      </div>
      <div className="info-area">
        <Text line={1} size="xs" align="center" block weight="bold">
          {name}
        </Text>
        <View direction="column" mt={15} spacing={5}>
          <Text size="xxs" color="#717171">
            {description}
          </Text>

          {itemCount && (
            <View align="center" justify="space-between">
              <Text size="xxs" color="#717171">
                Items
              </Text>
              <Price price={itemCount} />
            </View>
          )}
        </View>
      </div>
    </Wrap>
  );
};

export default CardTypeSmallComponent;

const Wrap = styled.div`
  width: calc(50% - 20px);
  margin: 10px;
  background: #fff;
  border: 1px solid #efefef;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  overflow: hidden;

  .bg-area {
    height: 110px;
    position: relative;

    > img {
      object-fit: cover;
    }

    .avatar {
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);

      .img-icon {
        background: transparent;
      }
    }
  }

  .info-area {
    padding: 20px 13px 19px;
  }
`;
