import React from 'react';
import styled from 'styled-components';

const PopMenuStyles: React.FC<any> = ({ children, ...props }) => {
  return <Wrap>{children}</Wrap>;
};

export default PopMenuStyles;

const Wrap = styled.ul`
  & > li {
    height: 40px;
    width: 40px;
    line-height: 0;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & + li {
      border-top: 1px solid #eaeaea;
    }
  }
`;
