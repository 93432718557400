import React from 'react';

const WalletSvg: React.FC<any> = ({ active, ...props }) => {
  return (
    <svg width="53" height="30" viewBox="0 0 53 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {active ? (
        <>
          <rect width="53" height="30" fill="white" />
          <path d="M30.355 7.43657L39.7741 13.0995L39.801 24.0874L30.4087 29.4124L20.9895 23.7494L20.9626 12.7615L30.355 7.43657Z" fill="#E4E4E4" />
          <path
            d="M17.0175 10.3174L25.5061 5.50475L34.019 10.6228L34.0434 20.5935L25.5548 25.4061L17.0418 20.288L17.0175 10.3174Z"
            stroke="url(#paint0_linear_1577_23995)"
            strokeWidth="4"
            fill="transparent"
          />
          <defs>
            <linearGradient id="paint0_linear_1577_23995" x1="15.0111" y1="7.55622" x2="38.6513" y2="20.0371" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF0000" />
              <stop offset="0.473958" stopColor="#FF00D6" />
              <stop offset="1" stopColor="#001D84" />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <rect width="53" height="30" fill="white" />
          <path d="M30.355 7.43657L39.7741 13.0995L39.801 24.0874L30.4087 29.4124L20.9895 23.7494L20.9626 12.7615L30.355 7.43657Z" fill="#E4E4E4" />
          <path d="M17.0175 10.3174L25.5061 5.50475L34.019 10.6228L34.0434 20.5935L25.5548 25.4061L17.0418 20.288L17.0175 10.3174Z" stroke="#505050" strokeWidth="4" fill="transparent" />
        </>
      )}
    </svg>
  );
};

export default WalletSvg;
