import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { CardBgAvatar, Price, View, Text } from 'styles';

const CardTypeLargeComponent: React.FC<any> = ({ uid, name, imageUrl, memberPhoto, description, itemCount, price, items, total, cryptoIcon }) => {
  const navigate = useNavigate();
  return (
    <Wrap onClick={() => navigate(`/market/${uid}`)}>
      <CardBgAvatar
        name={name}
        bg={imageUrl}
        avatar={memberPhoto}
        cryptoIcon={cryptoIcon}
        content={
          <View block justify="space-between" padding={'0 28'}>
            <Text size="xxs" color="#717171">
              {description}
            </Text>
            {/* <View direction="column" justify="center" align="center">
              <Text size="xxs" color="#717171">
                Floor Price
              </Text>
              <Price justify="center" icon={cryptoIcon} price={price} fontSize={14} />
            </View> */}
            <View direction="column" justify="center" align="center">
              <Text size="xxs" color="#717171">
                Items
              </Text>
              <Price justify="center" price={itemCount} fontSize={14} />
            </View>
            {/* <View direction="column" justify="center" align="center">
              <Text size="xxs" color="#717171">
                Total Volume
              </Text>
              <Price justify="center" icon={cryptoIcon} price={total} fontSize={14} />
            </View> */}
          </View>
        }
      />
    </Wrap>
  );
};

export default CardTypeLargeComponent;
const Wrap = styled.div`
  .img-icon {
    border: none !important;
    background: transparent;
  }
`;
