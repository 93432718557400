export const MY_NFT_LIST: any = {
  day: [
    {
      id: 0,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585394-b727dcd5-84ce-4de7-82c3-f7ce4ade66f5.png',
      title1: 'The Nature 1/1',
      title2: 'the nature #125',
      price: 65,
      usd: 493201.03,
      timestamp: '12 hours ago',
      author: 'The Nature 1/1',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 1,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585001-1728e9aa-175a-4db8-bf45-87810cbe3cde.png',
      title1: 'Moonbirds',
      title2: '#7841',
      price: 1.1025,
      usd: 3259.36,
      timestamp: '4 days ago',
      author: 'cryptohp1890',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 2,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585516-c495816a-c224-4f1d-8dc4-de77a1fea97b.png',
      title1: 'CLONE X - X TAKASHI MURAKAMI',
      title2: 'CloneX #17503',
      price: 22.5,
      usd: 66662.55,
      timestamp: '4 days ago',
      author: '29093A',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 3,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585609-d4f993b4-bd1c-407f-a16a-30259327870c.png',
      title1: '0x3aac9ab49...2b8f',
      title2: 'Caesars',
      price: 1000000000,
      usd: 116666255,
      timestamp: '4 days ago',
      author: '0xb1eb0d5b8...93ca',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 4,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863520-e07f5e86-9e38-4089-822a-c71faaf989bd.png',
      title1: 'The Nature 1/1',
      title2: 'the nature #125',
      author: 'The Nature 1/1',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 5,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863612-66a18281-647d-4c2a-be37-27e9073be412.png',
      title1: 'Moonbirds',
      title2: '#7841',
      author: 'cryptohp1890',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 6,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863678-9f44de9a-c3a6-4a43-b3e5-caa2607ad155.png',
      title1: 'CLONE X - X TAKASHI MURAKAMI',
      title2: 'CloneX #17503',
      author: '29093A',
      price: 22.5,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 5,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863749-63605c03-8388-4912-8422-bf0ba615eb44.png',
      title1: '0x3aac9ab49...2b8f',
      title2: 'Caesars',
      author: '0xb1eb0d5b8...93ca',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    }
  ],
  week: [
    {
      id: 0,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863749-63605c03-8388-4912-8422-bf0ba615eb44.png',
      title1: '0x3aac9ab49...2b8f',
      title2: 'Caesars',
      author: '0xb1eb0d5b8...93ca',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 1,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585001-1728e9aa-175a-4db8-bf45-87810cbe3cde.png',
      title1: 'Moonbirds',
      title2: '#7841',
      price: 1.1025,
      usd: 3259.36,
      timestamp: '4 days ago',
      author: 'cryptohp1890',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 2,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585516-c495816a-c224-4f1d-8dc4-de77a1fea97b.png',
      title1: 'CLONE X - X TAKASHI MURAKAMI',
      title2: 'CloneX #17503',
      price: 22.5,
      usd: 66662.55,
      timestamp: '4 days ago',
      author: '29093A',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 3,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585609-d4f993b4-bd1c-407f-a16a-30259327870c.png',
      title1: '0x3aac9ab49...2b8f',
      title2: 'Caesars',
      price: 1000000000,
      usd: 116666255,
      timestamp: '4 days ago',
      author: '0xb1eb0d5b8...93ca',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 4,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863520-e07f5e86-9e38-4089-822a-c71faaf989bd.png',
      title1: 'The Nature 1/1',
      title2: 'the nature #125',
      author: 'The Nature 1/1',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 5,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863612-66a18281-647d-4c2a-be37-27e9073be412.png',
      title1: 'Moonbirds',
      title2: '#7841',
      author: 'cryptohp1890',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 6,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863678-9f44de9a-c3a6-4a43-b3e5-caa2607ad155.png',
      title1: 'CLONE X - X TAKASHI MURAKAMI',
      title2: 'CloneX #17503',
      author: '29093A',
      price: 22.5,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 5,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585394-b727dcd5-84ce-4de7-82c3-f7ce4ade66f5.png',
      title1: 'The Nature 1/1',
      title2: 'the nature #125',
      price: 65,
      usd: 493201.03,
      timestamp: '12 hours ago',
      author: 'The Nature 1/1',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    }
  ],
  month: [
    {
      id: 0,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585516-c495816a-c224-4f1d-8dc4-de77a1fea97b.png',
      title1: 'CLONE X - X TAKASHI MURAKAMI',
      title2: 'CloneX #17503',
      price: 22.5,
      usd: 66662.55,
      timestamp: '4 days ago',
      author: '29093A',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 1,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585001-1728e9aa-175a-4db8-bf45-87810cbe3cde.png',
      title1: 'Moonbirds',
      title2: '#7841',
      price: 1.1025,
      usd: 3259.36,
      timestamp: '4 days ago',
      author: 'cryptohp1890',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 2,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863749-63605c03-8388-4912-8422-bf0ba615eb44.png',
      title1: '0x3aac9ab49...2b8f',
      title2: 'Caesars',
      author: '0xb1eb0d5b8...93ca',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 3,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585609-d4f993b4-bd1c-407f-a16a-30259327870c.png',
      title1: '0x3aac9ab49...2b8f',
      title2: 'Caesars',
      price: 1000000000,
      usd: 116666255,
      timestamp: '4 days ago',
      author: '0xb1eb0d5b8...93ca',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 4,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863520-e07f5e86-9e38-4089-822a-c71faaf989bd.png',
      title1: 'The Nature 1/1',
      title2: 'the nature #125',
      author: 'The Nature 1/1',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 5,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863612-66a18281-647d-4c2a-be37-27e9073be412.png',
      title1: 'Moonbirds',
      title2: '#7841',
      author: 'cryptohp1890',
      price: 65,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 6,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167863678-9f44de9a-c3a6-4a43-b3e5-caa2607ad155.png',
      title1: 'CLONE X - X TAKASHI MURAKAMI',
      title2: 'CloneX #17503',
      author: '29093A',
      price: 22.5,
      usd: 116666255,
      timestamp: '4 days ago',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    },
    {
      id: 5,
      imageUrl: 'https://user-images.githubusercontent.com/45615584/167585394-b727dcd5-84ce-4de7-82c3-f7ce4ade66f5.png',
      title1: 'The Nature 1/1',
      title2: 'the nature #125',
      price: 65,
      usd: 493201.03,
      timestamp: '12 hours ago',
      author: 'The Nature 1/1',
      cryptoUrl: 'https://user-images.githubusercontent.com/45615584/167597291-8fea4d62-943a-460b-bd88-0a3287a04049.png'
    }
  ]
};

export const NFT_ITEMS = [
  {
    id: 123,
    name: 'ITEM NAME KOREA TREE',
    type: 'art',
    typeUrl: 'https://user-images.githubusercontent.com/45615584/197333348-0bc6639d-9815-4832-a797-f0ee46df2080.png',
    coinIcon: 1,
    price: 0.00999999,
    count: 3,
    link: 'my link1',
    nftUrl: 'https://user-images.githubusercontent.com/45615584/197333471-de6c744b-8800-46c8-ae5d-df6adc1b52cf.png'
  },
  {
    id: 1,
    name: 'ITEM NAME KOREA TREE',
    type: 'art',
    typeUrl: 'https://user-images.githubusercontent.com/45615584/197333348-0bc6639d-9815-4832-a797-f0ee46df2080.png',
    coinIcon: 1,
    price: 0.009,
    link: 'my link2',
    count: 10,
    nftUrl: 'https://user-images.githubusercontent.com/45615584/197333471-de6c744b-8800-46c8-ae5d-df6adc1b52cf.png'
  },
  {
    id: 2,
    name: 'ITEM NAME KOREA TREE',
    type: 'art',
    typeUrl: 'https://user-images.githubusercontent.com/45615584/197333348-0bc6639d-9815-4832-a797-f0ee46df2080.png',
    coinIcon: 1,
    price: 0.009,
    count: 3,
    link: 'my link3',
    nftUrl: 'https://user-images.githubusercontent.com/45615584/197333471-de6c744b-8800-46c8-ae5d-df6adc1b52cf.png'
  }
];

export const NFT_CREATOR_LIST = [
  {
    uid: 1,
    name: 'User Name',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/197475126-3c99fdb9-1eaf-4099-b0fd-74c817d3c242.png',
    avatarUrl: 'https://user-images.githubusercontent.com/45615584/197475101-7335a80c-eff4-42bd-9309-f292113467ea.png'
  },
  {
    uid: 2,
    name: 'Paul McCartney',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/197475135-2cd030f7-77a1-4f34-af77-73ecba92a511.png',
    avatarUrl: 'https://user-images.githubusercontent.com/45615584/197475156-c872d562-e760-425a-b4d1-d8bac6cfb799.png'
  },
  {
    uid: 3,
    name: 'NFT COIN TOP',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/197475168-854f869c-4612-4328-be71-e841474f4f80.png',
    avatarUrl: 'https://user-images.githubusercontent.com/45615584/197475182-32f5ea87-c9ab-4fbc-accd-67b019a00ef2.png'
  },
  {
    uid: 4,
    name: 'Happy Dog Dog',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/197475195-14e39e67-3c9b-42aa-ab0c-e4860d63ea54.png',
    avatarUrl: 'https://user-images.githubusercontent.com/45615584/197475201-8e734c91-4639-4280-aadc-4877854c0d46.png'
  }
];

export const NFT_COLLECTION = {
  uid: 2,
  name: 'Paul McCartney',
  bgUrl: 'https://user-images.githubusercontent.com/45615584/197480775-cb291235-503c-4caf-b4e3-9b5b4507a425.png',
  avatarUrl: 'https://user-images.githubusercontent.com/45615584/197475156-c872d562-e760-425a-b4d1-d8bac6cfb799.png',
  desc: 'Various backgrounds are provided as photos.'
};
