import React, { SVGProps } from 'react';
import styled, { keyframes } from 'styled-components';
// import { ReactComponent as SuccessSvg } from '@register/assets/icons/check-success.svg';
// import { ReactComponent as FailedSvg } from '@register/assets/icons/check-failed.svg';
import { AllColorUnion } from 'styles/@types/color';
import { useDispatch } from 'react-redux';
import { View, Text } from 'styles';
import { alertMessage } from 'store/reducers/System';

type ColorUnion = AllColorUnion | undefined;

type TypeIconProps = React.FunctionComponent<React.SVGProps<SVGSVGElement>> | React.SVGProps<SVGSVGElement> | React.ReactNode | null;

interface AlertMessageProps {
  type?: 'warning' | 'information' | 'success' | 'failed' | 'default' | undefined;
  title?: string;
}

const AlertMessageSyles: React.FC<AlertMessageProps> = ({ title, type, ...props }) => {
  const dispatch = useDispatch();

  // const { TypeIcon, TypeColor, TypeBg } = React.useMemo(() => {
  //   let TypeIcon: TypeIconProps = FailedSvg;
  //   let TypeColor: ColorUnion = undefined;
  //   let TypeBg = undefined;

  //   switch (type) {
  //     case 'failed':
  //       TypeIcon = FailedSvg;
  //       TypeColor = 'red-color-400';
  //       TypeBg = '#FFE6E6';
  //       break;

  //     case 'success':
  //       TypeIcon = SuccessSvg;
  //       TypeColor = 'teal-color-300';
  //       TypeBg = '#F3FDFF';
  //       break;

  //     case 'default':
  //       TypeIcon = null;
  //       TypeColor = 'gray-color-500';
  //       TypeBg = '#FFFFFF';
  //       break;
  //   }

  //   return { TypeIcon, TypeColor, TypeBg };
  // }, [type]);

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(alertMessage({ type: undefined }));
    }, 2500);
  }, []);

  return (
    <StyledToast {...props}>
      <View align="center" justify="center" block>
        {/* <StyledInner bgColor={TypeBg} align="center" justify="flex-start" spacing={16} flex={0}> */}
        <StyledInner bgColor="#626262" align="center" justify="flex-start" spacing={16} flex={0}>
          {/* {TypeIcon && <TypeIcon />} */}
          {/* <Text color={TypeColor}>{title}</Text> */}
          <Text color="#fff">{title}</Text>
        </StyledInner>
      </View>
    </StyledToast>
  );
};

export default AlertMessageSyles;

AlertMessageSyles.defaultProps = {
  type: undefined
};

const fadeInOut = keyframes`
  0% {
    transform: scale(.25);
    opacity: 0;
  }

  5% {
    transform: scale(1);
    opacity: 1;
  }

  95% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(.25);
    opacity: 0;
  }
 
`;

const StyledToast = styled.div<AlertMessageProps>`
  position: fixed;
  bottom: 12%;
  left: 0;
  right: 0;
  z-index: 9999;
  /* visibility: ${props => (props.type ? 'hidden' : 'visible')}; */
  animation: ${fadeInOut} 2500ms ease-in;
  transition: visibility 2500ms ease-in;
`;

const StyledInner = styled(View)<AlertMessageProps>`
  padding: 10px 16px;
  border-radius: 8px;
  box-shadow: 0px 14px 34px rgba(105, 120, 160, 0.2);
`;
