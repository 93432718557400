import SubHeader from 'components/Header/SubHeader';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { View, Text } from 'styles/';
import ButtonUI from 'ui/button/Button';
import Input from 'ui/input/Input';
import Textarea, { TextareaUI } from 'ui/textarea/Textarea';
import UploadUI from 'ui/upload/Upload';
import { ReactComponent as LinkIcon } from 'assets/icon/link.svg';
import { ReactComponent as FacebookIcon } from 'assets/logo/facebook-fill.svg';
import { ReactComponent as TwitterIcon } from 'assets/logo/twitter-fill.svg';
import { ReactComponent as InstagramIcon } from 'assets/logo/instagram-lined.svg';
import { alertMessage } from 'store/reducers/System';

import { Switch } from 'antd';
import SwitchUI from 'ui/switch/Switch';
import ModalStyles from 'styles/modal/Modal';
import SelectButtonUI from 'ui/select-button/SelectButton';
import BackgroundUI from 'ui/background/Background';

import APIService from 'api';
import { useDispatch } from 'react-redux';
import LoadingComponent from 'components/v2/loading/Loading';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { ConnectableObservable } from 'rxjs';
import { reduceEachTrailingCommentRange } from 'typescript';
const apiService = new APIService();

const NftItemCreatePage: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [hasError, setHasError] = React.useState(true);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openCtgModal, setOpenCtgModal] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [inputValue, setInputValue] = React.useState({
    name: '',
    lockFlag: true,
    description: '',
    externalUrl: '',
    keyword: '',
    commonCodeUid: 26,
    imageUrl: ''
  });

  const [collectionCategory, setCollectionCategory] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [createUid, setCreateUid] = React.useState(0);

  const getCollectionCategory = async () => {
    const resultCollectionCategory = await apiService.getCollectionCategory();
    setCollectionCategory(resultCollectionCategory.list);
  };

  const getFile = React.useCallback(
    async (file: File | null) => {
      if (file) {
        setIsLoading(true);
        try {
          const resultUpload = await apiService.uploadNftImage(file);

          resultUpload && setInputValue({ ...inputValue, imageUrl: resultUpload.image });
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          console.log(e, 'e');
        }
      }
    },
    [setInputValue]
  );

  const closeModal = () => {
    setOpenDeleteModal(false);
    setOpenConfirm(false);
  };

  const changeInputValue = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (e.currentTarget.name === 'name') {
      if (value && value.length >= 50) {
        dispatch(alertMessage({ type: 'default', msg: 'Exceeded the maximum characters' }));
        setHasError(true);
      } else {
        setHasError(false);
      }
    }

    if (e.currentTarget.name === 'externalUrl') {
      if (value && !(!value.indexOf('http://') || !value.indexOf('https://'))) {
        dispatch(alertMessage({ type: 'default', msg: 'Please enter detailed address with https://~' }));
        setHasError(true);
      } else {
        setHasError(false);
      }
    }

    setInputValue({ ...inputValue, [e.currentTarget.name]: value.trim() });
  };

  const onChangeDesc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    if (value === '') setHasError(true);
    if (value && value.length < 20) {
      dispatch(alertMessage({ type: 'default', msg: 'Please enter at least 20 characters in detail.' }));
      setHasError(true);
    } else if (value && value.length > 100) {
      dispatch(alertMessage({ type: 'default', msg: 'Exceeded the maximum characters' }));
      setHasError(true);
    } else {
      setHasError(false);
    }
    setInputValue({ ...inputValue, description: e.target.value });
  };

  const onChangeCtg = (value: number) => {
    setInputValue({ ...inputValue, commonCodeUid: value });
    setOpenCtgModal(false);
  };

  const onChangeKeyword = (value: string) => {
    if (value && value.length > 100) {
      dispatch(alertMessage({ type: 'default', msg: 'Exceeded the maximum characters' }));
      setHasError(true);
    } else {
      setHasError(false);
    }
    setInputValue({ ...inputValue, keyword: value });
    setOpenCtgModal(false);
  };

  const onSpaceCheck = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { keyCode } = event;
    if (keyCode === 32) {
      event.preventDefault();
      return false;
    }
  };

  const onChangeLock = (value: boolean) => {
    setInputValue({ ...inputValue, lockFlag: value });
  };

  const createNft = async () => {
    console.log(inputValue, 'inputvalue');
    try {
      const result = await apiService.createNft(inputValue);
      setCreateUid(result.uid);
      setOpenConfirm(true);
    } catch (e) {
      console.log(e, 'e');
    }
  };

  const createComplete = () => {
    navigate(`/nft/item/${createUid}?linkState=Created`);
  };

  React.useEffect(() => {
    getCollectionCategory();
  }, []);

  return (
    <StyledWrap>
      <SubHeader title="NFT Create" />
      <View direction="column" pl={20} pr={20} mb={8} block spacing={24}>
        <View direction="column" spacing={4}>
          <Text size="xl" weight="semi-bold">
            Create New Item
          </Text>
          <Text lh={13} size="xxs" color={'#7B7B7B'} required="left">
            Required fields
          </Text>
        </View>

        <View direction="column" spacing={4} block>
          <Text lh={16} size="xs" weight="bold" required="right">
            Image , Video , Audio. Or 3D Model
          </Text>
          <Text size="xxs" color={'#7B7B7B'}>
            File types : JPG , PNG , GIF , SVG , MP4 , WEBM , MP3 , WAV, OGG , GLB, GLTF (MAX Size : 100MB)
          </Text>
        </View>
      </View>

      <UploadUI minh={200} fileChange={getFile} accept=".jpg,.png,.svg,.gif,.mp,.wav,.ogg,.glb,.gltf,.mp3,.mp4,.webm" />

      <View direction="column" spacing={32} block pl={20} pr={20} mt={32}>
        <Input required name="name" label="Name" placeholder={'Item name (Within 50 characters)'} onChange={changeInputValue} block />

        <Input
          label="External link"
          name="externalUrl"
          infoPos="top"
          infoNode={
            <View direction="column" mb={8} block>
              <Text size="xxs" color="#7B7B7B">
                Please input your URL of the web page or YouTube link.{' '}
              </Text>
              <Text size="xxs" color="#7B7B7B">
                It makes others to click to learn more about your item.
              </Text>
              <Text size="xxs" color="#7B7B7B">
                - Illegal links or web page related to crimes will be deleted or charged.
              </Text>
            </View>
          }
          placeholder={'Please enter detailed address. (ex.https://yoursite.io)'}
          onChange={changeInputValue}
          block
        />

        <TextareaUI label="Description" name="description" required placeholder="Provide a detailed description of your item. (More than 20 characters)" onChange={onChangeDesc} />

        <SelectButtonUI
          label="Category"
          infoPos="top"
          infoNode={
            <View direction="column" mb={8} block>
              <Text size="xxs" color="#7B7B7B">
                Please select category. Modification can be done before final minting and it will be shown as classified in the NFT Market.
              </Text>
            </View>
          }
          required
          onClick={() => setOpenCtgModal(true)}
        >
          {collectionCategory.length > 0 && collectionCategory.filter((item: any) => item.uid === inputValue.commonCodeUid)[0].name}
        </SelectButtonUI>

        <Textarea
          label="Key Word"
          placeholder="#keyword,#keyword2,….. (Within 100 characters)"
          h={80}
          onKeyDown={onSpaceCheck}
          onKeyUp={onSpaceCheck}
          onChange={e => onChangeKeyword(e.target.value)}
        />

        <View justify="space-between" block align="flex-end">
          <View direction="column" maxW={272} flex={2}>
            <Text size="xs" weight="bold">
              Unlockable Content
            </Text>
            <Text size="xxs" color={'#7B7B7B'}>
              You can decide whether to disclose only your own items. Provides the ability for owners to disclose.
            </Text>
          </View>
          <SwitchUI>
            <Switch defaultChecked={inputValue.lockFlag} onChange={() => onChangeLock(!inputValue.lockFlag)} />
          </SwitchUI>
        </View>
      </View>

      <View block padding="42px 20px 0">
        <ButtonUI full color="black" onClick={createNft} disabled={hasError || inputValue.name === '' || inputValue.description === ''}>
          Create
        </ButtonUI>
      </View>

      <ModalStyles
        open={openDeleteModal}
        w={'90%'}
        isCenter={true}
        handleCancle={closeModal}
        type="confirm"
        footer={
          <View block spacing={11}>
            <ButtonUI full color="borderBlack" onClick={() => setOpenDeleteModal(false)}>
              CANCEL
            </ButtonUI>
            <ButtonUI full color="black" onClick={() => console.log('delete item')}>
              DELETE ITEM
            </ButtonUI>
          </View>
        }
      >
        <Text size="sm">Are you sure to delete this item?</Text>
      </ModalStyles>

      <ModalStyles open={openCtgModal} isCenter={true} handleCancle={closeModal} type="select">
        {collectionCategory.map((item: any, idx) => (
          <StyledOptionItem key={idx} active={inputValue.commonCodeUid === item.uid} className="select-item" onClick={() => onChangeCtg(item.uid)}>
            {item.name}
          </StyledOptionItem>
        ))}
      </ModalStyles>

      <ModalStyles
        open={openConfirm}
        isCenter={true}
        w={335}
        handleCancle={createComplete}
        type="confirm"
        footer={
          <ButtonUI color="black" full onClick={createComplete}>
            OK
          </ButtonUI>
        }
      >
        <View spacing={12} mb={40}>
          <BackgroundUI bgUrl={inputValue.imageUrl} w={100} h={100} objectFit="contain" />
          <View direction="column" spacing={12}>
            <View direction="column">
              <Text size="xxs">Wow, you just created</Text>
              <Text weight="bold" color={'#AB3FFF'} size="sm">
                {inputValue.name}
              </Text>
            </View>
            <View direction="column" spacing={8}>
              <Text size="xxs" weight="bold" color={'#B0B0B0'}>
                SHARE
              </Text>
              <View spacing={20} block align="center">
                <LinkIcon style={{ transform: 'rotate(-45deg)' }} />
                <FacebookIcon />
                <TwitterIcon />
                <InstagramIcon />
              </View>
            </View>
          </View>
        </View>
      </ModalStyles>
    </StyledWrap>
  );
};

export default NftItemCreatePage;

const StyledWrap = styled.div`
  padding-top: 16px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
`;

const StyledOptionItem = styled.div<{ active?: boolean }>`
  color: ${p => (p.active ? '#0075FF' : '#808080')} !important;
`;
