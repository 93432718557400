import React from 'react';
import { View, Text } from 'styles';
import { TextSizeType, TextWeightType } from 'styles/text';
import { mappingNftTypeIcon, mappingNftTypeIconWhite, mappingNftTypeName } from 'utils';
import CssFilterConverter from 'css-filter-converter';
import styled from 'styled-components';

interface NftTypeProps {
  fontSize?: TextSizeType;
  type: string;
  color?: 'black' | 'white';
  fontColor?: string;
  fontWeight?: TextWeightType;
}

const NftTypeComponent: React.FC<NftTypeProps> = ({ type, color, fontSize, fontColor, fontWeight }) => {
  const hexTofilterGray = CssFilterConverter.hexToFilter('#808080');
  const hexTofilterWhite = CssFilterConverter.hexToFilter('#fff');

  return (
    <View align="center" spacing={6}>
      {color === 'black' && <StyledIcon filter={hexTofilterGray.color} src={mappingNftTypeIcon(type)} />}
      {color === 'white' && <StyledIcon filter={hexTofilterWhite.color} src={mappingNftTypeIcon(type)} />}
      <Text lh={19} size={fontSize} color={fontColor} weight={fontWeight} textSpacing={-0.5}>
        {mappingNftTypeName[type]}
      </Text>
    </View>
  );
};

export default NftTypeComponent;

NftTypeComponent.defaultProps = {
  color: 'black',
  fontSize: 'sm',
  fontColor: '#808080',
  fontWeight: 'default'
};

const StyledIcon = styled.img<{ filter?: any }>`
  width: 15px;
  height: 18px;
  object-fit: contain;
  filter: ${p => p.filter};
`;
