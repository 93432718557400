import numeral from 'numeral';
import common from 'utils/common';
import art from 'assets/icon/art.svg';
import collectibles from 'assets/icon/collectibles.svg';
import photography from 'assets/icon/photography.svg';
import sports from 'assets/icon/sports.svg';
import tradingCard from 'assets/icon/tradingCard.svg';
import character from 'assets/icon/character.svg';
import games from 'assets/icon/games.svg';
import fanArt from 'assets/icon/fanArt.svg';
import membership from 'assets/icon/membership.svg';
import world from 'assets/icon/world.svg';
import all from 'assets/icon/all.svg';
import metamask_logo from 'assets/logo/wallet/metamask.svg';
import moa_logo from 'assets/logo/wallet/moa.svg';
import nvia_logo from 'assets/logo/wallet/nvia_logo.svg';
import coinbase_logo from 'assets/logo/wallet/coinbase.svg';
import solflare_logo from 'assets/logo/wallet/solflare.svg';
import wallet_logo from 'assets/logo/wallet/wallet.svg';
import trust_logo from 'assets/logo/wallet/trust.svg';
import phantom_logo from 'assets/logo/wallet/phantom.svg';
import kaikas_logo from 'assets/logo/wallet/kaikas.svg';

export default { common };

export const setDigit = (value = 0, decimals = 0, cut = true, usd = false) => {
  if (value) {
    const result = numeral(value).format(`0,0.${Array(decimals).fill('0').join('')}`, Math.floor);
    if (cut) {
      const unit = result.split('.');
      const cutNumber = Math.abs(parseFloat(`0.${unit.at(1)}`))
        .toString()
        .split('.');
      return `${unit.at(0)}${cutNumber.length > 1 ? `.${cutNumber.at(1)}` : ''}`;
    } else return result;
  } else {
    return usd ? '0.00' : '0';
  }
};

export const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const nftMarketSite = (site: number) => {
  switch (site) {
    case 1:
      return 'https://user-images.githubusercontent.com/6345000/176415331-370f528c-d1a1-4eba-adc8-a3c51d283022.png';
    // foundation
    case 2:
      return 'https://user-images.githubusercontent.com/6345000/176415353-c4d92ff7-c1b2-4591-b38d-0f596147040a.png';
    case 3:
      return 'https://user-images.githubusercontent.com/6345000/176415386-9586b382-1b29-465e-b864-6cb369223e5b.png';
    case 4:
      return 'https://user-images.githubusercontent.com/6345000/176415398-3b81a703-33fb-492f-8bb2-35ea1f0a610d.png';
    // nifty gateway
    case 5:
      return 'https://user-images.githubusercontent.com/6345000/176415424-a83211fc-13b7-48d3-b43e-fe09dce1221f.png';
    // opensea
    case 6:
      return 'https://user-images.githubusercontent.com/6345000/176415449-d4fb07e5-a678-4015-a2a2-a10baef3811c.png';
    case 7:
      return 'https://user-images.githubusercontent.com/6345000/176415480-03e7b13d-fde5-425c-a560-73d0091e7c49.png';
    // knownOrigin
    case 8:
      return 'https://user-images.githubusercontent.com/45615584/194067789-e9944af1-6324-4112-b7b5-23ce6d057734.png';
    // atomichub
    case 9:
      return 'https://user-images.githubusercontent.com/45615584/194075218-1bd4080b-33d8-4926-91db-b5fe84225864.png';
    // makersplace
    case 10:
      return 'https://user-images.githubusercontent.com/45615584/194078712-bd7c8688-baae-4c66-a65d-e092b33bbd8e.png';
    // mintable
    case 11:
      return 'https://user-images.githubusercontent.com/45615584/194079239-1d8276d3-f1a6-4271-878c-54fd741d307f.png';
  }
};

export const cryptoMarket = (market: number) => {
  switch (market) {
    // 이더리움
    case 1:
      return 'https://user-images.githubusercontent.com/6345000/176996980-6b53ce63-1012-4a0f-87f4-0e11dbe38f6e.png';
    // 폴리곤
    case 2:
      return 'https://user-images.githubusercontent.com/6345000/176996965-cec0a6ef-bc45-4a62-9358-16006c1d236f.png';
    // 솔라나
    case 3:
      return 'https://user-images.githubusercontent.com/45615584/194065332-57d41ad3-b4c4-4255-80f1-d23265d3a2ea.png';
    // 왁스
    case 4:
      return 'https://user-images.githubusercontent.com/45615584/194076705-9ba9c375-d2ff-4862-b435-54556174599b.png';
  }
};

export const mappingNftTypeName: { [key: string]: string } = {
  art: 'Art',
  collectibles: 'Collectibles',
  photography: 'Photography',
  sports: 'Sports',
  tradingcard: 'Trading Card',
  character: 'Character',
  games: 'Games',
  fanart: 'Fan Art',
  membership: 'Membership',
  world: 'World',
  all: 'All'
};

export const mappingNftTypeIcon = (type: string) => {
  switch (type) {
    case 'all':
      return all;
    case 'art':
      return art;
    case 'collectibles':
      return collectibles;
    case 'photography':
      return photography;
    case 'sports':
      return sports;
    case 'tradingcard':
      return tradingCard;
    case 'character':
      return character;
    case 'games':
      return games;
    case 'fanart':
      return fanArt;
    case 'membership':
      return membership;
    case 'world':
      return world;
  }
};

export const mappingNftTypeIconWhite = (type: string) => {
  switch (type) {
    case 'all':
      return 'https://user-images.githubusercontent.com/45615584/197410699-96c3c20f-32d7-4efe-8afe-08f848d96fc7.png';
    case 'art':
      return 'https://user-images.githubusercontent.com/45615584/197410782-1ddbcf17-c2ea-48f5-893c-65348d62f570.png';
    case 'collectibles':
      return 'https://user-images.githubusercontent.com/45615584/197410779-ddb8ce81-382a-4dcf-8852-0ace8756fb72.png';
    case 'photography':
      return 'https://user-images.githubusercontent.com/45615584/197410778-143286ff-3323-4152-baed-d3850e33f901.png';
    case 'sports':
      return 'https://user-images.githubusercontent.com/45615584/197410775-de5418d9-3460-4e69-b42e-95299c9d1ae3.png';
    case 'tradingCard':
      return 'https://user-images.githubusercontent.com/45615584/197410771-2a870633-0f1d-4bf5-873c-7105a8c92ed3.png';
    case 'character':
      return 'https://user-images.githubusercontent.com/45615584/197410771-2a870633-0f1d-4bf5-873c-7105a8c92ed3.png';
    case 'games':
      return 'https://user-images.githubusercontent.com/45615584/197410763-5ae2f08f-a093-4717-a250-dbe81ebbd042.png';
    case 'fanArt':
      return 'https://user-images.githubusercontent.com/45615584/197411979-b3da3e63-8748-441d-8fd1-5e53049c56e8.png';
    case 'membership':
      return 'https://user-images.githubusercontent.com/45615584/197410718-dec34e79-e90c-4bfa-a938-2bcd192eb19f.png';
    case 'world':
      return 'https://user-images.githubusercontent.com/45615584/197410709-5cc8dd5b-a5cb-4f2e-bee7-c67699b9a93e.png';
  }
};

export const shortText = (text: string, startCount = 4, endCount = 4, middleText = '....'): string => {
  if (text.length >= startCount + endCount) return `${text.slice(0, startCount)}${middleText}${text.slice(text.length - endCount, text.length)}`;
  else return text;
};

export const mappingWalletLogo = (value: WalletListUnion | string) => {
  switch (value) {
    case 'metamask':
      return metamask_logo;
    case 'coinbase':
      return coinbase_logo;
    case 'moa':
      return nvia_logo;
    case 'wallet':
      return wallet_logo;
    case 'trust':
      return trust_logo;
    case 'phantom':
      return phantom_logo;
    case 'kaikas':
      return kaikas_logo;
    case 'solflare':
      return solflare_logo;
  }
};

export const mappingWalletName = (value: WalletListUnion | string) => {
  switch (value) {
    case 'metamask':
      return 'MetaMask';
    case 'coinbase':
      return 'CoinBase';
    case 'moa':
      return 'Moa';
    case 'wallet':
      return 'WalletConnect';
    case 'trust':
      return 'Trust Wallet';
    case 'phantom':
      return 'Phantom';
    case 'kaikas':
      return 'Kaikas';
    case 'solflare':
      return 'Solflare';
  }
};
