// BuyNft 0xe135783649BfA7c9c4c6F8E528C7f56166efC8a6
export const marketAbi = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256'
      }
    ],
    name: 'Cancel',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address payable',
        name: 'seller',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address payable',
        name: 'owner',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'isListed',
        type: 'bool'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'salleId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'nftId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'price',
        type: 'uint256'
      }
    ],
    name: 'NFTListed',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_price',
        type: 'uint256'
      }
    ],
    name: 'RePrice',
    type: 'event'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_nftContract',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_saleId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      }
    ],
    name: 'buyNFT',
    outputs: [
      {
        components: [
          {
            internalType: 'address payable',
            name: 'seller',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'owner',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'buyer',
            type: 'address'
          },
          {
            internalType: 'bool',
            name: 'isListed',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'nftId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'saleId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'isCanceled',
            type: 'bool'
          }
        ],
        internalType: 'struct Marketplace.SaleData',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_nftContract',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256'
      }
    ],
    name: 'cancel',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_saleId',
        type: 'uint256'
      }
    ],
    name: 'getListInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'address payable',
            name: 'seller',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'owner',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'buyer',
            type: 'address'
          },
          {
            internalType: 'bool',
            name: 'isListed',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'nftId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'saleId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'isCanceled',
            type: 'bool'
          }
        ],
        internalType: 'struct Marketplace.SaleData',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_nftContract',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_tokenId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_price',
        type: 'uint256'
      }
    ],
    name: 'listNFT',
    outputs: [
      {
        components: [
          {
            internalType: 'address payable',
            name: 'seller',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'owner',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'buyer',
            type: 'address'
          },
          {
            internalType: 'bool',
            name: 'isListed',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'nftId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'saleId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'isCanceled',
            type: 'bool'
          }
        ],
        internalType: 'struct Marketplace.SaleData',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_nftContract',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'ownerAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_tokenId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_price',
        type: 'uint256'
      }
    ],
    name: 'listNFTExternal',
    outputs: [
      {
        components: [
          {
            internalType: 'address payable',
            name: 'seller',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'owner',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'buyer',
            type: 'address'
          },
          {
            internalType: 'bool',
            name: 'isListed',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'nftId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'saleId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'isCanceled',
            type: 'bool'
          }
        ],
        internalType: 'struct Marketplace.SaleData',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      },
      {
        internalType: 'address',
        name: '',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes'
      }
    ],
    name: 'onERC1155Received',
    outputs: [
      {
        internalType: 'bytes4',
        name: '',
        type: 'bytes4'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'saleList',
    outputs: [
      {
        components: [
          {
            internalType: 'address payable',
            name: 'seller',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'owner',
            type: 'address'
          },
          {
            internalType: 'address payable',
            name: 'buyer',
            type: 'address'
          },
          {
            internalType: 'bool',
            name: 'isListed',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'nftId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'saleId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'isCanceled',
            type: 'bool'
          }
        ],
        internalType: 'struct Marketplace.SaleData[]',
        name: '',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'test',
    outputs: [
      {
        internalType: 'uint256',
        name: 'aa',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  }
];
