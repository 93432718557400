import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isBorder?: boolean;
  line?: number;
  lineH?: number;
  h?: number;
  maxH?: number;
  label?: string;
  viewType?: 'count' | 'fill';
  isViewLength?: boolean;
  placeholderColor?: string;
  isWhiteSpace?: boolean;
}

export function TextareaUI({ h = 124, maxH = 185, isBorder = true, line = 6, lineH = 20.7, isWhiteSpace = false, label, placeholderColor, viewType = 'count', isViewLength, ...props }: TextareaProps) {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const resize = React.useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.currentTarget.style.height = '1px';
    const textAreaHeight = lineH + event.currentTarget.scrollHeight - 4;
    if (textAreaHeight > lineH * line) {
      event.currentTarget.style.height = textAreaHeight + 'px';
    } else {
      event.currentTarget.style.height = lineH * line + 'px';
    }

    props.onKeyUp && props.onKeyUp(event);
    props.onKeyDown && props.onKeyDown(event);
  }, []);

  // console.log('placeholder', placeholderColor);

  const maxLength = React.useMemo(() => props.maxLength || 0, []);
  const charLength = React.useMemo(() => {
    const charArray = Array.from((props.value || '') as string);
    return charArray.reduce((acc, ch) => {
      if (escape(ch).length > 4) acc += 2;
      else acc += 1;

      return acc;
    }, 0);
  }, [props.value]);

  const isMaxLength = React.useMemo(() => {
    if (!maxLength || charLength < maxLength) {
      return false;
    }
    return true;
  }, [charLength, maxLength]);

  return (
    <StyledWrap>
      {label && <StyledLabel required={props.required}>{label}</StyledLabel>}
      <StyledTextarea isBorder={isBorder}>
        <StyledInput ref={textareaRef} placeholderColor={placeholderColor} h={h} maxH={maxH} {...props} onKeyUp={resize} onKeyDown={resize} />
        {isViewLength && viewType === 'count' && <StyledCount isMaxLength={isMaxLength}>{maxLength - charLength}</StyledCount>}
        {isViewLength && viewType === 'fill' && <StyledCount isMaxLength={isMaxLength}>{`${charLength}/${maxLength}`}</StyledCount>}
      </StyledTextarea>
    </StyledWrap>
  );
}

export default TextareaUI;

const StyledWrap = styled.div<TextareaProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 2px;
`;

const StyledTextarea = styled.div<TextareaProps>`
  position: relative;
  width: 100%;
  background-color: #fff;
  border: ${p => (p.isBorder ? '1px solid #e6e8ec !important;' : '0 none')};
  border-radius: 8px;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  color: #000;
  height: ${p => p.h}px;

  &:focus-within {
    /* border-color: var(--brand-color-500) !important; */
  }
`;

const StyledInput = styled.textarea<TextareaProps>`
  width: 100%;
  height: ${p => p.h}px;
  line-height: 24px;
  letter-spacing: -1%;
  max-height: ${p => p.maxH}px;
  padding: 16px 16px 24px;
  background-color: transparent;
  overflow-y: hidden;
  border: none;
  outline: none;
  color: #000;

  resize: none;
  &::placeholder {
    color: ${p => {
      // console.log(p.placeholderColor);
      return p.placeholderColor ? `var(--${p.placeholderColor}) !important` : '#BFBFBF !important';
    }};
  }
`;

const StyledLabel = styled.label<{ required?: boolean }>`
  margin-bottom: 8px;
  color: #000 !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;

  ${p =>
    p.required &&
    css`
      &::after {
        content: ' *';
        color: #ab3fff;
      }
    `}
`;

const StyledCount = styled.div<{ isMaxLength: boolean }>`
  display: flex !important;
  position: fixed;
  bottom: 12px;
  right: 16px;
  font-size: 14px !important;
  align-items: center !important;
  color: ${p => (p.isMaxLength ? 'var(--red-color-400)' : '#000')};
`;
