import nftmoaBanner01 from '../../assets/banner/nftmoa-banner-01.png';
import nftmoaBanner02 from '../../assets/banner/nftmoa-banner-02.png';
import pbcnincBanner01 from '../../assets/banner/pbcninc-banner-01.png';
import pbcnincBanner02 from '../../assets/banner/pbcninc-banner-02.png';

export const BANNERS = [
  // {
  //   id: 0,
  //   url: 'https://coinmarketcap.com/currencies/pepe-the-frog',
  //   img: nftmoaBanner01
  // },
  // {
  //   id: 1,
  //   url: 'https://www.shibatoken.com',
  //   img: nftmoaBanner02
  // },
  // {
  //   id: 2,
  //   url: 'https://opensea.io/collection/mutant-ape-yacht-club',
  //   img: 'https://user-images.githubusercontent.com/6345000/219348171-17da909a-0c08-45e8-af00-97524b0cf6b8.png'
  // },
  // {
  //   id: 3,
  //   url: 'https://opensea.io/collection/clonex',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609176-3176d713-16ca-46c8-8a3f-eec13c80f13a.png'
  // },
  // {
  //   id: 4,
  //   url: 'https://www.binance.com/en/nft/collection/stepn-x-asics-nft-sneakers-565720121522372609?orderBy=favorites&orderType=-1&isBack=0&id=565720121522372609&order=favorites%40-1',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609196-599d5e3b-ce76-41a3-bd28-b5e224d457b6.png'
  // },
  // {
  //   id: 5,
  //   url: 'https://opensea.io/collection/azuki',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609221-20274408-1ece-4c57-a0b6-1303851800b8.png'
  // },
  // {
  //   id: 6,
  //   url: 'https://superrare.com/',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609247-53ecf837-7967-4f86-9d45-100230e421e5.png'
  // },
  // {
  //   id: 7,
  //   url: 'https://otherside.xyz/world',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609582-a432432c-2b18-418d-86e8-b1c36f37ff48.png'
  // },
  // {
  //   id: 8,
  //   url: 'https://www.binance.com/en/nft/collection/wonderfulday-tiger-nft-559586812315783169?orderBy=favorites&orderType=-1&isBack=0&id=559586812315783169&order=favorites%40-1',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609299-b5f7aadc-fdca-4256-8509-9bce7c605113.png'
  // },
  // {
  //   id: 9,
  //   url: 'https://opensea.io/collection/proof-moonbirds',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609320-9bdb3afe-1bc6-4db1-8657-049442ef0eb1.png'
  // },
  // {
  //   id: 10,
  //   url: 'https://www.binance.com/en/nft/collection/syn-city-limited-blueprint-519022065384943617?orderBy=favorites&orderType=-1&isBack=0&id=519022065384943617&order=favorites%40-1',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609352-700009bf-7f61-4bb9-81b2-466ed6ab7d0c.png'
  // },
  // {
  //   id: 11,
  //   url: 'https://opensea.io/collection/doodles-official',
  //   img: 'https://user-images.githubusercontent.com/6345000/219609377-ee47621d-d67f-4731-80fa-adcfdc450a50.png'
  // },
  // {
  //   id: 1,
  //   url: 'https://www.pbcninc.com/',
  //   img: pbcnincBanner01
  // },
  {
    id: 1,
    url: 'https://coinmarketcap.com/currencies/pepe-the-frog',
    img: nftmoaBanner01
  },
  {
    id: 2,
    url: 'https://www.shibatoken.com',
    img: nftmoaBanner02
  },
  // {
  //   id: 4,
  //   url: 'https://www.pbcninc.com/',
  //   img: pbcnincBanner02
  // },
  {
    id: 5,
    url: 'https://www.binance.com/en/nft/collection/stepn-x-asics-nft-sneakers-565720121522372609?orderBy=favorites&orderType=-1&isBack=0&id=565720121522372609&order=favorites%40-1',
    img: 'https://user-images.githubusercontent.com/6345000/219609196-599d5e3b-ce76-41a3-bd28-b5e224d457b6.png'
  },
  {
    id: 6,
    url: 'https://www.binance.com/en/nft/collection/wonderfulday-tiger-nft-559586812315783169?orderBy=favorites&orderType=-1&isBack=0&id=559586812315783169&order=favorites%40-1',
    img: 'https://user-images.githubusercontent.com/6345000/219609299-b5f7aadc-fdca-4256-8509-9bce7c605113.png'
  }
];

export const BROWSERS = [
  {
    id: 1,
    nftName: 'Sneaker...',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219348801-6e900cc0-984e-4ffa-bac7-1e5d4db3901e.png',
    author: 'STEPN x ASICS',
    isFavorite: false,
    url: 'https://opensea.io/assets/ethereum/0x60e4d786628fea6478f785a6d7e704777c86a7c6/1393'
  },
  {
    id: 2,
    nftName: '#4557',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219348821-8dc88a5f-888a-44ad-bb5d-8768f6db5065.png',
    author: 'Moonbirds',
    isFavorite: false,
    url: 'https://opensea.io/assets/ethereum/0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b/16834'
  },
  {
    id: 3,
    nftName: 'Mobland Genesis...',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219348844-b8832484-da08-4f44-97d3-4443be79eb35.png',
    author: 'SYN CITY',
    isFavorite: true,
    url: 'https://www.binance.com/en/nft/item/36937172'
  },
  {
    id: 4,
    nftName: 'Rakkudo #4557',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219348869-1cd9a301-be9a-4125-b7b9-2e9c1856c0bc.png',
    author: 'Rakkudo',
    isFavorite: true,
    url: 'https://opensea.io/assets/ethereum/0xed5af388653567af2f388e6224dc7c4b3241c544/5397'
  },
  {
    id: 5,
    nftName: 'Fritt Tiger...',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219348883-e9ae12c1-9c1f-4301-9922-9adeabca393e.png',
    author: 'WonderfulDay Tige...',
    isFavorite: false,
    url: 'https://opensea.io/assets/ethereum/0x764aeebcf425d56800ef2c84f2578689415a2daa/698'
  },
  {
    id: 6,
    nftName: '#83210',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219351890-9ae2086a-3e2f-4fb2-8307-0a902a20ab6b.png',
    author: 'Otherdeed for ...',
    isFavorite: false,
    url: 'https://opensea.io/assets/ethereum/0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258/83210'
  },
  {
    id: 7,
    nftName: 'Rakkudo #4557',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219348963-b3e4deb3-86a6-40fd-98e4-df17a3eb83f6.png',
    author: 'Rakkudo',
    isFavorite: false,
    url: 'https://www.binance.com/en/nft/item/24237295'
  },
  {
    id: 8,
    nftName: 'Doodle #813',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219348992-fc83c68c-9c8e-4579-88ae-9b8bdbc56ea1.png',
    author: 'Doodle',
    isFavorite: true,
    url: 'https://opensea.io/assets/ethereum/0x23581767a106ae21c074b2276d25e5c3e136a68b/4884'
  },
  {
    id: 9,
    nftName: 'Meebit #15934',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219349015-78327f39-bc9b-4fe8-91dd-2de74143d4a2.png',
    author: 'Meebits',
    isFavorite: false,
    url: 'https://www.binance.com/en/nft/item/12295013'
  },
  {
    id: 10,
    nftName: 'CryptoPunk #9998',
    nftImage: 'https://user-images.githubusercontent.com/6345000/219351778-d8b2f751-4495-4f6b-acee-0b6fda429033.png',
    author: 'CryptoPunks',
    isFavorite: false,
    url: 'https://opensea.io/assets/ethereum/0x8a90cab2b38dba80c64b7734e58ee1db38b8992e/813'
  }
];

export const RANKS: any = {
  day: [
    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: 'CT #1823',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CopeTown',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951888-d1a4edce-3216-4722-b393-fcadfae4c02b.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'Shimmer by Dave Whyte #60',
      author: 'Feral File #001 - Social Codes',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953499-e7da66dc-ae2b-4197-b729-4b8607e6da99.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951898-9fe954f6-21a1-4f27-a704-cb3de44fb834.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'NIKPLACE',
      nftmarketIcon: 'https://user-images.githubusercontent.com/45615584/175953488-81a2c66c-0f42-4cd7-b21d-ab58bc04597a.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951912-e7ba2983-01bb-49fa-b442-0898771e9f67.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951922-de8c7f3c-c3c0-4ca7-9296-d09cfb908972.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/175951934-176cd548-2639-421a-815d-c71bc6260c1d.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    }
  ],
  week: [
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 1,
      beforeRank: 1,
      title: 'Clone X #6638',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'CloneX',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403981-587773f3-6484-4b89-9011-1ea16663924e.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: 'BoredApeKennelClub #4366',
      author: 'BoredApeKennelClub',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403987-003a503e-5a6b-4fbd-8ac9-d5f890cc9026.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: '#9 HOPPER - STARMAN',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'SHAKKABLOOD',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166403994-a7469c79-4cf2-4338-85cd-efad21c5ac0a.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'flow#boi',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'LIRONA',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404031-70c5fec7-5127-47a7-a816-bb6abb2fda22.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: 'Meebit #8990',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Meebits',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166404044-8c144550-b226-4c2a-b464-7dd3042111dc.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '1,2',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },
    {
      rank: 100,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://user-images.githubusercontent.com/45615584/175953480-0068b8ec-9eaa-4b0a-b957-b3139aa4aa1e.png',
      price: '2,365',
      timestamp: '+7% Last week',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    }
  ],
  month: [
    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    },

    {
      rank: 1,
      beforeRank: 1,
      title: '#473',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Mutant Ape Yacht Club',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405193-6f62a84a-4ac1-4763-88c8-a9d5bbef9d55.png'
    },
    {
      rank: 2,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Moonbirds',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405202-a4ba7e9a-6708-4c90-88b0-3a99ff1f0fe0.png'
    },
    {
      rank: 3,
      beforeRank: 1,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405212-18b317aa-5f5b-42aa-81f8-b58f45adc99b.png'
    },
    {
      rank: 4,
      beforeRank: 4,
      title: 'Okay Bear #9007',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Okay Bears',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405220-24c6d08a-3ff6-4c14-822b-d5a2add9cb61.png'
    },
    {
      rank: 5,
      beforeRank: 4,
      title: "Slay'n Slug #55508",
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'VeeFriends Series 2',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/166405227-188073cd-32ae-4110-8dd2-0b8c28360da5.png'
    },
    {
      rank: 6,
      beforeRank: 4,
      title: 'Aurorian #2956',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Aurory',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743654-a11fe939-3089-4805-91a2-4d5fa70169d7.png'
    },
    {
      rank: 7,
      beforeRank: 3,
      title: '2016 David Bowie X 33',
      author: 'Aurory',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743671-49581ddf-8d94-4491-b8f9-50d2e335e5f6.png'
    },
    {
      rank: 8,
      beforeRank: 1,
      title: 'BELLYGOM 6 (100 PIECES)',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Nikplsce',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743681-360fb53b-491d-4c37-a40a-fe0c82cf1694.png'
    },
    {
      rank: 9,
      beforeRank: 4,
      title: 'Piano to the world',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'WonderPals',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743690-b2e51c94-5a8a-4c1a-9431-87df1a27102d.png'
    },
    {
      rank: 10,
      beforeRank: 4,
      title: 'Smirc 109',
      cryptoIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
      author: 'Smirc',
      nftmarket: 'Solanart',
      nftmarketIcon: 'https://solanart.io/logo.png',
      mainnet: 'eth',
      mainnetIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: '2,365',
      timestamp: '12min ago',
      nftImageUrl: 'https://user-images.githubusercontent.com/45615584/165743696-907b34f0-c785-41c5-9482-c0961f40042d.png'
    }
  ]
};

export const TOP_SELLERS: any = {
  day: [
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    }
  ],
  week: [
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    }
  ],
  month: [
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 1,
      author: 'CryptoPunks',
      profileImage: 'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 2,
      author: 'Doodles',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133204-4d15e2f6-140c-4438-9532-3cd3dee88308.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 3,
      author: 'Take My Muffin',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133250-af17c70f-8269-471f-982b-e9fe005cc9c9.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 4,
      author: 'MURI by Haus',
      profileImage: 'https://lh3.googleusercontent.com/OPgdc2e-yuBEMeyS8ttfl8cINQ5amQofP0xS4wWj59bWyUUo84A4g-WaulcpghyQCde1mQh88XABNHWjT9MRhPo34-QF50bdYQZa=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 5,
      author: 'fityeth',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133173-a8224db2-98c4-49ac-be91-b09aa8869453.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '148,2',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 6,
      author: '(B)APETAVERSE',
      profileImage: 'https://lh3.googleusercontent.com/6wQWKLjp6SK6wUGS-GI90ob9EwfAz_H0KEWYmwLXce0gmVmgugxi2-KGqoj1EB6XZTXSNECMcb9fDHFTxwdiQbGlfJ5vSFJNs51uUw=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 7,
      author: 'CLONE X - X...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133192-4676f193-023f-4ae8-b528-d38df26de796.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 8,
      author: 'Art Blocks ...',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133195-b5722d94-0827-46a7-b77c-6d0645de2b6b.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 9,
      author: 'Board Apeal',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133198-da2ecc2c-57a8-455a-afcc-c4fada6bd197.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 10,
      author: 'MAX PAIN',
      profileImage: 'https://user-images.githubusercontent.com/45615584/166133201-ad626af1-e27a-4880-a062-9a913838f51e.png',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    },
    {
      rank: 11,
      author: 'Azuki',
      profileImage: 'https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s96',
      cryptoIcon: 'https://static.opensea.io/general/ETH.svg',
      price: '2,365',
      timestamp: '+7% 1 Day Ago'
    }
  ]
};

export const TEMP_ARTICLE = [
  {
    id: '1',
    title: 'Beyond Bitcoin: Diversification Can Help Manage Crypto Risks',
    content: 'When it comes to investing, diversification is key. By spreading your wealth around, you’re less likely to suffer a m',
    author: {
      photo: 'https://pbs.twimg.com/profile_images/651797520658489345/BVYPZWH7_bigger.jpg',
      name: 'Andy Rosen',
      email: 'arosen@nerdwallet.com.'
    }
  },
  {
    id: '2',
    title: 'Beyond Bitcoin: Diversification Can Help Manage Crypto Risks',
    content: 'When it comes to investing, diversification is key. By spreading your wealth around, you’re less likely to suffer a m',
    author: {
      photo: 'https://pbs.twimg.com/profile_images/651797520658489345/BVYPZWH7_bigger.jpg',
      name: 'Andy Rosen',
      email: 'arosen@nerdwallet.com.'
    }
  },
  {
    id: '3',
    title: 'Beyond Bitcoin: Diversification Can Help Manage Crypto Risks',
    content: 'When it comes to investing, diversification is key. By spreading your wealth around, you’re less likely to suffer a m',
    author: {
      photo: 'https://pbs.twimg.com/profile_images/651797520658489345/BVYPZWH7_bigger.jpg',
      name: 'Andy Rosen',
      email: 'arosen@nerdwallet.com.'
    }
  }
];

export const COLLECTION_ITEM = [
  {
    nftName: 'Smircs',
    nftCtg: 'Smircs',
    nftBg: 'https://smircs.com/smircs/1.png',
    nftThum: 'https://lh3.googleusercontent.com/Temghd6YxpZ-MVio3rCtuf3s4Tl31Rkp2suVAg3Q-tX2oU_GQOkXfZvvNU5pvF4k613alcZ-VmleOfG879X2boCMB2KnfK2Z5KZp=w600',
    content: 'Smircs is a non generative collection handcrafted by combrisi X flatizy.'
  },
  {
    nftName: 'Smircs',
    nftCtg: 'Smircs',
    nftBg: 'https://smircs.com/smircs/1.png',
    nftThum: 'https://lh3.googleusercontent.com/Temghd6YxpZ-MVio3rCtuf3s4Tl31Rkp2suVAg3Q-tX2oU_GQOkXfZvvNU5pvF4k613alcZ-VmleOfG879X2boCMB2KnfK2Z5KZp=w600',
    content: 'Smircs is a non generative collection handcrafted by combrisi X flatizy.'
  },
  {
    nftName: 'Smircs',
    nftCtg: 'Smircs',
    nftBg: 'https://smircs.com/smircs/1.png',
    nftThum: 'https://lh3.googleusercontent.com/Temghd6YxpZ-MVio3rCtuf3s4Tl31Rkp2suVAg3Q-tX2oU_GQOkXfZvvNU5pvF4k613alcZ-VmleOfG879X2boCMB2KnfK2Z5KZp=w600',
    content: 'Smircs is a non generative collection handcrafted by combrisi X flatizy.'
  },
  {
    nftName: 'Smircs',
    nftCtg: 'Smircs',
    nftBg: 'https://smircs.com/smircs/1.png',
    nftThum: 'https://lh3.googleusercontent.com/Temghd6YxpZ-MVio3rCtuf3s4Tl31Rkp2suVAg3Q-tX2oU_GQOkXfZvvNU5pvF4k613alcZ-VmleOfG879X2boCMB2KnfK2Z5KZp=w600',
    content: 'Smircs is a non generative collection handcrafted by combrisi X flatizy.'
  },
  {
    nftName: 'Smircs',
    nftCtg: 'Smircs',
    nftBg: 'https://smircs.com/smircs/1.png',
    nftThum: 'https://lh3.googleusercontent.com/Temghd6YxpZ-MVio3rCtuf3s4Tl31Rkp2suVAg3Q-tX2oU_GQOkXfZvvNU5pvF4k613alcZ-VmleOfG879X2boCMB2KnfK2Z5KZp=w600',
    content: 'Smircs is a non generative collection handcrafted by combrisi X flatizy.'
  }
];

export const COMMUNITY = [
  {
    title: 'Beyond Bitcoin: Diversification Can Help Manage Crypto Risks',
    author: {
      photo: 'https://pbs.twimg.com/profile_images/651797520658489345/BVYPZWH7_bigger.jpg',
      name: 'Andy Rosen',
      timestamp: '1hr ago'
    },
    read: 305
  },
  {
    title: 'Beyond Bitcoin: Diversification Can Help Manage Crypto Risks',
    author: {
      photo: 'https://pbs.twimg.com/profile_images/651797520658489345/BVYPZWH7_bigger.jpg',
      name: 'Andy Rosen',
      timestamp: '1hr ago'
    },
    read: 305
  },
  {
    title: 'Beyond Bitcoin: Diversification Can Help Manage Crypto Risks',
    author: {
      photo: 'https://pbs.twimg.com/profile_images/651797520658489345/BVYPZWH7_bigger.jpg',
      name: 'Andy Rosen',
      timestamp: '1hr ago'
    },
    read: 305
  }
];

export const QNA_ITEM = [
  {
    id: 0,
    postId: 0,
    iconUrl: 'https://user-images.githubusercontent.com/45615584/167830165-1d00f1c8-9813-4e47-be79-f1291a9b956a.png',
    ctg: 'buying',
    title: 'How do I buy fixed-price NFTs?',
    by: 'NVIA.',
    bg: '#000'
  },
  {
    id: 1,
    postId: 0,
    iconUrl: 'https://user-images.githubusercontent.com/45615584/167830175-844e4bb6-8f18-48a9-a42d-295f031e4a42.png',
    ctg: 'create',
    title: 'How do I create an NFT?',
    by: 'NVIA.',
    bg: '#007EB5'
  }
];

export const CTG = [
  { name: 'art', img: 'https://user-images.githubusercontent.com/45615584/201080742-1d946f91-3979-4ecb-b096-b73f72890acf.png', keyword: 'art' },
  { name: 'collectibles', keyword: 'collectibles', img: 'https://user-images.githubusercontent.com/45615584/201080758-294b9454-78d0-4dbe-a06e-7927e84c674f.png' },
  { name: 'photo graphy', img: 'https://user-images.githubusercontent.com/45615584/201080768-be8b06e5-710f-4db8-8b97-fd6a8a759223.png', keyword: 'photography' },
  { name: 'sports', img: 'https://user-images.githubusercontent.com/45615584/201080774-9396da84-59e7-49bd-8557-b320142afcef.png', keyword: 'sports' },
  { name: 'trading Card', img: 'https://user-images.githubusercontent.com/45615584/201081514-33fd163f-002c-4b8f-a47b-ab593b69752a.png', keyword: 'tradingCard' },
  { name: 'character', img: 'https://user-images.githubusercontent.com/45615584/201080796-ed9411dd-69d7-4484-81d7-7663dd518344.png', keyword: 'character' },
  { name: 'games', img: 'https://user-images.githubusercontent.com/45615584/201081772-8b1a4b21-21ee-4ff8-8f2b-8546fc9c1160.png', keyword: 'games' },
  { name: 'fanArt', img: 'https://user-images.githubusercontent.com/45615584/201080821-1a63fffc-befa-4ced-8cec-31b74ff4d9c0.png', keyword: 'fanArt' },
  { name: 'membership', img: 'https://user-images.githubusercontent.com/45615584/201080838-2cf3eb78-e74b-4818-b33a-0e5f56499d26.png', keyword: 'membership' },
  { name: 'world', img: 'https://user-images.githubusercontent.com/45615584/201081922-63b758fd-d463-4d0e-8083-d5fa419b2641.png', keyword: 'world' }
];

export const CTG_TAG = ['ART', 'MUSIC', 'GAMES', 'PHOTOGRAPHY', 'DOMAINS', 'SPORTS', 'VIRTUAL WORDS', 'METAVERSES', 'ETC'];

export const TRENDINGS: any = {
  'art': [
    {
      id: 4,
      type: 'author',
      nftName: 'My Beautiful Little Nightmares',
      authorName: 'VintageMozart',
      nftBg: 'https://user-images.githubusercontent.com/45615584/175956996-e564465f-2624-4d8e-a4d2-af869aba48f5.png',
      nftThumb: 'https://user-images.githubusercontent.com/45615584/175957166-b428efa8-f393-48cd-8f83-3de8a3da4114.png',
      content: `This collection was about returning to my roots, i've been a setDigital artist for many years and i have always yearned`
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Anticyclone by William Mapan',
      authorName: 'ArtBlocks_Admin',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166118972-a4981295-f13e-46bb-8b56-e947aa7bf0d0.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166118974-93f8f4aa-c902-4f27-95c2-9847a9b629cb.png',
      content: `This collection was about returning to my roots, i've been a setDigital artist for many years and i have always yearned`
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ],
  'music': [
    {
      id: 4,
      type: 'author',
      nftName: 'Snoop Dogg - B.O.D.R',
      authorName: 'cooltown',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166118978-1d4a3ee6-25f5-4987-b27a-c68e7c0de44a.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166118980-5bdba9ae-47a0-45b1-bedf-8e4308a6b051.png',
      content: `NFTs of Snoop Dogg's B.O.D.R., brought to you exclusively by Gala Music.`
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Future Dream is Comming',
      authorName: 'Poe',
      nftBg: 'https://smircs.com/smircs/2.png',
      nftThumb: 'https://lh3.googleusercontent.com/fXfimMmYPjo4jvl7IrhZkii8ZtAh5-_n3Pw824KaxfWVYWm5i7ueh9GVJAc7v9hjivXevoK0ReWya9F8oiIPcfSrMMsFIyTZhM8TLQ=s130',
      content: 'Mutant Shiba Club is a collection of 10,000 animated NFTs. Each NFT is unique and resides on the Ethereum blockchain.'
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ],
  'games': [
    {
      id: 4,
      type: 'author',
      nftName: 'Void Crystal',
      authorName: 'Parallel',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166118982-c5976418-51a5-4554-ace6-64bc9ccaeb9c.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166118983-ca8aa88e-b21b-412c-a142-d58c339840d8.png',
      content: 'Sci-fi collectable card game with NFTs.'
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Future Dream is Comming',
      authorName: 'Poe',
      nftBg: 'https://smircs.com/smircs/2.png',
      nftThumb: 'https://lh3.googleusercontent.com/fXfimMmYPjo4jvl7IrhZkii8ZtAh5-_n3Pw824KaxfWVYWm5i7ueh9GVJAc7v9hjivXevoK0ReWya9F8oiIPcfSrMMsFIyTZhM8TLQ=s130',
      content: 'Mutant Shiba Club is a collection of 10,000 animated NFTs. Each NFT is unique and resides on the Ethereum blockchain.'
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ],
  'photography': [
    {
      id: 4,
      type: 'author',
      nftName: 'Actors',
      authorName: 'Markmann',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166118986-c3ffa52b-6b40-4307-b3b2-e3c3f7bed334.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166118987-1f454289-efc5-455d-b0b2-64ae08d68618.png',
      content: `MARK MANN is a photographer originally from the sun soaked streets of Glasgow Scotland with NFTs. `
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Future Dream is Comming',
      authorName: 'Poe',
      nftBg: 'https://smircs.com/smircs/2.png',
      nftThumb: 'https://lh3.googleusercontent.com/fXfimMmYPjo4jvl7IrhZkii8ZtAh5-_n3Pw824KaxfWVYWm5i7ueh9GVJAc7v9hjivXevoK0ReWya9F8oiIPcfSrMMsFIyTZhM8TLQ=s130',
      content: 'Mutant Shiba Club is a collection of 10,000 animated NFTs. Each NFT is unique and resides on the Ethereum blockchain.'
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ],
  'domains': [
    {
      id: 4,
      type: 'author',
      nftName: 'Unstoppable Domains Animals',
      authorName: 'Unstoppable_Domains',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166119005-daa8e256-d6c3-4187-892e-b521d3d98615.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166119006-dcc0d785-9270-4370-bde1-d5e347ec8e91.png',
      content: `Using the IPFS technology, Etherland is connecting physical real estate and world places' data with a ...`
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Future Dream is Comming',
      authorName: 'Poe',
      nftBg: 'https://smircs.com/smircs/2.png',
      nftThumb: 'https://lh3.googleusercontent.com/fXfimMmYPjo4jvl7IrhZkii8ZtAh5-_n3Pw824KaxfWVYWm5i7ueh9GVJAc7v9hjivXevoK0ReWya9F8oiIPcfSrMMsFIyTZhM8TLQ=s130',
      content: 'Mutant Shiba Club is a collection of 10,000 animated NFTs. Each NFT is unique and resides on the Ethereum blockchain.'
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ],
  'sports': [
    {
      id: 4,
      type: 'author',
      nftName: 'Pol Espargaró',
      authorName: 'MotoGP™ Ignition',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166118996-5c52879d-2809-43ae-b196-a34857b8d1b1.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166118997-453f6f58-f179-46e0-b816-802764e7d43f.png',
      content: `Kiwami is a next-generation collective of artists, builders, and rule-breakers. `
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Future Dream is Comming',
      authorName: 'Poe',
      nftBg: 'https://smircs.com/smircs/2.png',
      nftThumb: 'https://lh3.googleusercontent.com/fXfimMmYPjo4jvl7IrhZkii8ZtAh5-_n3Pw824KaxfWVYWm5i7ueh9GVJAc7v9hjivXevoK0ReWya9F8oiIPcfSrMMsFIyTZhM8TLQ=s130',
      content: 'Mutant Shiba Club is a collection of 10,000 animated NFTs. Each NFT is unique and resides on the Ethereum blockchain.'
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ],
  'virtual words': [
    {
      id: 4,
      type: 'author',
      nftName: 'Etherland',
      authorName: 'Etherland',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166119001-26f4f4c7-96a7-4875-b5c0-0ae3d25883d4.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166119003-bc35d554-f0e6-4989-a25b-54cb232baaba.png',
      content: `Using the IPFS technology, Etherland is connecting physical real estate and world places' data with a ...`
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Future Dream is Comming',
      authorName: 'Poe',
      nftBg: 'https://smircs.com/smircs/2.png',
      nftThumb: 'https://lh3.googleusercontent.com/fXfimMmYPjo4jvl7IrhZkii8ZtAh5-_n3Pw824KaxfWVYWm5i7ueh9GVJAc7v9hjivXevoK0ReWya9F8oiIPcfSrMMsFIyTZhM8TLQ=s130',
      content: 'Mutant Shiba Club is a collection of 10,000 animated NFTs. Each NFT is unique and resides on the Ethereum blockchain.'
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ],
  'metaverses': [
    {
      id: 4,
      type: 'author',
      nftName: 'Kiwami ',
      authorName: 'KIWAMI Genesis',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166118989-7117c293-c526-4a2b-8ce8-96c7da304130.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166118993-d38adb8e-95fd-4197-aba3-a79e1639e9dc.png',
      content: `Kiwami is a next-generation collective of artists, builders, and rule-breakers. `
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Future Dream is Comming',
      authorName: 'Poe',
      nftBg: 'https://smircs.com/smircs/2.png',
      nftThumb: 'https://lh3.googleusercontent.com/fXfimMmYPjo4jvl7IrhZkii8ZtAh5-_n3Pw824KaxfWVYWm5i7ueh9GVJAc7v9hjivXevoK0ReWya9F8oiIPcfSrMMsFIyTZhM8TLQ=s130',
      content: 'Mutant Shiba Club is a collection of 10,000 animated NFTs. Each NFT is unique and resides on the Ethereum blockchain.'
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ],
  'etc': [
    {
      id: 4,
      type: 'author',
      nftName: 'The Lofts Business Club',
      authorName: 'TheLoftsBusinessClub_...',
      nftBg: 'https://user-images.githubusercontent.com/6345000/166119009-80300630-bada-45b6-9232-d35c57184fee.png',
      nftThumb: 'https://user-images.githubusercontent.com/6345000/166119010-03790f7b-5f93-4f19-befc-d9d9442fa58e.png',
      content: `Building the Agora, the Metaverse for shopping and business where you will be able to purchase NFT's...`
    },
    {
      id: 7,
      type: 'author',
      nftName: 'Future Dream is Comming',
      authorName: 'Poe',
      nftBg: 'https://smircs.com/smircs/2.png',
      nftThumb: 'https://lh3.googleusercontent.com/fXfimMmYPjo4jvl7IrhZkii8ZtAh5-_n3Pw824KaxfWVYWm5i7ueh9GVJAc7v9hjivXevoK0ReWya9F8oiIPcfSrMMsFIyTZhM8TLQ=s130',
      content: 'Mutant Shiba Club is a collection of 10,000 animated NFTs. Each NFT is unique and resides on the Ethereum blockchain.'
    },
    {
      id: 8,
      type: 'author',
      nftName: "DooDoos's Dreaming",
      authorName: 'DooDoos',
      nftBg: 'https://lh3.googleusercontent.com/QH8uwjc6ov9TY5E6GZDLhdTMlFPjsDFA6krbQFye9FrLu064malFxCKCNjo2ABOlkShTyLq1TeVPmryvIFPr80sRoV0PeeDSi7bNUw=h600',
      nftThumb: 'https://lh3.googleusercontent.com/Mkf2feqSlha_8-KeSFTX_aeO38I5endrEU-fhfVORpW_MDrzRx-B-5bYW6-_udwgw00Ifgt4O-6ncyFfwZmnLsOM5Eq0qGg-LJWW=s130',
      content: 'A collection of 69 Genesis Doodoos.  Doodoos are not affiliated with any other NFT and are just silly fun.'
    }
  ]
};

/*
id:,
    name:'',
    bgUrl:'',
    avatarUrl:'',
    displayDecimal:1,
    price:952,
    items:89,
    volume:1418
*/
export const MARKET_LIST = [
  {
    id: 0,
    name: 'Women Unite - 10k Assemble',
    bgUrl: 'https://user-images.githubusercontent.com/45615584/175954525-4f78b7df-4375-43bf-bdea-cdbc2f72b66a.png',
    avatarUrl: 'https://user-images.githubusercontent.com/45615584/175954589-e29420fb-fbc7-44bb-9f3b-c30ed0e5110e.png',
    displayDecimal: 1,
    cryptoIcon: 'https://user-images.githubusercontent.com/45615584/171037837-383cebd1-c8cb-4108-ac0d-e10e3b3b342b.png',
    price: '95,2',
    items: '89K',
    volume: '141,8'
  },
  {
    id: 0,
    name: `Sandbox's LANDs`,
    bgUrl: 'https://user-images.githubusercontent.com/45615584/171036842-f1a27be4-a1df-4d6a-95cb-e028819fbf52.png',
    avatarUrl: 'https://user-images.githubusercontent.com/45615584/171036857-f6af3071-8b99-46de-8232-e638bc6cd251.png',
    displayDecimal: 1,
    cryptoIcon: 'https://user-images.githubusercontent.com/45615584/171037837-383cebd1-c8cb-4108-ac0d-e10e3b3b342b.png',
    price: '303,2M',
    items: '109.7K',
    volume: '49M'
  }
];

export const COMMUNITY_CTG = ['ALL', 'ART', 'GAMES', 'PHOTOGRAPHY', 'DOMAINS', 'SPORTS', 'VIRTUAL WORDS', 'METAVERSES', 'ETC'];

export const HOT_COMMUNITY_LIST: any = {
  'all': [
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/175954813-bd7263ec-c87f-4578-a51e-e81562584eff.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/175954832-2bc8b719-a324-4d1e-998b-14495618eac8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/175954837-438fc9d2-3e4a-4dac-a375-34ebc9949d72.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/175955174-2a4decd6-7e03-46f8-801b-0250daf278f2.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ],
  'art': [
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049665-2334d127-6e52-4fb6-ba4c-a79c933302e4.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/171043373-aa06a962-f354-4f51-b34b-1680938b5dd8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049681-39fd973b-fae4-4f55-a0ad-0def8e038d73.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049689-f2ebc173-ddcc-4b49-8bae-c3180daf29e0.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ],
  'games': [
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049681-39fd973b-fae4-4f55-a0ad-0def8e038d73.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049689-f2ebc173-ddcc-4b49-8bae-c3180daf29e0.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/171043373-aa06a962-f354-4f51-b34b-1680938b5dd8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049665-2334d127-6e52-4fb6-ba4c-a79c933302e4.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ],
  'photography': [
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/171043373-aa06a962-f354-4f51-b34b-1680938b5dd8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049681-39fd973b-fae4-4f55-a0ad-0def8e038d73.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049665-2334d127-6e52-4fb6-ba4c-a79c933302e4.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049689-f2ebc173-ddcc-4b49-8bae-c3180daf29e0.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ],
  'domains': [
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049689-f2ebc173-ddcc-4b49-8bae-c3180daf29e0.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/171043373-aa06a962-f354-4f51-b34b-1680938b5dd8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049665-2334d127-6e52-4fb6-ba4c-a79c933302e4.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049681-39fd973b-fae4-4f55-a0ad-0def8e038d73.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ],
  'sports': [
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/171043373-aa06a962-f354-4f51-b34b-1680938b5dd8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049665-2334d127-6e52-4fb6-ba4c-a79c933302e4.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049681-39fd973b-fae4-4f55-a0ad-0def8e038d73.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049689-f2ebc173-ddcc-4b49-8bae-c3180daf29e0.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ],
  'virtual words': [
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049665-2334d127-6e52-4fb6-ba4c-a79c933302e4.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/171043373-aa06a962-f354-4f51-b34b-1680938b5dd8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049681-39fd973b-fae4-4f55-a0ad-0def8e038d73.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049689-f2ebc173-ddcc-4b49-8bae-c3180daf29e0.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ],
  'metaverses': [
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/171043373-aa06a962-f354-4f51-b34b-1680938b5dd8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049665-2334d127-6e52-4fb6-ba4c-a79c933302e4.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049681-39fd973b-fae4-4f55-a0ad-0def8e038d73.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049689-f2ebc173-ddcc-4b49-8bae-c3180daf29e0.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ],
  'etc': [
    {
      id: 8,
      name: 'Ian',
      title: 'Sandbox’s LANDs',
      isOnline: false,
      content: '3000 artistic CryptsPAD keys which grant exclusive access to a financial DAO. The authentic zombies and skeletons are...',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049681-39fd973b-fae4-4f55-a0ad-0def8e038d73.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 194,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 4,
      name: 'justin',
      title: 'Bored Ape Yacht Club',
      isOnline: true,
      content: 'You are a great designer. Your work is very personal and fits my collection very well. I will always work with you. Will also ...',
      thumbnail: 'https://user-images.githubusercontent.com/45615584/171043373-aa06a962-f354-4f51-b34b-1680938b5dd8.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 98,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 7,
      name: 'Beaznofficial',
      title: 'Dolce&Gabbana : DFfamily',
      isOnline: true,
      content: 'https://twitter.com/liliomclub/status/1491048898756251649?s=21',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049665-2334d127-6e52-4fb6-ba4c-a79c933302e4.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 311,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    },
    {
      id: 9,
      name: 'bynoWRLD',
      title: 'Wibin Headphones',
      isOnline: true,
      content: '555 Headphones Wibin around the Solana blockchain',
      thumbnail: 'https://user-images.githubusercontent.com/6345000/171049689-f2ebc173-ddcc-4b49-8bae-c3180daf29e0.png',
      timestamp: '1hr ago',
      datestamp: 'Today at 1:50AM',
      comments: {
        total: 87,
        data: [
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043359-3a83eed7-6404-4261-8e20-c0f7862673ad.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043365-cf32a6e7-725a-404a-a136-f0dd7671c308.png'
          },
          {
            avatar: 'https://user-images.githubusercontent.com/45615584/171043371-9f353b2f-16f4-46d0-a294-d85b412502d0.png'
          }
        ]
      }
    }
  ]
};

export const MCN_LIST = [
  {
    uid: 9704,
    createdAt: '2022-10-24T10:20:33.000+0900',
    updatedAt: '2022-10-24T13:37:20.000+0900',
    deletedFlag: false,
    usedFlag: true,
    rank: 1,
    imageUrl: 'https://user-images.githubusercontent.com/6345000/198429765-f8b9d896-d37f-45a8-82b0-37212b833741.png',
    name: 'Summer #7932',
    seller: 'Be VEE - Summer Collection',
    price: '100',
    categoryCodeUid: 31,
    nftUid: 3074090,
    categoryName: '1 Day Ago',
    exlink: 'https://opensea.io/assets/ethereum/0xaea4fa9451f527d5f36e65f833d88dbb56a0c264/7932'
  },
  {
    uid: 9705,
    createdAt: '2022-10-24T10:20:33.000+0900',
    updatedAt: '2022-10-24T11:02:51.000+0900',
    deletedFlag: false,
    usedFlag: true,
    rank: 2,
    imageUrl: 'https://user-images.githubusercontent.com/6345000/198429802-9adb5e02-aa03-496c-a500-9d871858b864.png',
    name: 'MTDZ#9963',
    seller: 'Meta Toy DragonZ',
    price: '7.215',
    categoryCodeUid: 31,
    nftUid: 531934,
    categoryName: '1 Day Ago',
    exlink: 'https://opensea.io/assets/klaytn/0x46dbdc7965cf3cd2257c054feab941a05ff46488/9963'
  },
  {
    uid: 9706,
    createdAt: '2022-10-24T10:20:33.000+0900',
    updatedAt: '2022-10-24T13:37:24.000+0900',
    deletedFlag: false,
    usedFlag: true,
    rank: 3,
    imageUrl: 'https://user-images.githubusercontent.com/6345000/198429840-7be8a4a9-7770-4bb6-babf-d86782e7e7fc.png',
    name: 'MTG#1180',
    seller: 'Meta Toy Gamers',
    price: '0.0623',
    categoryCodeUid: 31,
    nftUid: 3074095,
    categoryName: '1 Day Ago',
    exlink: 'https://opensea.io/assets/klaytn/0x30b0bd905efa7503879554fa8f8714c5c82f8ebc/1692'
  },
  {
    uid: 9707,
    createdAt: '2022-10-24T10:20:33.000+0900',
    updatedAt: '2022-10-24T13:37:28.000+0900',
    deletedFlag: false,
    usedFlag: true,
    rank: 4,
    imageUrl: 'https://user-images.githubusercontent.com/6345000/198429902-5931640b-2da2-4f12-804a-486b82a031bf.png',
    name: 'MTDZ#9889',
    seller: 'Meta Toy DragonZ',
    price: '0.1826',
    categoryCodeUid: 31,
    nftUid: 3074101,
    categoryName: '1 Day Ago',
    exlink: 'https://opensea.io/assets/klaytn/0x46dbdc7965cf3cd2257c054feab941a05ff46488/9889'
  },
  {
    uid: 9708,
    createdAt: '2022-10-24T10:20:33.000+0900',
    updatedAt: '2022-10-24T13:37:33.000+0900',
    deletedFlag: false,
    usedFlag: true,
    rank: 5,
    imageUrl: 'https://user-images.githubusercontent.com/6345000/198430088-b1103580-ab6f-45c8-83b0-a58fa8d8f4bd.png',
    name: 'Summer #2716',
    seller: 'Be VEE - Summer Collection',
    price: '0.01',
    categoryCodeUid: 31,
    nftUid: 3074105,
    categoryName: '1 Day Ago',
    exlink: 'https://opensea.io/assets/ethereum/0xaea4fa9451f527d5f36e65f833d88dbb56a0c264/2716'
  }
];

export const McnItemList = [
  {
    imgUrl: 'https://user-images.githubusercontent.com/6345000/198501249-fdaf5a84-659c-42bf-94ba-acc1b89a8a75.png',
    content: 'Isaac "Drift" Wright is a United States Army special operations veteran who learned to cope with PTSD and depression through urban exploring',
    url: 'https://opensea.io/Driftershoots?tab=created&search[resultModel]=ASSETS&search[sortBy]=CREATED_DATE&search[identity][username]=Driftershoots&search[sortAscending]=false'
  },
  {
    imgUrl: 'https://user-images.githubusercontent.com/6345000/198501257-9c7fbb6c-d364-428e-8496-fd480924efc6.png',
    content: `The Breezyverse is a series of 3D animated NFT's with sound and visual effects implemented on the Ethereum blockchain.`,
    url: 'https://opensea.io/collection/the-breezyverse-genesis?search[sortBy]=UNIT_PRICE&search[sortAscending]=false&search[toggles][0]=BUY_NOW '
  },
  {
    imgUrl: 'https://user-images.githubusercontent.com/6345000/198501265-72c3c9e0-5eba-4229-90d4-e1dbeb04c4b0.png',
    content: `"Paris: Past Lives, New Beginnings" is a collaboration with two innovative female digital artists, Paris Hilton and Superplastic’s synthetic superstar, Dayzee.`,
    url: 'https://paris-hilton.story.xyz/ '
  },
  {
    imgUrl: 'https://user-images.githubusercontent.com/6345000/198501275-04c6c19b-08f2-46b2-a41e-83602a847fe0.png',
    content: `“metaverse” and in real life. The band even threw in ownership rights to your deathbat so we look forward to seeing your creativity. Inside the collection many 1/1 experiences are hidden and can be redeemed at avengedsevenfold.io.`,
    url: 'https://www.youtube.com/channel/UCFcqi7MrlzIp9RMTtUlxE8g'
  },
  {
    imgUrl: 'https://user-images.githubusercontent.com/6345000/198501287-463f87c2-c14d-4cdf-b95c-540fc217feda.png',
    content: `In CryptoKitties, users collect and breed oh-so-adorable creatures that we call CryptoKitties! Each kitty has a unique genome that defines its appearance and traits. Players can breed their kitties to create new furry friends and unlock rare cattributes.`,
    url: 'https://www.cryptokitties.co/search?include=sale '
  }
];
