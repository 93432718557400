import React from 'react';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import styled from 'styles/theme';
import Footer from './Footer';

import HomeSvg from 'assets/common/nav_home';
import SearchSvg from 'assets/common/nav_search';
import CommunitySvg from 'assets/common/nav_community';
import MarketSvg from 'assets/common/nav_market';
import WalletSvg from 'assets/common/nav_wallet';
import NftSvg from 'assets/common/nav_nft';
import ProfileSvg from 'assets/common/nav_profile';
import bg_round from 'assets/common/bg_round.png';
import useWindowDimensions from 'hook/useWindowDimensions';
import { View, Text, Button } from 'styles';
import { useSelector, useDispatch } from 'react-redux';
import AlertMessageSyles from 'styles/alert-message/AlertMessage';
import MainHeader from 'components/Header/MainHeader';

import cookie from 'js-cookie';

import APIService from 'api/index';
import ModalStyles from 'styles/modal/Modal';
import Web3 from 'web3';

import { profileUpdate } from 'store/reducers/System';

import { ReactComponent as NftMoaLogo } from 'assets/logo/nftmoa.svg';
import { ReactComponent as MetaMaskLogo } from 'assets/logo/metamask.svg';
import WalletItemComponent from 'components/v2/wallet-item/WalletItem';
import { mappingWalletLogo } from 'utils';
import BlinderUI from 'ui/blinder/Blinder';
import ButtonUI from 'ui/button/Button';

const apiService = new APIService();

export default () => {
  const navigate = useNavigate();
  const { profile, alertMessage } = useSelector((store: IStore) => store.System);
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();

  const [openConnectModal, setOpenConnectModal] = React.useState(false);
  const [openMetaMask, setOpenMetaMask] = React.useState(false);

  const nav = [
    {
      name: 'market',
      link: '/market',
      icon: MarketSvg
    },
    {
      name: 'nft',
      link: '/mynft',
      icon: NftSvg
    },
    {
      name: 'home',
      link: '/',
      icon: HomeSvg
    },
    {
      name: 'search',
      link: '/search',
      icon: SearchSvg
    },

    {
      name: 'community',
      link: '/community',
      icon: CommunitySvg
    }
  ];

  const signMessage = async (data: any) => {
    const timestamp = new Date(Date()).toISOString();
    const { publicKey } = data;

    const signNftMoaMessage = `Welcome to NVIA\n\nClick to sign in and accept the NVIA Terms of Service: https://cantena.io\n\nSign in with passwords are needed.\n\nApprove” only means you have proved this wallet is owned by you.\n\nThis request will not trigger any blockchain transaction or cost any fees.\n\n`;

    const message = signNftMoaMessage + JSON.stringify({ publicKey, timestamp });
    const provider = window.web3.currentProvider;
    const web3 = new Web3(provider);
    return new Promise((resolve, reject) => {
      web3.eth.personal
        .sign(message, publicKey!, publicKey!)
        .then(signature => resolve({ timestamp, signature }))
        .catch((error: any) => {
          // if (error.code === -32603) reject({ code: '__sign_message_canceled_by_user__', message: '사용자에 의해 signMessage가 취소되었습니다.' });
          // Trust
          // MetaMask
          // Coinbase
          // Binance
          if (error.code === -32603) reject({ code: '__extension_does_not_installed__', message: '사용자에 의해 signMessage가 취소되었습니다.' });
          else reject({ code: '__fail_sign_message__', message: 'binance SignMessage 실패' });
        });
    });
  };

  const handleConnect = async () => {
    setOpenConnectModal(true);
  };

  const handleOpenMetaMask = async () => {
    const installedWallet = Boolean(window.ethereum);
    // const installedWallet = Boolean(window.ethereum) || Boolean(window.BinanceChain);
    if (installedWallet) {
      try {
        const provider = window.web3.currentProvider;
        const web3 = new Web3(provider);
        const accounts = await web3.eth.requestAccounts();
        const publicKey = accounts[0];

        const result = (await signMessage({ publicKey })) as any;

        try {
          const loginResult = await apiService.connectWalletLogin({ publicKey, timestamp: result.timestamp, signature: result.signature });
          if (loginResult?.jwtToken) {
            setOpenConnectModal(false);
            cookie.set('accessToken', loginResult?.jwtToken.accessToken);
            cookie.set('refreshToken', loginResult?.jwtToken.refreshToken);
            cookie.set('expired', `${new Date().getTime()}`);
            dispatch(profileUpdate({ profile: loginResult }));
            document.removeEventListener('click', handleConnectEvent);
          } else {
            alert('please connect wallet retry');
            return;
          }
        } catch {
          alert('please connect wallet retry');
        }

        // // const { signature, timestamp } = await ChainNetworkService.bsc.signMessage({ signMessage: env.SIGN_MESSAGE, wallet, account: publicKey! });
        // const result = await login('bsc', { publicKey, timestamp, signature });
      } catch (e: any) {
        console.log('ddd');
      }
    } else {
      setOpenConnectModal(false);
      setOpenMetaMask(true);
    }
  };

  const handleCancle = () => {
    setOpenConnectModal(false);
    setOpenMetaMask(false);
    setCurWallet('metamask');
    setIsMoreWallet(false);
  };

  const moveGnb = ({ name, link }: any) => {
    if (!profile && (name === 'community' || name === 'nft')) {
      setTimeout(() => navigate('/'), 1);
      handleConnect();
      return false;
    }
  };

  // wrap-google
  React.useEffect(() => {
    window.addEventListener('click', () => {
      const googleWrap = document.querySelector('.wrap-google');
      if (googleWrap) {
        googleWrap.addEventListener('click', e => {
          const target = e.target as HTMLAnchorElement;

          if (target.className.includes('gs-title') || (target.className.includes('gs-image') && target.nodeName === 'A')) {
            console.log(target.nodeName, 'target.nodeName');
            e.preventDefault();
            e.stopImmediatePropagation();

            window.open(target.href);
          } else if (target.nodeName === 'B' || target.nodeName === 'IMG') {
            e.preventDefault();
            e.stopImmediatePropagation();

            const parent = target.parentElement;

            if (parent && (parent.className.includes('gs-title') || target.className.includes('gs-image'))) {
              window.open((parent as HTMLAnchorElement).href);
            }
          }

          console.log(target.className, 'target.className');
        });
      }
    });
  }, []);

  const [curWallet, setCurWallet] = React.useState<WalletListUnion>('metamask');
  const [isMoreWallet, setIsMoreWallet] = React.useState<boolean>(false);
  const hasMoreHeight = React.useMemo(() => (!isMoreWallet ? 133 : '100%'), [isMoreWallet]);

  const WALLET_LIST: { id: number; value: WalletListUnion; name: string }[] = [
    {
      id: 0,
      value: 'metamask',
      name: 'METAMASK'
    },
    {
      id: 2,
      value: 'coinbase',
      name: 'Coinbase Wallet'
    },
    {
      id: 1,
      value: 'moa',
      name: 'NVIA Wallet'
    },
    {
      id: 3,
      value: 'wallet',
      name: 'WalletConnect'
    },
    {
      id: 4,
      value: 'trust',
      name: 'Trust'
    },
    {
      id: 5,
      value: 'phantom',
      name: 'Phantom'
    },
    {
      id: 6,
      value: 'kaikas',
      name: 'Kaikas'
    },
    {
      id: 7,
      value: 'solflare',
      name: 'Solflare'
    }
  ];

  const WALLET_LIST_SORT = WALLET_LIST.sort((a, b) => a.id - b.id);

  const changeCurWallet = (value: WalletListUnion) => {
    setCurWallet(value);
  };

  const handleConnectEvent = (e: MouseEvent) => {
    const target = e.target as HTMLButtonElement;

    if (target.classList.contains('wallet-button')) {
      handleConnect();
    }
  };

  React.useEffect(() => {
    if (!profile) {
      document.addEventListener('click', handleConnectEvent);
    }
  }, [profile]);

  return (
    <Layout>
      <Container>
        {alertMessage.type && <AlertMessageSyles title={alertMessage.msg} type={alertMessage.type} />}
        <MainHeader callConnect={handleConnect} />
        <StyledOutlet minHeight={height - 50 - 89}>
          <Outlet />
        </StyledOutlet>
        <Footer />
        <ModalStyles w={345} isCenter={true} type="empty" open={openConnectModal} handleCancle={handleCancle}>
          <View spacing={24} direction="column">
            <NftMoaLogo />
            <View direction="column">
              <View mb={12}>
                <Text size="sm" lh={20}>
                  Please choose how you want to connect.
                </Text>
              </View>
              <View mb={24}>
                <Text size="sm" lh={20}>
                  If you don’t have a wallet, you can select a provider and create one.
                </Text>
              </View>

              <StyledSelectedWallet>
                <View border="1px solid #c9c9c9" h={56} padding="0 14px 0 25" align="center" radius={20} onClick={curWallet === 'metamask' && handleOpenMetaMask} block>
                  <View justify="space-between" align="center" block>
                    <View align="center" spacing={9}>
                      <img className="img-logo" src={mappingWalletLogo(curWallet)} />
                      <Text size="md" weight="semi-bold">
                        {WALLET_LIST.filter(item => item.value === curWallet)[0].name}
                      </Text>
                    </View>
                    <Button text="Select" color="blue" h={30} w={80} fontSize={12} />
                  </View>
                </View>
              </StyledSelectedWallet>

              <View direction="column" spacing={16} block mb={24}>
                <BlinderUI h={hasMoreHeight} gradient={false}>
                  <View direction="column" spacing={10} mb={24} block>
                    {WALLET_LIST_SORT.map((item, idx) => (
                      <WalletItemComponent key={idx} value={item.value} name={item.name} curWallet={curWallet} setCurWallet={setCurWallet} onClick={() => changeCurWallet(item.value)} />
                    ))}
                  </View>
                </BlinderUI>

                {!isMoreWallet && (
                  <View block align="center" justify="center">
                    <ButtonUI shape="round" w={160} h={36} onClick={() => setIsMoreWallet(true)} border={'1px solid #C9C9C9'} fontColor="#000" fontSize={14}>
                      + Show More
                    </ButtonUI>
                  </View>
                )}
              </View>
              {/* <View mb={24} block>
                <View border="1px solid #c9c9c9" padding="6px 14" radius={20} block>
                  <View justify="space-between" align="center" block>
                    <MetaMaskLogo />
                    <Button text="Select" color="blue" h={30} w={80} fontSize={12} onClick={handleOpenMetaMask} />
                  </View>
                </View>
              </View> */}

              <View mb={10}>
                <Text size="sm" lh={20}>
                  Under security verification for the application of other wallets provider.
                </Text>
              </View>
              <View mb={20}>
                <Text size="sm" lh={20}>
                  Validated wallets will continue to be added soon.
                </Text>
              </View>
            </View>
          </View>
        </ModalStyles>

        <ModalStyles w={'90%'} isCenter={true} type="empty" open={openMetaMask} handleCancle={handleCancle}>
          <StyledIframe src={'https://metamask.io/download/'} />
        </ModalStyles>
      </Container>

      {/* 하단 네비게이션 */}
      <Nav id={'Nav'}>
        {nav.map((item, index) => (
          <View block align="center" justify="flex-start" key={index} direction="column">
            <NavLink to={item.link} onClick={() => moveGnb(item)}>
              {({ isActive }) => <item.icon active={isActive} />}
            </NavLink>
            <Text color={'#000'} size={'xxs'} align="center">
              {item.name}
            </Text>
          </View>
        ))}
      </Nav>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin: 0 auto;
  max-width: 650px;
  min-height: 100vh;
  background-color: ${p => p.theme.COLORS.BG_BODY};
`;

const Container = styled.div`
  order: 1;
  width: 100%;
  padding: 50px 0 90px 0;
`;

const Nav = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: #ffffff;
  border-top: 1px solid #cccccc;
  box-sizing: border-box;
  box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;

  &.active:before {
    content: '';
    position: absolute;
    top: -13px;
    left: 0;
    width: 100%;
    height: 103px;
    background: url(${bg_round}) center -3px no-repeat;
  }

  a {
    display: block;
    position: relative;
    padding: 15px 0 0;
    text-align: center;
    z-index: 2;

    &.active {
      svg {
        path {
          stroke: linear-gradient(direction, #ff0000, #ff00d6, #001d84);
        }
      }
    }
  }

  div {
    font-weight: 600 !important;
    text-transform: uppercase;
  }
`;

const StyledOutlet = styled.div<{ minHeight?: number }>`
  min-height: ${p => p.minHeight}px;

  & > div {
    position: relative;
    min-height: ${p => p.minHeight}px;
  }
`;

const StyledSelectedWallet = styled.div`
  width: 100%;
  margin-bottom: 17px;

  .img-logo {
    width: 24px;
    height: 22px;
    object-fit: contain;
  }
`;
const StyledIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 100px);
`;
