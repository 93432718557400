import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/svg/deleteCircle.svg';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import View from 'styles/view';

export interface ModalProps {
  open?: boolean;
  handleCancle?: VoidFunction;
  type?: 'empty' | 'default' | 'select' | 'confirm';
  children?: React.ReactNode;
  header?: React.ReactNode;
  isCenter?: boolean;
  w?: number | string;
  maxW?: number | string;
  footer?: React.ReactNode;
  isClose?: boolean;
}

const ModalStyles: React.FC<ModalProps> = ({ w, isClose, open, isCenter, footer, header, type, handleCancle, children, ...props }) => {
  const renderClosable = type === 'select' || !isClose ? false : true;

  return (
    <StyledModal>
      <Modal width={w} wrapClassName={`modal-type-${type}`} closable={renderClosable} centered={isCenter} closeIcon={<CloseIcon />} open={open} onCancel={handleCancle} footer={false}>
        <StyledModalInner>
          {header && <div className="header">{header}</div>}
          <div className={`content ${type === 'select' ? 'select-content' : ''}`}>{children}</div>
          {footer && <div className="footer">{footer}</div>}
        </StyledModalInner>
      </Modal>
    </StyledModal>
  );
};

export default ModalStyles;

ModalStyles.defaultProps = {
  type: 'default',
  isCenter: false,
  isClose: true
};

const StyledModal = styled.div`
  max-width: 345px;

  .modal-type-select {
    .content {
      display: flex;
      flex-direction: column;
    }
  }
`;

const StyledModalInner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;

  & > div {
    width: 100%;
  }

  .content {
    min-height: 120px;
    display: flex;
    flex: 1 1 auto;
  }

  .select-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .select-item {
      width: 100%;
      padding: 12px 0;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #808080;
      font-weight: bold;
    }
  }
`;
