import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styles/theme';
import ScrollWrap from 'components/common/slideWrap';
import Section from 'components/common/section';
import RankItem from 'components/rankItem';
// import ArticleItem from 'components/articleItem';
import CommunityItem from 'components/communityItem';
import CollectionItem from 'components/topSellerItem';
import BrowseFavoriteItem from 'components/browseFavoriteItem';
import QnaItem from 'components/qnaItem';
import { ReactComponent as SelectDownArrowIcon } from 'assets/svg/downArrow.svg';

import { Carousel, Select } from 'antd';

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';

import { Banners, CardBgAvatar, Price, Rollings, Tab, ThumbTitle, View, FlashNews, Text, MoaLogo, Button } from 'styles';
import { BANNERS, CTG, BROWSERS, COMMUNITY_CTG, HOT_COMMUNITY_LIST, MCN_LIST, McnItemList } from 'api/mock/home';
import AuthorItemComponent from 'components/authorItem';
import { AD_LIST } from 'api/mock/search';
import NewsItemComponent from 'components/v2/news-item/NewsItem';

import { scamList } from '../search/result/v2/dummy';
import APIService from 'api/index';
import BestDrop from './components/BestDrop';
import Droptem from './components/Droptem';
import { useHome } from './useHome';
import ArticleItem from './components/articleItem/ArticleItem';
import ListCardComponent from 'views/scam/components/ListCard';

const apiService = new APIService();

const HomeView: React.FC<any> = ({ user, ...props }) => {
  const navigate = useNavigate();
  const [topItemDate, setTopItemDate] = React.useState('31');
  const [topSellerDate, setTopSellerDate] = React.useState('31');
  const [activeTab, setActiveTab] = React.useState('');
  const [activeCommunityTab, setActiveCommunityTab] = React.useState('ALL');
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [articleList, setArticleList] = React.useState<any>([]);
  const [collectionCategory, setCollectionCategory] = React.useState([]);
  const [collection, setCollection] = React.useState([]);
  const [tabCollection, setTabCollection] = React.useState([]);
  const [faq, setFaq] = React.useState([]);
  const [rankCtg, setRankCtg] = React.useState([]);
  const [rankItem, setRankItem] = React.useState([]);
  const [rankSeller, setRankSeller] = React.useState([]);
  const { Option } = Select;

  const tradingSlider = React.useRef(null);

  const { dummyArticleList, scamAlert } = useHome();

  const handleChangeTopItem = (value: string) => {
    getRankItem({ uid: value, size: '5' });
    setTopItemDate(value);
  };

  const handleChangeSeller = (value: string) => {
    getRankSeller({ uid: value, size: '12' });
    setTopSellerDate(value);
  };

  const getData = async () => {
    const articleData = await apiService.getArticleList({ page: 1, size: 10 });

    setArticleList(articleData.list);
  };

  const getCollectionCategory = async () => {
    const resultCollectionCategory = await apiService.getCollectionCategory();
    setCollectionCategory(resultCollectionCategory.list);
    setActiveTab(resultCollectionCategory.list[0].name);
  };

  const getCollectionList = async () => {
    const resultCollection = await apiService.getCollectionList({ searchValue: 'fast' });
    setCollection(resultCollection.list);
  };

  const getCollectionTab = async () => {
    const resultCollection = await apiService.getCollectionList({ searchValue: activeTab });
    setTabCollection(resultCollection.list);
  };

  const getFaqCtg = async () => {
    const faqData = await apiService.getFaqCtg();

    setFaq(faqData.list);
  };

  const getRankCtg = async () => {
    const resultData = await apiService.getRankCategory();

    setRankCtg(resultData.list);
    setTopItemDate(`${resultData.list[0].uid}`);
    setTopSellerDate(`${resultData.list[0].uid}`);
  };

  const getRankItem = async ({ uid, size }: any) => {
    const resultData = await apiService.getRankItem({ uid, size });

    setRankItem(resultData.list);
  };

  const getRankSeller = async ({ uid, size }: any) => {
    const resultData = await apiService.getRankSeller({ uid, size });

    setRankSeller(resultData.list);
  };

  const handleSearch = (e: any) => {
    let searchWord;
    if (e.keyCode && e.keyCode === 13) {
      searchWord = e.target.value;
      if (searchWord.length > 2) {
        navigate(`/search/result?name=${searchWord}`);
      } else {
        alert('Please enter at least 3 characters.');
      }
    } else if (!e.keyCode) {
      const getValue = (document.getElementById('nft-search') as HTMLInputElement).value;
      searchWord = getValue;

      if (searchWord.length > 2) {
        navigate(`/search/result?name=${searchWord}`);
      } else {
        alert('Please enter at least 3 characters.');
      }
    }
  };

  const handleTradingTab = (tab: string) => {
    setActiveTab(tab);
    if (tradingSlider.current) {
      (tradingSlider.current as any).goTo(0);
    }
  };

  const handleCommunityTab = (tab: string) => {
    setActiveCommunityTab(tab);
    if (tradingSlider.current) {
      (tradingSlider.current as any).goTo(0);
    }
  };

  const moveToExplore = (uid: any, name: any) => {
    navigate(`/search/result?name=${name}`);
  };

  React.useEffect(() => {
    getData();
    getFaqCtg();
    getCollectionCategory();
    getCollectionList();
    getCollectionTab();
    getRankCtg();
    getRankItem({ uid: '31', size: '5' });
    getRankSeller({ uid: '31', size: '12' });
  }, []);

  React.useEffect(() => {
    getCollectionTab();
  }, [activeTab]);

  return (
    <Wrap>
      <SearchBig>
        <div className="wrap">
          <input placeholder="Search anything NFT items _" id="nft-search" onKeyDown={handleSearch} />
          <SearchIcon className="icon_search" onClick={handleSearch} />
        </div>
        <p>
          Here’s the NFT Items, collection name <strong>NVIA.</strong>
        </p>
      </SearchBig>
      <Rollings list={BANNERS} />
      <ScrollWrap className={'browse-nft'}>
        {BROWSERS.map((item, idx) => (
          <BrowseFavoriteItem key={idx} {...item} setIsFavorite={setIsFavorite} onClick={() => window.open(item.url)} />
        ))}
      </ScrollWrap>
      <Section
        round
        moreLink={'/rank/item'}
        className="wrap-top-item"
        title={
          <div>
            <span>Top Items in</span>
            {rankCtg.length > 0 && (
              <Select suffixIcon={<SelectDownArrowIcon />} bordered={false} defaultValue={topItemDate} onChange={handleChangeTopItem}>
                {rankCtg.map((item: any, index: number) => (
                  <Option key={index} value={`${item.uid}`}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </div>
        }
      >
        {rankItem.map((item: any, index: number) => {
          if (index > 4) return false;
          return <RankItem {...item} key={index} />;
        })}
      </Section>
      {/* <Section
        round
        className="wrap-top-item"
        title={
          <div>
            <span>Hot in MCN</span>
          </div>
        }
      >
        {MCN_LIST.map((item: any, index: number) => {
          if (index > 4) return false;
          return <RankItem {...item} key={index} onClick={() => window.open(item.exlink)} />;
        })}
      </Section> */}
      <Section round className="wrap-top-item">
        <BestDrop />
      </Section>
      <Section title={'Popular MCN Creators or Web 3.0'} style={{ backgroundColor: '#F5F5F5' }}>
        {McnItemList.map((item, index) => (
          <Droptem key={index} {...item} />
        ))}
      </Section>
      <Rollings list={[{ id: 0, img: 'https://user-images.githubusercontent.com/6345000/198515346-477ba61a-289b-4177-b36a-5eceb268c81c.png', url: 'https://propy.com/browse/' }]} />
      <Rollings list={[{ id: 0, img: 'https://user-images.githubusercontent.com/6345000/198515360-97d870be-84d0-48f7-915a-16a35f6d78fb.png', url: 'https://www.sandbox.game/en/snoopdogg/' }]} />
      <Rollings
        list={[
          {
            id: 0,
            img: 'https://user-images.githubusercontent.com/6345000/198515368-2082387e-fee2-4a15-b9f5-ac920778cda3.png',
            url: 'https://opensea.io/collection/be-vee-summer-collection'
          }
        ]}
      />
      <ScrollWrap bg={'#F5F5F5'} title="Trending in Market" className={'wrap-trending-market'} moreLink={'/market'}>
        <View align="center" justify="center" spacing={22}>
          {collection.map((item: any, idx: number) => (
            <div key={idx} onClick={() => moveToExplore(item.uid, item.name)}>
              <CardBgAvatar
                name={item.name}
                bg={item.imageUrl}
                avatar={item.imageUrl}
                cryptoIcon={item.cryptoIcon}
                content={
                  <View block justify="space-between" padding={'0 14'}>
                    <View direction="column" justify="center" align="center">
                      <p className={'label'}>Floor Price</p>
                      <Price justify="center" icon={item.cryptoIcon} price={item.site} fontSize={14} />
                    </View>
                    <View direction="column" justify="center" align="center">
                      <p className={'label'}>Items</p>
                      <Price justify="center" price={item.itemCount} fontSize={14} />
                    </View>
                    <View direction="column" justify="center" align="center">
                      <p className={'label'}>Total Volume</p>
                      <Price justify="center" icon={item.cryptoIcon} price={item.uid} fontSize={14} />
                    </View>
                  </View>
                }
              />
            </div>
          ))}
        </View>
      </ScrollWrap>
      <Section title="HOT Community" className={'wrap-hot-community'} moreLink={'/community'}>
        <div style={{ marginBottom: 26 }}>
          <ScrollWrap padding={false}>
            <Tab style={{ height: 40 }} active={activeCommunityTab} padding={false}>
              {COMMUNITY_CTG.map((item, index) => (
                <Tab.Item key={index} value={item} circle onClick={() => handleCommunityTab(item)} />
              ))}
            </Tab>
          </ScrollWrap>
        </div>
        {HOT_COMMUNITY_LIST[`${activeCommunityTab.toLocaleLowerCase()}`].map((item: any, index: number) => (
          <CommunityItem {...item} key={index} />
        ))}
      </Section>
      <ScrollWrap title="Browse by Category" className={'browse-ctg'}>
        {CTG.map((item, index) => (
          <ThumbTitle key={index} {...item} />
        ))}
      </ScrollWrap>

      <FlashNews />

      <ScrollWrap bg={'#fff'} title="Article &amp; Disclosure" className={'wrap-article'} moreLink={`/articles/${articleList[0]?.uid}`}>
        <View align="center" justify="center" spacing={10}>
          {articleList.map((item: any, idx: any) => (
            <ArticleItem key={idx} {...item} onClick={() => navigate(`/articles/${item.uid}`)} />
          ))}
        </View>
      </ScrollWrap>

      <Banners list={AD_LIST} />

      <ScrollWrap title="Scam Alert" className={'scamalert'} moreLink="/scam">
        <View align="center" justify="center" spacing={10}>
          {scamAlert.length > 0 &&
            scamAlert.map((item: any, idx: number) => (
              <div key={idx} className={'card-wrap'}>
                <ListCardComponent {...item} />
              </div>
            ))}
        </View>
      </ScrollWrap>
      <ScrollWrap
        className={'wrap-top-seller'}
        moreLink={'/rank/seller'}
        title={
          <div>
            <span>Top Sellers in</span>
            <Select suffixIcon={<SelectDownArrowIcon />} bordered={false} defaultValue={topSellerDate} onChange={handleChangeSeller}>
              {rankCtg.map((item: any, index: number) => (
                <Option key={index} value={`${item.uid}`}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        }
      >
        {rankSeller.length > 0 &&
          rankSeller.map((item: any, index: number) => {
            if (index > 11) return false;
            return <CollectionItem {...item} rank={index + 1} key={index} />;
          })}
      </ScrollWrap>
      <Section title="Trending in Category" className={'ctgTrending'} style={{ backgroundColor: '#F5F5F5' }}>
        <div style={{ marginBottom: 26 }}>
          <ScrollWrap padding={false} bg={'#F5F5F5'}>
            <Tab style={{ height: 40 }} active={activeTab} padding={false}>
              {collectionCategory.length > 0 && collectionCategory.map((item: any, index) => <Tab.Item key={index} value={item.name} circle onClick={() => handleTradingTab(item.name)} />)}
            </Tab>
          </ScrollWrap>
        </div>
        <Carousel ref={tradingSlider}>
          {tabCollection.length > 0 && tabCollection.map((item: any, index: number) => <AuthorItemComponent {...item} onClick={() => navigate(`/explore/${item.uid}`)} key={index} />)}
        </Carousel>
      </Section>
      <ScrollWrap title="NVIA A to Z" className={'qna'}>
        {faq.map((item: any, index: number) => (
          <QnaItem {...item} key={index} />
        ))}
      </ScrollWrap>
    </Wrap>
  );
};

export default HomeView;

const Wrap = styled.div`
  position: relative;
  padding-top: 4px;
  color: ${p => p.theme.COLORS.TEXT_PRIMARY};
  ${p => p.theme.TEXTS.BOARD_CONTENT};

  .wrap-trending-market {
    .label {
      font-weight: 400;
      font-size: 10px;
      color: #717171;
      text-align: center;
    }
  }

  .browse-ctg {
    background: #fff;

    a {
      font-weight: 800;
      font-size: 16px;
      letter-spacing: -1px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }

  .wrap-top-seller {
    & > div {
      flex-direction: column;
      height: 250px;
      flex-wrap: wrap;
    }
  }

  .wrap-top-item,
  .wrap-top-seller {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
    .ant-select-selection-item {
      color: #0085ff;
      text-decoration: underline;
      font-weight: 800;
      font-size: 16px;
      padding-right: 24px;
    }
    .ant-select-arrow {
      transform-origin: 50% 37%;
      top: 43%;
      path {
        fill: none;
      }
    }
    .ant-select-open {
      .ant-select-arrow {
        transform: rotate(-180deg);
      }
    }
  }

  .ctgTrending {
    padding: 24px 20px 60px;
    .item-list-tag {
      & + span {
        margin-left: 8px;
      }
    }

    & .ant-carousel {
      & .slick-dots-bottom {
        bottom: -23px;
        width: 100%;
        margin: 0;

        & > li {
          width: 8px !important;
          height: 8px !important;
          border-radius: 50%;
          overflow: hidden;

          &.slick-active {
            width: 26px !important;
            border-radius: 70px;
          }

          & > button {
            width: 100%;
            height: 100%;
            background: #c4c4c4 !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }

  .scamalert .card-wrap {
    width: 305px;
    height: 312px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    overflow: hidden;

    & > div {
      width: 100%;
      border-bottom: 0;
      margin-bottom: 0;

      .subject {
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: 500;
        height: 36px;
        line-height: 36px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .contents {
        > div:first-of-type + div > div:first-of-type {
          display: flex;
          align-items: center;
          width: 100%;
          height: 126px;
          line-height: 24px !important;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;

          + div {
            > div:first-of-type {
              > div {
                width: 249px;
              }
            }
            .reaction-container {
              border-top: 0 none !important;
              gap: 4px !important;
            }
          }
        }
      }
    }
  }
`;

const SearchBig = styled.div`
  margin-bottom: 25px;
  padding: 0 20px;

  .wrap {
    position: relative;
    padding: 12px;
    background: rgb(255, 0, 0);
    background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 214, 1) 51%, rgba(0, 29, 132, 1) 100%);
  }

  input {
    display: block;
    padding: 12px;
    width: 100%;
    height: 52px;
    font-size: 16px;
    line-height: 26px;
    border: 0 none;
    background: #fff;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .icon_search {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  p {
    margin-top: 5px;
    padding: 0 15px;
    font-size: 11px;
    line-height: 26px;

    color: rgba(0, 0, 0, 0.5);

    strong {
      color: #000;
      font-weight: bold;
    }
  }
`;
